import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { claimJob, ID, Job } from "@api";

import { usePointDate } from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { useWindowEvent } from "@utils/event";
import { composel } from "@utils/fn";
import { newID } from "@utils/id";
import { toRef } from "@utils/property-refs";
import { hashable } from "@utils/serializable";
import { minutesAgo, now, secondsAgo, useTick } from "@utils/time";

import { clearJob, setClaiming, setJob, setJobs, setRunning } from "./actions";
import { allJobs, JobAtom, JobStoreAtom, MyJobQueueAtom } from "./atoms";
import { getJobQueueLoader } from "./queries";

export function useCheckForNewJobs() {
  const [store, setStore] = useRecoilState(JobStoreAtom);
  const check = useCallback(async () => {
    return await getJobQueueLoader((jobs) => setStore(setJobs(jobs)));
  }, []);

  return useMemo(() => ({ check, lastChecked: store.lastChecked }), []);
}

export function useJob(id: ID) {
  return useRecoilValue(JobAtom(id));
}

export function useJobQueue() {
  const jobs = useRecoilValue(allJobs);
  const { check, lastChecked } = useCheckForNewJobs();

  // Check every 10 minutes if we have new jobs
  useEffect(() => {
    if (!lastChecked || usePointDate(lastChecked, (d) => minutesAgo(d) > 10)) {
      check();
    }
  }, [useTick("1 minute")]);

  return { jobs, check };
}

export function useMyQueue() {
  const [init, setInit] = useState(now());
  // When window is refocused, set init to now
  useWindowEvent("focus", () => setInit(now()));
  const isIdle = useMemo(
    () => secondsAgo(init) > 10,
    [init, useTick("2.5 seconds")]
  );

  return useRecoilValue(
    MyJobQueueAtom(hashable({ isIdle, lockKey: LOCK_KEY }))
  );
}

export function useRunningJob() {
  return useRecoilValue(JobStoreAtom).running;
}

export function useQueueNextJob() {
  const myQueue = useMyQueue();
  const store = useRecoilValue(JobStoreAtom);
  const next = useMemo(() => myQueue?.[0], [myQueue?.[0]?.id]);
  const { claim } = useClaimJob();

  useAsyncEffect(async () => {
    if (store.claiming || store.running || !next) {
      return;
    }

    await claim(next);
  }, [next?.id, store.claiming?.id, store.running?.id]);

  return store.running;
}

// Lock key prevents multiple tabs from running the same job
export const LOCK_KEY = newID();

export function useClaimJob() {
  const [store, setStore] = useRecoilState(JobStoreAtom);

  const claim = useCallback(
    async (job: Job) => {
      if (store.claiming) {
        throw new Error("Already claiming job.");
      }

      if (store.running) {
        throw new Error("Can only claim/run one job at a time.");
      }

      setStore(setClaiming(job));

      try {
        const updated = await claimJob(toRef(job).id, LOCK_KEY);

        if (!updated) {
          throw new Error("Job not found.");
        }

        if (updated.lockKey === LOCK_KEY) {
          setStore(setRunning(updated));
        } else {
          setStore(composel(setJob(updated), setClaiming(undefined)));
        }
      } catch (err) {
        // Job already claimed by someone else, ignore the error
        setStore(composel(clearJob(job.id), setClaiming(undefined)));
      }
    },
    [store.claiming, store.running]
  );

  return useMemo(() => ({ claim }), [claim]);
}
