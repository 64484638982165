import { subDays } from "date-fns";
import { keys, reduce } from "lodash";

import { usePointDate } from "@utils/date-fp";
import { Maybe } from "@utils/maybe";
import { now } from "@utils/now";
import { setDirty } from "@utils/object";
import { isGreaterThan } from "@utils/time";

import { RecentsState } from "./atoms";

// Remove all recents that are more than 7 days old
export const removeOldRecents = (state: Maybe<Partial<RecentsState>>) => {
  const cutoff = subDays(now(), 7);
  return reduce(
    keys(state?.lookup),
    (acc, id) => {
      const val = state?.lookup?.[id];
      if (!!val && usePointDate(val, (d) => !!d && isGreaterThan(d, cutoff))) {
        setDirty(acc.lookup, id, val);
      }
      return acc;
    },
    { ...state, lookup: {} } as RecentsState
  );
};
