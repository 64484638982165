import { useMemo } from "react";

import { Calendar, PropertyRef } from "@api";

import { useLazyPropertyValue } from "@state/properties";

import { whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";

import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { PropertyValueStack } from "@ui/property-value-stack";
import { RelationIcon } from "@ui/relation-label";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";

import { ListItemOpts, UIEngine } from "../types";
import { CalendarCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";

export { CalendarCreateDialog } from "./create-dialog";

export const CalendarEngine: UIEngine<Calendar> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    const { value } = useLazyPropertyValue(item, {
      field: "owner",
      type: "relation",
    });
    const owner = useMemo(() => value.relation, [value]);

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <Label icon={when(item.icon, iconFromString)} text={item.name} />
            <HStack>
              {owner && <Icon icon={<RelationIcon relation={owner} />} />}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListCard {...props}>
          <VStack className={styles.bar}>
            {when(iconFromString(item.icon), (icon) => (
              <Icon size="large" icon={icon} />
            ))}

            <SpaceBetween>
              <Label className={styles.title}>{item.name || "Calendar"}</Label>
            </SpaceBetween>
          </VStack>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: CalendarListItem,
  asCreateDialog: CalendarCreateDialog,
};

const DEFAULT_PROPS: PropertyRef<Calendar>[] = [
  { field: "status", type: "status" },
  { field: "start", type: "date" },
  { field: "end", type: "date" },
];

function CalendarListItem(props: ListItemOpts<Calendar>) {
  const { item, className, showProps, onChange } = props;

  return (
    <EntityContextMenu
      entity={item}
      selection={props.selection}
      setSelection={props.setSelection}
    >
      <SelectableListItem {...props} className={cx(styles.listItem, className)}>
        <SpaceBetween className={styles.upper}>
          <HStack className={styles.middle} gap={4}>
            {when(iconFromString(item.icon), (i) => (
              <Icon icon={i} />
            ))}
            <EditableText
              key={item.id}
              text={item.name || ""}
              disabled={true}
              placeholder="Untitled calendar"
              blurOnEnter={true}
              onChange={(v) =>
                onChange?.(toMutation(item, { field: "name", type: "text" }, v))
              }
            />
          </HStack>

          <PropertyValueStack
            className={styles.rowDetails}
            justify="flex-end"
            gap={2}
            item={item}
            props={whenEmpty(showProps, DEFAULT_PROPS)}
            hideEmpty={props.hideEmpty}
            onChange={onChange}
          />
        </SpaceBetween>
      </SelectableListItem>
    </EntityContextMenu>
  );
}
