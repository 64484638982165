import { ReactNode } from "react";

import { DatabaseID, PropertyDef, PropertyRef } from "@api/types";

import { useLazyPropertyDef } from "@state/properties";

import { when } from "@utils/maybe";
import { isPropertyDef, toFieldName } from "@utils/property-refs";

import { SpaceBetween } from "@ui/flex";
import { Label, Props as LabelProps } from "@ui/label";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Text } from "@ui/text";

type DefLabelProps = {
  prop: PropertyDef;
  subtitle?: string;
  actions?: ReactNode;
} & LabelProps;

type RefLabelProps = {
  prop: PropertyRef;
  source: DatabaseID;
  subtitle?: string;
  actions?: ReactNode;
} & LabelProps;

export const PropertyDefLabel = ({
  prop,
  icon,
  subtitle,
  iconRight,
  actions,
}: DefLabelProps) => (
  <Label
    subtle={!(prop.label || toFieldName(prop))}
    fit="content"
    icon={
      icon === false
        ? undefined
        : icon ?? when(prop, (r) => <PropertyTypeIcon {...r} />)
    }
    iconRight={iconRight}
  >
    <SpaceBetween>
      <Text subtle={!prop}>{prop.label ?? toFieldName(prop)}</Text>
      {actions ?? (!!prop && subtitle && <Text subtle>{subtitle}</Text>)}
    </SpaceBetween>
  </Label>
);

export const PropertyRefLabel = ({ prop, source, ...props }: RefLabelProps) => {
  const def = isPropertyDef(prop) ? prop : useLazyPropertyDef(source, prop);

  if (!def && !isPropertyDef(prop)) {
    return (
      <Label
        fit="content"
        icon={
          props.icon === false
            ? undefined
            : props.icon ?? <PropertyTypeIcon type={prop.type} />
        }
      >
        {prop.field}
      </Label>
    );
  }

  return <PropertyDefLabel prop={def || (prop as PropertyDef)} {...props} />;
};
