import { HasLocation } from "@api";

import { useOpenAppCommands } from "@state/app";
import { useUpdateEntity } from "@state/generic";

import { Button } from "@ui/button";
import { Container, Props as ContainerProps } from "@ui/container";
import { CopyLinkButton } from "@ui/copy-link-button";
import { HStack, SpaceBetween } from "@ui/flex";
import { FollowButton } from "@ui/follow-button";
import { KeySlash } from "@ui/icon";

import { LocationBreadcrumb } from "./location-button";
import { PackageTag } from "./package-label";

type Props = {
  entity: HasLocation;
  padding?: ContainerProps["padding"];
  showFollowing?: boolean;
  showTag?: boolean;
};

export const EntityHeaderBar = ({
  entity,
  padding,
  showFollowing,
  showTag,
}: Props) => {
  const mutate = useUpdateEntity(entity.id);
  const openCmdK = useOpenAppCommands(entity);

  return (
    <Container padding={padding ?? "none"}>
      <SpaceBetween>
        <Container
          inset="left"
          padding="none"
          fit="content"
          stack="horizontal"
          gap={0}
        >
          <LocationBreadcrumb location={entity.location} variant="full" />
        </Container>

        <HStack gap={3}>
          <HStack gap={2}>
            {showTag !== false && (
              <PackageTag
                type={entity.source.type}
                scope={entity.source.scope}
              />
            )}
            <CopyLinkButton entity={entity} size="small" />
            {showFollowing !== false && (
              <FollowButton
                variant="icon-only"
                entity={entity}
                mutate={mutate}
              />
            )}
            <Button
              size="small"
              icon={KeySlash}
              subtle
              onClick={() => openCmdK()}
            >
              Modify
            </Button>
          </HStack>
        </HStack>
      </SpaceBetween>
    </Container>
  );
};
