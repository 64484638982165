import { filter, map } from "lodash";
import { useMemo, useState } from "react";

import { Entity, HasRefs } from "@api";

import { useFormsForLocation } from "@state/form";
import { useLazyEntities } from "@state/generic";

import { useShowMore } from "@utils/hooks";
import { usePushTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";
import { extractTeam } from "@utils/scope";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";

import { FormCreateDialog } from "./create-dialog";

export const TemplateFormsPane = ({ template }: { template: HasRefs }) => {
  const pushTo = usePushTo();
  const forms = useLazyEntities<"form">(template?.refs?.forms);
  const engine = useEngine("form");
  const [creating, setCreating] = useState(false);
  const formDefaults = useMemo(
    () => ({
      location: extractTeam(template.source.scope),
      useTemplate: toRef(template),
    }),
    [template?.id]
  );

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader
        title="Connected Forms"
        description="Forms that use this template to create work."
        actions={
          <Button
            size="small"
            variant={!forms?.length ? "primary" : "secondary"}
            onClick={() => setCreating(true)}
          >
            Create Form
          </Button>
        }
      />
      {creating && (
        <FormCreateDialog
          defaults={formDefaults}
          onCancel={() => setCreating(false)}
          onSaved={(form) => {
            setCreating(false);
            pushTo([form, "builder"]);
          }}
        />
      )}
      <Container size="half">
        <Menu>
          <MenuGroup>
            {map(forms, (e) =>
              render(engine.asMenuItem, {
                key: e.id,
                item: e,
                onOpen: pushTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </Container>
    </Sheet>
  );
};

export const FormsCreatingHere = ({ item }: { item: Entity }) => {
  const pushTo = usePushTo();
  const allForms = useFormsForLocation(item);
  const creatingHere = useMemo(
    () => filter(allForms, (f) => !!f.inLocation?.includes(item.id)),
    [allForms]
  );
  const { visible: forms, hasMore, showMore } = useShowMore(creatingHere, 3);
  const engine = useEngine("form");

  if (!forms.length) {
    return <></>;
  }

  return (
    <Menu>
      <MenuGroup label="Submit a Form" divider={false}>
        {map(forms, (cal) =>
          render(engine.asMenuItem, {
            key: cal.id,
            item: cal,
            onOpen: pushTo,
          })
        )}
        {hasMore && <ShowMoreMenuItem onClick={showMore} />}
      </MenuGroup>
    </Menu>
  );
};
