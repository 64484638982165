import { map } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { getUrlMeta, Link } from "@api";

import { linkName, useAiUseCase } from "@state/ai";

import { replace } from "@utils/array";
import { cx } from "@utils/class-names";
import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { all } from "@utils/promise";
import { isUrl, toDomain } from "@utils/url";

import { VStack } from "@ui/flex";
import { Icon, ImageIcon, LinkAlt, PlusIcon, SpinnerIcon } from "@ui/icon";
import { TextInput } from "@ui/input";

import { Button } from "./button";
import { TextSmall } from "./text";

import styles from "./input-link.module.css";

export interface Props {
  link: Maybe<Link>;
  className?: string;
  placeholder?: string;
  onValidLink?: Fn<Link, boolean>;
  onChanged?: Fn<Link, void>;
}

export interface MultiProps {
  links: Maybe<Link[]>;
  className?: string;
  placeholder?: string;
  onValidLink?: Fn<Link, boolean>;
  onChanged?: Fn<Link[], void>;
}

export const LinkInput = ({
  link: _link,
  className,
  placeholder,
  onChanged: _onChanged,
  onValidLink,
}: Props) => {
  const enriching = useRef<string>();
  const [suggested, setSuggested] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(
    _link || { url: "", text: "", icon: undefined }
  );
  const { run, loading: aiLoading } = useAiUseCase(linkName);

  const onChanged = useCallback(
    (link: Link) => {
      if (link?.url === _link?.url && link?.text === _link?.text) {
        return;
      }

      _onChanged?.({
        ...link,
        text: link.text || suggested || toDomain(link.url) || "New Link",
      });
    },
    [_link]
  );

  const ready = useMemo(() => !!link.url && isUrl(link.url), [link.url]);

  const debouncedOnChanged = useDebouncedCallback(onChanged, 500, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    debouncedOnChanged(link);
  }, [link]);

  useAsyncEffect(async () => {
    const url = link.url;
    if (url.startsWith("http")) {
      setLoading(true);
      enriching.current = url;
      const [metadata, aiTitle] = await all([
        getUrlMeta(url),
        run({ link: url }),
      ]);

      if (enriching?.current !== url) {
        return;
      }

      const title =
        aiTitle && (metadata?.confidence || 0) < 0.8 ? aiTitle : metadata?.text;

      const validated = onValidLink?.({
        ...link,
        icon: metadata?.icon,
        text: title,
      });

      if (!validated === false) {
        setLink({ url: "" });
        setSuggested("");
      } else {
        setLink({ ...link, text: link.text || title, icon: metadata?.icon });
        setSuggested(title || "");
      }

      setLoading(false);
      enriching.current = undefined;
    }
  }, [link.url, enriching]);

  return (
    <VStack className={className} gap={0}>
      {ready && (
        <TextInput
          className={styles.inputTop}
          icon={
            <Icon
              icon={
                !!enriching?.current ? (
                  SpinnerIcon
                ) : link.icon ? (
                  <ImageIcon url={link.icon} />
                ) : (
                  LinkAlt
                )
              }
            />
          }
          // Hide the text input if the suggested text is the same as the link text
          value={(suggested && link.text === suggested ? "" : link.text) || ""}
          autoFocus={!!link.url}
          onChange={(text) => setLink({ ...link, text: text || "" })}
          updateOn="blur"
          placeholder={
            suggested ||
            (loading ? "Loading link information..." : "Link name...")
          }
        />
      )}

      <TextInput
        className={cx(ready && styles.inputBottom)}
        icon={LinkAlt}
        value={link.url}
        autoFocus={!link.url}
        onChange={(url) =>
          setLink(
            url ? { ...link, url } : { url: "", text: "", icon: undefined }
          )
        }
        updateOn="change"
        placeholder={placeholder || "Paste a link..."}
      />
    </VStack>
  );
};

export const LinksInput = ({
  links,
  className,
  onChanged,
  onValidLink,
  placeholder,
}: MultiProps) => {
  return (
    <VStack>
      {map(links, (link, i) => (
        <LinkInput
          key={i}
          link={link}
          className={className}
          placeholder={placeholder}
          onChanged={(link) => onChanged?.(replace(links || [], i, link))}
          onValidLink={onValidLink}
        />
      ))}
      <Button
        icon={PlusIcon}
        iconSize="small"
        subtle
        inset
        size="tiny"
        onClick={() => onChanged?.([...(links || []), { url: "" }])}
      >
        <TextSmall subtle>Add Link</TextSmall>
      </Button>
    </VStack>
  );
};
