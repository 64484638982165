import { atomFamily } from "recoil";

import { ID } from "@api";

import { indexedDBStorageForSimpleStore } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { PointDate } from "@utils/date-fp";
import { Maybe, SafeRecord } from "@utils/maybe";

import { removeOldRecents } from "./utils";

export interface RecentsState {
  lookup: SafeRecord<ID, PointDate>;
  updatedAt: Maybe<PointDate>;
}

export const WorkspaceRecentsAtom = atomFamily<RecentsState, ID>({
  key: "WorkspaceRecentsAtom",
  default: { lookup: {}, updatedAt: undefined },
  effects: (wid) => [
    indexedDBStorageForSimpleStore(wid, "recents", removeOldRecents),
  ],
});

export const RecentsAtom = WorkspaceWrappedAtom(
  "RecentsAtom",
  WorkspaceRecentsAtom
);
