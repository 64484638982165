import { isString, orderBy } from "lodash";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { ID, Ref, RefOrID } from "@api";

import { toRef } from "@utils/property-refs";
import { useArrayKey } from "@utils/react";

import { addToRecents } from "./actions";
import { RecentsAtom } from "./atoms";
import { recentsForScope, recentsList, recentsListAsRefs } from "./selectors";

const toId = (id: ID | Ref): ID => toRef(id).id;

export const useAddToRecents = (thing: ID | Ref) => {
  const setStore = useSetRecoilState(RecentsAtom);
  useEffect(() => {
    setStore(addToRecents(toId(thing)));
  }, [thing]);
};

export const useIsRecent = (id: ID | Ref) => {
  const { lookup } = useRecoilValue(RecentsAtom);

  return useMemo(() => !!lookup[toId(id)], [lookup, id]);
};

export const useRecents = () => {
  return useRecoilValue(recentsList);
};

export const useRecentRefs = () => {
  return useRecoilValue(recentsListAsRefs);
};

export const useRecentsForScope = (scope: ID) => {
  return useRecoilValue(recentsForScope(scope));
};

export const useSortByRecentlyViewed = <T extends RefOrID>(things: T[]) => {
  const recents = useRecoilValue(RecentsAtom);

  return useMemo(
    () => orderBy(things, (t) => recents.lookup[toId(t)] || "0000", "desc"),
    [useArrayKey(things, (t) => toRef(t).id), things, recents]
  );
};
