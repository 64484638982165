import { atomFamily } from "recoil";

import { ID, Request } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type RequestStoreState = StoreState<Request>;

export const WorkspaceRequestStoreAtom = atomFamily<RequestStoreState, ID>({
  key: "WorkspaceRequestStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "request" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "request")],
});

export const RequestStoreAtom = WorkspaceWrappedAtom(
  "RequestStoreAtom",
  WorkspaceRequestStoreAtom
);
