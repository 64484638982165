import { ReactNode } from "react";

import { Fn } from "@utils/fn";

import { Icon, SpinnerIcon } from "@ui/icon";
import { Modal } from "@ui/modal";
import { Text } from "@ui/text";

import { Centered } from "./flex";

type Props = {
  message: string;
  onClose?: Fn<void, void>;
  children?: ReactNode;
};

export const LoadingModal = ({ children, message, onClose }: Props) => {
  return (
    <Modal autoPosition open={true} onOpenChanged={() => onClose?.()}>
      <Centered gap={10}>
        <Icon size="large" icon={SpinnerIcon} />
        <Text subtle>{message}</Text>
        {children}
      </Centered>
    </Modal>
  );
};
