import { getDay, startOfDay } from "date-fns";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import {
  Content,
  DatabaseID,
  Entity,
  EntityType,
  hasDates,
  ID,
  isTemplate,
  Period,
  PropertyMutation,
  PropertyValueRef,
  Ref,
  Schedule,
  Template,
  toTitleOrName,
  Update,
} from "@api";

import { useLazyProperties } from "@state/properties";
import {
  useCreateFromObject,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
  useSource,
} from "@state/generic";
import { ScheduleStoreAtom, useScheduleEntityType } from "@state/schedule";
import {
  toDayOfPeriod,
  toNextDate,
  toOverrides,
  toSentence,
} from "@state/schedule/utils";
import { useEntityLabels } from "@state/settings";

import { ensureMany, OneOrMany } from "@utils/array";
import { formatDay } from "@utils/date";
import { fromCalDate, toCalDate, useISODate } from "@utils/date-fp";
import { composel, Fn } from "@utils/fn";
import { isLocalID, maybeTypeFromId } from "@utils/id";
import { Maybe, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { omitEmpty as omitEmptyObj } from "@utils/object";
import { asMutation, asTempUpdate, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { toChildLocation } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button, Props as ButtonProps } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { Dropdown, Props as DropdownProps, useOpenState } from "@ui/dropdown";
import { EditableText } from "@ui/editable-text";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { useMutate } from "@ui/mutate";
import { OverridesTemplateConfigure } from "@ui/template-configure";
import { Text } from "@ui/text";

import { ScheduleRepeatOptions } from "./repeat-options";

import styles from "./editor.module.css";

interface EditProps {
  schedule: Schedule;
  instanceId?: ID;
  templateId?: ID;
  defaults?: Partial<Schedule>;
  source: DatabaseID;
  onChanged?: Fn<Maybe<Ref>, void>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
  showTemplate?: boolean;
  showNext?: boolean;
  pageId?: string;
}

export type Props = Partial<DropdownProps> &
  Omit<EditProps, "schedule"> & {
    schedule: Maybe<Ref>;
    parentId: Maybe<ID>;
    children?: ReactNode;
  };

type TemplateMode = "use-template" | "use-overrides";

export const ScheduleEditor = ({
  schedule,
  templateId,
  showTemplate,
  showNext = true,
  onChanged,
  onSaved,
  onCancel,
  source,
  pageId,
}: Omit<EditProps, "defaults">) => {
  const [saving, setSaving] = useState(false);
  const isCreating = isLocalID(schedule?.id);
  const childType = useScheduleEntityType(schedule);
  const childSource = useSource(childType, schedule.source.scope);
  const toLabel = useEntityLabels(schedule?.source.scope, {
    plural: false,
    case: "lower",
  });
  const queue = useQueueUpdates(pageId);

  const { changes, save, rollback } = useLocalChanges(
    schedule.id || "",
    ScheduleStoreAtom
  );
  const reccomended = useMemo(
    () => (templateId ? "use-template" : "use-overrides"),
    [templateId]
  );
  const [templateMode, setTemplateMode] = useState<TemplateMode>(reccomended);

  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>);

  const handleDiscard = useCallback(() => {
    rollback();
    onCancel?.();
  }, [rollback]);

  const handleSave = useCallback(
    (template?: Ref) => {
      setSaving(true);

      if (isCreating) {
        // Publish schedules that are not published on create
        save([
          asUpdate(schedule, [
            asMutation({ field: "status", type: "status" }, { id: "ACT" }),
          ]),
        ]);
      } else {
        save();
      }

      onChanged?.(toRef(schedule));

      if (isCreating && template) {
        onSaved?.(template);
      }

      setSaving(false);
    },
    [save, templateMode, onChanged]
  );

  // When creating a new schedule, set template mode depending of whether a template or instance was passed in
  useEffect(() => {
    setTemplateMode(reccomended);
  }, [reccomended]);

  if (!schedule) {
    return <>Loading...</>;
  }

  return (
    <Container stack="vertical" gap={10} padding="none">
      <ScheduleRepeatOptions mutate={handleChange} schedule={schedule} />

      {showTemplate !== false && (
        <CollapsibleSection
          title={`${toLabel(childType, { case: "title" })} details`}
          defaultOpen={showTemplate === true}
        >
          {childSource && (
            <OverridesTemplateConfigure
              template={schedule.useTemplate}
              overrides={safeAs<PropertyValueRef[]>(schedule.overrides)}
              source={childSource}
              onChange={handleChange}
            />
          )}
        </CollapsibleSection>
      )}

      <SpaceBetween>
        {showNext && (
          <Text subtle>
            Next scheduled for{" "}
            {when(
              fromCalDate(schedule.next || toNextDate(schedule)),
              formatDay
            ) || "unkown."}
          </Text>
        )}
        {!!changes?.length ? (
          <HStack>
            <Button subtle onClick={handleDiscard}>
              Discard
            </Button>
            <Button
              variant="primary-alt"
              onClick={() => handleSave()}
              loading={saving}
            >
              {isCreating ? "Schedule work" : "Save changes"}
            </Button>
          </HStack>
        ) : onCancel ? (
          <Button variant="secondary" onClick={() => onCancel?.()}>
            Close
          </Button>
        ) : undefined}
      </SpaceBetween>
    </Container>
  );
};

export const ScheduleEditorPopover = ({
  schedule: _schedule,
  parentId,
  source,
  children,
  open: _open,
  setOpen: _setOpen,
  defaults: _defaults,
  onSaved,
  onChanged: onChange,
  ...props
}: Props) => {
  const pageId = usePageId();
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const parent = useLazyEntity(parentId);
  const [editingId, setEditingId] = useState<Maybe<ID>>(_schedule?.id);
  const schedule = useLazyEntity<"schedule">(editingId);
  const isInstance = !when(parent, isTemplate);
  const isCreating = useMemo(() => !_schedule?.id, [_schedule?.id]);
  const childSource = parent?.source;
  const childProps = useLazyProperties(childSource);
  const create = useCreateFromObject("schedule", source?.scope, pageId, true);
  const templateId = !isInstance ? parent?.id : undefined;
  const instanceId = isInstance ? parent?.id : undefined;
  const mutate = useMutate();

  // If the entity is template then create the schedule within in (since using this template will create a new shcedule)
  const scheduleSource = useMemo(
    (): DatabaseID<"schedule"> => ({
      type: "schedule",
      scope: templateId
        ? toChildLocation(source.scope, templateId)
        : source.scope,
    }),
    [source]
  );

  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && mutate(asTempUpdate(schedule, changes) as Update<Entity>);

  // used to create a local instance below
  const toDefaults = useCallback(() => {
    const name = parent && !isTemplate(parent) ? toTitleOrName(parent) : "";
    // When creating from instance, use from date of instance
    const from =
      when(parent, (p) =>
        hasDates(p) ? p.start || p.end : safeAs<Content>(p)?.publish
      ) || toCalDate(now(), "local");

    if (parent && isInstance) {
      // Set from & last to current instance date
      return {
        name,
        from: from,
        last: from,
        daysOfPeriod: when(from, (d) => [useISODate(d, getDay)]),
        useTemplate: undefined,
        instances: when(instanceId, composel(toRef, ensureMany)),
        overrides: toOverrides(parent, childProps),
        ..._defaults,
      };
    }

    // Parent that is trying to be repeated is a template
    if (parent && !isInstance) {
      return {
        name,
        from: undefined,
        last: undefined,

        status: { id: "ACT" },
        template: "nested" as Template,
        useTemplate: when(templateId, toRef),
        instances: [],
        ..._defaults,
      };
    }

    return {
      name,
      ..._defaults,
    };
  }, [parent, _defaults]);

  const handleSaved = useCallback(
    (ref: Ref) => {
      if (isCreating) {
        onSaved?.(ref);
      }
      setOpen(false);
    },
    [isCreating, parent, schedule, childProps]
  );

  const handleCancel = useCallback(() => {
    setEditingId(_schedule?.id);
    setOpen(false);
    props.onCancel?.();
  }, []);

  // Create a local schedule (unpersisted) if no ref is passed in
  useEffect(() => {
    if (!editingId && open && create) {
      const from = toCalDate(startOfDay(now()), "local");
      const [saved] = create([
        {
          from: from,
          period: Period.Week,
          daysOfPeriod: [toDayOfPeriod(from, Period.Week)],
          frequency: 1,
          precreate: 1,
          timeOfDay: undefined,

          entity:
            when(instanceId || templateId, (id) =>
              maybeTypeFromId<EntityType>(id)
            ) || "task",

          location: scheduleSource.scope,
          source: scheduleSource,
          ...omitEmptyObj(toDefaults()),
        },
      ]);

      setEditingId(saved.id);
    }
  }, [editingId, open, create]);

  return (
    <Dropdown
      {...props}
      open={open}
      setOpen={setOpen}
      closeOnEscape={false}
      closeOnClickAway={false}
      className={{ popover: styles.dropdown }}
      position="bottom-right"
      trigger={
        children || (
          <Button subtle size="small">
            Schedule
          </Button>
        )
      }
    >
      {open && schedule && (
        <Container size="half" stack="vertical" gap={10}>
          <SpaceBetween align="flex-start">
            <VStack gap={0} fit="container">
              <EditableText
                text={schedule?.name || ""}
                onChange={(name) =>
                  handleChange(
                    asMutation({ field: "name", type: "text" }, name)
                  )
                }
                updateOn="change"
                placeholder="Name this repeating work..."
              />
              <Text subtle>{toSentence(schedule)}</Text>
            </VStack>
          </SpaceBetween>

          <Divider direction="horizontal" />

          {open && (
            <ScheduleEditor
              schedule={schedule}
              templateId={templateId}
              instanceId={instanceId}
              source={source}
              showTemplate={!isCreating}
              onChanged={onChange}
              onSaved={handleSaved}
              onCancel={handleCancel}
              pageId={pageId}
            />
          )}
        </Container>
      )}
    </Dropdown>
  );
};

export const ScheduleButton = ({
  schedule: ref,
  parent,
  subtle,
  ...props
}: { parent: Entity; schedule: Maybe<Ref> } & ButtonProps) => {
  const schedule = useLazyEntity<"schedule">(ref?.id);

  return (
    <ScheduleEditorPopover
      schedule={schedule || ref}
      parentId={parent.id}
      source={parent.source}
      portal={true}
    >
      <Button subtle size="small" {...props}>
        <SpaceBetween>
          <Text subtle={subtle}>
            {schedule ? toSentence(schedule) : "Setup a repeating schedule"}
          </Text>
        </SpaceBetween>
      </Button>
    </ScheduleEditorPopover>
  );
};
