import { filter, find, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  ID,
  isEntity,
  PropertyValueRef,
  Ref,
  VariableDef,
  Workflow,
} from "@api";

import { useLazyTemplates } from "@state/templates";
import { useStartWorkflow } from "@state/workflow/effects";
import { WorkflowRunnerModal } from "@state/workflow/runner-modal";
import { useActiveWorkspaceId } from "@state/workspace";

import { Fn } from "@utils/fn";
import { useShowMore } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import { fuzzyMatch } from "@utils/search";
import { ensureValues } from "@utils/variables";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { render, useEngine } from "@ui/engine";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { SearchInput } from "@ui/search-input";

import { CollectDialog } from "./workflow-collect-dialog";

export const RunWorkflowModal = ({
  entity,
  workflow,
  autoStart = !!workflow,
  onClose,
}: {
  entity: Maybe<Entity>;
  workflow?: Ref | Workflow;
  autoStart?: boolean;
  onClose: Fn<void, void>;
}) => {
  const wID = useActiveWorkspaceId();
  const allWorkflows = useLazyTemplates<"workflow">({
    type: "workflow",
    scope:
      entity?.source?.scope || safeAs<Workflow>(workflow)?.source.scope || wID,
  });
  const active = useMemo(
    () => filter(allWorkflows, (w) => w.status?.id === "AVL"),
    [allWorkflows, workflow?.id]
  );

  const [started, setStarted] = useState<Maybe<Ref>>();
  const [search, setSearch] = useState<Maybe<string>>();
  const filtered = useMemo(
    () =>
      !!search
        ? filter(active, (w) => fuzzyMatch(search, w.name || ""))
        : active,
    [active, search]
  );
  const workflows = useShowMore(filtered, 5);
  const { start, ready, starting, collecting, onCollected, onCancelled } =
    useStartWorkflow(entity, setStarted);
  const [selected, setSelected] = useState<Maybe<ID>>(workflow?.id);
  const engine = useEngine("workflow");

  useEffect(() => {
    if (workflows.visible.length === 1 && !!search) {
      setSelected(workflows.visible[0].id);
    } else if (!find(workflows.visible, { id: selected })) {
      setSelected(undefined);
    }
  }, [workflows.visible, search]);

  const handleStart = useCallback(
    () => when(find(allWorkflows, { id: selected }), start),
    [selected]
  );

  // If default is passed in and is in the list, then auto-start it
  useEffect(() => {
    if (autoStart && isEntity(workflow, "workflow")) {
      start(workflow);
    } else if (!!workflow && selected === workflow.id) {
      handleStart();
    }
  }, [autoStart]);

  // Avoid flashing the dialog if auto-starting
  if (autoStart && workflow && !(started || starting)) {
    return <></>;
  }

  if (started) {
    return <WorkflowRunnerModal id={started?.id} onClose={onClose} />;
  }

  if (starting) {
    return (
      <CollectDialog
        collect={collecting as PropertyValueRef<Entity>[]}
        source={starting.source}
        title={starting.name || "Workflow"}
        description="This workflow requires the below information to begin."
        submitLabel="Start"
        onCollected={(pv) => onCollected(ensureValues(pv))}
        onCancel={() => {
          onCancelled();
          onClose();
        }}
        onClose={onClose}
      />
    );
  }

  return (
    <Dialog
      title="Start a Workflow"
      description={
        <SearchInput search={search} setSearch={(s) => setSearch(s)} />
      }
      autoPosition
      onDismiss={onClose}
      actions={
        <Button
          variant="primary"
          disabled={!selected || !ready || !!starting}
          onClick={handleStart}
        >
          Start Workflow
        </Button>
      }
    >
      <Menu>
        <MenuGroup>
          {map(workflows.visible, (w) =>
            render(engine.asMenuItem, {
              key: w.id,
              item: w,
              selected: w.id === selected,
              onOpen: () => setSelected(w.id),
            })
          )}
          {workflows.hasMore && (
            <ShowMoreMenuItem
              count={workflows.moreCount}
              onClick={workflows.showMore}
            />
          )}
        </MenuGroup>
      </Menu>
    </Dialog>
  );
};
