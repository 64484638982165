import { filter, map, orderBy } from "lodash";
import { Fragment, useMemo } from "react";

import { Entity, HasRefs, ID, PropertyRef, Ref } from "@api";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { toScheduleField } from "@state/schedule";

import { useShowMore } from "@utils/hooks";
import { usePushTo } from "@utils/navigation";
import { toPropertyValueRef, toSortable } from "@utils/property-refs";

import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { VStack } from "@ui/flex";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { PaneHeader } from "@ui/pane-header";
import { useSyncPaneCount } from "@ui/pane-manager";
import { Sheet } from "@ui/sheet-layout";
import { Text, TextMedium } from "@ui/text";

import { ScheduleButton } from "./editor";

import styles from "./styles.module.css";

export const ScheduleInstancesPane = ({
  schedule: ref,
  instanceId,
  title,
}: {
  schedule: Ref;
  instanceId?: ID;
  title?: string;
}) => {
  const pushTo = usePushTo();
  const schedule = useLazyEntity<"schedule">(ref.id);
  const instances = useLazyEntities(schedule?.instances);
  const openInstance = useLazyEntity(instanceId);
  const engine = useEngine(instances?.[0]?.source.type || "task");
  const ordered = useMemo(() => {
    if (!schedule) {
      return [];
    }

    const dateField: PropertyRef = {
      field: toScheduleField(schedule),
      type: "date",
    };

    return orderBy(
      instances,
      (i) =>
        toSortable(toPropertyValueRef(i, dateField), schedule?.source.scope) ||
        i.createdAt,
      "asc"
    );
  }, [instances]);

  const showMore = useShowMore(ordered as Entity[], 6, false, "end");

  // Sync the count with the pane manager
  useSyncPaneCount(schedule?.instances?.length);

  if (!schedule || !instances?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader
        title={
          <VStack gap={0}>
            <TextMedium bold>{title || "Repeat Occurrences"}</TextMedium>
          </VStack>
        }
        actions={
          !!openInstance && (
            <ScheduleButton
              size="tiny"
              subtle={true}
              schedule={schedule}
              parent={openInstance}
            />
          )
        }
      />
      <Container size="half">
        <Menu>
          <MenuGroup>
            {!showMore.visible.length && (
              <MenuItem disabled>
                <Text subtle>No repeating occurrences</Text>
              </MenuItem>
            )}

            {showMore.hasMore && (
              <ShowMoreMenuItem
                text="Show earlier"
                count={showMore.moreCount}
                onClick={showMore.showMore}
              />
            )}
            {map(showMore.visible, (e, i) => (
              <Fragment key={e.id}>
                {render(engine.asMenuItem, {
                  item: e,
                  selected: e.id === instanceId,
                  showProps: [
                    { field: toScheduleField(schedule), type: "date" },
                  ],
                  onOpen: pushTo,
                })}
              </Fragment>
            ))}
          </MenuGroup>
        </Menu>
      </Container>
    </Sheet>
  );
};

export const TemplateSchedulesPane = ({ template }: { template: HasRefs }) => {
  const pushTo = usePushTo();
  const allSchedules = useLazyEntities<"schedule">(
    template.refs?.schedules || []
  );
  // Template schedules should not be shown (these are when the template has a repeat setup on it)
  // rather than when the template is being used by a schedule
  const schedules = useMemo(
    () => filter(allSchedules, (s) => !s.template),
    [allSchedules]
  );
  const engine = useEngine("schedule");

  if (!schedules?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader
        title="Connected Rules"
        description="Recurring work rules that use this template"
      />
      <Container size="half">
        {map(schedules, (e) =>
          render(engine.asListCard, {
            key: e.id,
            item: e,
            className: styles.listItem,
          })
        )}
      </Container>
    </Sheet>
  );
};
