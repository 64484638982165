import { find, first, groupBy, keys, last, map, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  DatabaseID,
  EntityType,
  HasTemplate,
  ID,
  isBuilderEnitity,
  isTemplateEntity,
  Schedule,
} from "@api";

import { useCreateEntity, useUpdateEntity } from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { useMe } from "@state/persons";
import { useEntityLabels, useSetting } from "@state/settings";
import { useLazyTeam } from "@state/teams";
import { useLazyAllTemplates, useLazyTemplates } from "@state/templates";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";
import { useLazyWorkspace } from "@state/workspace";

import { useConst, useQueryParams, useStickyState } from "@utils/hooks";
import { maybeTypeFromId } from "@utils/id";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asMutation, toMutation } from "@utils/property-mutations";
import { fallbackPropertyValue, toLabel, toRef } from "@utils/property-refs";
import { toScope } from "@utils/scope";
import { usePageSelection } from "@utils/selectable";
import { plural, sentenceCase } from "@utils/string";

import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered } from "@ui/container";
import { Divider } from "@ui/divider";
import { getEngine, render, useEngine } from "@ui/engine";
import { FormCreateDialog } from "@ui/engine/form/create-dialog";
import templateEngine from "@ui/engine/template";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Building, Eye, EyeSlash, Icon, PlusIcon, Swatch } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { EmptyMenuItem } from "@ui/menu-item";
import {
  PersonMultiSelect,
  PersonSelect,
  Select,
  TextSubtextOption,
} from "@ui/select";
import { ColorSelect } from "@ui/select/color";
import { NotionSelect } from "@ui/select/notion";
import { SlackSelect } from "@ui/select/slack";
import { TimezoneSelect } from "@ui/select/timezone";
import { TabBar } from "@ui/tab-bar";
import { Heading, Text, TextLarge } from "@ui/text";

import { AddWorkButton } from "./add-work-dialog";
import SettingsSchema from "./settings-schemas";
import { TeamButton, TeamSelect } from "./team-select";

import styles from "./settings-team.module.css";

interface Props {
  teamId: ID;
}

export const TEAM_VISIBILITIES = [
  {
    id: "open",
    name: "Open",
    subtext: "Team and work visible to all members in workspace.",
    icon: Eye,
  },
  {
    id: "closed",
    name: "Closed",
    subtext: "Team (not work) visible to all members in workspace.",
    icon: Building,
  },
  {
    id: "private",
    name: "Private",
    subtext: "Visible only to members.",
    icon: EyeSlash,
  },
];

export default function SettingsTeam({ teamId }: Props) {
  const entity = useLazyTeam(teamId);
  const workspace = useLazyWorkspace();
  const mutate = useUpdateEntity(teamId);
  const timezone = useSetting<Maybe<string>>(teamId, "timezone");

  if (!(entity && workspace)) {
    return <></>;
  }

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Team Details</Heading>
        <Text subtle>Core information and settings for your team.</Text>
      </VStack>

      <Divider direction="horizontal" />

      <SpaceBetween gap={6}>
        <Field label="Name">
          <TextInput
            icon={
              <ColorSelect
                color={entity?.color}
                onChange={(color) =>
                  entity &&
                  mutate([
                    toMutation(entity, { field: "color", type: "text" }, color),
                  ])
                }
              >
                <Icon icon={<Swatch color={entity?.color || "gray_5"} />} />
              </ColorSelect>
            }
            value={entity?.name || ""}
            onChange={(name) =>
              entity &&
              name &&
              mutate([
                toMutation(entity, { field: "name", type: "text" }, name),
              ])
            }
            updateOn="blur"
            placeholder="Update team name..."
          />
        </Field>

        <Field label="Parent Team">
          <TeamSelect
            team={entity.parent}
            portal={true}
            onChanged={(p) =>
              mutate([
                asMutation(
                  { field: "parent", type: "relation" },
                  when(p, toRef)
                ),
              ])
            }
          >
            <TeamButton
              team={entity.parent}
              caret={true}
              className={styles.control}
              emptyIsPrivate={false}
            />
          </TeamSelect>
        </Field>

        <Field label="Visibility">
          <Select
            options={TEAM_VISIBILITIES}
            value={find(TEAM_VISIBILITIES, (o) => o.id === entity.visibility)}
            overrides={{ Option: TextSubtextOption }}
            className={{ trigger: styles.control }}
            caret={true}
            onChange={(v) =>
              mutate([
                toMutation(
                  entity,
                  { field: "visibility", type: "text" },
                  v?.id
                ),
              ])
            }
          />
        </Field>
      </SpaceBetween>

      <SpaceBetween gap={6}>
        <Field label="Team Owner">
          <PersonSelect
            className={styles.control}
            value={entity.owner}
            portal={true}
            onChange={(p) =>
              p &&
              mutate([
                toMutation(entity, { field: "owner", type: "relation" }, p),
              ])
            }
            placeholder="Select owner..."
          />
        </Field>
        <Field label="Team Members" fit="container">
          <PersonMultiSelect
            className={{ trigger: styles.control }}
            value={entity.people}
            portal={true}
            onChange={(p) =>
              p &&
              mutate([
                toMutation(entity, { field: "people", type: "relations" }, p),
              ])
            }
            placeholder="Select owner..."
          />
        </Field>
      </SpaceBetween>

      <Field label="Default Timezone">
        <TimezoneSelect
          value={timezone}
          onChange={(tz) =>
            mutate([
              asMutation({ field: "settings.timezone", type: "text" }, tz),
            ])
          }
          placeholder="Choose default timezone..."
        />
      </Field>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Team Notion Homepage</TextLarge>
              <Text subtle>Location for Team Docs.</Text>
            </VStack>
          </FillSpace>

          <NotionSelect
            value={(entity?.notionId as Maybe<string>) || ""}
            mode="pages"
            onChange={(t) =>
              entity &&
              mutate([
                toMutation(entity, { field: "notionId", type: "text" }, t),
              ])
            }
          />
        </SpaceBetween>

        <Divider />

        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Team Slack Channel</TextLarge>
              <Text subtle>Create new threads in this Slack Channel.</Text>
            </VStack>
          </FillSpace>

          <SlackSelect
            mode="channel"
            channel={
              fallbackPropertyValue([entity, workspace], {
                field: "settings.channel",
                type: "text",
              })?.text
            }
            placeholder="Select a channel..."
            onChange={(id) =>
              mutate([
                toMutation(
                  entity,
                  { field: "settings.channel", type: "text" },
                  id
                ),
              ])
            }
          />
        </SpaceBetween>
      </VStack>
    </Centered>
  );
}

export function SettingsTeamProperties({ teamId }: Props) {
  const entity = useLazyTeam(teamId);
  const workspace = useLazyWorkspace();
  const params = useQueryParams();

  if (!(entity && workspace)) {
    return <></>;
  }

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Fields</Heading>
        <Text subtle>Customize the fields available on your team's work.</Text>
      </VStack>

      {entity && (
        <SettingsSchema
          parent={entity}
          type={params.type as Maybe<EntityType>}
          editing={params.field as Maybe<string>}
        />
      )}

      <Text subtle>
        More fields are inherited from your workspace. You can edit them in{" "}
        <Link to="/settings/properties">Workspace settings</Link>.
      </Text>
    </Centered>
  );
}

const CreateTemplateActionItem = ({
  label,
  source,
}: {
  label: string;
  source: DatabaseID;
}) => {
  const pushTo = usePushTo();
  const create = useCreateEntity(source.type, source.scope);

  return (
    <ActionItem
      icon={PlusIcon}
      onClick={() =>
        pushTo(
          create([{ field: "template", type: "text", value: { text: "root" } }])
        )
      }
    >
      {label}
    </ActionItem>
  );
};

const TEMPLATE_TABS = [
  { id: "items", title: "Work Templates" },
  { id: "forms", title: "Forms" },
  // { id: "workflows", title: "Workflows" },
];

export function SettingsTeamTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const [creating, setCreating] = useState<EntityType>();
  const engine = useEngine(creating);
  const toLabel = useEntityLabels(teamId);
  const installedTypes = useInstalledEntities(teamId);
  const createables = useMemo(
    () => maybeMap(installedTypes, (t) => ({ type: t, scope: teamId })),
    [installedTypes]
  );

  return (
    <Centered gap={20} stack="vertical">
      {creating &&
        render(engine?.asCreateDialog, {
          defaults: {
            location: teamId,
            source: { type: creating, scope: teamId },
          },
          onSaved: (t) => pushTo(t),
          onCancel: () => setCreating(undefined),
        })}

      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>Templates</Heading>
          <Text subtle>
            The templates here are available for use anywhere within this team.
          </Text>
        </VStack>

        <ActionMenu
          actions={
            <>
              {map(createables, (source) =>
                getEngine(source.type)?.asCreateDialog ? (
                  <ActionItem
                    icon={PlusIcon}
                    onClick={() => setCreating(source.type)}
                  >
                    {sentenceCase(toLabel(source.type))} template
                  </ActionItem>
                ) : (
                  <CreateTemplateActionItem
                    key={source.type}
                    source={source}
                    label={`${sentenceCase(toLabel(source.type))} template`}
                  />
                )
              )}
            </>
          }
        >
          <Button variant="primary">New template</Button>
        </ActionMenu>
      </SpaceBetween>

      <BaseTemplates teamId={teamId} />
    </Centered>
  );
}

function FormTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const forms = useLazyAllTemplates(teamId, { allowed: ["form"] });
  const byType = useMemo(
    () => groupBy(forms.form, (f) => f.entity),
    [forms.form]
  );
  const toLabel = useEntityLabels(teamId);
  const [selection, setSelection] = usePageSelection();

  return (
    <VStack gap={10}>
      {map(
        byType,
        (vals, type: EntityType) =>
          !!vals?.length &&
          !isTemplateEntity(type) && (
            <CollapsibleSection
              key={type}
              title={`Forms creating ${toLabel(type, { plural: true })}`}
              padded={false}
            >
              <VStack gap={0} fit="container">
                {map(vals as HasTemplate[], (item) =>
                  render(templateEngine.asListItem, {
                    key: item.id,
                    item: item,
                    selection: selection,
                    setSelection: setSelection,
                    onOpen: (f) => pushTo([f, "/builder"]),
                  })
                )}
              </VStack>
            </CollapsibleSection>
          )
      )}
    </VStack>
  );
}

function BaseTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const templates = useLazyAllTemplates(teamId);
  const toLabel = useEntityLabels(teamId);
  const [selection, setSelection] = usePageSelection();

  return (
    <VStack gap={30}>
      {map(
        templates,
        (vals, type: EntityType) =>
          !!vals?.length && (
            <CollapsibleSection
              key={type}
              title={plural(toLabel(type))}
              padded={false}
            >
              <VStack gap={0} fit="container">
                {map(vals as HasTemplate[], (item) =>
                  render(templateEngine.asListItem, {
                    key: item.id,
                    item: item,
                    selection: selection,
                    setSelection: setSelection,
                    onOpen: isBuilderEnitity(type)
                      ? (o) => pushTo([o, "/builder"])
                      : pushTo,
                  })
                )}
              </VStack>
            </CollapsibleSection>
          )
      )}
    </VStack>
  );
}

export function SettingsTeamSchedules({ teamId }: Props) {
  const pushTo = usePushTo();
  const schedules = useLazyItemsForView(
    toTemplateViewId("team-schedule", { parent: teamId })
  );
  const toLabel = useEntityLabels(teamId);
  const engine = useEngine("schedule");
  const [selection, setSelection] = usePageSelection();
  const grouped = useMemo(
    () =>
      groupBy(
        schedules.items?.sorted as Schedule[],
        (s) =>
          s.entity ||
          // TODO: Technically we need to figure out the NEXT instance, not the last
          when((s.useTemplate || last(s.instances))?.id, maybeTypeFromId)
      ),
    [schedules.items?.sorted]
  );
  const groups = useMemo(() => orderBy(keys(grouped)), [grouped]);
  const openSchedule = useCallback(
    // TODO: Technically we need to figure out the NEXT instance, not the last
    (s: Schedule) => when(last(s.instances), pushTo),
    []
  );

  return (
    <Centered gap={20} stack="vertical">
      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>Recurring Work</Heading>
          <Text subtle>Work scheduled to repeat for this team</Text>
        </VStack>

        <HStack>
          <AddWorkButton
            defaults={{
              location: teamId,
              source: { type: "schedule", scope: teamId },
            }}
          >
            Recurring Work
          </AddWorkButton>
        </HStack>
      </SpaceBetween>

      {map(groups, (type: EntityType) => (
        <CollapsibleSection
          key={type}
          title={`Recurring ${toLabel(type, { plural: true })}`}
        >
          <VStack gap={0} fit="container">
            {map(grouped[type], (schedule: Schedule) =>
              render(engine?.asListItem, {
                key: schedule.id,
                item: schedule,
                onOpen: (r) =>
                  r.id === schedule.id ? openSchedule(schedule) : pushTo(r),
                selection,
                setSelection,
              })
            )}

            {!(schedules?.items?.sorted || schedules?.items?.all)?.length && (
              <EmptyMenuItem>
                No schedules created within this team.
              </EmptyMenuItem>
            )}
          </VStack>
        </CollapsibleSection>
      ))}
    </Centered>
  );
}

export function SettingsTeamWorkflows({ teamId }: Props) {
  const pushTo = usePushTo();
  const me = useMe();
  const source = useConst({
    type: "workflow",
    scope: teamId,
  } as DatabaseID<"workflow">);
  const workflows = useLazyTemplates<"workflow">(source);
  const engine = useEngine("workflow");
  const create = useCreateEntity("workflow", teamId);
  const handleCreate = useCallback(() => {
    const saved = create([
      asMutation({ field: "template", type: "text" }, "root"),
      asMutation({ field: "owner", type: "relation" }, toRef(me)),
      asMutation({ field: "status", type: "status" }, { id: "DFT" }),
    ]);

    pushTo([saved, "/builder"]);
  }, [create, me]);

  return (
    <Centered gap={20} stack="vertical">
      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>Workflows Templates</Heading>
          <Text subtle>
            Create and manage workflow definitions for your team.
          </Text>
        </VStack>

        <Button onClick={handleCreate}>New Workflow</Button>
      </SpaceBetween>

      <VStack>
        {map(workflows, (workflow) =>
          render(engine.asListItem, {
            key: workflow.id,
            item: workflow,
            onOpen: (w) => pushTo([w, "/builder"]),
          })
        )}
      </VStack>
    </Centered>
  );
}
