import { find, flatMap, keys, map, mapValues, reduce, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { Entity, EntityType, Ref, Status } from "@api";

import { allStatusesForTeam } from "@state/properties";
import {
  useLazyEntities,
  useManyNestedEntities,
  useQueueUpdates,
} from "@state/generic";

import { indexBy } from "@utils/array";
import { Fn } from "@utils/fn";
import { newID } from "@utils/id";
import { Maybe, maybeMap } from "@utils/maybe";
import { maybeValues, PartialRecord } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { plural } from "@utils/string";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Dialog } from "@ui/dialog";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Fields } from "@ui/input";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { showSuccess } from "@ui/notifications";
import { StatusSelect } from "@ui/select";
import { StatusTag } from "@ui/tag";
import { Text } from "@ui/text";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const PublishDialog = ({ targets, onCancel, onComplete }: Props) => {
  const pageId = usePageId();
  const entities = useLazyEntities(targets);
  const parentTypes = useMemo(
    () => uniq(maybeMap(entities, (e) => e.source?.type)),
    [entities]
  );
  const { children, loading } = useManyNestedEntities(entities);
  const queue = useQueueUpdates(pageId);
  const allStatuses = useRecoilValue(
    allStatusesForTeam(entities?.[0]?.source.scope || "never")
  );
  const statusesByType = useMemo(
    () => indexBy(allStatuses, (s) => s.entity),
    [allStatuses]
  );
  const [publish, setPublish] = useState<PartialRecord<EntityType, boolean>>(
    {}
  );
  const [statuses, setStatuses] = useState<PartialRecord<EntityType, Status>>(
    {}
  );

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entities?.length) {
      return;
    }

    const transaction = newID();
    const updates = flatMap(publish, (enabled, type) => {
      const status = statuses[type as EntityType];

      if (!status || enabled === false) {
        return [];
      }

      return map(children?.[type as EntityType] as Maybe<Entity[]>, (c) =>
        asUpdate(
          c,
          asMutation({ field: "status", type: "status" }, status),
          transaction
        )
      );
    });

    // Publish all changes
    queue(updates);

    showSuccess("Published all nested work.");

    // Callback
    onComplete?.();
  }, [queue, entities, publish, statuses]);

  useEffect(() => {
    setPublish(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  useEffect(() => {
    setStatuses(
      mapValues(publish, (enabled, type: EntityType) =>
        find(
          find(allStatuses, (s) => s.entity?.includes(type))?.values?.status,
          (v) => v?.group !== "planning"
        )
      )
    );
  }, [publish, allStatuses]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      onDismiss={() => onCancel?.()}
      title={"Publish nested work"}
      description="Mark all nested work as published (non-draft status)."
      actions={
        <>
          <HStack gap={4} fit="container" justify="flex-end">
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>
              Publish all
            </Button>
          </HStack>
        </>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields>
              <Menu>
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled onChecked={() => {}}>
                    Publish {entities?.length} selected{" "}
                    {map(parentTypes, (t) => plural(t, entities?.length))?.join(
                      "/"
                    )}
                  </CheckMenuItem>

                  {loading && (
                    <MenuItem disabled icon={SpinnerIcon}>
                      Collecting nested work...
                    </MenuItem>
                  )}

                  {!loading && !hasChildren && (
                    <MenuItem disabled icon={Check} text="No nested work" />
                  )}

                  {!loading &&
                    map(children, (values, type) => (
                      <SpaceBetween key={type}>
                        <CheckMenuItem
                          checked={publish[type as EntityType] ?? false}
                          onChecked={() =>
                            setPublish((d) => ({
                              ...d,
                              [type]: !(publish[type as EntityType] ?? false),
                            }))
                          }
                        >
                          <Text>
                            Publish {values?.length || 0} nested{" "}
                            {plural(type, values?.length || 0)}
                          </Text>
                        </CheckMenuItem>

                        <StatusSelect
                          portal={true}
                          value={statuses[type as EntityType]}
                          onChange={(status) =>
                            setStatuses((d) => ({ ...d, [type]: status }))
                          }
                          options={
                            statusesByType[type as EntityType]?.values
                              ?.status || []
                          }
                        >
                          <Button size="small" subtle>
                            <StatusTag status={statuses[type as EntityType]}>
                              {statuses[type as EntityType]?.name}
                            </StatusTag>
                          </Button>
                        </StatusSelect>
                      </SpaceBetween>
                    ))}
                </MenuGroup>
              </Menu>
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};
