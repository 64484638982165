import { find, groupBy, map, pick, without } from "lodash";
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  CreateOrUpdate,
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  isStatusable,
  JsonArray,
  PropertyDef,
  PropertyMutation,
  PropertyType,
  PropertyValueRef,
  Ref,
  RichText,
  Update,
  VariableDef,
  Workflow,
  WorkflowAction,
  WorkflowStep,
} from "@api";

import { JsonObject } from "@prisma/client/runtime/library";

import { useAiUseCase, workflowStepCreate } from "@state/ai";
import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useFilterableProperties, useLazyProperties } from "@state/properties";
import {
  useCreateEntity,
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useAddToRecents } from "@state/recents";
import { aiStepToUpdate, useVariables } from "@state/workflow";

import {
  ensureArray,
  ensureMany,
  justOne,
  maybeLookup,
  omitEmpty,
  omitFalsey,
  OneOrMany,
  uniqBy,
} from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { extractVarReference, toVarReference } from "@utils/formula";
import { useConst } from "@utils/hooks";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import {
  asAppendMutation,
  asFormulaMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import {
  asFormulaValue,
  getJsonValue,
  getPropertyValue,
  isAnyRelation,
  toRef,
} from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { toBaseScope, toLast } from "@utils/scope";
import { plural, titleCase } from "@utils/string";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { CounterButton } from "@ui/counter-button";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { PaneOpts } from "@ui/engine";
import { EntityPreview } from "@ui/entity-preview";
import { AlphaFeature } from "@ui/feature-flag";
import { Centered, FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  BoltAlt,
  Box,
  ClipboardNotes,
  ClockHistory,
  CounterIcon,
  Icon,
  Magic,
  Play,
  SpinnerIcon,
} from "@ui/icon";
import { InflatedStatusTag } from "@ui/inflated-status-tag";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { ListItem } from "@ui/list-item";
import { LocationButton } from "@ui/location-button";
import { Menu } from "@ui/menu";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";
import { PackageTag } from "@ui/package-label";
import AppPage from "@ui/page/app-page";
import { NestedPropertyFilter } from "@ui/property-filter";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { DocumentEditor, TextBox } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { GlobalEntitySelect, LocationSelect, PersonSelect } from "@ui/select";
import {
  EntityTypeMultiSelect,
  EntityTypeSelect,
} from "@ui/select/entity-type";
import { SlackSelect } from "@ui/select/slack";
import { WithVariableSelect } from "@ui/select/variable";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SquareButton } from "@ui/square-button";
import { Switch } from "@ui/switch";
import { TabBar } from "@ui/tab-bar";
import { TemplateConfigure } from "@ui/template-configure";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { VariableList } from "@ui/variable-list";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";
import { RunWorkflowModal } from "@ui/workflow-run-modal";

import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./editor.module.css";

export const WorkflowEditorPane = (props: PaneOpts<Workflow>) => {
  const { id, item: workflow } = props;
  const [page] = useRegisterPage(id, workflow);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const teamId = useMemo(() => toBaseScope(workflow.source.scope), [workflow]);
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const mutate = useMutate();
  const create = useCreateEntity(stepSource.type, stepSource.scope);
  const createForm = useCreateEntity("form", teamId);
  const [selected, setSelected] = useState<Maybe<ID>>();
  const [active, setActive] = useState<"build" | "edit" | "run" | string>(
    workflow?.template ? "build" : "run"
  );
  const [adding, setAdding] = useState<boolean | string>(false);
  const [addingTo, setAddingTo] = useState<WorkflowStep | undefined>();
  const steps = useLazyEntities<"workflow_step">(workflow.refs?.steps);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Add to recents
  useAddToRecents(id);

  const addStep = useCallback(
    (defaults: PropertyMutation[]) => {
      const action =
        (find(defaults, { field: "action" })?.value
          .text as Maybe<WorkflowAction>) || WorkflowAction.Create;

      const step = create([
        ...switchEnum<WorkflowAction, PropertyMutation[]>(action, {
          create: () => [
            asMutation({ field: "name", type: "text" }, "Add Work"),
            asMutation({ field: "options.entity", type: "text" }, "task"),
          ],
          control: () => [
            asMutation({ field: "name", type: "text" }, "Wait All"),
            asMutation({ field: "options.all", type: "boolean" }, true),
          ],
          ai: () => [
            asMutation({ field: "name", type: "text" }, "AI Prompt"),
            // asMutation({ field: "outputs", type: "json" }, [
            //   { field: "response", type: "text", label: "Response" },
            // ]),
          ],
          message: () => [
            asMutation({ field: "name", type: "text" }, "Send Message"),
            asMutation({ field: "options.via", type: "text" }, "slack"),
          ],
          exit: () => [
            asMutation({ field: "name", type: "text" }, "End Workflow"),
          ],
          condition: () => [
            asMutation({ field: "name", type: "text" }, "Condition"),
          ],
          update: () => [asMutation({ field: "name", type: "text" }, "Update")],
          wait: () => [asMutation({ field: "name", type: "text" }, "Wait")],
          else: () => [],
        }),

        asMutation({ field: "location", type: "text" }, stepSource.scope),
        asMutation({ field: "action", type: "text" }, action),
        asMutation({ field: "status", type: "status" }, { id: "NTS" }),
        asMutation(
          { field: "template", type: "text" },
          !!workflow.template ? "nested" : undefined
        ),
        asMutation(
          { field: "refs.blockedBy", type: "relations" },
          when(toRef(addingTo), ensureArray)
        ),
        asMutation({ field: "refs.workflow", type: "relations" }, [
          { id: workflow.id },
        ]),
        ...defaults,
      ]);

      if (addingTo) {
        // Always add here (even though done serverside) so that UI updates immediately
        mutate(
          asUpdate(
            addingTo,
            omitEmpty([
              asAppendMutation({ field: "refs.blocks", type: "relations" }, [
                toRef(step),
              ]),
              adding === "else"
                ? asAppendMutation({ field: "refs.else", type: "relations" }, [
                    toRef(step),
                  ])
                : undefined,
            ])
          )
        );
      }

      mutate(
        asUpdate(
          workflow,
          asAppendMutation({ field: "refs.steps", type: "relations" }, [
            toRef(step),
          ])
        )
      );

      setAdding(false);
      setAddingTo(undefined);

      setSelected(step.id);
    },
    [addingTo, workflow?.template]
  );

  const handleAddOrUpdate = useCallback(
    (updates: Update<Entity>[]) => {
      const { creates, rest } = groupBy(updates, (u) =>
        switchEnum(u.method, {
          create: "creates",
          else: "rest",
        })
      );
      // Process the creates first
      map(creates, (s: CreateOrUpdate) =>
        addStep([
          { field: "id", type: "text", value: { text: s.id } },
          ...s.changes,
        ])
      );

      // Then process the rest
      map(rest, (u) => mutate(u));
    },
    [workflow]
  );

  const handleAddStep = useCallback(
    (connectTo?: WorkflowStep, handle?: string) => {
      setAddingTo(connectTo);
      setAdding(handle || true);
    },
    [create]
  );

  const handleOpenStep = useCallback(
    (step: WorkflowStep) => {
      if (step.template) {
        setSelected(step.id);
      } else if (step.refs?.created?.length) {
        pushTo(step.refs.created[0]);
      }
    },
    [setSelected, setActive]
  );

  const handleSetupForm = useCallback(() => {
    const form = createForm([
      asMutation({ field: "name", type: "text" }, workflow.name),
      asMutation({ field: "template", type: "text" }, "root"),
      asMutation(
        { field: "refs.runWorkflow", type: "relation" },
        toRef(workflow)
      ),
    ]);
    pushTo(form);
  }, [workflow]);

  const availableOn = useMemo(
    () => getJsonValue<EntityType[]>(workflow, "options.availableOn"),
    [workflow.options?.availableOn]
  );

  useEffect(() => {
    if (!selected?.length && active === "edit") {
      setActive(workflow?.template ? "build" : "run");
    }
  }, [selected]);

  if (!workflow) {
    return <></>;
  }

  return (
    <Sheet
      size="full"
      transparency="mid"
      interactable={false}
      className={styles.container}
    >
      {adding && <AddWorkModal onAdd={addStep} onClose={setAdding} />}

      <HeaderBar {...props} />

      <div className={styles.main}>
        <WorkflowBuilderFlow
          id={id}
          selected={when(selected, ensureArray) || []}
          onAddStep={handleAddStep}
          onOpenStep={handleOpenStep}
          onSelectionChanged={(s) => {
            setSelected(justOne(s));
            setActive("edit");
          }}
        />

        <AlphaFeature>
          <AddNextStep
            workflow={workflow}
            steps={steps}
            mutate={handleAddOrUpdate}
          />
        </AlphaFeature>
      </div>

      <VStack className={styles.pane} fit="container" gap={20}>
        <TabBar
          active={active}
          onActiveChanged={setActive}
          className={styles.tabBar}
          options={[
            { id: "build", title: "Build" },
            { id: "edit", title: workflow?.template ? "Edit" : "Details" },
            { id: "run", title: "Run" },
          ]}
        />

        {active === "build" && (
          <SpaceBetween direction="vertical" width="container">
            <FillSpace direction="vertical" fit="container" width="container">
              <VStack fit="container" gap={16}>
                <Field label="Status">
                  <SpaceBetween>
                    <MenuItem>
                      <SpaceBetween>
                        <Text>
                          {workflow.status?.id === "DFT"
                            ? "Ready to publish"
                            : "Available for use"}
                        </Text>
                        <Switch
                          checked={workflow.status?.id !== "DFT"}
                          onChange={(checked) =>
                            mutate(
                              asUpdate(
                                workflow,
                                asMutation(
                                  { field: "status", type: "status" },
                                  { id: checked ? "AVL" : "DFT" }
                                )
                              )
                            )
                          }
                        />
                      </SpaceBetween>
                    </MenuItem>
                  </SpaceBetween>
                </Field>

                <Field label="Workflow Owner">
                  <PersonSelect
                    value={workflow.owner}
                    className={{ trigger: styles.control }}
                    onChange={(v) =>
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation({ field: "owner", type: "relation" }, v)
                        )
                      )
                    }
                  />
                </Field>

                <Field label="Workflow Triggers">
                  <ColoredSection>
                    <VStack gap={10}>
                      <SpaceBetween>
                        <Label icon={Box}>Available From</Label>
                        <EntityTypeMultiSelect
                          scope={workflow.source.scope}
                          value={availableOn}
                          onChange={(v) =>
                            mutate(
                              asUpdate(
                                workflow,
                                asMutation(
                                  {
                                    field: "options.availableOn",
                                    type: "json",
                                  },
                                  v
                                )
                              )
                            )
                          }
                        >
                          {when(availableOn?.length || undefined, (count) => (
                            <CounterIcon color="green" count={count} />
                          )) || (
                            <Button size="tiny">
                              <TextSmall subtle>Setup</TextSmall>
                            </Button>
                          )}
                        </EntityTypeMultiSelect>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={ClockHistory}>Recurring Schedules</Label>

                        <div
                          onClick={() => goTo([teamId, "/settings/schedules"])}
                        >
                          {when(
                            workflow?.refs?.schedules?.length || undefined,
                            (count) => (
                              <Container inset="right" padding="none">
                                <CounterButton count={count} />
                              </Container>
                            )
                          ) || (
                            <Button size="tiny" variant="primary">
                              <TextSmall color="inverse">Setup</TextSmall>
                            </Button>
                          )}
                        </div>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={ClipboardNotes}>Forms</Label>
                        <div>
                          {when(
                            workflow?.refs?.forms?.length || undefined,
                            (count) => (
                              <Container
                                inset="right"
                                padding="none"
                                onClick={() =>
                                  goTo([teamId, "/settings/templates"])
                                }
                              >
                                <CounterButton count={count} />
                              </Container>
                            )
                          ) || (
                            <Button
                              size="tiny"
                              variant="primary"
                              onClick={handleSetupForm}
                            >
                              <TextSmall color="inverse">Setup</TextSmall>
                            </Button>
                          )}
                        </div>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={BoltAlt}>Automated Rules</Label>
                        {/* <TextSmall subtle>Setup</TextSmall> */}
                        <TextSmall subtle>Coming soon...</TextSmall>
                      </SpaceBetween>
                    </VStack>
                  </ColoredSection>
                </Field>

                <Field label="Input Variables">
                  <VariableList
                    variables={workflow.inputs}
                    scope={workflow.source.scope}
                    onChanged={(vars) => {
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation(
                            { field: "inputs", type: "json" },
                            safeAs<JsonArray>(vars)
                          )
                        )
                      );
                    }}
                  />
                </Field>
              </VStack>
            </FillSpace>
            <Field>
              <SquareButton
                className={styles.addButton}
                onClick={() => handleAddStep()}
              >
                <VStack fit="container" justify="center" align="center">
                  <HStack className={styles.stack}>
                    <WorkflowStepIcon action={WorkflowAction.AI} />
                    <WorkflowStepIcon action={WorkflowAction.Create} />
                    <WorkflowStepIcon action={WorkflowAction.Condition} />
                    <WorkflowStepIcon action={WorkflowAction.Message} />
                    <WorkflowStepIcon action={WorkflowAction.Exit} />
                  </HStack>
                  <Text>Add Step</Text>
                </VStack>
              </SquareButton>
            </Field>
          </SpaceBetween>
        )}

        {active === "edit" && (
          <WorkflowStepEditor id={selected} workflow={workflow} steps={steps} />
        )}

        {active === "run" && (
          <>
            {!!workflow.template && (
              <WorkflowRunHistory id={id} workflow={workflow} />
            )}
          </>
        )}
      </VStack>
    </Sheet>
  );
};

const HeaderBar = ({ id, item: workflow }: PaneOpts<Workflow>) => {
  const mutate = useUpdateEntity(id);
  const goTo = useGoTo();
  return (
    <VStack className={styles.header} fit="content" gap={0}>
      <HStack>
        <EditableHeading
          key={workflow.id}
          size="h3"
          text={workflow.name || ""}
          placeholder="Untitled"
          onChange={(t) =>
            t && mutate(asMutation({ field: "name", type: "text" }, t))
          }
        />
        <PackageTag type={workflow.source.type} scope={workflow.source.scope} />
      </HStack>
      {!!workflow?.refs?.fromTemplate?.length && (
        <Button
          className={styles.hoverLink}
          variant="link"
          onClick={() => when(workflow.refs.fromTemplate?.[0], (t) => goTo(t))}
        >
          <HStack gap={4}>
            <Text>Based on</Text>
            <RelationLabel
              icon={false}
              relation={toRef(workflow.refs.fromTemplate[0])}
              iconRight={ArrowUpRight}
            />
          </HStack>
        </Button>
      )}
    </VStack>
  );
};

const ADD_STEPS = [
  {
    action: WorkflowAction.Create,
    label: "Add Work",
  },
  {
    action: WorkflowAction.Update,
    label: "Update Work",
  },
  {
    action: WorkflowAction.Find,
    label: "Find Work",
  },
  {
    action: WorkflowAction.Wait,
    label: "Wait Time",
  },
  {
    action: WorkflowAction.Control,
    label: "Wait All",
  },
  {
    action: WorkflowAction.Condition,
    label: "Condition",
  },
  {
    action: WorkflowAction.AI,
    label: "AI Prompt",
  },
  {
    action: WorkflowAction.Message,
    label: "Send Message",
  },
  {
    action: WorkflowAction.RunWorkflow,
    label: "Run Workflow",
  },
  {
    action: WorkflowAction.Exit,
    label: "End Workflow",
  },
];

const AddStepOptions = memo(
  ({ onAdd }: { onAdd: Fn<WorkflowAction, void> }) => {
    return (
      <HStack wrap gap={0}>
        {map(ADD_STEPS, ({ label, action }) => (
          <SquareButton
            key={action}
            className={styles.squareButton}
            icon={<WorkflowStepIcon size={"medium"} action={action} />}
            iconSize="xlarge"
            text={label}
            onClick={() => onAdd?.(action)}
          />
        ))}
      </HStack>
    );
  },
  (prev, next) => prev.onAdd === next.onAdd
);

AddStepOptions.displayName = "AddStepOptions";

const AddWorkModal = ({
  onAdd,
  onClose,
}: {
  onAdd: Fn<PropertyMutation[], void>;
  onClose: Fn<boolean, void>;
}) => {
  const [action, _setAction] = useState<WorkflowAction>();

  const setAction = useCallback((a: WorkflowAction) => {
    _setAction(a);
    onAdd([asMutation({ field: "action", type: "text" }, a)]);
  }, []);

  return (
    <Modal
      padding="none"
      open={true}
      autoPosition
      onOpenChanged={onClose}
      closeOnEscape={true}
      className={styles.addModal}
    >
      {!action && (
        <Container size="half">
          <AddStepOptions onAdd={setAction} />
        </Container>
      )}
    </Modal>
  );
};

const WorkflowStepEditor = ({
  id,
  workflow,
  steps,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
  steps: Maybe<WorkflowStep[]>;
}) => {
  const step = useLazyEntity<"workflow_step">(id);
  const mutate = useUpdateEntity(id || "");

  if (!step) {
    return (
      <Centered fit="container" direction="horizontal">
        <Text subtle>Nothing selected.</Text>
      </Centered>
    );
  }

  return (
    <VStack fit="container">
      <Field>
        <TextInput
          icon={<WorkflowStepIcon action={step.action} />}
          value={step.name || ""}
          placeholder="Untitled step"
          autoFocus={false}
          onChange={(n) =>
            mutate(asMutation({ field: "name", type: "text" }, n))
          }
        />
      </Field>

      <Divider />

      {step.action === "wait" && (
        <WorkflowStepEditorWait
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "ai" && (
        <WorkflowStepEditorAI
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "message" && (
        <WorkflowStepEditorMessage
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "control" && (
        <WorkflowStepEditorControl
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "find" && (
        <WorkflowStepEditorFind
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "condition" && (
        <WorkflowStepEditorCondition
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "create" && (
        <WorkflowStepEditorCreate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "update" && (
        <WorkflowStepEditorUpdate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
    </VStack>
  );
};

const WorkflowRunHistory = ({
  id,
  workflow,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
}) => {
  const pushTo = usePushTo();
  const parent = useLazyEntity(toLast(workflow.location));
  const [starting, setStarting] = useState<Workflow>();
  const runs = useLazyQuery(
    "workflow",
    useMemo(
      () => ({
        field: "refs.fromTemplate",
        type: "relation",
        op: "equals",
        // TODO: getting replaced by createFromTemplate
        value: { relation: { id: workflow.id } },
      }),
      [workflow.id]
    )
  );

  return (
    <VStack>
      <TextMedium bold>Run History</TextMedium>

      {starting && (
        <RunWorkflowModal
          entity={parent}
          workflow={starting}
          autoStart={true}
          onClose={() => setStarting(undefined)}
        />
      )}

      <Field>
        <VStack>
          {map(runs, (r) => (
            <ListItem key={r.id} onClick={() => pushTo(r)}>
              <SpaceBetween>
                <Text>{r.name}</Text>
                <InflatedStatusTag status={r.status} source={r.source} />
              </SpaceBetween>
            </ListItem>
          ))}
        </VStack>
      </Field>

      <Divider />

      <HStack fit="container" justify="flex-end">
        <Button
          variant="primary"
          size="small"
          loading={!!starting}
          onClick={() => setStarting?.(workflow)}
          icon={Play}
        >
          Test Workflow
        </Button>
      </HStack>
    </VStack>
  );
};

const WorkflowStepListItem = ({
  step: s,
  onClick,
}: {
  step: WorkflowStep;
  onClick: Fn<MouseEvent, void>;
}) => {
  const pushTo = usePushTo();
  return (
    <ListItem key={s.id} onClick={onClick}>
      <SpaceBetween>
        <HStack>
          <WorkflowStepIcon step={s} size="small" />
          <Text>{s.name}</Text>
          <TextSmall subtle>{s.id}</TextSmall>
        </HStack>

        {map(s.refs?.created, (r) => (
          <Icon
            key={r.id}
            onClick={() => pushTo(r)}
            icon={<RelationIcon relation={r} />}
          />
        ))}
      </SpaceBetween>
    </ListItem>
  );
};

/* Step Editors */

type StepEditorProps = {
  step: WorkflowStep;
  workflow: Workflow;
  allSteps: Maybe<WorkflowStep[]>;
};

const WorkflowStepEditorCreate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const itemSource = useMemo(
    () => ({
      scope: step.source.scope,
      type: safeAs<EntityType>(step.options?.entity) || "task",
    }),
    [step.source.scope, step.options?.entity]
  );
  const properties = useLazyProperties(itemSource);
  const variables = useVariables(workflow, allSteps);

  const titleProp = useMemo(
    () => find(step.overrides, (o) => equalsAny(o.field, ["title", "name"])),
    [step.overrides]
  );
  const [title, titleVariable] = useReferencedVariable(
    titleProp?.value.text || titleProp?.value.formula,
    variables,
    "number"
  );

  const bodyProp = useMemo(
    () => find(properties, { field: "body" }),
    [properties]
  );

  const body = useMemo(
    () =>
      find(step.overrides, { field: "body" })?.value.rich_text || {
        html: "",
      },
    [step.overrides]
  );

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: step.options?.entity || "default",
                  type: "relation",
                  options: { references: step.options?.entity },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const mutateOverride = useMutateOverride(step, mutate);

  if (step.refs?.created?.length) {
    return (
      <CreatedWorkPreview
        work={step.refs.created[0]}
        onOpen={(r) => !!r && pushTo(r)}
      />
    );
  }

  return (
    <>
      <Field padded>
        <SpaceBetween>
          <EntityTypeSelect
            value={step.options?.entity as Maybe<EntityType>}
            scope={step.source.scope}
            plural={false}
            onChange={(v) =>
              mutate([
                // Change the type
                asMutation({ field: "options.entity", type: "text" }, v),
                // Blank out the outputs since it stores the type
                asMutation({ field: "outputs", type: "json" }, []),
              ])
            }
          />

          <LocationSelect
            variant="full"
            location={
              safeAs<string>(step.options?.inLocation) || itemSource.scope
            }
            clearable={true}
            createable={false}
            showOpen={false}
            onChange={(v) =>
              mutate(
                asMutation({ field: "options.inLocation", type: "text" }, v)
              )
            }
          >
            {when(
              safeAs<string>(step.options?.inLocation) || undefined,
              (loc) => (
                <LocationButton variant="full" size="small" location={loc} />
              )
            ) || (
              <Button size="small" subtle>
                Default location
              </Button>
            )}
          </LocationSelect>
        </SpaceBetween>
      </Field>

      {titleProp && (
        <Field padded>
          <WithVariableSelect
            value={titleVariable}
            options={variables}
            allowed={["text"]}
            onChange={(v) =>
              mutateOverride(
                asFormulaMutation(titleProp, when(v, toVarReference))
              )
            }
          >
            <EditableHeading
              key={step.id}
              size="h3"
              text={title?.text || ""}
              autoFocus={!title.text}
              placeholder="Untitled"
              onChange={(t) => {
                // Save to overrides
                mutateOverride(asMutation(titleProp, t));
                // Rename the step
                mutate(asMutation({ field: "name", type: "text" }, t));
              }}
            />
          </WithVariableSelect>
        </Field>
      )}

      <Field>
        <TemplateConfigure
          template={when(safeAs<string>(step.options?.useTemplate), toRef)}
          overrides={safeAs<PropertyValueRef[]>(step.overrides)}
          field="overrides"
          variables={variables}
          blacklist={["name", "title", "body"]}
          source={itemSource}
          onChange={(c) => {
            mutate(c);
            // When setting the owner/assigned on the task, also set it on the step
            when(
              find(ensureMany(c), (c) => c.field === "overrides"),
              (c) =>
                mutate(
                  asMutation(
                    { field: "owner", type: "relation" },
                    find(
                      safeAs<PropertyMutation[]>(c.value.json),
                      (p) =>
                        p.type === "relation" &&
                        equalsAny(p.field, ["owner", "assigned"])
                    )?.value?.relation
                  )
                )
            );
          }}
        />
      </Field>

      <Field label={bodyProp?.label} padded>
        <DocumentEditor
          placeholder="What needs to get done..."
          newLineSpace="large"
          scope={itemSource.scope}
          variables={variables}
          onNewVariable={(v) =>
            mutateWorkflow(
              asMutation(
                { field: "inputs", type: "json" },
                safeAs<JsonArray>([...(workflow.inputs || []), v])
              )
            )
          }
          content={body}
          onChanged={(rt) =>
            mutateOverride(asMutation({ field: "body", type: "rich_text" }, rt))
          }
        />
      </Field>

      <Divider />

      {isStatusable(itemSource.type) && (
        <Field>
          <CheckMenuItem
            inset
            checked={safeAs<boolean>(step.options?.waitDone) ?? true}
            onChecked={(wait) =>
              mutate(
                asMutation({ field: "options.waitDone", type: "boolean" }, wait)
              )
            }
            text="Wait until complete"
          />
        </Field>
      )}

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([
                    { ...outputVariable, field: t?.replace(/^@/, "") },
                  ])
                )
              )
            }
          />
        )}
      </Field>
    </>
  );
};

export const toWaitLabel = (
  step: Partial<WorkflowStep>,
  prefix: string = "Wait"
) => {
  const waitTimes = when(
    safeAs<number | string>(step.options?.waitTimes),
    Number
  );
  const waitPeriod = when(safeAs<string>(step.options?.waitPeriod), String);

  if (!waitTimes || !waitPeriod) {
    return "Wait";
  }

  return `${prefix} ${waitTimes} ${plural(titleCase(waitPeriod), waitTimes)}`;
};

const WorkflowStepEditorWait = ({
  workflow,
  allSteps,
  step,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);

  const [waitTimesValue, waitTimesVariable] = useReferencedVariable(
    safeAs<string | number>(step.options?.waitTimes),
    variables,
    "number"
  );

  const handleChange = useCallback(
    ({
      waitTimes,
      waitPeriod,
    }: Partial<{ waitTimes: number; waitPeriod: string }>) => {
      mutate(
        omitFalsey([
          !!waitTimes
            ? asMutation(
                { field: "options.waitTimes", type: "number" },
                Math.max(waitTimes, 0)
              )
            : undefined,
          waitPeriod
            ? asMutation(
                { field: "options.waitPeriod", type: "text" },
                waitPeriod
              )
            : undefined,
          asMutation(
            { field: "name", type: "text" },
            toWaitLabel({
              options: {
                waitTimes: waitTimes || step.options?.waitTimes,
                waitPeriod: waitPeriod || step.options?.waitPeriod,
              },
            })
          ),
        ])
      );
    },
    [step]
  );

  return (
    <>
      <Field label="Wait">
        <SpaceBetween gap={6}>
          <div className={styles.numberInput}>
            <WithVariableSelect
              value={waitTimesVariable}
              options={variables}
              allowed={["number"]}
              onChange={(v) =>
                mutate(
                  asFormulaMutation(
                    { field: "options.waitTimes", type: "number" },
                    when(v, toVarReference)
                  )
                )
              }
            >
              <TextInput
                value={when(waitTimesValue.number, String) || "0"}
                updateOn="change"
                onChange={(v) => handleChange({ waitTimes: parseInt(v, 10) })}
                inputType="number"
              />
            </WithVariableSelect>
          </div>
          <FillSpace direction="horizontal">
            <ButtonGroup fit="container">
              {map(["hour", "day", "week", "month"], (period) => (
                <SplitButton
                  key={period}
                  size="small"
                  fit="container"
                  selected={step.options?.waitPeriod === period}
                  onClick={() => handleChange({ waitPeriod: period })}
                >
                  {plural(
                    titleCase(period),
                    when(step.options?.waitTimes, Number) ?? 2
                  )}
                </SplitButton>
              ))}
            </ButtonGroup>
          </FillSpace>
        </SpaceBetween>
      </Field>
    </>
  );
};

const WorkflowStepEditorControl = ({ step }: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);

  return (
    <>
      <Field label="Required paths to complete">
        <FillSpace>
          <ButtonGroup fit="container">
            <SplitButton
              size="small"
              fit="container"
              selected={!!step.options?.all}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, true),
                  asMutation({ field: "name", type: "text" }, "Wait All"),
                ])
              }
            >
              All
            </SplitButton>

            <SplitButton
              size="small"
              fit="container"
              selected={
                !step.options?.all && (step.options?.atLeast || 1) === 1
              }
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, false),
                  asMutation({ field: "name", type: "text" }, "Wait Any"),
                ])
              }
            >
              Any
            </SplitButton>
          </ButtonGroup>
        </FillSpace>
      </Field>
    </>
  );
};

const WorkflowStepEditorCondition = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const [targetVal, targetVariable] = useReferencedVariable(
    safeAs<string>(step.options?.target),
    variables,
    "relation"
  );
  const target = useLazyEntity(targetVal.relation?.id);

  const filteringOnType = useMemo(() => {
    if (target?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(target)?.options?.entity as Maybe<EntityType>;
    }

    if (targetVariable) {
      return justOne(targetVariable?.options?.references);
    }

    return target?.source.type;
  }, [targetVal, targetVariable]);
  const filterSource = useMemo(
    () =>
      filteringOnType
        ? ({ type: filteringOnType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [filteringOnType, step.source.scope]
  );
  const props = useLazyProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);

  const filter = useMemo((): FilterQuery => {
    if (!!targetVariable && !isAnyRelation(targetVariable)) {
      return (
        safeAs<FilterQuery>(
          getPropertyValue(step, { field: "options.filter", type: "json" }).json
        ) || {
          field: targetVariable.field,
          type: targetVariable.type,
          op: "equals",
        }
      );
    }

    return (
      safeAs<FilterQuery>(
        getPropertyValue(step, { field: "options.filter", type: "json" }).json
      ) || { and: [] }
    );
  }, [step.options?.filter]);

  return (
    <>
      <Field label="Check if work">
        <WithVariableSelect
          value={targetVariable}
          options={variables}
          onChange={(v) =>
            mutate([
              // Clear out filter so default chosen above
              asMutation({ field: "options.filter", type: "json" }, undefined),
              // Set the target
              asFormulaMutation(
                { field: "options.target", type: "relation" },
                when(v, toVarReference)
              ),
            ])
          }
        >
          <GlobalEntitySelect
            value={targetVal.relation}
            type={"workflow_step"}
            className={{ trigger: styles.control }}
            allowed={["workflow", "workflow_step"]}
            templates={!!workflow.template}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.target", type: "relation" },
                  toRef(v?.id)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {!!targetVariable && !isAnyRelation(targetVariable) && (
        <Field label="Passes condition">
          <Menu className={cx(styles.control, styles.filterMenu)}>
            <NestedPropertyFilter
              filter={filter}
              definition={(_) => safeAs<PropertyDef>(targetVariable)}
              source={undefined}
              onChanged={(f) =>
                mutate(
                  asMutation(
                    { field: "options.filter", type: "json" },
                    f as Maybe<JsonObject>
                  )
                )
              }
            />
          </Menu>
        </Field>
      )}

      {filterSource && (
        <Field label="Passes condition">
          <Menu className={cx(styles.control, styles.filterMenu)}>
            <NestedPropertyFilter
              filter={filter}
              definition={getProp}
              onChanged={(f) =>
                mutate(
                  asMutation(
                    { field: "options.filter", type: "json" },
                    f as Maybe<JsonObject>
                  )
                )
              }
              source={filterSource}
            />
          </Menu>
        </Field>
      )}

      {step.options?.filter && (
        <Field>
          <CheckMenuItem
            checked={safeAs<boolean>(step.options?.keepChecking) ?? false}
            text="Keep checking"
            onChecked={(v) =>
              mutate(
                asMutation(
                  { field: "options.keepChecking", type: "boolean" },
                  v
                )
              )
            }
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorUpdate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const mutateOverride = useMutateOverride(step, mutate);

  const [sourceVal, sourceVariable] = useReferencedVariable(
    safeAs<string>(step.options?.target),
    variables,
    "relation"
  );
  const target = useLazyEntity(sourceVal.relation?.id);

  const targetType = useMemo(() => {
    if (target?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(target)?.options?.entity as Maybe<EntityType>;
    }

    if (sourceVariable) {
      return justOne(sourceVariable?.options?.references);
    }

    return target?.source.type;
  }, [sourceVal, sourceVariable]);

  const targetSource = useMemo(
    () =>
      targetType
        ? ({ type: targetType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [targetType, step.source.scope]
  );
  const properties = useLazyProperties(targetSource);

  const bodyProp = useMemo(
    () => find(properties, { field: "body" }),
    [properties]
  );
  const body = useMemo(
    () =>
      find(step.overrides, { field: "body" })?.value.rich_text || { html: "" },
    [step.overrides]
  );

  return (
    <>
      <Field label="Work to be updated">
        <WithVariableSelect
          value={sourceVariable}
          allowed={["relation", "relations"]}
          options={variables}
          onChange={(v) =>
            mutate(
              asFormulaMutation(
                { field: "options.target", type: "relation" },
                when(v, toVarReference)
              )
            )
          }
        >
          <GlobalEntitySelect
            value={sourceVal.relation}
            type={"workflow_step"}
            className={{ trigger: styles.control }}
            allowed={["workflow", "workflow_step"]}
            templates={!!workflow.template}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.target", type: "relation" },
                  toRef(v?.id)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {targetSource && (
        <Field label="Fields to update">
          <TemplateConfigure
            template={undefined}
            overrides={safeAs<PropertyValueRef[]>(step.overrides)}
            field="overrides"
            blacklist={["body"]}
            showPicker={false}
            variables={variables}
            source={targetSource}
            onChange={mutate}
          />
        </Field>
      )}

      {bodyProp && targetSource && (
        <Field
          label={`Append to ${(bodyProp?.label || "body").toLowerCase()}`}
          padded
        >
          <DocumentEditor
            placeholder="Start typing..."
            newLineSpace="large"
            scope={targetSource.scope}
            variables={variables}
            content={body}
            onChanged={(rt) =>
              mutateOverride(
                asAppendMutation({ field: "body", type: "rich_text" }, rt)
              )
            }
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorFind = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const findingType = useMemo(
    () => safeAs<EntityType>(step.options?.entity),
    [step?.options?.entity]
  );
  const variables = useVariables(workflow, allSteps);

  const [filterWithinValue, filterVariable] = useReferencedVariable(
    safeAs<string>(step.options?.within),
    variables
  );

  const filterSource = useMemo(
    () =>
      findingType
        ? ({
            type: findingType,
            scope: filterWithinValue.text || toBaseScope(step.source.scope),
          } as DatabaseID)
        : undefined,
    [findingType, filterWithinValue, step.source.scope]
  );
  const props = useFilterableProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);

  const filter = useMemo(
    () =>
      safeAs<FilterQuery>(
        getPropertyValue(step, { field: "options.filter", type: "json" }).json
      ) || { and: [] },
    [step.options?.filter]
  );

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const toOutputMutation = useCallback(
    (
      changes: Partial<{
        field: string;
        type: "relation" | "relations";
        references: EntityType;
      }>
    ) =>
      asMutation({ field: "outputs", type: "json" }, [
        {
          field:
            changes.field ||
            step.outputs?.[0]?.field ||
            step.options?.entity ||
            "default",
          type:
            changes?.type ||
            step.outputs?.[0]?.type ||
            (step.options?.limit === 1 ? "relation" : "relations"),
          options: {
            references:
              changes.references ||
              step.outputs?.[0]?.options?.references ||
              step.options?.entity,
          },
        },
      ]),
    [step.outputs, step.options?.entity]
  );

  useEffect(() => {
    if (!step.outputs?.length) {
      mutate(toOutputMutation({}));
    }
  }, []);

  return (
    <>
      <Field label="Find">
        <EntityTypeSelect
          value={step.options?.entity as Maybe<EntityType>}
          scope={step.source.scope}
          additional={useConst(["note"])}
          plural={false}
          onChange={(v) =>
            mutate(asMutation({ field: "options.entity", type: "text" }, v))
          }
        />
      </Field>

      {filterSource && (
        <>
          <Field label="Located in">
            <WithVariableSelect
              value={filterVariable}
              options={variables}
              allowed={["relation"]}
              onChange={(v) =>
                v &&
                mutate(
                  asMutation(
                    { field: "options.within", type: "text" },
                    toVarReference(v)
                  )
                )
              }
            >
              <LocationSelect
                className={{ trigger: styles.control }}
                location={filterSource?.scope}
                onChange={(v) =>
                  mutate(
                    asMutation({ field: "options.within", type: "text" }, v)
                  )
                }
              />
            </WithVariableSelect>
          </Field>

          <Field label="Matching filter">
            <Menu className={cx(styles.control, styles.filterMenu)}>
              <NestedPropertyFilter
                filter={filter}
                definition={getProp}
                onChanged={(f) =>
                  mutate(
                    asMutation(
                      { field: "options.filter", type: "json" },
                      f as Maybe<JsonObject>
                    )
                  )
                }
                source={filterSource}
              />
            </Menu>
          </Field>
        </>
      )}

      <Field label="Limit to">
        {/* Field > * css causing some issues with button immediately beneath */}
        <div>
          <ButtonGroup fit="container">
            <SplitButton
              selected={step.options?.limit === 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 1),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              One
            </SplitButton>
            <SplitButton
              selected={step.options?.limit !== 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 1000),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              Multiple
            </SplitButton>
          </ButtonGroup>
        </div>
      </Field>

      {step.options?.limit === 1 && (
        <Field label="Sort by">
          {/* TODO: Refactor SortByOptionsMenu from view-options-menu so that it can be reused here...  */}
          <Button size="small">...</Button>
        </Field>
      )}

      <Field label={"Save as variable"}>
        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(toOutputMutation({ field: t?.replace(/^@/, "") }))
            }
          />
        )}
      </Field>
    </>
  );
};

const WorkflowStepEditorAI = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const variables = useVariables(workflow, allSteps);

  return (
    <VStack gap={20}>
      <Field label="Prompt">
        <ColoredSection>
          <DocumentEditor
            newLineSpace="large"
            autoFocus={!step?.options?.prompt}
            placeholder="Write instructions for AI to follow..."
            scope={step.source.scope}
            content={
              getPropertyValue(step, {
                field: "options.prompt",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.prompt", type: "rich_text" }, c)
              )
            }
            variables={variables}
            onNewVariable={(v) =>
              mutateWorkflow(
                asMutation(
                  { field: "inputs", type: "json" },
                  safeAs<JsonArray>([...(workflow.inputs || []), v])
                )
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="Outputs" help="Variables you want AI to fill out.">
        <VariableList
          variables={step.outputs}
          scope={step.source.scope}
          onChanged={(vars) =>
            mutate(
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>(vars)
              )
            )
          }
        />
      </Field>
    </VStack>
  );
};

const WorkflowStepEditorMessage = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, without(allSteps, step));

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const [threadValue, threadVariable] = useReferencedVariable(
    safeAs<string>(step.options?.thread),
    variables
  );
  const [fromValue, fromVariable] = useReferencedVariable(
    safeAs<string>(step.options?.from),
    variables,
    "relation"
  );
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: "message",
                  type: "relation",
                  options: { references: "note" },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const [channel, setChannel] = useState(safeAs<string>(step.options?.channel));
  const [thread, setThread] = useState<Maybe<string>>(
    threadValue?.text || undefined
  );

  const setChannelOptions = useCallback(
    ({ channel, thread }: { channel?: string; thread?: string }) => {
      setChannel(channel);
      setThread(thread);

      mutate(
        omitFalsey([
          channel
            ? asMutation({ field: "options.channel", type: "text" }, channel)
            : undefined,
          thread
            ? asMutation({ field: "options.thread", type: "text" }, thread)
            : undefined,
        ])
      );
    },
    [channel, thread]
  );

  return (
    <VStack gap={20}>
      <Field label="Message">
        <ColoredSection>
          <DocumentEditor
            variables={variables}
            newLineSpace="small"
            autoFocus={!step?.options?.prompt}
            placeholder="Write your message..."
            content={
              getPropertyValue(step, {
                field: "options.message",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.message", type: "rich_text" }, c)
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="From">
        <WithVariableSelect
          value={fromVariable}
          allowed={["relation"]}
          options={variables}
          onChange={(v) =>
            mutate(
              asFormulaMutation(
                { field: "options.from", type: "relation" },
                when(v, toVarReference)
              )
            )
          }
        >
          <GlobalEntitySelect
            value={fromValue.relation}
            className={styles.control}
            placeholder="System"
            type="person"
            allowed={["person"]}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.from", type: "relation" },
                  toRef(v)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      <Field label="Send via">
        <HStack>
          <ButtonGroup fit="container">
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "email"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "email")
                // )
              }
            >
              Email
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "slack"}
              onClick={() =>
                mutate(
                  asMutation({ field: "options.via", type: "text" }, "slack")
                )
              }
            >
              Slack
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "sms"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "sms")
                // )
              }
            >
              SMS
            </SplitButton>
          </ButtonGroup>
        </HStack>
      </Field>

      {step.options?.via === "slack" && (
        <Field label="Channel">
          <WithVariableSelect
            value={threadVariable}
            allowed={["relation"]}
            options={variables}
            onChange={(v) =>
              mutate(
                asFormulaMutation(
                  { field: "options.thread", type: "text" },
                  when(v, toVarReference)
                )
              )
            }
          >
            <SlackSelect
              className={styles.control}
              position="bottom-right"
              channel={channel}
              thread={thread}
              mode="thread"
              placeholder="Select a channel..."
              onChange={(c, t) => setChannelOptions({ channel: c, thread: t })}
            />
          </WithVariableSelect>
        </Field>
      )}

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field}
            updateOn="blur"
            placeholder="Variable to save to..."
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([{ ...outputVariable, field: t }])
                )
              )
            }
          />
        )}
      </Field>
    </VStack>
  );
};

const CreatedWorkPreview = ({
  work,
  onOpen,
}: {
  work: Ref;
  onOpen: Fn<Maybe<Ref>, void>;
}) => {
  const entity = useLazyEntity(work.id);
  const blacklist = useConst(["refs.workflows", "refs.fromWorkflow"]);

  if (entity) {
    return (
      <EntityPreview
        entity={entity}
        onOpen={onOpen}
        propBlacklist={blacklist}
      />
    );
  }

  return <RelationLabel relation={work} onClick={() => onOpen(work)} />;
};

export const WorkflowEditorPage = ({ id }: { id: ID }) => {
  const workflow = useLazyEntity<"workflow">(id);
  const pageId = usePageId();
  const [page] = useRegisterPage(id, workflow);
  const goTo = useGoTo();

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!workflow) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!workflow} title={workflow.name}>
      <StackContainer>
        {!workflow.template ? (
          <SmartBreadcrumbSheet />
        ) : (
          <SmartBreadcrumbSheet
            ignorePath={true}
            onClick={() =>
              goTo(
                location.pathname?.replace(`/${workflow.id || id}/builder`, "")
              )
            }
          />
        )}
        <WorkflowEditorPane id={id} item={workflow} />
      </StackContainer>
    </AppPage>
  );
};

const useReferencedVariable = (
  value: Maybe<string | number>,
  variables: VariableDef[],
  type?: PropertyType
) => {
  const valueRef = useMemo(() => asFormulaValue(value, type), [value]);
  const variable = useMemo(
    () =>
      when(
        valueRef?.formula && extractVarReference(valueRef.formula),
        (field) => find(variables, { field })
      ),
    [variables, valueRef]
  );
  return [valueRef, variable] as const;
};

const AddNextStep = ({
  workflow,
  steps,
  mutate,
}: {
  workflow: Workflow;
  steps: Maybe<WorkflowStep[]>;
  mutate: Fn<Update<Entity>[], void>;
}) => {
  const [input, setInput] = useState<RichText>({ html: "" });
  const [focused, setFocused] = useState(false);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const stepProps = useLazyProperties(stepSource);

  const ai = useAiUseCase(workflowStepCreate);
  const createNextStep = useCallback(
    async (p: Maybe<RichText>) => {
      if (!p || isEmpty(p)) {
        showError("Please write what you want to happen next in the workflow.");
        return;
      }

      try {
        const result = await ai.run({
          workflow,
          steps: steps || [],
          prompt: p.html || "",
        });
        mutate(
          map(result, (a) =>
            aiStepToUpdate(workflow, a, stepProps)
          ) as Update<Entity>[]
        );
      } catch (e) {
        showError("Failed to add next step.");
      }
    },
    [ai.run, workflow, steps]
  );

  return (
    <div className={cx(styles.floatingInput, focused && styles.large)}>
      <HStack gap={0}>
        <Icon icon={ai.loading ? SpinnerIcon : Magic} />

        <TextBox
          key="workflow-next-step"
          className={styles.input}
          text={input}
          onChanged={setInput}
          placeholder="What should happen next?"
          submitOnEnter
          blurOnEnter
          onEnter={createNextStep}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </HStack>
    </div>
  );
};

const useMutateOverride = (
  step: WorkflowStep,
  mutate: Fn<OneOrMany<PropertyMutation>, void>
) => {
  return useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate(
        omitFalsey([
          asMutation(
            { field: "overrides", type: "json" },
            safeAs<JsonArray>(
              uniqBy(
                [
                  ...(step.overrides || []),
                  ...map(ensureMany(change), (c) =>
                    pick(c, "field", "value", "type")
                  ),
                ],
                (c) => c.field,
                "last"
              )
            )
          ),
        ])
      );
    },
    [step.overrides, step.id]
  );
};
