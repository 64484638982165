import { atomFamily } from "recoil";

import { Backlog, ID } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type BacklogStoreState = StoreState<Backlog>;

export const WorkspaceBacklogStoreAtom = atomFamily<BacklogStoreState, ID>({
  key: "WorkspaceBacklogStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "backlog" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "backlog")],
});

export const BacklogStoreAtom = WorkspaceWrappedAtom(
  "BacklogStoreAtom",
  WorkspaceBacklogStoreAtom
);
