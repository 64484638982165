import { atomFamily } from "recoil";

import { Agenda, ID } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type AgendaStoreState = StoreState<Agenda>;

export const WorkspaceAgendaStoreAtom = atomFamily<AgendaStoreState, ID>({
  key: "WorkspaceAgendaStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "agenda" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "agenda")],
});

export const AgendaStoreAtom = WorkspaceWrappedAtom(
  "AgendaStoreAtom",
  WorkspaceAgendaStoreAtom
);
