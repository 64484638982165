import { useMemo } from "react";

import { Workflow, WorkflowAction, WorkflowStep } from "@api";

import { useLazyPropertyValue } from "@state/properties";
import { useLazyEntities } from "@state/generic";
import { toStepAssigned, toWorkflowOwner } from "@state/workflow";

import { findPreferential } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatHuman } from "@utils/date";
import { format } from "@utils/epoch-date";
import { respectHandled, withHandle } from "@utils/event";
import { safeAs, when } from "@utils/maybe";
import { sentenceCase } from "@utils/string";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, SubtasksIcon, WorkflowFilled } from "@ui/icon";
import { Label } from "@ui/label";
import { ListCard } from "@ui/list-card";
import { SmartLocationLabel } from "@ui/location-button";
import { MenuItem } from "@ui/menu-item";
import { OnHover } from "@ui/on-hover";
import { RelationIcon, RelationLabel, RelationText } from "@ui/relation-label";
import { SelectableListItem } from "@ui/selectable-items";
import { Switch } from "@ui/switch";
import { StatusTag } from "@ui/tag";
import { SectionLabel, Text, TextLarge, TextMedium, TextSmall } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { UIEngine } from "../types";
import { WorkflowProgressBar, WorkflowStepsBar } from "./components";
import { toWaitLabel } from "./editor";
import { WorkflowPane } from "./pane";

import styles from "./styles.module.css";

export { WorkflowEditorPane as WorkflowPane } from "./editor";
export { RelatedWorkflowsPane } from "./secondary-pane";

const TemplateWorkflowEngine: UIEngine<Workflow> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <Label icon={WorkflowFilled} text={item.name} />

            <HStack>
              {item?.owner && (
                <Icon icon={<RelationIcon relation={item.owner} />} />
              )}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },

  asListItem: (props) => {
    const { item, selection, setSelection, className, onOpen } = props;
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    const isEnabled = useMemo(
      () => status.value.status?.group !== "planning",
      [status.value.status?.group]
    );

    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem className={cx(className)} {...props}>
          <SpaceBetween>
            <VStack gap={0}>
              <Text>{item.name}</Text>
              <HStack gap={10}>
                <Tooltip text="Steps">
                  <Label subtle size="small">
                    <Icon size="small" icon={SubtasksIcon} />
                    {item.refs?.steps?.length || 0}
                  </Label>
                </Tooltip>
                <RelationLabel
                  subtle
                  size="small"
                  relation={toWorkflowOwner(item)}
                />
                <Tooltip text="Last ran">
                  <Label subtle size="small">
                    {when(item.stamps?.lastRun?.at, (d) =>
                      format(d, formatHuman)
                    ) || "Never"}
                  </Label>
                </Tooltip>
              </HStack>
            </VStack>

            <Tooltip text={isEnabled ? "Enabled" : "Disabled"}>
              <Switch checked={isEnabled} onChange={() => {}} />
              {/* <StatusTag status={status.value.status} showIcon={false} /> */}
            </Tooltip>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },

  asListCard: ({ item, onOpen, ...rest }) => {
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu entity={item}>
        <ListCard
          padding="none"
          selectable={false}
          onClick={respectHandled(() => onOpen?.(item))}
        >
          <Container fit="container" stack="vertical" gap={10} size="half">
            <SpaceBetween align="flex-start">
              <VStack gap={0}>
                <SectionLabel primary>Workflow</SectionLabel>
                <TextLarge bold>{item.name || "Workflow"}</TextLarge>
              </VStack>

              <HStack fit="container" align="center" justify="flex-end">
                <StatusTag status={status.value.status || item?.status} />
                <Tooltip text="Started by">
                  <Icon icon={<RelationIcon relation={item?.createdBy} />} />
                </Tooltip>
              </HStack>
            </SpaceBetween>

            <WorkflowStepsBar workflow={item} status={status?.value.status} />
          </Container>
        </ListCard>
      </EntityContextMenu>
    );
  },

  asPrimaryPane: WorkflowPane,
};

const RunningWorkflowEngine: UIEngine<Workflow> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <HStack>
              <Label icon={WorkflowFilled} text={item.name} />
              <SmartLocationLabel
                showTeam={false}
                showIcons={false}
                size="small"
                subtle
                location={item.location}
              />
            </HStack>

            <HStack>
              <WorkflowProgressBar
                className={styles.menuProgressBar}
                workflow={item}
                status={status.value.status}
              />
              {when(toWorkflowOwner(item), (owner) => (
                <Icon icon={<RelationIcon relation={owner} />} />
              ))}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },

  asListItem: (props) => {
    const { item, selection, setSelection, className } = props;
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <OnHover.Trigger>
          <SelectableListItem className={cx(className)} {...props}>
            <SpaceBetween fit="container">
              <HStack gap={4}>
                <Text>{item.name}</Text>
                <SmartLocationLabel
                  showTeam={false}
                  showIcons={false}
                  size="small"
                  subtle
                  location={item.location}
                />
              </HStack>
              <HStack gap={10}>
                <WorkflowProgressBar
                  className={styles.menuProgressBar}
                  workflow={item}
                  status={status.value.status}
                />
                <RelationLabel subtle size="small" relation={item.createdBy} />
              </HStack>
            </SpaceBetween>
          </SelectableListItem>
        </OnHover.Trigger>
      </EntityContextMenu>
    );
  },

  asListCard: ({ item, onOpen, ...rest }) => {
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const steps = useLazyEntities<"workflow_step">(item?.refs?.steps, false);
    const blockedBy = useMemo(
      () =>
        findPreferential(
          steps || [],
          (s) => s.status?.id === "RUN",
          (s) => s.status?.id === "WAI" && s.action === "create",
          (s) => s.status?.id === "WAI" && s.action === "condition",
          (s) => s.status?.id === "WAI"
        ),
      [steps]
    );
    const isFinished = useMemo(
      () => status?.value.status?.group === "done",
      [status?.value.status]
    );

    return (
      <EntityContextMenu entity={item}>
        <ListCard
          padding="none"
          selectable={false}
          onClick={respectHandled(() => onOpen?.(item))}
        >
          <Container fit="container" stack="vertical" gap={10} size="half">
            <SpaceBetween align="flex-start">
              <VStack gap={0}>
                <SectionLabel primary>Workflow</SectionLabel>
                <TextMedium bold>{item.name || "Workflow"}</TextMedium>
              </VStack>

              <HStack fit="container" align="center" justify="flex-end">
                <StatusTag status={status.value.status || item?.status} />
                <Tooltip text="Started by">
                  <Icon icon={<RelationIcon relation={item?.createdBy} />} />
                </Tooltip>
              </HStack>
            </SpaceBetween>

            {!isFinished && (
              <WorkflowStepsBar workflow={item} status={status?.value.status} />
            )}

            {!isFinished && !blockedBy && (
              <TextSmall>Workflow preparing to run...</TextSmall>
            )}

            {!isFinished && !!blockedBy && (
              <Button
                size="small"
                variant="link"
                onClick={withHandle(() =>
                  onOpen?.(blockedBy?.refs?.created?.[0] || blockedBy)
                )}
              >
                {toWaitingMessage(item, blockedBy)}
              </Button>
            )}
          </Container>
        </ListCard>
      </EntityContextMenu>
    );
  },

  asPrimaryPane: WorkflowPane,
};

const toWaitingMessage = (workflow: Workflow, blocking: WorkflowStep) => {
  if (blocking?.status?.id === "RUN") {
    return <TextSmall>Evaluating next step...</TextSmall>;
  }

  if (blocking?.action === WorkflowAction.Wait) {
    return (
      <TextSmall>
        {sentenceCase(toWaitLabel(blocking, "Waiting for"))}
      </TextSmall>
    );
  }

  if (
    blocking?.action === WorkflowAction.Condition &&
    safeAs<boolean>(blocking?.options?.keepChecking)
  ) {
    return <TextSmall>Waiting for {blocking.name?.toLowerCase()}...</TextSmall>;
  }

  return (
    <TextSmall>
      Waiting for{" "}
      {when(toStepAssigned(blocking, { workflow, steps: [] }), (owner) => (
        <RelationText relation={owner} />
      )) || "anyone"}{" "}
      to complete{" "}
      {when(blocking?.refs?.created?.[0], (r) => (
        <RelationText relation={r} />
      ))}
    </TextSmall>
  );
};

export const WorkflowEngine: UIEngine<Workflow> = {
  asMenuItem: (props) =>
    props.item?.template
      ? TemplateWorkflowEngine.asMenuItem(props)
      : RunningWorkflowEngine.asMenuItem(props),

  asListItem: (props) =>
    props.item?.template
      ? TemplateWorkflowEngine.asListItem(props)
      : RunningWorkflowEngine.asListItem(props),

  asListCard: (props) =>
    props.item?.template
      ? TemplateWorkflowEngine.asListCard(props)
      : RunningWorkflowEngine.asListCard(props),

  asPrimaryPane: WorkflowPane,
};
