import { first, flatMap, flatten, map, reduce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  DatabaseID,
  Entity,
  EntityType,
  Error,
  getByIds,
  isError,
  PropertyValueRef,
  Update,
} from "@api";
import { EntityForType, TypeForEntity } from "@api/mappings";

import { propertiesForScope } from "@state/properties";
import {
  useAllStores,
  useGetItemFromAnyStore,
  useQueueUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";
import { getItem, StoreState } from "@state/store";
import { ActiveWorkspaceSessionAtom } from "@state/workspace";

import { ensureMany, OneOrMany, pushDirty, reduceAsync } from "@utils/array";
import { switchEnum } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { inflateStatuses } from "@utils/property-refs";

import { getActions, getSuggestions, getTriggers } from "./definitions";
import {
  RefOrID,
  toId,
  WorkflowAction,
  WorkflowContext,
  WorkflowData,
  WorkflowDefinition,
  WorkflowSuggestion,
} from "./types";
import {
  whenActionWorkflow,
  whenDidUpdate,
  whenSuggestionWorkflow,
  whenWillUpdate,
} from "./utils";

const omitError = (e: Entity | Error): Maybe<Entity> =>
  isError(e) ? undefined : e;

export const withMode = <T extends Entity>(
  u: Update<T>,
  mode: Update<T>["mode"]
) => ({ ...u, mode });

export const withWorkflowMode = <T extends Entity>(
  from: Update<T>,
  to: OneOrMany<Update<T>>
) => {
  const newMode = switchEnum(from?.mode || "", {
    sync: () => "sync",
    temp: () => "temp",
    else: () => "workflow",
  }) as Update["mode"];
  return map(ensureMany(to || []), (a) => withMode(a, newMode));
};

const useToWorkflowContext = () => {
  const me = useMe();
  const stores = useAllStores();
  const session = useRecoilValue(ActiveWorkspaceSessionAtom);
  const getItem = useGetItemFromAnyStore();

  return useCallback(
    <T extends EntityType>(
      source: DatabaseID<T>
    ): WorkflowContext<EntityForType<T>> => {
      const props = propertiesForScope(stores.props, source, me);

      const getItemFromStore = (ref: Maybe<RefOrID>) =>
        when(ref && toId(ref), getItem);

      return {
        stores,
        props,
        session,

        getItem: getItemFromStore,
        getItems: (ids) => map(ids, getItemFromStore),

        fetchItem: async (id: Maybe<RefOrID>) => {
          if (!id) return;

          return (
            getItemFromStore(id) ||
            when(first(await getByIds([toId(id)])), omitError)
          );
        },

        fetchItems: async (ids: Maybe<RefOrID[]>) => {
          if (!ids?.length) return [];

          const loaded = maybeMap(ids, getItemFromStore);

          // If all items are loaded already
          if (loaded.length === ids.length) {
            return loaded;
          }

          return map(
            await getByIds(map(ids, toId)),
            omitError
          ) as Maybe<Entity>[];
        },
      };
    },
    [stores, session, getItem]
  );
};

export const useSuggestionWorkflows = <T extends Entity = Entity>() => {
  const stores = useAllStores();
  const toContext = useToWorkflowContext();

  return useCallback(
    (update: Update<T>): WorkflowSuggestion<T>[] => {
      const entity = getItem<T>(
        // TODO: Need to figure out...
        stores[update.source.type] as any as StoreState<T>,
        update.id
      );

      if (!entity) {
        return [];
      }

      // Pull out all props for this entity
      const context = toContext(entity.source);
      const inflated = inflateStatuses(entity, context.props);

      return reduce(
        getSuggestions(entity.source.type) as WorkflowDefinition<Entity>[],
        (r, w) =>
          whenSuggestionWorkflow(
            w,
            entity.source.type as TypeForEntity<T>,
            (w) => {
              const data = {
                entity: inflated as T,
                update: update as Update<T>,
              };

              if (w.allowed(data, context)) {
                return [...r, w];
              }

              return r;
            }
          ) || r,
        [] as WorkflowSuggestion<T>[]
      );
    },
    [stores, toContext]
  );
};

export const useWillUpdateWorkflows = <T extends Entity = Entity>() => {
  const toContext = useToWorkflowContext();
  const getItem = useGetItemFromAnyStore();

  const runOnChange = useCallback(
    (entity: Maybe<T>, update: Update<T>, depth: number = 0): Update<T>[] => {
      if (!entity) {
        return [];
      }

      // Pull out all props for this entity
      const context = toContext(entity?.source || update.source);
      const inflated = inflateStatuses(entity, context.props);
      const triggers = getTriggers(
        entity.source.type
      ) as WorkflowDefinition<Entity>[];

      return reduce(
        triggers,
        (r, w) =>
          whenWillUpdate(w, entity.source.type as TypeForEntity<T>, (w) => {
            const data = { entity: inflated as T, update: update as Update<T> };

            if (!w.allowed(data, context)) {
              return r;
            }

            const updates = withWorkflowMode(
              update,
              w.execute(data, context) || []
            );

            if (!updates?.length) {
              return r;
            }

            pushDirty(r, ...updates);

            // Allow 3 levels of triggers to run
            if (depth < 3) {
              pushDirty(
                r,
                ...flatMap(updates, (u) =>
                  runOnChange(
                    u.id === entity.id ? entity : getItem(u.id),
                    u,
                    depth + 1
                  )
                )
              );
            }

            return r;
          }) || r,
        [] as Update<T>[]
      );
    },
    [toContext]
  );

  return runOnChange;
};

export const useDidUpdateWorkflows = <T extends Entity = Entity>() => {
  const stores = useAllStores();
  const toContext = useToWorkflowContext();

  // Runs asyncronous
  const runOnChange = useCallback(
    async (
      entity: T,
      update: Update<T>,
      depth: number = 0
    ): Promise<Update<T>[]> => {
      // Pull out all props for this entity
      const context = toContext(entity.source);
      const inflated = inflateStatuses(entity, context.props);
      const triggers = getTriggers(
        update.source.type
      ) as WorkflowDefinition<Entity>[];

      return reduceAsync(
        triggers,
        async (r, w) =>
          whenDidUpdate(
            w,
            update.source.type as TypeForEntity<T>,
            async (w) => {
              const data = {
                entity: inflated as T,
                update: update as Update<T>,
              };

              if (!w.allowed(data, context)) {
                return r;
              }

              const updates = withWorkflowMode(
                update,
                (await w.execute(data, context)) || []
              );

              if (!updates?.length) {
                return r;
              }

              pushDirty(r, ...updates);

              // Allow 3 levels of triggers to run
              if (depth < 3) {
                pushDirty(
                  r,
                  ...flatten(
                    await mapAll(updates, (u) =>
                      runOnChange(entity, u, depth + 1)
                    )
                  )
                );
              }

              return r;
            }
          ) || r,
        [] as Update<T>[]
      );
    },
    [stores, toContext]
  );

  return runOnChange;
};

export const useActionTriggers = <E extends Entity>(
  entity: Maybe<E>
): [WorkflowAction<E>[], Maybe<WorkflowData<E>>] => {
  const toContext = useToWorkflowContext();
  const data = useMemo(() => when(entity, (entity) => ({ entity })), [entity]);

  const actions = useMemo(() => {
    if (!entity) {
      return;
    }

    const context = toContext(entity.source);
    const inflated = inflateStatuses(entity, context.props);

    return maybeMap(
      getActions(entity.source.type) as WorkflowDefinition<Entity>[],
      (d) =>
        whenActionWorkflow(d, entity.source.type as TypeForEntity<E>, (d) =>
          d.allowed({ entity: inflated as E }, context) ? d : undefined
        )
    );
  }, [entity, toContext]);

  return useMemo(
    () => [actions, data] as [WorkflowAction<E>[], Maybe<WorkflowData<E>>],
    [actions, data]
  );
};

export const useRunAction = <T extends EntityType, E extends Entity>(
  type: T,
  data: Maybe<WorkflowData<E>>
) => {
  const stores = useAllStores();
  const mutate = useQueueUpdates();
  const toContext = useToWorkflowContext();

  const context = useMemo(
    () => when(data?.entity?.source, toContext),
    [data?.entity?.source, toContext]
  );

  const run = useCallback(
    (
      a: WorkflowAction<E> | WorkflowSuggestion<E>,
      collected: PropertyValueRef<E>[] = []
    ) => {
      if (!data || !context) {
        return;
      }

      const updates = ensureMany(
        a.execute({ ...data, collected }, context) || []
      );

      if (!!updates?.length) {
        mutate(updates as Update<Entity>[]);
      }
    },
    [context, data, stores]
  );

  return { run, context };
};

export function useRunWorkflowState<
  T extends EntityType,
  E extends EntityForType<T>
>(type: T, data: Maybe<WorkflowData<E>>) {
  const { run, context } = useRunAction<T, E>(type, data);
  const [collecting, setCollecting] = useState<Maybe<WorkflowAction<E>>>();

  const onRun = useCallback(
    (action: WorkflowAction<E>, values?: PropertyValueRef<E>[]) => {
      if (action.collect && !values) {
        setCollecting(action);
      } else {
        run(action, values);
        setCollecting(undefined);
      }
    },
    [run, setCollecting]
  );

  const cancelCollecting = useCallback(() => setCollecting(undefined), []);

  return useMemo(
    () => ({ run: onRun, context, collecting, cancelCollecting }),
    [onRun, context, collecting, cancelCollecting]
  );
}
