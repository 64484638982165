import { useSetRecoilState } from "recoil";

import { ID } from "@api";

import {
  AppCommandsAtom,
  setCommandsAction,
  useIsPageActive,
} from "@state/app";

import { useShortcut, validShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { SelectionState, SetSelectionState } from "@utils/selectable";

type Props = {
  onOpen: Fn<string, void>;
  selection: SelectionState;
  setSelection: SetSelectionState;
};

export const useSelectionShortcuts = (
  pageId: ID,
  { selection, setSelection, onOpen }: Props
) => {
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const isActive = useIsPageActive(pageId);

  useShortcut(
    ["Delete", "Backspace"],
    [
      (e) => isActive && validShortcut(e),
      () => {
        if (selection.selected.size) {
          setAppCommands(setCommandsAction("delete", true));
        }
      },
    ],
    [selection, isActive]
  );

  useShortcut(
    "Enter",
    [
      (e) => isActive && validShortcut(e),
      () => {
        if (selection.focused) {
          onOpen?.(selection.focused);
        }
      },
    ],
    [selection, isActive]
  );
};
