import { map, sample, times } from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { BaseColor, Color, Shade } from "@api";

import { cx } from "@utils/class-names";
import { toColor } from "@utils/color";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { Button } from "@ui/button";
import { Dropdown, Props as DropdownProps, useOpenState } from "@ui/dropdown";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Dice, Icon, Slash, Swatch, TimesCircle } from "@ui/icon";
import { Text } from "@ui/text";

import styles from "./color.module.css";
import { Tooltip } from "@ui/tooltip";

type Props = {
  color: Maybe<Color>;
  canClear?: boolean;
  onChange: Fn<Maybe<Color>, void>;
  children?: ReactNode;
  className?: DropdownProps["className"];
};

const COLORS: BaseColor[] = [
  "green",
  "turquoise",
  "blue",
  "purple",
  "yellow",
  "orange",
  "red",
  "pink",
  "brown",
  "gray",
];

const SHADES: Shade[] = [1, 2, 3, 4, 5];

export const randomColor = (): Color =>
  toColor(sample(COLORS) as BaseColor, sample(SHADES));

export const ColorSelect = ({
  color,
  children,
  className,
  onChange,
  canClear = true,
}: Props) => {
  const [suggested, setSuggested] = useState<Maybe<Color>>(color);
  const [open, setOpen] = useOpenState();
  const onSelected = useCallback(
    (color: Color) => {
      onChange(color);
      setOpen(false);
    },
    [onChange, setOpen]
  );

  const handleClear = useCallback(() => {
    onChange(undefined);
    setOpen(false);
  }, [onChange, setOpen]);

  const handleRandomize = useCallback(() => {
    const TIMES = 5;
    times(TIMES, (i) => {
      setTimeout(() => {
        const color = randomColor();
        setSuggested(color);
        // On last set the color but don't close picker
        if (i === TIMES - 1) {
          onChange(color);
        }
      }, i * 100);
    });
  }, [onChange]);

  useEffect(() => {
    if (!open) {
      setSuggested(undefined);
    } else {
      setSuggested(color);
    }
  }, [open]);

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      portal={true}
      className={className}
      trigger={
        children || (
          <Button
            subtle
            variant="secondary"
            size="small"
            className={styles.button}
            icon={
              <Icon size="xsmall" icon={<Swatch color={color || "gray"} />} />
            }
          />
        )
      }
    >
      <VStack gap={0}>
        <SpaceBetween className={styles.header}>
          <Text subtle>Select a color</Text>
          <HStack gap={0}>
            <Tooltip text="Random color">
              <Button
                subtle
                size="small"
                icon={Dice}
                onClick={handleRandomize}
              />
            </Tooltip>
            {canClear && (
              <Tooltip text="No color">
                <Button
                  subtle
                  size="small"
                  icon={Slash}
                  onClick={handleClear}
                />
              </Tooltip>
            )}
          </HStack>
        </SpaceBetween>

        <VStack gap={4}>
          {map(COLORS, (color) => (
            <HStack key={color} gap={2}>
              {map(SHADES, (shade) => (
                <Icon
                  key={shade}
                  onClick={() => onSelected(toColor(color, shade))}
                  className={cx(
                    styles.swatchPicker,
                    suggested === toColor(color, shade) && styles.hover
                  )}
                  icon={
                    <Swatch className={styles.swatch} color={[color, shade]} />
                  }
                />
              ))}
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Dropdown>
  );
};
