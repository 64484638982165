import { is } from "bluebird";
import { map, orderBy, reduce } from "lodash";
import { selector, selectorFamily } from "recoil";

import { Entity } from "@api";

import { EntityType } from "@prisma";

import { GenericItem, getStore } from "@state/generic";
import { getItem } from "@state/store";

import { pushDirty } from "@utils/array";
import { maybeTypeFromId, typeFromId } from "@utils/id";
import { ID, maybeMap } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { isWithinScope } from "@utils/scope";

import { RecentsAtom } from "./atoms";

export const recentsList = selector({
  key: "recentsList",
  get: ({ get }) => {
    const tuples = map(get(RecentsAtom).lookup, (date, id) => [id, date]);
    const ordered = orderBy(tuples, ([, date]) => date, "desc");
    return maybeMap(ordered, ([id]) => id);
  },
});

export const recentsListAsRefs = selector({
  key: "recentsListAsRefs",
  get: ({ get }) => {
    const tuples = map(get(RecentsAtom).lookup, (date, id) => [id, date]);
    const ordered = orderBy(tuples, ([, date]) => date, "desc");
    return maybeMap(ordered, ([id]) => toRef(id));
  },
});

export const recentsForScope = selectorFamily({
  key: "recentsForScope",
  get:
    (teamId: ID) =>
    ({ get }) =>
      reduce(
        get(RecentsAtom).lookup,
        (acc, _date, id) => {
          const store = get(getStore(typeFromId<EntityType>(id)));
          const item = getItem(store, id);

          if (!!item && isWithinScope(item?.source.scope, teamId)) {
            pushDirty(acc, item);
          }

          return acc;
        },
        [] as Entity[]
      ),
});
