import { atomFamily } from "recoil";

import { ID, Task } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace/atoms";

export type { Task };

export type TaskStoreState = StoreState<Task>;

export const WorkspaceTaskStoreAtom = atomFamily<TaskStoreState, ID>({
  key: "WorkspaceTaskStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "task" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "task")],
});

export const TaskStoreAtom = WorkspaceWrappedAtom(
  "TaskStoreAtom",
  WorkspaceTaskStoreAtom
);
