import { atomFamily } from "recoil";

import { ID, Meeting } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type MeetingStoreState = StoreState<Meeting>;

export const WorkspaceMeetingStoreAtom = atomFamily<MeetingStoreState, ID>({
  key: "WorkspaceMeetingStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "meeting" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "meeting")],
});

export const MeetingStoreAtom = WorkspaceWrappedAtom(
  "MeetingStoreAtom",
  WorkspaceMeetingStoreAtom
);
