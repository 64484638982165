import { flatMap, keys, map, reduce, set, some } from "lodash";

import { Form, FormPropDef, HasBody, Person, PropertyDef } from "@api";

import { FormData } from "@state/form";
import { NestableOverrides } from "@state/generic";
import { toDisplayName } from "@state/persons";

import { formatDay, formatTime } from "@utils/date";
import { strip } from "@utils/html";
import { Maybe, maybeMap, safeAs } from "@utils/maybe";
import { now } from "@utils/now";
import {
  toFieldKey,
  toFieldName,
  toHtml,
  toNestedKeys,
} from "@utils/property-refs";
import { append } from "@utils/rich-text";

export const isFormId = (id: string): boolean =>
  id?.startsWith("fm_") || id?.startsWith("[fm_");

export const makeNestedOverrides = (
  form: Form,
  formData: FormData,
  fields: [FormPropDef, Maybe<PropertyDef>][],
  me: Person
): NestableOverrides => {
  const connections = flatMap(fields, ([prop, def]) => {
    return map(prop.connections, (c) => [c, prop, def] as const);
  });
  const allKeys = keys(formData);

  const overrides = reduce(
    allKeys,
    (all, formKey) => {
      const value = formData[formKey];
      const connectTo = maybeMap(connections, ([c, p]) =>
        p?.field === formKey ? c : undefined
      );

      return reduce(
        connectTo,
        (all, target) => {
          const targetId = target?.id || "*";

          // Not mapped to any field
          if (!target?.field) {
            return all;
          }

          return set(
            all,
            [
              // The target entity to override (or all that have that field if not defined)
              targetId,
              ...(target.field.includes(".")
                ? toNestedKeys(target.field)
                : [target.field]),
            ],
            value
          );
        },
        all
      );
    },
    {} as NestableOverrides
  );

  const table = strip(
    `
    <p>
      <a data-mention-id="${form.id}">${form.name}</a> submitted by 
      <a data-mention-id="${me.id}" data-mention-type="person">
      ${toDisplayName(me)}
      </a> on ${formatDay(now())} at ${formatTime(now())}
    </p>
    <table>` +
      reduce(
        fields,
        (acc, [prop, def]) => {
          const value = formData[prop.field];

          return (
            acc +
            strip(
              `<tr>
                <th>${prop.label || toFieldName(def || prop)}</th>
                <td>${toHtml({
                  ...prop,
                  value: { [prop.type]: value },
                  def: def,
                })}</td>
              </tr>
              `
            )
          );
        },
        ""
      ) +
      `
      </table>
      <p></p>
      `
  );

  // Set the body of the template to the table
  set(
    overrides,
    [form?.useTemplate?.id || form.entity || "*", "body"],
    append(safeAs<HasBody>(formData)?.body, { html: table })
  );

  return overrides;
};

// Forms have fixed fields that can be in the form.fields but not always
export const withFixedFields = (fields: Form["fields"]) =>
  some(fields, (f) => f.field === "createdBy")
    ? fields
    : [
        ...(fields || []),
        { field: "createdBy", type: "relation", label: "Submitted by" },
      ];

export const isReadonlyField = ([prop, def]: [
  FormPropDef,
  Maybe<PropertyDef>
]): boolean => def?.readonly || prop.field === "createdBy";

export const toStoredField = ([prop, def]: [
  FormPropDef,
  Maybe<PropertyDef>
]): FormPropDef => ({
  field: `custom.${toFieldKey(prop.field)}`,
  type: prop.type,
  label: prop.label || toFieldName(def || prop),
  description: prop.description,
});
