import { keys } from "lodash";
import { atomFamily, selectorFamily } from "recoil";

import { Entity, ID, PropertyDef } from "@api";

import { indexedDBStorageForSimpleStore } from "@state/store";
import { TeamStoreAtom } from "@state/teams";
import {
  ActiveUserAtom,
  activeUserId,
  ActiveWorkspaceId,
  WorkspaceWrappedAtom,
} from "@state/workspace";

import { findMap } from "@utils/array";
import { fallback } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { changeScope } from "./utils";

/* Property Definition Store */

export type PropertyDefStoreState = {
  aliases?: Record<string, Maybe<string>>;
  lookup: Record<string, Maybe<PropertyDef<Entity>>>;
  updatedAt?: Maybe<Date>;
  dirty?: string[];
};

export const WorkspacePropertyDefStoreAtom = atomFamily<
  PropertyDefStoreState,
  ID
>({
  key: "WorkspacePropertyDefStoreAtom",
  default: { aliases: {}, lookup: {}, updatedAt: undefined },
  effects: (wid) => [indexedDBStorageForSimpleStore(wid, "property-defs")],
});

export const PropertyDefStoreAtom = WorkspaceWrappedAtom(
  "PropertyDefStoreAtom",
  WorkspacePropertyDefStoreAtom
);

const getItem = (s: ID, { lookup, aliases }: PropertyDefStoreState) =>
  lookup[s] || when(aliases?.[s], (id) => lookup[id]);

export const PropertyDefAtom = selectorFamily<Maybe<PropertyDef<Entity>>, ID>({
  key: "PropertyDefAtom",
  get:
    (id: ID) =>
    ({ get }): Maybe<PropertyDef<Entity>> => {
      const state = get(PropertyDefStoreAtom);

      return fallback(
        // Try get scoped version
        () => getItem(id, state),
        // Else fallback to workspace version
        () => getItem(changeScope(id, get(ActiveWorkspaceId)), state),
        // Else if a private scope, fallback to searching by team scope
        () => {
          if (!id?.endsWith(get(activeUserId) || "never")) {
            return undefined;
          }

          const teams = keys(get(TeamStoreAtom).lookup);
          return findMap(teams, (team) =>
            getItem(changeScope(id, team), state)
          );
        },
        // Else fallback to private scope
        () =>
          when(get(ActiveUserAtom)?.id, (userId) =>
            getItem(changeScope(id, userId), state)
          )
      );
    },
  set:
    (id: ID) =>
    ({ set }, val) => {
      set(PropertyDefStoreAtom, (s) => ({
        ...s,
        lookup: {
          ...s.lookup,
          [id]: val as PropertyDef<Entity>,
        },
      }));
    },
});
