import { reduce } from "lodash";

import { Job } from "@api";

import {
  clearSimpleStoreItems,
  removeSimpleStoreItem,
  setSimpleStoreItem,
  setSimpleStoreItems,
} from "@state/store";

import { ISODate, now } from "@utils/date-fp";
import { composel } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { JobStore } from "./atoms";

export const setJob = (job: Job) => (state: JobStore) =>
  setSimpleStoreItem(job.id, job)(state);

export const clearJob = (id: string) => (state: JobStore) =>
  removeSimpleStoreItem(id)(state);

export const setLastChecked = (date: ISODate) => (state: JobStore) => ({
  ...state,
  lastChecked: date,
});

export const clearLastChecked = () => (state: JobStore) => ({
  ...state,
  lastChecked: undefined,
});

export const setClaiming = (job: Maybe<Job>) => (state: JobStore) => {
  if (job && state.claiming) {
    throw new Error("Already claiming job");
  }

  return {
    ...state,
    claiming: job,
  };
};

export const setRunning = (job: Job) => (state: JobStore) => {
  if (state.running && state.running.id === job.id) {
    throw new Error("Already running job");
  }

  if (state.claiming?.id !== job.id) {
    throw new Error("Cannot run job that is not claimed");
  }

  return setJob(job)({
    ...state,
    claiming: undefined,
    running: job,
  });
};

export const finishRunning = (job: Job) => (state: JobStore) =>
  setJob(job)({
    ...state,
    running: undefined,
  });

// Setting jobs from server contains all active jobs
// If we don't clear then job that were completed by other people will stay in our store forever
// since they are not returned by the server
export const setJobs = (jobs: Job[]) => (state: JobStore) =>
  composel(
    clearSimpleStoreItems(),
    setSimpleStoreItems(jobs),
    setLastChecked(now())
  )(state);
