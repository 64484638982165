import { json } from "stream/consumers";

export const scopes = `
<training topic="general">
  Traction has a large set of Work Models known as Entities.
  
  \`\`\`typescript
  type EntityType =  "task" | "outcome" | "campaign" | "calendar" | "content" | "project" | "page" | "backlog" | "roadmap" | "sprint" | "schedule" | "view" | "team" | "note" | "resource" | "meeting" | "agenda" | "action" | "person" | "process" | "form" | "request" | "event" | "pipeline" | "workflow" | "workflow_step" | "knowledgebase" | "company" | "contact" | "deal" | "workspace";
  \`\`\`

  All work in traction can be linked (refs.*) to multiple things but lives in one place. 
  This location is called a Scope. A scope is scope is a "/" separated list of IDs that represent the location of the work. 
  The scope does NOT include the ID of the work itself, only it's parents.
  All scopes must either start with a team, person or a workspace ID (tm_ or u_ or w_).
  Only teams live in a workspace scope, all other work lives in a team or personal scope.
  
  A work's scope is stored on "source.scope" and also in the "location" property.
  E.g. a task that lives within a sprint might a location of "tm_DKIE/sp_ErKgv4pt"
  
  If you are creating work inside a parent, you should take the scope of the parent and append the parent ID to it.
  E.g. Creating a task inside a sprint ({id: "sp_ErKgv4pt", location: "tm_DKIE"}) would created a task with ({location: "tm_DKIE/sp_ErKgv4pt"})
  Except if the parent is a team, then the scope should start with the team ID and omit the workspace ID.

  Personal scopes are used for private work (u_*), nobody else can see these items.

  \`\`\`typescript
  interface DatabaseID {
    scope: string; // the location
    type: EntityType; // the type of entity
  }
  \`\`\`
</training>`;

export const richText = `
<training topic="rich_text">
  Traction rich text (rich_text property type) support two formats. Either html or markdown, however html is preferred. 
  To provide markdown you must return the rich_text property with the markdown key. (e.g. {"rich_text": {"markdown": "This is a markdown string"}})
  To provide html you must return the rich_text property with the html key. (e.g. {"rich_text": {"html": "<p>This is an html string</p>"}})

  Markdown supports common markdown syntax as well as:
  - Links: [link](https://traction.team)
  - Images: ![alt](https://traction.team/image.jpg)
  - Code: \`code\`
  - Code Block: \`\`\`code\`\`\`
  - Quotes: > quote
  - Todo: - [ ] todo
  
  HTML (preferred) supports the following tags:
  - Headers: h1, h2, h3, h4, h5, h6
  - Lists: ul, ol, li
  - Bold: b, strong
  - Italic: i, em
  - Http Links: a
  - tables: table, tr, td, th

  To mention any kind of work, use <a data-mention-id=":id">{optional_text}</a> where :id is the work ID.
  Traction Mentions will show the full name of the work inline, so when referencing work, you should use Traction Mentions rather than hyperlinking the text directly.

  User can be mentioned in rich_text by their ID in the format: <a data-mention-id=":id"></a>
    where :id is the user ID (does not support variables)

  Variables can be referenced in rich_text html by using: <span data-var-field=":field" data-var-type=":type">{:name}</span>
   where the placeholders: 
    :field is the variable.field
    :type is the variable.type (PropertyType)
    :name (optional) is the variable.label.

  Tables are a great way to display tabular data in rich text.
  When adding tables to store key/value data, use the left column as the header (th) and the right column as the value (td).
</training>`;

export const ids = `
<training topic="ids">
  Traction uses HumanIDs which are ids that are human readable and easy to remember.
  They are in the format of [type]_[4-20 alphanumeric characters] e.g. "t_ErKgv4pt".
  
  This is our HumanID configuration:
  \`\`\`typescript
  const humanId = configure(
    {
      // 6-character IDs
      team: ["tm", 6],
      calendar: ["ca", 6],
      campaign: ["cm", 6],
      content: ["c", 6],
      person: ["u", 6],
      backlog: ["bl", 6],
      sprint: ["sp", 6],
      workspace: ["w", 6],
      project: ["p", 6],
      roadmap: ["rm", 6],
  
      // 10-character IDs
      job: ["tj", 10],
  
      // All others default to 8 characters
      task: "t",
      outcome: "o",
      page: "d",
      knowledgebase: "kb",
      form: "fm",
      process: "ps",
      schema: "sc",
      schedule: "sd",
      person_workspace: "uw",
      resource: "r",
      meeting: "m",
      agenda: "ag",
      action: "ac",
      note: "n",
      view: "v",
      workflow: "wf",
      workflow_step: "ws",
      pipeline: "pl",
      event: "e",
      request: "re",
      company: "co",
      contact: "ct",
      deal: "dl",
    },
    { defaultLength: 6, // Most basic entities used 6 chars in original, generateId: cid }
  );
  \`\`\`

  When creating work that has not yet been saved to the API we use LocalIDs. This allows you to inter-reference work that needs to be created.
  A LocalID is any TractionID inside [] square brackets e.g. [t_ErKgv4pt]
  When returning work that does not yet exist but should be created use LocalIDs. 
  Traction will replace all the referenced LocalIDs with the actual IDs when the work is saved.
  If you are creating localIDs and want to make them friendly to read (e.g. [t_newtask]) make sure they are only alphanumeric characters.
</training>`;

export const workflows = `
<training topic="workflows">
  Workflows are a structured way to define a process that should be followed. They consist of WorkflowSteps that define what should be done in what order.
  Each WorkflowStep defines it's dependencies via the step.refs.blockedBy property (which is synced on the other side with the refs.blocks property).
  Workflows run by finding the next step that is not blocked and running it.
  Each step is essentially an instruction to the system to do something.

  \`\`\`typescript
  type Ref = { id: ID; name?: string };
  type RefsData = SafeRecord<string, Ref[]>;

  type CoreEntity<T extends EntityType> = CoreDates & {
    id: ID;
    refs: RefsData;
    custom: Maybe<CustomData>;
    stamps: Maybe<Stamps>;
    settings?: Maybe<SettingsData>;
    source: DatabaseID<T>;
  };
  
  type Locationable = {
    location: string;
  };
  
  type Templatable = {
    template: Maybe<Template>;
  };
  
  type Archivable = {
    archivedAt: Maybe<PointDate>;
    archivedBy: Maybe<PersonRef | Ref>;
  };

  type Workflow = CoreEntity<"workflow"> &
    Templatable &
    Locationable &
    Archivable & {
      name: Maybe<string>;
      owner: Maybe<Ref>;
      status: Maybe<Status>;
      orders: Maybe<Orders>;
      inputs: Maybe<Vars>;
      vars: Maybe<Vars>;
    };
  
  enum WorkflowAction { Create = "create", Update = "update", Wait = "wait", Find = "find", API = "api", Entry = "entry", Exit = "exit", Condition = "condition", RunWorkflow = "run_workflow", SetVar = "set_var", Control = "control", AI = "ai", Message = "message"}
  
  type WorkflowStep = CoreEntity<"workflow_step"> &
    Templatable &
    Locationable &
    Archivable & {
      name: Maybe<string>;
      status: Maybe<Status>;
      action: Maybe<WorkflowAction>;
      orders: Maybe<Orders>;
      condition: Maybe<FilterQuery>;
      outputs: Maybe<Vars>;
      options: Maybe<OptionsData>;
      overrides: Maybe<PropertyValueRef[]>;
    };
  \`\`\`

  ## Variables:
  
  Workflows can define Variables (inputs) that are required to be passed in when the workflow is run.
  Each WorkflowStep can also define Variables (outputs) that are "outputted" when the step is run.
  Any WorkflowStep can reference the variables from the workflow or previous steps outputs.
</training>`;

export const workflowSteps = `
<training topic="workflow_steps">
  Workflow Steps are the building blocks of a Workflow. They define what should be done in what order.

  ## Dependencies:
  
  Workflow Steps define dependencies via the refs.blockedBy property. A previous step must be completed before the next step can be run.
  If the previous step is a Create Task Step, then the task must be completed before the next step can run.
  When work is blocked by another step, use the step.refs.blockedBy, not on the work itself.
  
  For example, to define a WorkflowStep that relies on a previous step with the ID "ws_3vuUhL" you would return:
  \`\`\`json
  [{
    id: "[ws_hy2Q82]",
    name: "Second Step",
    action: "create",
    options: {entity: "task"},
    refs: {
      blockedBy: [{id: "ws_3vuUhL"}]
    },
    overrides: [
      {field: "title", type: "text", value: {text: "Second task to be completed"}}
    ],
    source: {type: "workflow_step", scope: "wf_3vuUhL"},
  }]
  \`\`\`

  ## "create" steps:
    - defines a step where work should be created
    - use "overrides" to define the template for the work as an array of PropertyMutation
    - use "options.entity" to specify what type of EntityType should be created. Curretnly only supports: task | document
    - The "overrides" property on a WorkflowStep can be thought of as a template. It allows you to define the mutations that should be applied when the step is run.
    - steps do not need to be assigned an "owner", most of the time the owner should be set on the work that is created.

  For example, to define a WorkflowStep that creates a task with the title "Some new task" when running you would return:
  \`\`\`json
  [{
    id: "[ws_hy2Q82]",
    name: "Create Step",
    action: "create",
    options: {entity: "task"}
    overrides: [
      {field: "title", type: "text", value: {text: "Some new task"}},
      {"field":"body", "type":"rich_text", op: "add", "value":{"rich_text": {"html": "<p>This is rich text in an override.</p>"}}}
    ],
    source: {type: "workflow_step", scope: "wf_3vuUhL"},
  }]
  \`\`\`

  ## "update" steps:
    - defines a step where work should be updated
    - use "options.target" to specify the ID of the work that should be updated

  For example, to define a WorkflowStep that will update the body of a task with the ID "t_6yYv91" when running you would return:
  \`\`\`json
  [{
    id: "[ws_hy2Q82]",
    name: "Update Step",
    action: "update",
    options: { target: "t_6yYv91" },
    overrides: [
      {"field":"body", "type":"rich_text", op: "add", "value":{"rich_text": {"html": "<p>This is added to the body</p>"}}}
    ],
    source: {type: "workflow_step", scope: "wf_3vuUhL"}
  }]
  \`\`\`

  ## "wait" steps:
    - defines a step where the workflow should pause until a condition is met
    - use "options.waitTimes" to specify how many periods should be waited
    - use "options.waitPeriod" to specify "hour" | "day" | "week" | "month" | "quarter" | "year"

  ## "control" steps:
    - defines a step where the workflow should pause until dependencies are met
    - use "options.all" to specify if all dependencies should be met or just one

  ## "message" steps:
    - defines a step where a message should be created, mentioning people in the message will notify them
    - use "options.message" to specify the message that should be created
    - optionally use "options.channel" to specify the slack channel ID to send it (only if known)

  ## "condition" steps:
    - define a step that conditionally passes based on a filter condition
    - the "options.target" references the work to check
    - the "options.filter" references the FilterQuery that needs to pass for this step to complete.

  ## "exit" steps:
    - define the end of a workflow
    - can be used in a branch of steps to end the workflow early

</training>`;

export const properties = `
<training topic="property">
  Traction defines allowed fields (properties) against an entity. Each field has a name, type and value, where value is in the format {[type]: value} (see types below).

  \`\`\`typescript
  type PropertyValueRef = {
    field: string;
    type: EntityType;
    value: PropertyValue;
  };

  type PropertyValue = {
    text?: string;
    rich_text?: {html?: string, markdown?: string, text?: string;};
    number?: number;
    status?: Status;
    select?: {id: string};
    date?: string;
    boolean?: boolean;
    formula?: string;
    multi_select?: {id: string}[];
    relation?: {id: string};
    relations?: {id: string}[];
    link?: {id: string, text?: string, url?: string};
    links?: {id: string, text?: string, url?: string}[];
    json?: Record<string, any> | any[];
  };

  type Status = {
    id?: string, 
    name?: string, 
    group?: "planning" | "not-started" | "in-progress" | "done"
  }
  
  type PropertyType = keyof PropertyValue;
  \`\`\`

  When defining rich text, in a PropertyValue (e.g. Update, Variable) you need to have rich_text then the html inside it:
  \`\`\`json
  {"value": {"rich_text": {"html": "<p>This is rich text</p>"}}}
  \`\`\`

</training>`;

export const mutating = `
<training topic="mutating">
  All mutations (create, update, delete) in Traction are done through an event queue system that accept Updates.
  Each Update defines how the system should be changed.
  For create/update Updates, the changes array contains the field Mutations that should be applied.

  \`\`\`typescript
    type Update {
      id: ID;
      source: DatabaseID;
      queued?: Timestamp;
      persisted?: Timestamp;
      transaction?: ID;
      mode?: "temp" | "sync" | "workflow";
      attempt?: number;
      nextAttempt?: PointDate;
    } & (
      | {
          method: "update";
          changes: PropertyMutation[];
        }
      | {
          method: "apply";
          changes: PropertyMutation[];
        }
      | {
          method: "create";
          changes: PropertyMutation[];
        }
      | {
          method: "delete";
          previous: Maybe<T>;
        }
      | {
          method: "restore";
          restored: Maybe<T>;
        }
    );

    type PropertyMutation = {
      field: string
      type: PropertyType;
      value: PropertyValue;
      op?: "set" | "clear" | "add" | "remove";
      prev?: PropertyValue;
    };
  \`\`\`

  For example if you wanted to create a task with the title "Some new task" you would return:
  \`\`\`json
  [{
    "id":"[t_Qackcv]",
    "source":{"type":"task","scope":"tm_6yYv91/bl_L8qd6z"}, // Some valid location
    "method":"create",
    "changes": [
      { "field":"title", "type":"text", "value":{"text":"Some new task"} } }
    ],
  }]      
  \`\`\`

  When updating/deleting work the id field of the Update should be the ID of the work you are modify.
  For example if you wanted to update an existing task (t_02FJMRM6) and set the title "A different title" you would return:
  \`\`\`json
  [{
    "id":"t_02FJMRM6",
    "method":"update",
    "source":{"type":"task","scope":"tm_6yYv91/bl_L8qd6z"}, // The existing location of the task
    "changes": [
      { "field":"title", "type":"text", "value":{"text":"A different title"} } }
    ],
  }]      
  \`\`\`

  Json fields store the whole value as a json object.
  - If you want to add a new value to a json field, you should use op: "add"
  
  e.g.
  \`\`\`json
  {
    id: "ws_hy2Q82",
    method: "update", 
    source: {type: "workflow_step", scope: "wf_3vuUhL"},
    changes: [
      {"field":"overrides", "type":"json", "op": "add", "value":{"json":[{"field":"assigned", "type":"relation", "value": {"relation": {"id": "u_6yYv91"}}}]}}
    ]
  }
  \`\`\`

</training>`;

export const links = `
<training topic="links">
  All work in traction can be linked to by simply using the ID.
  E.g. the url for a task with id t_ErKgv4pt would be https://traction.team/t_ErKgv4pt

  Links can also contain the location (scope) of the entity (but do not have to).
  E.g. the url for a task with id t_ErKgv4pt and location tm_DKIE/sp_ErKgv4pt could be https://traction.team/tm_DKIE/sp_ErKgv4pt/t_ErKgv4pt
</training>`;

export const variables = `
<training topic="variables">
  Variables are stored in JSON fields such as (inputs, outputs, and vars);
  
  \`\`\`typescript
  type Variable = {
    field: string; // A string identifier for the variable
    type: PropertyType;
    value: PropertyValue;
    label?: Maybe<string>;
    description?: Maybe<string>;
    options?: {
      // Relation Options
      references?: OrStar<OneOrMany<EntityType>>;
      sync?: string;
      hierarchy?: "parent" | "child";
    
      // Date options
      mode?: "calendar" | "point";
    
      // Number options
      format?: Maybe<PropertyFormat>;
    };
  }

  type Vars = Variable[];
  \`\`\`

  ## Types of Variables
  Variables can be of any PropertyType. Some tips for deciding what type to use:
  - text: for plain text
  - rich_text: for rich text (html with links, mentions, variable usage, etc)
  - number: for numbers
  - date: for dates
  - boolean: for true/false values
  - relation: for a single relation
  - relations: for multiple relations
  - select: for selecting a single value from pre-defined options
  - multi_select: for selecting multiple values from pre-defined options
  - link: for collecting a url (with optional text)
  - links: for collecting multiple urls (with optional text)

  ## Using Variables:
  There are two ways that you can use variables.

  1. As a property value
  Variables can be used as a PropertyValue by setting value to {formula: "=var(':field')"} where :field is the variable.field.
  For example, if you wanted to use a person variable with the field "assigned" you would return:
  \`\`\`json
    {"field": "assigned", "type": "relation", "value": {"formula": "=var('assigned')"}}
  \`\`\`

  2. In rich text
  Variables can be used in the html of rich_text PropertyValues by using: <span data-var-field=":field" data-var-type=":type">{:name}</span>
  For example, to reference a variabel inside a rich_text html you would use:
  \`\`\`json
  {"rich_text": {"html": "<p>This is rich text with a variable: <span data-var-field='assigned' data-var-type='relation'>Assigned</span></p>"}}
  \`\`\`
  
  Variables cannot be used in inside plain text ("text") fields or property values.
  Variables cannot be used in title or name fields (even when inside overrides).
</training>`;

export const filters = `
<training topic="Filters">
  Filters define a where clause.

  \`\`\`typescript
  export type FilterOperation = "does_not_contain" | "contains" | "does_not_equal" | "greater_than" | "less_than" | "equals" | "after" | "before" | "starts_with" | "ends_with" | "is_empty" | "is_not_empty" | "equals" | "before" | "after" | "on_or_before" | "on_or_after";
  
  export type PropertyFilter = {
    field: string;
    type: PropertyType;
    op: FilterOperation;
    value?: Maybe<PropertyValue>;
    values?: Maybe<PropertyValues>;
    empty?: boolean;
  };
  
  export type FilterQuery<E extends Entity = Entity> =
    | PropertyFilter
    | { and: FilterQuery<E>[] }
    | { or: FilterQuery<E>[] };

  export type PropertyValues = {
    [P in keyof PropertyValue]?: FlattenType<Exclude<PropertyValue[P], undefined>>[];
  };
  \`\`\`

  Some rules:
  - PropertyFilter.value should be used when the operation is a single value
  - PropertyFilter.values should be used when the operation is multiple values
  - PropertyFilter.empty is used to indicate that fields without a value should be included. This allows you to include empty fields in other operations.


  For example, if you wanted to filter tasks that not assigned to a user or match a certain status you would return:
  \`\`\`json
  {or:[
    {field: "assignee", type: "relation", op: "is_empty"},
    {field: "status", type: "status", op: "equals", value: {status: {id: "20ks"}}}
  ]}
  \`\`\`

  When filtering on Status, you can either specify the status id or the status group. E.g. if you want to show all tasks that are not done you could use:
  \`\`\`json
    {field: "status", type: "status", op: "does_not_equal", value: {status: {group: "done"}}}
  \`\`\`
</training>`;
