import { orderBy, reduce } from "lodash";
import { selectorFamily } from "recoil";

import {
  Entity,
  EntityType,
  HasArchivedAt,
  HasTemplate,
  ID,
  toTitleOrName,
} from "@api";
import { EntityMap } from "@api/mappings";

import { getStore } from "@state/generic";

import { safeAs } from "@utils/maybe";
import { maybeValues, set } from "@utils/object";
import { isMatch } from "@utils/scope";

export const allTemplates = selectorFamily({
  key: "allTemplates",
  get:
    ({
      entities,
      archived,
      teamId,
    }: {
      entities: EntityType[];
      archived?: boolean;
      teamId?: ID;
    }) =>
    ({ get }): EntityMap =>
      reduce(
        entities,
        (acc, type) => {
          const store = get(getStore(type));
          const res = orderBy(
            maybeValues(
              store.lookup,
              (i) =>
                (i as HasTemplate)?.template === "root" &&
                (archived
                  ? !!safeAs<HasArchivedAt>(i)?.archivedAt
                  : !safeAs<HasArchivedAt>(i)?.archivedAt) &&
                (!teamId || isMatch(i.source?.scope, teamId))
            ),
            (i) => toTitleOrName(i)
          );
          return set(acc, { [type]: res });
        },
        {} as EntityMap
      ),
});

export const templatesForSource = selectorFamily({
  key: "templatesForSource",
  get:
    ({
      type,
      archived,
      scope,
    }: {
      type: EntityType;
      archived?: boolean;
      scope: string;
    }) =>
    ({ get }): Entity[] => {
      const store = get(getStore(type));

      return maybeValues(
        store.lookup,
        (i) =>
          (i as HasTemplate)?.template === "root" &&
          (archived
            ? !!safeAs<HasArchivedAt>(i)?.archivedAt
            : !safeAs<HasArchivedAt>(i)?.archivedAt) &&
          isMatch(i.source?.scope, scope)
      );
    },
});
