import { filter, first, flatMap, map, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { Workflow } from "@api";

import {
  useLazyEntities,
  useRunningTime,
  useUpdateEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import {
  isFinished,
  toStepAssigned,
  toSystemVars,
  useWorkflowSteps,
} from "@state/workflow";

import { maybeMap } from "@utils/array";
import { formatHuman } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { debug } from "@utils/debug";
import { format } from "@utils/epoch-date";
import { equalsAny, switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";
import { useGoTo, usePathName, usePushTo, useReplace } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { toParentScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityPreview } from "@ui/entity-preview";
import { EntitySheet } from "@ui/entity-sheet";
import { HalfSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight } from "@ui/icon";
import { InflatedStatusTag } from "@ui/inflated-status-tag";
import { ListCard } from "@ui/list-card";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem } from "@ui/menu-item";
import { PeopleStack } from "@ui/people-stack";
import { PropertyValueButton } from "@ui/property-value-button";
import { PropertyValuesList } from "@ui/property-values-list";
import { RelationText } from "@ui/relation-label";
import { Section } from "@ui/section";
import { Text, TextLarge, TextSmall } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";

import { render, toEngine } from "..";
import { PaneOpts } from "../types";
import { WorkflowStepsBar } from "./components";

import styles from "./styles.module.css";

export const WorkflowPane = ({ item, size, className }: PaneOpts<Workflow>) => {
  const me = useMe();
  const pushTo = usePushTo();
  const redirect = useReplace();
  const path = usePathName();
  const steps = useWorkflowSteps(item);
  const current = useMemo(
    () =>
      flatMap(steps, (s) =>
        equalsAny(s.status?.id, ["WAI", "RUN"]) ? s.refs.created || [] : []
      ),
    [steps]
  );
  const blockingWork = useLazyEntities(current);
  const myWorkSteps = useMemo(
    () =>
      maybeMap(steps, (s) =>
        toStepAssigned(s, { workflow: item, steps })?.id === me?.id
          ? toRef(first(s.refs?.created) || s)
          : undefined
      ),
    [current, steps]
  );
  const myWork = useLazyEntities(myWorkSteps);

  const runningTime = useRunningTime(item);

  useEffect(() => {
    if (item.template) {
      debug("Redirecting to builder, this is probably a bad link.");
      redirect([path, "/builder"]);
    }
  }, []);

  return (
    <EntitySheet
      height="container"
      size={size || "primary"}
      className={className}
      entity={item}
    >
      <Container
        stack="vertical"
        gap={40}
        fit="container"
        style={{ paddingTop: "0px" }}
      >
        <Container inset="left" padding={"none"} stack="vertical" gap={40}>
          <VStack>
            <WorkflowHeader workflow={item} />

            <WorkflowStepsBar workflow={item} />
          </VStack>
        </Container>
        <Card interactable={false} padding="both">
          <VStack gap={20}>
            <SpaceBetween align="flex-start">
              <HalfSpace>
                <VStack gap={10}>
                  <VStack gap={0}>
                    <Text subtle>Running Time</Text>
                    <TextLarge bold>
                      {runningTime?.mins < 60
                        ? `${runningTime.mins} mins`
                        : runningTime?.hours < 24
                        ? `${runningTime.hours} hours`
                        : `${runningTime.days} days`}
                    </TextLarge>
                  </VStack>
                </VStack>
              </HalfSpace>
            </SpaceBetween>

            <SpaceBetween align="flex-start" gap={20}>
              <HalfSpace>
                <Section title="Current" padded={false}>
                  <Menu>
                    <MenuGroup>
                      {map(blockingWork, (item) =>
                        render(toEngine(item)?.asMenuItem, {
                          key: item.id,
                          item,
                          showProps: [{ field: "assigned", type: "relation" }],
                          onOpen: () => when(item, pushTo),
                        })
                      )}
                      {!blockingWork?.length && (
                        <EmptyMenuItem text="All required steps complete." />
                      )}
                    </MenuGroup>
                  </Menu>
                </Section>
              </HalfSpace>
              <HalfSpace>
                <Section title="Assigned to Me" padded={false}>
                  <Menu>
                    <MenuGroup>
                      {map(myWork, (item) =>
                        render(toEngine(item)?.asMenuItem, {
                          key: item.id,
                          item,
                          disabled: item.source.type === "workflow_step",
                          showProps: [{ field: "assigned", type: "relation" }],
                          onOpen: pushTo,
                        })
                      )}
                      {!myWork?.length && (
                        <EmptyMenuItem text="Nothing in this workflow is assigned to you." />
                      )}
                    </MenuGroup>
                  </Menu>
                </Section>
              </HalfSpace>
            </SpaceBetween>
          </VStack>
        </Card>

        <Divider />

        <CollapsibleSection divider={false}>
          <WorkflowTimeline workflow={item} />
        </CollapsibleSection>

        <CollapsibleSection
          title="Workflow Templates"
          padded={false}
          defaultOpen={false}
          actions={
            <Button
              onClick={() => pushTo("/builder")}
              iconRight={ArrowUpRight}
              size="small"
              subtle
            >
              Edit
            </Button>
          }
        >
          <WorkflowBuilderFlow id={item.id} className={styles.flowPreview} />
        </CollapsibleSection>

        <HStack>
          <WorkflowActions entity={item} showCustom={false} />
        </HStack>
      </Container>
    </EntitySheet>
  );
};

interface HeaderProps {
  workflow: Workflow;
}

const WorkflowHeader = ({ workflow }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(workflow.id, pageId);
  const goTo = useGoTo();

  if (!workflow) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={80}>
      <EntityHeaderBar entity={workflow} padding="vertical" />

      <Centered padding="none">
        <VStack gap={20} fit="container">
          <PeopleStack size="xlarge" people={workflow.refs?.followers} />

          <SpaceBetween align="flex-end">
            <VStack gap={6} fit="container">
              <EditableHeading
                key={workflow.id}
                text={workflow.name || ""}
                size="h2"
                placeholder="Workflow name"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />

              {when(toParentScope(workflow.location), (id) => (
                <TextSmall subtle>
                  For{" "}
                  <Button variant="link" onClick={() => goTo(id)}>
                    <RelationText
                      icon={false}
                      subtle
                      size="small"
                      relation={{ id }}
                    />
                  </Button>
                </TextSmall>
              ))}
              {/* <TextSmall subtle>
                Last updated {useISODate(workflow.updatedAt, formatHuman)}
              </TextSmall> */}
            </VStack>

            <HStack>
              <PropertyValueButton
                entity={workflow}
                field="status"
                inset={false}
                type="status"
                icon={false}
                editable={false}
              />
            </HStack>
          </SpaceBetween>
        </VStack>
      </Centered>
    </VStack>
  );
};

export const WorkflowTimeline = ({ workflow }: HeaderProps) => {
  const pushTo = usePushTo();
  const finished = useMemo(() => isFinished(workflow), [workflow?.status]);
  const steps = useWorkflowSteps(workflow);
  const [showInputs, setShowInputs] = useState(false);
  const upcoming = useMemo(
    () =>
      filter(steps, (s) =>
        switchEnum(s.action || "", {
          create: s.status?.id === "NTS",
          wait: s.status?.id === "WAI",
          else: false,
        })
      ),
    [steps]
  );
  const workIds = useMemo(
    () => flatMap(steps, (s) => s.refs?.created || []),
    [steps]
  );
  const work = useLazyEntities(workIds);
  const orderdWork = useMemo(
    () => orderBy(work, (w) => w.createdAt, "asc"),
    [work]
  );

  return (
    <VStack gap={10}>
      <VStack gap={4}>
        <TextSmall subtle>
          {useISODate(workflow.createdAt, formatHuman)}
        </TextSmall>

        <ListCard selectable={false}>
          <VStack gap={10}>
            <SpaceBetween>
              <Text>
                {workflow.name || "Workflow"} started by{" "}
                <RelationText relation={workflow.createdBy} />
                {when(workflow.refs?.startedFrom?.[0], (from) => (
                  <>
                    {" "}
                    from{" "}
                    <Button variant="link" onClick={() => pushTo(from)}>
                      <RelationText relation={from} />
                    </Button>
                  </>
                ))}
              </Text>

              {!!workflow.inputs?.length && (
                <Button
                  subtle
                  size="small"
                  onClick={() => setShowInputs(!showInputs)}
                >
                  <Text subtle>Show {showInputs ? "less" : "more"}</Text>
                </Button>
              )}
            </SpaceBetween>

            {showInputs && !!workflow.inputs?.length && (
              <>
                <PropertyValuesList
                  propsEditable={false}
                  values={workflow.inputs}
                  source={workflow.source}
                  editable={false}
                />

                <Divider label="System" />

                <PropertyValuesList
                  propsEditable={false}
                  values={toSystemVars(workflow)}
                  source={workflow.source}
                  editable={false}
                />
              </>
            )}
          </VStack>
        </ListCard>
        <div className={styles.timeline} />
      </VStack>

      {map(orderdWork, (item, i) => (
        <VStack key={item.id} fit="container" gap={4}>
          <TextSmall subtle>
            {useISODate(item.createdAt, formatHuman)}
          </TextSmall>

          {render(toEngine(item)?.asListItem, {
            key: item.id,
            item,
            onOpen: pushTo,
          })}

          {false && (
            <Card>
              <EntityPreview
                entity={item}
                onOpen={pushTo}
                editable={true}
                propBlacklist={["refs.fromWorkflow"]}
                hideParents={true}
              />
            </Card>
          )}
          {i !== (orderdWork?.length || 0) - 1 && (
            <div className={styles.timeline} />
          )}
        </VStack>
      ))}

      {!finished && !!upcoming.length && (
        <>
          <div className={styles.timeline} />

          <Divider label="Upcoming" />
          <div className={styles.timeline} />
          {map(upcoming, (item, i) =>
            render(toEngine(item)?.asListItem, {
              key: item.id,
              item,
              onOpen: () => pushTo([workflow, "/builder"]),
            })
          )}
        </>
      )}

      {finished && (
        <VStack gap={4}>
          <div className={styles.timeline} />
          <TextSmall subtle>
            {when(workflow.stamps?.completed?.at, (d) =>
              format(d, formatHuman)
            ) || useISODate(workflow.updatedAt, formatHuman)}
          </TextSmall>

          <ListCard selectable={false}>
            <VStack gap={10}>
              <Text>
                Workflow finished with status{" "}
                <InflatedStatusTag
                  status={workflow.status}
                  source={workflow.source}
                />
              </Text>
            </VStack>
          </ListCard>
        </VStack>
      )}
    </VStack>
  );
};
