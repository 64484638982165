import { isFunction } from "lodash";
import {
  ClipboardEventHandler,
  CSSProperties,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { cx } from "@utils/class-names";
import { useInputShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import { VStack } from "@ui/flex";

import { Props as ContainerProps } from "./container";
import { Icon } from "./icon";
import { Label } from "./label";
import { TextSmall } from "./text";

import styles from "./input.module.css";

interface FieldProps {
  layout?: "vertical" | "horizontal";
  icon?: ComponentOrNode;
  label?: string;
  help?: string;
  padded?: boolean;
  fit?: ContainerProps["fit"];
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
  onClick?: Fn<React.MouseEvent, void>;
}

export const Field = ({
  children,
  icon,
  layout = "vertical",
  fit = "container",
  padded = false,
  onClick,
  help,
  label,
  style,
  className,
}: FieldProps) => (
  <div
    className={cx(
      styles.field,
      fit && styles[fit],
      layout && styles[layout],
      className
    )}
    style={style}
    onClick={onClick}
  >
    <VStack gap={0} fit="content" className={styles.labelContainer}>
      {label && (
        <Label icon={icon} className={cx(styles.label)}>
          {label}
        </Label>
      )}
      {help && (
        <TextSmall subtle className={styles.help}>
          {help}
        </TextSmall>
      )}
    </VStack>
    {padded ? <div className={styles.padding}>{children}</div> : children}
  </div>
);

export const Fields = ({ children, label, icon }: FieldProps) => (
  <VStack className={styles.field} fit="container" gap={4}>
    {label && (
      <div className={cx(styles.label, !!icon && styles.inset)}>
        <Label subtle icon={icon} text={label} />
      </div>
    )}
    <div className={styles.fieldStack}>{children}</div>
  </VStack>
);

export interface TextInputProps {
  value: string;
  variant?: "secondary" | "default";
  placeholder?: string;
  updateOn?: "change" | "blur";
  disabled?: boolean;
  onChange?: Fn<string, void>;
  inputType?: "text" | "number";
  icon?: ComponentOrNode;
  className?: string;
  selectOnFocus?: boolean;
  clearOnEscape?: boolean;
  blurOnEnter?: boolean;
  autoFocus?: boolean | undefined;
  style?: CSSProperties;
  isValid?: Fn<string, boolean>;
  onEnter?: Fn<Maybe<string>, void>;
  onFocus?: Fn<void, void>;
  onBlur?: Fn<void, void>;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  onClick?: Fn<React.MouseEvent, void>;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      value,
      placeholder,
      updateOn = "blur",
      variant = "default",
      icon,
      autoFocus,
      selectOnFocus,
      inputType = "text",
      clearOnEscape = false,
      blurOnEnter = true,
      disabled,
      isValid,
      onEnter,
      onChange,
      onClick,
      className,
      onFocus,
      onBlur,
      onPaste,
      style,
    },
    ref
  ) => {
    const [val, setVal] = useState(value);
    const localRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (val !== value) {
        setVal(value);
      }
    }, [value]);

    // Clear or blur on escape
    useInputShortcut(
      localRef,
      "Escape",
      () => {
        if (clearOnEscape && val) {
          onChange?.("");
          setVal("");
        } else {
          localRef.current?.blur();
        }
      },
      [onChange, val]
    );

    // Submit on enter
    useInputShortcut(
      localRef,
      "Enter",
      [
        () => blurOnEnter || !!onEnter,
        () => {
          if (blurOnEnter) {
            localRef.current?.blur();
          }
          if (onEnter) {
            onEnter?.(localRef.current?.value);
          }
        },
      ],
      [blurOnEnter, onEnter, localRef.current]
    );

    // Forward the ref when changes
    useEffect(() => {
      if (ref) {
        isFunction(ref)
          ? ref?.(localRef.current)
          : (ref.current = localRef.current);
      }
    }, [localRef.current]);

    return (
      <div className={cx(styles.inputWrapper, !!icon && styles.indent)}>
        {icon && <Icon className={styles.icon} icon={icon} />}
        <input
          ref={localRef}
          onClick={onClick}
          className={cx(
            styles.input,
            !!val && !!isValid && !isValid?.(val) ? styles.invalid : undefined,
            variant && styles[variant],
            disabled && styles.disabled,
            className
          )}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          type={inputType}
          style={style}
          value={val}
          onPaste={onPaste}
          onChange={(e) => {
            setVal(e.target.value);
            if (updateOn === "change") {
              onChange?.(e.target.value);
            }
          }}
          onFocus={(e) => {
            onFocus?.();
            if (selectOnFocus) {
              e.target.select();
            }
          }}
          onBlur={() => {
            if (updateOn === "blur") {
              onChange?.(val);
            }
            onBlur?.();
          }}
        />
      </div>
    );
  }
);
