import {
  Dictionary,
  every,
  filter,
  find,
  first,
  flatMap,
  flatten,
  groupBy,
  isString,
  last,
  map,
  omit,
  reduce,
  without,
} from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { getRecoil } from "recoil-nexus";

import {
  CreateOrUpdate,
  DatabaseID,
  Entity,
  EntityType,
  HasAssigned,
  HasBody,
  hasBody,
  HasCode,
  HasDates,
  HasOrders,
  hasOrders,
  HasOwner,
  HasRefs,
  HasRepeat,
  hasStatus,
  HasTitle,
  ID,
  Integration,
  isEntity,
  isStatusable,
  Note,
  PropertyDef,
  PropertyMutation,
  PropertyRef,
  Ref,
  Status,
  toTitleOrName,
  Update,
} from "@api";
import { EntityForType, EntityMap } from "@api/mappings";

import { useClearAllStores } from "@state/debug";
import {
  allPropertiesForSource,
  allStatusesForTeam,
  PropertyDefStoreAtom,
  useLazyProperties,
  useLazyPropertyValues,
  useNestedProperties,
} from "@state/properties";
import { useAddToRecents } from "@state/recents";
import { toDayOfPeriod } from "@state/schedule";
import {
  addChanges,
  getItem,
  mutReferencesLocalIDs,
  replaceRelationRefs,
} from "@state/store";
import { useWillUpdateWorkflows } from "@state/workflows";
import { useActiveWorkspaceId, useAuthedUserId } from "@state/workspace";

import {
  ensureMany,
  groupByMany,
  justOne,
  maybeLookup,
  omitEmpty,
  uniqBy,
} from "@utils/array";
import { now } from "@utils/date-fp";
import { debug, warn } from "@utils/debug";
import { DragRef, DragToRef } from "@utils/drag-drop";
import { useAsyncEffect } from "@utils/effects";
import { fallback, Fn } from "@utils/fn";
import { toGroupUpdate } from "@utils/grouping";
import {
  asLocal,
  isLocalID,
  newADDDID,
  newHumanId,
  newID,
  newLocalHumanId,
} from "@utils/id";
import { equalsAny, ifDo, switchEnum } from "@utils/logic";
import {
  isDefined,
  Maybe,
  maybeMap,
  safeAs,
  when,
  whenTruthy,
} from "@utils/maybe";
import {
  maybeValues,
  merge,
  omitEmpty as omitEmptyVals,
  set,
  setDirty,
} from "@utils/object";
import {
  DecimalOrdering,
  evenlyBetween,
  setOrders,
  toNewOrders,
  toOrder,
  toOrders,
} from "@utils/ordering";
import {
  asAppendMutation,
  asDeleteUpdate,
  asMutation,
  asUpdate,
  flattenUpdate,
  toMutation,
} from "@utils/property-mutations";
import {
  getPropertyValue,
  isEmptyRef,
  isTimestamp,
  omitEmptyRef,
  toRef,
} from "@utils/property-refs";
import { append, isEmpty, toHtml } from "@utils/rich-text";
import {
  fromScope,
  toBaseScope,
  toLocation,
  toNestedLocation,
  toScope,
} from "@utils/scope";

import { useMutate } from "@ui/mutate";

import { getStore } from "../atoms";
import { GenericItem } from "../selectors";
import { NestableOverrides } from "../types";
import { flattenFor } from "../utils";
import { useGetItemFromAnyStore, useQueueUpdates } from "./core";
import { useLazyEntity, useNestedEntities } from "./fetching";

export function useCreateEntity<
  T extends EntityType,
  E extends EntityForType<T>
>(type: T, scope: string, pageId?: ID, temp?: boolean) {
  const workspace = useActiveWorkspaceId();
  const meId = useAuthedUserId();
  const source = useMemo(() => {
    // TODO: Potentially can remove after making scope = location (not have workspace in it)
    if (scope === workspace && type !== "team") {
      return { type, scope: toScope(meId), source: Integration.Traction };
    }
    return { type, scope: scope, source: Integration.Traction };
  }, [type, scope, workspace, meId]);
  const queueUpdate = useQueueUpdates<E>(pageId);
  const props = useLazyProperties(source);
  const getAdditionalUpdates = useWillUpdateWorkflows();
  const defaultStatus = useDefaultStatus(
    source?.type,
    source?.scope || workspace
  );

  return useCallback(
    (
      changes: PropertyMutation<E>[],
      transaction?: ID
    ): Ref & { source: DatabaseID } => {
      const id =
        find(changes, (c) => c.field === "id")?.value?.text ||
        asLocal(newHumanId(type));

      const getChange = maybeLookup(changes, (c) => c.field as string);
      const getOverride = (field: string) => {
        const res = getChange(field);
        return !!res && isEmptyRef(res) ? undefined : res;
      };

      const update: Update<E> = {
        id: id,
        method: "create",
        source: source,
        ...when(transaction, () => ({ transaction })),
        ...ifDo(temp, () => ({ mode: "temp" })),

        changes: maybeMap(
          uniqBy([...props, ...changes], (p) =>
            // Treat title and name as same field
            ["title", "name"]?.includes(p.field as string)
              ? "name"
              : ["owner", "assigned"]?.includes(p.field as string)
              ? // Treat owner and assigned as same field
                "assigned"
              : (p.field as string)
          ),
          (p) => {
            return switchEnum(p.field as string, {
              // Don't process ID fields here, used above
              id: () => undefined,

              // Default status if not passed in
              status: () =>
                getOverride(p.field as string) ||
                asMutation({ field: "status", type: "status" }, defaultStatus),

              // Generate new code if not passed in
              code: () =>
                getOverride(p.field as string) ||
                asMutation({ field: "code", type: "text" }, newADDDID()),

              // Default location incase not set
              location: () =>
                getOverride(p.field as string) ||
                asMutation(
                  { field: "location", type: "text" },
                  toLocation(source.scope)
                ),

              // Allow passing in either name|title and the correct value being set
              name: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("name") || getOverride("title"))?.value.text ||
                    ""
                ) as PropertyMutation<E>,
              title: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("name") || getOverride("title"))?.value.text ||
                    ""
                ) as PropertyMutation<E>,

              // Allow passing in either owner|assigned and the correct value being set
              assigned: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("assigned") || getOverride("owner"))?.value
                    .relation
                ) as PropertyMutation<E>,
              owner: () =>
                asMutation(
                  p as PropertyRef,
                  (getOverride("assigned") || getOverride("owner"))?.value
                    .relation
                ) as PropertyMutation<E>,

              else: () => getOverride(p.field as string),
            });
          }
        ),
      };

      const entity = flattenUpdate(update);

      const additional = getAdditionalUpdates(
        entity as Entity,
        update as Update<Entity>
      );

      const mergable = filter(
        additional,
        (a) =>
          ["create", "update"]?.includes(a.method) &&
          // Don't allow merging in add/remove ops into create since they will conflict with sets
          every(safeAs<CreateOrUpdate>(a)?.changes, (c) => c.op === "set")
      );

      // Merge in additional changes from workflows that can be merged
      // so it goes through in the create update
      queueUpdate(
        setDirty(update, "changes", [
          ...update.changes,
          ...reduce(
            mergable,
            (r, a) => [...r, ...(safeAs<CreateOrUpdate<E>>(a)?.changes || [])],
            [] as PropertyMutation<E>[]
          ),
        ])
      );

      // Save remaining updates separately
      map(without(additional, ...mergable), queueUpdate);

      return { id, source };
    },
    [workspace, queueUpdate, defaultStatus, props, source]
  );
}

export function useCreateFromObject<T extends EntityType>(
  type: T,
  scope?: string,
  pageId?: ID,
  temp?: boolean
) {
  const workspaceId = useActiveWorkspaceId();
  const source = useMemo(
    (): DatabaseID<T> => ({
      type: type,
      scope: scope || toScope(workspaceId),
      source: Integration.Traction,
    }),
    [type, scope, workspaceId]
  );
  const createCore = useCreateEntity(type, source.scope, pageId, temp);
  const props = useLazyProperties(source);

  const create = useCallback(
    (ts: Partial<EntityForType<T>>[], transaction: string = newID()) => {
      const orders = whenTruthy(
        ts.length > 1 && hasOrders(ts[0]) && (first(ts) as Partial<HasOrders>),
        (first) => {
          const start = Number(toOrder(first?.orders, "default")) || 1;
          const end = DecimalOrdering.bump(start);
          return evenlyBetween([start, end], ts.length, DecimalOrdering);
        }
      );

      // Allow temp IDs to be passed in on objects
      const withID = [{ field: "id", type: "text" }, ...props] as PropertyRef<
        EntityForType<T>
      >[];

      return map(ts, (t, i) =>
        createCore(
          maybeMap(withID, (p) => {
            const val = getPropertyValue(t, p);

            if (p.field === "orders" && !!orders) {
              return asMutation(p, toNewOrders("default", orders[i]));
            }

            if (p.field === "id") {
              return asMutation(
                p,
                when(val?.text, (id) => isLocalID(id) && id) ||
                  newLocalHumanId(type)
              );
            }

            // Filter out empty changes
            return omitEmptyRef(toMutation(t, p, val?.[p.type]));
          }),
          transaction
        )
      );
    },
    [createCore, props]
  );

  // Hack for now: when not really fetched there is often 1 prop (status)
  // Matches hack inside useLazyProps
  return props?.length > 1 ? create : undefined;
}

export function useDefaultStatus(
  type: Maybe<EntityType>,
  scope: Maybe<string>
) {
  const baseScope = useMemo(() => toBaseScope(scope || ""), [scope]);
  const hierarchy = useMemo(() => fromScope(scope), [scope]);
  const getItem = useGetItemFromAnyStore();
  const parents = useMemo(() => map(hierarchy, getItem), [hierarchy, getItem]);
  const props = useRecoilValue(allStatusesForTeam(baseScope));
  const getStatus = useMemo(
    () =>
      maybeLookup(
        flatMap(props, (p) => p.values?.status),
        (s) => s?.id
      ),
    [props]
  );
  const { status: statuses } = useLazyPropertyValues(
    type && scope ? { type, scope } : undefined,
    when(type, isStatusable) ? { field: "status", type: "status" } : undefined
  );

  return useMemo(() => {
    const parentPreference = maybeMap(parents, (p) =>
      hasStatus(p)
        ? getStatus(p.status?.id || "none")?.group
        : ["roadmap", "backlog"]?.includes(p?.source.type || "")
        ? "planning"
        : undefined
    );

    if (!statuses?.length) {
      // Status is not supported on this entity
      return undefined;
    }

    const desiredGroup = fallback(
      // If there is a parent status then go of planning/not-planning
      () =>
        when(last(parentPreference), (group) =>
          group === "planning" ? "planning" : "not-started"
        ),

      // Else if the parrent isn't statusable then default to not-started
      () => "not-started"
    );

    return (
      // Try find ideal status
      find(statuses, (s) => s.group === desiredGroup) ||
      // Fallback to the first not-started
      find(statuses, (s) => s.group === "not-started") ||
      // Fallback to the first planning
      find(statuses, (s) => s.group === "planning") ||
      // Fallback to whatever we have lol
      first(statuses) ||
      ({ id: "TODO", name: "To do" } as Status)
    );
  }, [statuses, parents]);
}

export const useDuplicate = (
  destination: Maybe<DatabaseID>,
  overrides?: NestableOverrides
) => {
  const mutate = useMutate();
  const [duplicating, setDuplicating] = useState(false);

  const { ready: propsReady } = useNestedProperties(destination);

  const ready = useMemo(
    () => !!destination && propsReady,
    [destination, propsReady]
  );

  const duplicate = useCallback(
    (entity: Entity, nested: Maybe<EntityMap>, opts?: CreateTemplateOpts) => {
      if (!destination) {
        throw new Error("No destination provided for duplication.");
      }

      if (!propsReady) {
        throw new Error("Nested props not loaded, cant' duplicate yet.");
      }

      // Warning: not breaking these out into separate variables as it causes typescript to hang indefinetly
      const finalOverrides = merge<NestableOverrides>(
        overrides,
        opts?.overrides
      );
      const finalDefaults = (opts?.defaults || {}) as NestableOverrides;

      setDuplicating(true);

      const parent = merge<Partial<Entity>>(
        flattenFor(finalDefaults, entity),
        // Don't duplcate the createdBy field
        omit(entity, "createdBy") as Partial<Entity>,
        flattenFor(finalOverrides, entity),
        { source: destination } as Partial<Entity>
      ) as Entity;

      const children = map(
        flatten(maybeValues(nested as Dictionary<Entity[]>)),
        (child) =>
          merge(
            // Warning: not breaking these out into separate variables as it causes typescript to hang indefinetly
            flattenFor(finalDefaults, child) as Partial<Entity>,
            // Don't duplicate the createdBy field
            omit(
              omitEmptyVals<Entity, keyof Entity>(child),
              "createdBy"
            ) as Partial<Entity>,

            // Final overrides
            flattenFor(finalOverrides, child),

            child.source.type === "schedule"
              ? {
                  source: {
                    type: child.source.type,
                    // Save nested schedules to the destination scope
                    scope: destination.scope,
                  },
                }
              : ({
                  source: {
                    type: child.source.type,
                    scope: toNestedLocation(
                      child.source.scope,
                      entity.id,
                      destination.scope
                    ),
                  },
                } as Partial<Entity>)
          ) as Entity
      );

      const allEntities = [
        // Add the entity itself with defaults and the new destination source
        parent,

        // Add all nested entities with defaults and the new destination source
        ...children,
      ];

      // Generate new local IDs for all entities
      const newIds = reduce(
        allEntities,
        (acc, e) => set(acc, { [e.id]: newLocalHumanId(e.source?.type) }),
        {} as Record<ID, ID>
      );

      const transactions = {
        create: newID(),
        update: newID(),
      };

      // TODO: Abstract away this complexity. Would be nice to be able to process a bunch of creates that
      // inter-reference each other but not have the saving blocked...
      const updates = flatMap(allEntities, (e): Update<Entity>[] => {
        let entityProps = getRecoil(
          allPropertiesForSource({
            scope: destination.scope,
            type: e.source?.type,
          })
        );

        if (isEntity(e, "form") && e.fields?.length) {
          entityProps = [...entityProps, ...(e.fields as PropertyDef[])];
        }

        if (!entityProps) {
          warn("No props for nested entity.", {
            entity: e,
            type: e.source?.type,
          });
        }

        // First create all changes for this entity and replace any references
        // of duplicated IDs with new IDs
        const ogChanges = maybeMap(entityProps, (p) => {
          // Don't copy aliases as then they break views... however this means that when using view templates
          // the alias wont' be copied over, which I think is correct as aliases have IDs in them...
          if (p.field === "alias") {
            return;
          }

          // Change title of passed in entity to include (copy) at end
          if (e.id === entity.id && equalsAny(p?.field, ["name", "title"])) {
            const baseName = toTitleOrName(e) || toTitleOrName(entity);

            const name =
              opts?.appendName !== false
                ? baseName +
                  (isString(opts?.appendName) ? opts?.appendName : " (copy)")
                : baseName;

            return asMutation(p, name);
          }

          if (p.field === "code") {
            return asMutation(
              p,
              (e.id === entity.id ? safeAs<HasCode>(e)?.code : undefined) ||
                newADDDID()
            );
          }

          // Locations determined up above when overriding the source
          if (p.field === "location") {
            return asMutation(
              { field: "location", type: "text" },
              e.source.scope
            );
          }

          // Use defaults passed in for all values
          const mutation = toMutation(e, p);

          // Filter out empty values
          return omitEmptyRef(mutation);
        });

        const changes = map(ogChanges, (c) => replaceRelationRefs(c, newIds));

        // Separate updates into two transactions, first the create with any safe fields
        // then an update with any local (unpersisted) fields
        const { create, update } = groupBy(changes, (c) =>
          c.field === "location" || !mutReferencesLocalIDs(c, {})
            ? "create"
            : "update"
        );

        return omitEmpty([
          create && {
            id: newIds[e.id],
            method: "create",
            source: e.source,
            transaction: transactions.create,
            changes: create,
          },

          update && {
            id: newIds[e.id],
            method: "update",
            changes: update,
            transaction: transactions.update,
            source: e.source,
          },
        ]);
      });

      // Queue all updates individually so they don't get added to the same store...
      mutate(updates);

      setDuplicating(false);

      return map(allEntities, (e) => ({ id: newIds[e.id], source: e.source }));
    },
    [destination?.scope, destination?.type, propsReady]
  );

  return useMemo(
    () => ({ duplicate, ready, duplicating }),
    [duplicate, ready, duplicating]
  );
};

export interface CreateTemplateOpts {
  appendName?: boolean | string;
  defaults?: NestableOverrides;
  overrides?: NestableOverrides;
}

// TODO: Need a new pattern for this kind of stuff...
// Lol this is gross. So the way that we store things in recoil and then pass back the Ref rather than the Entity
// means that people who try to lookup the ref using won't get it yet. I think this may be just an issue with useAllStores, not sure...
export const useCreateFromTemplate = (
  source: Maybe<DatabaseID>,
  onCreated?: Fn<Ref, void>,
  opts?: CreateTemplateOpts
) => {
  const runOnce = useRef<boolean>(false);
  const [runFor, setRunFor] = useState<{ id: ID; opts?: CreateTemplateOpts }>();
  const [completedId, setCompletedId] = useState<ID>();
  const completed = useRecoilValue(GenericItem(completedId || ""));

  // Fetch template an all nested entities
  const template = useLazyEntity(runFor?.id);
  const templateSchedule = useLazyEntity<"schedule">(
    justOne(safeAs<HasRepeat>(template)?.refs.repeat)?.id
  );
  const templateChildren = useNestedEntities(template, undefined, true);

  const {
    duplicate,
    ready: duplicateReady,
    duplicating,
  } = useDuplicate(source);

  const loading = useMemo(
    () => duplicating || (!!template && templateChildren.loading),
    [duplicating, templateChildren.loading]
  );

  // When everything is loaded and ready to run, call duplicate once
  useAsyncEffect(async () => {
    if (
      runFor &&
      !runOnce.current &&
      !!templateChildren.children &&
      template &&
      (!justOne(safeAs<HasRefs>(template)?.refs?.repeat) || templateSchedule) &&
      !templateChildren.loading &&
      !completedId &&
      duplicateReady
    ) {
      runOnce.current = true;

      // Set some more overrides now that we have the template
      let opts = runFor.opts;
      let children = templateChildren.children;
      const scheduledBeingUsed = justOne(
        safeAs<HasRefs>(opts?.overrides?.[template.id])?.refs?.repeat
      );

      /*
      /* Hacky Temp Rules for creating from template
      */

      if (hasBody(template) && hasBody(opts?.overrides?.[template.id])) {
        // Merge body values together if both template + overrides are set
        const baseBody = template?.body;
        const overrideBody = safeAs<HasBody>(
          opts?.overrides?.[template.id]
        )?.body;
        const body =
          !isEmpty(baseBody) && !isEmpty(overrideBody)
            ? { html: toHtml(baseBody) + "<hr>" + toHtml(overrideBody) }
            : append(baseBody, overrideBody);

        opts = merge(opts, { overrides: { [template?.id]: { body: body } } });
      }

      // Schedule being used is not the schedule set on the template.repeat,
      // so should be removed from children and ignored.
      if (
        scheduledBeingUsed?.id &&
        templateSchedule?.id !== scheduledBeingUsed.id
      ) {
        children.schedule = filter(
          children.schedule,
          (c) => c.id !== templateSchedule?.id
        );
        // Switch the repeat to reference the schedule being used (not the template.repeat)
        opts = merge(opts, {
          [template.id]: {
            refs: { schedules: [], repeat: [toRef(scheduledBeingUsed)] },
          },
        });
      }

      // If the template work has a schedule (template) that should be duplicated as well
      // and it's being created from the same (or no) schedule
      // E.g. Birthday Reminder Event Template will have a Template Schedule to repeat every 1 year
      if (
        !!templateSchedule &&
        (!scheduledBeingUsed || templateSchedule.id === scheduledBeingUsed?.id)
      ) {
        const from =
          when(
            safeAs<HasDates>(opts?.overrides?.[template.id]),
            (e) => e?.start || e?.end
          ) || now();

        opts = merge(opts, {
          overrides: {
            [template.id]: {
              refs: { schedules: [], repeat: [templateSchedule] },
            },
            [templateSchedule.id]: {
              name: `${
                toTitleOrName(opts?.overrides?.[template.id] as HasTitle) ||
                "New work"
              } – ${toTitleOrName(template)}`,

              from: from,
              last: from,
              daysOfPeriod: [toDayOfPeriod(from, templateSchedule.period)],

              // Use the incoming text/title for all future creations from this schedule
              overrides: [
                {
                  field: "title",
                  type: "text",
                  value: {
                    text: toTitleOrName(
                      opts?.overrides?.[template.id] as HasTitle
                    ),
                  },
                },
              ],
              // We want this to stay as a reference to the actual template
              useTemplate: toRef(template.id),
              // We want this to reference the new instance
              refs: { instances: [toRef(template.id)] },
              // Move the schedule to wherever the work is being created
              location:
                source?.scope || when(template.source.scope, toBaseScope),
            },
          },
        });
      }

      const [saved] = duplicate(template, children, opts);

      setCompletedId(saved.id);
    }
  }, [
    runFor,
    template,
    templateSchedule,
    templateChildren.loading,
    duplicateReady,
  ]);

  // Wait for completed to be set from the duplication
  useEffect(() => {
    if (completed && runOnce.current === true && !isLocalID(completed.id)) {
      onCreated?.(completed);
      setRunFor(undefined);
      setCompletedId(undefined);
      runOnce.current = false;
    }
  }, [completed]);

  const create = useCallback(
    (template: Ref, opts2?: CreateTemplateOpts) => {
      if (runFor) {
        throw new Error("Already creating a template.");
      }

      // Merge all overrides together
      const overrides = merge(opts?.overrides, opts2?.overrides, {
        "*": {
          template: undefined,
          refs: { schedules: [] },
        },
        [template.id]: {
          refs: { forms: [], schedules: [], fromTemplate: [toRef(template)] },
        },
      }) as NestableOverrides;

      let defaults = merge(opts?.defaults, opts2?.defaults);

      // Hack at the moment to assign all unassigned nested work when assigning the parent
      // TODO: Do some sort of property placeholder or role solution for defining people in templates
      defaults = merge(defaults, {
        "*": {
          ...when(
            safeAs<HasAssigned>(overrides?.[template?.id])?.assigned ||
              safeAs<HasOwner>(overrides?.[template?.id])?.owner,
            (r) => ({ assigned: r })
          ),
        },
      }) as NestableOverrides;

      setRunFor({
        id: template.id,
        opts: {
          appendName: opts?.appendName ?? opts2?.appendName ?? false,
          overrides,
          defaults,
        } as CreateTemplateOpts,
      });
    },
    [runFor, opts]
  );

  // Requires an onCreated callback to be passed in
  return useMemo(
    () => ({
      create: create,
      ready: !runFor, // Create just sets runFor so can be called at any time
      loading: loading,
      template: template,
    }),
    [create, runFor, loading, template]
  );
};

export interface ApplyTemplateOpts {
  ignoreProps?: string[];
}

// TODO: Need a new pattern for this kind of stuff...
// Lol this is gross. So the way that we store things in recoil and then pass back the Ref rather than the Entity
// means that people who try to lookup the ref using won't get it yet. I think this may be just an issue with useAllStores, not sure...
export const useApplyTemplate = (
  existing: Maybe<Entity>,
  onUpdated?: Fn<void, void>,
  options?: ApplyTemplateOpts
) => {
  const runOnce = useRef<boolean>();
  const [runFor, setRunFor] = useState<{ id: ID; opts?: ApplyTemplateOpts }>();
  const [loading, setLoading] = useState(false);
  const [completedId, setCompletedId] = useState<ID>();
  const completed = useRecoilValue(GenericItem(completedId || ""));
  const propsStore = useRecoilValue(PropertyDefStoreAtom);

  // Fetch template an all nested entities
  const template = useLazyEntity(runFor?.id);
  const nested = useNestedEntities(template, undefined, true);

  const mutate = useMutate();

  const apply = useCallback(
    (
      existing: Entity,
      template: Entity,
      nested: Maybe<EntityMap>,
      opts?: ApplyTemplateOpts
    ) => {
      // TODO: Shouldn't be relying on all properties to already be fetched...
      const props = groupByMany(
        maybeValues(propsStore.lookup),
        (l) => l?.entity
      );

      const children = map(
        flatten(maybeValues(nested as Dictionary<Entity[]>)),
        (v) =>
          merge(
            // Don't duplicate the createdBy field
            omitEmptyVals<Entity, keyof Entity>(v) as Partial<Entity>,
            {
              source: {
                type: v.source.type,
                scope: toNestedLocation(
                  v.source.scope,
                  existing.id,
                  existing.source.scope
                ),
              },
            } as Partial<Entity>
          ) as Entity
      );

      // Generate new local IDs for the children only, not the parent
      const aliases = reduce(
        children,
        (acc, e) => set(acc, { [e.id]: newLocalHumanId(e.source?.type) }),
        { [template?.id]: existing.id } as Record<ID, ID>
      );

      const transactions = {
        create: newID(),
        update: newID(),
      };

      const ignoreProps = [
        "createdBy",
        "createdAt",
        "updatedAt",
        "updatedBy",
        "workspace",
        "title",
        "code",
        "name",
        "template",
        ...(opts?.ignoreProps || []),
      ];

      const parentUpdate = asUpdate(
        existing,
        maybeMap(props[existing.source?.type], (p) => {
          if (!p) {
            return;
          }

          // For existing entity, ignore props passed in and timestamp fields
          if (equalsAny(p.field, ignoreProps) || isTimestamp(p.field)) {
            return;
          }

          // Default to existing value from existing entity, then fallback to template value
          const mutation =
            omitEmptyRef(toMutation(existing, p)) || toMutation(template, p);

          // Filter out empty changes
          if (isEmptyRef(mutation)) {
            return;
          }

          return replaceRelationRefs(
            p.type === "relations" &&
              (mutation.value.relations?.length || 0) > 0
              ? // Apply multi-relations as appends (only when not "blanking" out with empty array)
                { ...mutation, op: "add" }
              : // Use defaults passed in for all values
                mutation,
            aliases
          );
        }),
        transactions.update
      );

      // TODO: Abstract away this complexity. Would be nice to be able to process a bunch of creates that
      // inter-reference each other but not have the saving blocked...
      const childUpdates = flatMap(children, (e): Update<Entity>[] => {
        // First create all changes for this entity and replace any references
        // of duplicated IDs with new IDs
        const ogChanges = maybeMap(props[e.source?.type], (p) => {
          if (!p) {
            return;
          }

          // Don't copy aliases as then they break views... however this means that when using view templates
          // the alias wont' be copied over, which I think is correct as aliases have IDs in them...
          if (
            !!p?.field &&
            equalsAny(p?.field, [
              "alias",
              "updatedAt",
              "updatedBy",
              "createdAt",
              "createdBy",
            ])
          ) {
            return;
          }

          // Don't copy timestamp fields
          if (isTimestamp(p.field)) {
            return;
          }

          // Clear out template field when instantiating
          if (p.field === "template") {
            return;
          }

          if (p.field === "code") {
            return asMutation(p, newADDDID());
          }

          // Locations determined up above when overriding the source
          if (p.field === "location") {
            return asMutation(
              { field: "location", type: "text" },
              e.source.scope
            );
          }

          // Use defaults passed in for all values
          const mutation = toMutation(e, p);

          // Filter out empty values
          return omitEmptyRef(mutation);
        });

        const changes = map(ogChanges, (c) => replaceRelationRefs(c, aliases));

        // Separate updates into two transactions, first the create with any safe fields
        // then an update with any local (unpersisted) fields
        const { create, update } = groupBy(changes, (c) =>
          c.field === "location" || !mutReferencesLocalIDs(c, {})
            ? "create"
            : "update"
        );

        return omitEmpty([
          create && {
            id: aliases[e.id],
            method: "create",
            source: e.source,
            transaction: transactions.create,
            changes: create,
          },

          update && {
            id: aliases[e.id],
            method: "update",
            changes: update,
            transaction: transactions.update,
            source: e.source,
          },
        ]);
      });

      // Process parent update
      mutate(parentUpdate);

      // Queue all updates individually so they don't get added to the same store...
      mutate(childUpdates);

      setLoading(false);

      return [
        existing,
        ...map(children, (e) => ({ id: aliases[e.id], source: e.source })),
      ];
    },
    [propsStore]
  );

  const run = useCallback(
    (template: Ref, opts2?: ApplyTemplateOpts) => {
      setLoading(true);

      setRunFor({
        id: template.id,
        opts: merge(options, opts2),
      });
    },
    [setRunFor, options]
  );

  // Wait for hooks to have fetched the nested children and template
  useAsyncEffect(async () => {
    if (runFor && existing && template && !nested.loading) {
      // Reset for below usage...
      runOnce.current = false;

      const [saved] = apply(existing, template, nested.children, runFor.opts);
      setRunFor(undefined);
      setCompletedId(saved.id);
    }
  }, [template, runFor, nested.loading]);

  // Wait for completed to be in the recoil stores before calling the onLink which will look it up by ID
  // and need to update it...
  useEffect(() => {
    if (!runOnce.current && completed && !isLocalID(completed.id)) {
      runOnce.current = true;
      setCompletedId(undefined);
      setLoading(false);
      onUpdated?.();
    }
  }, [completed]);

  // Requires an onCreated callback to be passed in
  return useMemo(
    () => ({
      apply: run,
      ready: !nested.loading,
      loading: loading || nested.loading,
      template: template,
    }),
    [run, loading, nested.loading]
  );
};

export function useDeleteEntitys(pageId?: ID) {
  const save = useQueueUpdates(pageId);
  const getItem = useGetItemFromAnyStore();

  return useCallback(
    async (ids: ID[]) => {
      const transaction = newID();
      map(ids, (id) =>
        when(getItem(id), (entity) => save(asDeleteUpdate(entity)))
      );
    },
    [save, getItem]
  );
}

export function useReorderItems<T extends EntityType>(
  type: T,
  pageId?: ID,
  scope: string = "default",
  canReorder?: Fn<[DragRef<Entity>[], DragToRef<Entity>], boolean>
) {
  const mutate = useQueueUpdates(pageId);

  return useCallback(
    (source: DragRef<Entity>[], to: DragToRef<Entity>) => {
      // Useful for warnings etc.
      if (canReorder && !canReorder([source, to])) {
        return;
      }

      const store = getRecoil(getStore(type));
      const transaction = newID();
      const [id, id2] = when(to.entity, ensureMany) || [];

      const target = when(id, (id) => getItem(store, id)) as Maybe<Entity>;
      const target2 = when(id2, (id) => getItem(store, id)) as Maybe<Entity>;
      const items = map(source, (d) => getItem(store, d.entity)) as Entity[];

      const current = when((target as HasOrders)?.orders, (o) =>
        Number(toOrder(o, scope) || 0)
      );

      // No reordering, just setting groups
      if (!isDefined(current) && !!to.groups?.length) {
        return mutate(
          flatMap(items, (item): Update<Entity>[] => {
            if (!item) {
              return [];
            }

            if (!item) {
              debug("Can't find entity to reorder.");
              return [];
            }

            return [toGroupUpdate(item, to.groups || [], transaction)];
          })
        );
      } else if (!isDefined(current)) {
        debug("No known location for drop.");
        return [];
      }

      const next =
        when((target2 as HasOrders)?.orders, (o) =>
          Number(toOrder(o, scope))
        ) ?? DecimalOrdering.bump(current);

      const ordered = switchEnum(to.position, {
        before: () => [...items, target || target2],
        after: () => [target2 || target, ...items],
        between: () => [target, ...items, target2],
      });

      const newOrders = evenlyBetween(
        [current, next],
        source?.length,
        DecimalOrdering
      );

      const updates = maybeMap(ordered, (item, i) => {
        if (!item) {
          debug("Can't find entity to reorder.");
          return undefined;
        }

        // Get new order from calculated orders
        const newOrder = newOrders[i];

        // When not reorderable, just mutate applty the group values
        if (
          !hasOrders(item) ||
          (!!target && !hasOrders(target)) ||
          (!!target2 && !hasOrders(target2))
        ) {
          return toGroupUpdate(item, to.groups || [], transaction);
        }

        // Do nothing if order hasn't changed
        if (String(newOrder) === toOrder(item.orders, scope)) {
          return undefined;
        }

        // Update the order and the group values
        return addChanges(
          toGroupUpdate(item, to.groups || [], transaction),
          toMutation<Entity, "json">(
            item,
            { field: "orders", type: "json" },
            setOrders(toOrders(item.orders), scope, newOrder)
          )
        );
      });

      mutate([
        // Change the to entities if their order changed
        ...(target && isDefined(current) && current !== newOrders[0]
          ? [
              asUpdate(
                target,
                asMutation(
                  { field: "orders", type: "json" },
                  setOrders(
                    toOrders((target as HasOrders).orders),
                    scope,
                    newOrders[0]
                  )
                ),
                transaction
              ),
            ]
          : []),

        // Change the from entities if their order changed
        ...(target2 &&
        !!newOrders.length &&
        isDefined(next) &&
        next !== newOrders[0]
          ? [
              asUpdate(
                target2,
                asMutation(
                  { field: "orders", type: "json" },
                  setOrders(
                    toOrders((target as HasOrders).orders),
                    scope,
                    last(newOrders) ?? 1
                  )
                ),
                transaction
              ),
            ]
          : []),

        // Updates calculated above
        ...updates,
      ]);
    },
    [mutate, scope]
  );
}

export function useDetatchRelation(pageId?: string) {
  const update = useQueueUpdates(pageId);

  return useCallback(
    (
      entity: Entity,
      relation: PropertyDef<Entity, "relation" | "relations">,
      related: Entity
    ) => {
      if (relation.type === "relation") {
        // Remove the relation
        update(asUpdate(entity, [asMutation(relation, undefined)]));
      } else {
        update(
          asUpdate(entity, [
            asAppendMutation(
              relation as PropertyDef<Entity, "relations">,
              [related],
              "remove"
            ),
          ])
        );
      }
    },
    [pageId]
  );
}

export function useMarkAsSeen(entityId: string, pageId?: string) {
  const entity = useLazyEntity(entityId);
  const meId = useAuthedUserId();
  const mutate = useQueueUpdates(pageId);

  useAddToRecents(entityId);

  useAsyncEffect(async () => {
    // Not already marked as seen
    const seenBy = (entity as Maybe<Note>)?.refs?.seenBy || [];
    if (
      entity?.source.scope &&
      !["schedule", "workspace", "person", "team"]?.includes(
        entity?.source.type
      ) &&
      !!(meId && entity && seenBy) &&
      !find(seenBy, (e) => e.id === meId)
    ) {
      mutate([
        asUpdate(entity, [
          asAppendMutation({ field: "refs.seenBy", type: "relations" }, [
            toRef(meId),
          ]),
        ]),
      ]);
    }
  }, [entity?.id, meId]);
}

export const useLogout = () => {
  const clearAll = useClearAllStores();
  return useCallback(() => {
    // Clear all states
    clearAll();
    // Redirect to login
    setTimeout(() => (window.location.href = "/auth/login"), 60);
  }, []);
};
