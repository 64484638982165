import { DatabaseID, Status, Task } from "@api";

import { useLazyPropertyValues } from "@state/properties";

import { when } from "@utils/maybe";

import { StatusSelect } from "@ui/select";

import { StatusIcon } from "./status-button";

interface Props {
  task: { source: DatabaseID; blocked?: boolean };
  status: Task["status"];
  placeholder?: string;
  onChange?: (t: Task["status"]) => void;
}

export const TaskCheck = ({ task, placeholder, status, onChange }: Props) => {
  const { status: options } = useLazyPropertyValues(task.source, {
    type: "status",
    field: "status",
  });

  return (
    <StatusSelect
      value={status}
      options={(options as Status[]) || []}
      portal
      placeholder={placeholder}
      simpleSuggestions
      onChange={(v) => when(v, (value) => onChange?.(value))}
    >
      {status && <StatusIcon status={status} blocked={task.blocked} />}
    </StatusSelect>
  );
};
