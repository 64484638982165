import { PageFragmentFragmentDoc } from "@api/integrations/traction/graph/types";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity } from "@state/generic";

import { useSyncPathnameID } from "@utils/url";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import AppPage from "@ui/page/app-page";
import { Sheet, StackContainer } from "@ui/sheet-layout";

import { SchedulePane } from "./pane";
import { ScheduleInstancesPane } from "./secondary-panes";

interface Props {
  id: string;
  showBack?: boolean;
}

export default function SchedulePage({ id, showBack = true }: Props) {
  const schedule = useLazyEntity<"schedule">(id);

  const [page] = useRegisterPage(id, schedule);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, schedule?.id);

  if (!schedule) {
    return <></>;
  }

  return (
    <AppPage page={page} title={schedule?.name} loading={!schedule}>
      <StackContainer>
        {showBack && <SmartBreadcrumbSheet />}

        <SchedulePane id={schedule.id} item={schedule} />
        {!schedule.template && <ScheduleInstancesPane schedule={schedule} />}
        {!!schedule.template && (
          <Sheet size="secondary" mode="sizing">
            <></>
          </Sheet>
        )}
      </StackContainer>
    </AppPage>
  );
}
