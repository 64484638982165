import { concat, groupBy, includes, isString, map } from "lodash";
import { ReactNode, useMemo, useState } from "react";

import { DatabaseID, Entity, PropertyMutation } from "@api";

import { isPropInScope, useLazyProperties } from "@state/properties";

import { maybeMap, OneOrMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import {
  isEmptyRef,
  sortByUseful,
  toFieldName,
  toPropertyValueRef,
} from "@utils/property-refs";

import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { EllipsisH } from "@ui/icon";
import { Label } from "@ui/label";
import { PropertyTypeIcon } from "@ui/property-type-icon";

import { PropertyLabel } from "./property-label";
import { PropertyValue, Props } from "./property-value";

import styles from "./property-value-pill.module.css";

export const PillButton = ({
  size = "small",
  className,
  children,
  icon,
  ...props
}: ButtonProps) => (
  <Button
    size={size}
    className={cx(styles.pill, styles[size], className)}
    {...props}
  >
    {children && !isString(children) ? (
      children
    ) : (
      <Label icon={icon} size={"small"} text={String(children)} />
    )}
  </Button>
);

export const PropertyValuePill = (props: Props) => {
  const { valueRef, size = "small" } = props;

  return (
    <PropertyValue {...props}>
      <PillButton size={size} className={props.className}>
        {isEmptyRef(valueRef) ? (
          <Label
            icon={<PropertyTypeIcon {...valueRef} />}
            size={"small"}
            text={valueRef.def?.label || toFieldName(valueRef)}
          />
        ) : (
          <PropertyLabel
            source={props.source}
            valueRef={valueRef}
            size={size === "tiny" ? "xsmall" : "small"}
          />
        )}
      </PillButton>
    </PropertyValue>
  );
};

export const PropertyValuePills = ({
  entity: work,
  source: workSource,
  blacklist,
  onChange,
  children,
}: {
  entity: Partial<Entity>;
  source: DatabaseID;
  blacklist?: string[];
  onChange: Fn<OneOrMany<PropertyMutation>, void>;
  children?: ReactNode;
}) => {
  const [whitelist, setWhitelist] = useState<string[]>([]);
  const props = useLazyProperties(workSource);
  const sorted = useMemo(() => sortByUseful(props), [props]);

  const propValues = useMemo(
    () =>
      maybeMap(sorted, (p) =>
        !equalsAny(p.field, blacklist || []) &&
        isPropInScope(p, workSource) &&
        p.displayAs === "property" &&
        !p.readonly &&
        !equalsAny(p.field, ["title", "name", "body", "icon", "code"])
          ? toPropertyValueRef(work as Entity, p)
          : undefined
      ),
    [sorted, work, workSource.type]
  );
  const { visible, more } = useMemo(
    () =>
      groupBy(propValues, (v) =>
        includes(whitelist, v.field) ||
        v.def?.visibility === "show_always" ||
        (v.def?.visibility === "hide_empty" && !isEmptyRef(v))
          ? "visible"
          : v.def?.visibility === "hidden" ||
            v.def?.visibility === "hide_always"
          ? "system"
          : "more"
      ),
    [propValues, whitelist]
  );

  return (
    <Container stack="horizontal" inset="left" padding="none" gap={4} wrap>
      {map(visible, (prop) => (
        <PropertyValuePill
          size="small"
          valueRef={prop}
          source={workSource}
          onChange={(v) => onChange({ ...prop, value: v })}
        />
      ))}

      {children}

      <ActionMenu
        actions={
          <>
            {map(more, (prop) => (
              <ActionItem
                key={prop.field}
                text={prop.def?.label || toFieldName(prop)}
                icon={<PropertyTypeIcon {...prop} />}
                onClick={() => setWhitelist((w) => concat(w, [prop.field]))}
              />
            ))}
          </>
        }
      >
        <Button subtle size="small" icon={EllipsisH} />
      </ActionMenu>
    </Container>
  );
};
