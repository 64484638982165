import { atomFamily } from "recoil";

import { ID, Process } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type ProcessStoreState = StoreState<Process>;

export const WorkspaceProcessStoreAtom = atomFamily<ProcessStoreState, ID>({
  key: "WorkspaceProcessStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "process" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "process")],
});

export const ProcessStoreAtom = WorkspaceWrappedAtom(
  "ProcessStoreAtom",
  WorkspaceProcessStoreAtom
);
