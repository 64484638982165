import { find, map, pick } from "lodash";
import { useCallback, useMemo } from "react";

import {
  DatabaseID,
  JsonArray,
  PropertyMutation,
  PropertyValueRef,
  Ref,
  toTitleOrName,
  VariableDef,
} from "@api";

import { useLazyProperties, useLazyPropertyDef } from "@state/properties";
import { useLazyEntity } from "@state/generic";

import {
  ensureMany,
  justOne,
  maybeLookup,
  OneOrMany,
  uniqBy,
} from "@utils/array";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { asPropertyValueRef, isAnyText, toRef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { Field } from "@ui/input";
import { PropertyValuesList } from "@ui/property-values-list";
import { DocumentEditor } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { TemplateSelect } from "@ui/select";
import { ScopedPropertySelect } from "@ui/select/property";
import { Text } from "@ui/text";

import styles from "./template-configure.module.css";

type Props = {
  template?: Maybe<Ref>;
  overrides?: Maybe<PropertyValueRef[]>;
  source: DatabaseID;
  field?: string;
  blacklist?: string[];
  variables?: VariableDef[];
  showPicker?: boolean;
  onChange: Fn<OneOrMany<PropertyMutation>, void>;
};

export const TemplateConfigure = ({
  template,
  overrides,
  source,
  field = "overrides",
  variables,
  blacklist,
  onChange,
  showPicker = true,
}: Props) => {
  const props = useLazyProperties(source);
  const values = useMemo(() => {
    const getProp = maybeLookup(props, (p) => p.field);
    return maybeMap(overrides, (c) => {
      const prop = getProp(c.field);
      return !equalsAny(c.field, blacklist || []) &&
        !prop?.readonly &&
        prop?.visibility !== "hide_always"
        ? asPropertyValueRef(prop || c, c.value)
        : undefined;
    });
  }, [props, overrides]);

  const updateOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      onChange(
        asMutation(
          { field, type: "json" },
          safeAs<JsonArray>(
            maybeMap(overrides || [], (c) =>
              c.field === justOne(change)?.field
                ? when(justOne(change), (c) =>
                    pick(c, "type", "field", "value")
                  )
                : c
            )
          )
        )
      );
    },
    [overrides, onChange]
  );

  const addOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      onChange(
        asMutation(
          { field, type: "json" },
          safeAs<JsonArray>(
            uniqBy(
              [...(overrides || []), ...ensureMany(change)],
              (c) => c.field,
              "last"
            )
          )
        )
      );
    },
    [overrides, onChange]
  );

  const handleClear = useCallback(() => {
    const visible = map(values, (v) => v.field);
    // Clear out the variables that are visible only
    onChange(
      asMutation(
        { field, type: "json" },
        maybeMap(overrides, (c) => (visible.includes(c.field) ? undefined : c))
      )
    );
  }, [values, overrides, onChange]);

  return (
    <ColoredSection className={styles.templateSection}>
      <VStack gap={4}>
        {showPicker && (
          <>
            <Container
              padding="none"
              inset="horizontal"
              className={styles.templateSelect}
            >
              <TemplateSelect
                scope={source.scope}
                type={source.type}
                allowed={[source.type]}
                allowNew={false}
                value={template}
                onChange={(r) =>
                  onChange(
                    asMutation(
                      { field: "useTemplate", type: "relation" },
                      toRef(r)
                    )
                  )
                }
                closeOnSelect={true}
                placeholder="No base template..."
                className={{ trigger: styles.control }}
              />
            </Container>

            <Divider />
          </>
        )}

        <PropertyValuesList
          values={values}
          source={source}
          editable={true}
          propsEditable={false}
          inset={false}
          variables={variables}
          onMutate={updateOverride}
          className={styles.list}
        >
          <Container padding="none" inset="horizontal">
            <SpaceBetween>
              <ScopedPropertySelect
                type={source.type}
                scope={source.scope}
                portal={true}
                whitelist={(p) =>
                  p.field === "title" ||
                  p.type === "rich_text" ||
                  !(
                    isAnyText(p) ||
                    p.readonly === true ||
                    p.visibility === "hidden" ||
                    p.type === "checklist"
                  )
                }
                onChanged={(p) => p && addOverride(asMutation(p, undefined))}
              >
                <Button size="small" subtle icon={PlusIcon}>
                  <Text subtle>Set field</Text>
                </Button>
              </ScopedPropertySelect>

              <Button subtle size="small" onClick={handleClear}>
                <Text subtle>Clear</Text>
              </Button>
            </SpaceBetween>
          </Container>
        </PropertyValuesList>
      </VStack>
    </ColoredSection>
  );
};

export const OverridesTemplateConfigure = (
  props: Props & { autoFocus?: boolean }
) => {
  const { onChange: mutate, source, overrides, autoFocus } = props;
  const template = useLazyEntity(props.template?.id);
  const bodyProp = useLazyPropertyDef(source, {
    field: "body",
    type: "rich_text",
  });
  const title = useMemo(
    () =>
      find(overrides, (o) => equalsAny(o.field, ["title", "name"]))?.value
        ?.text,
    [overrides]
  );
  const body = useMemo(
    () =>
      find(props.overrides, { field: "body" })?.value.rich_text || {
        html: "",
      },
    [overrides]
  );

  const mutateOverride = useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate([
        asMutation(
          { field: "overrides", type: "json" },
          safeAs<JsonArray>(
            uniqBy(
              [
                ...(overrides || []),
                ...map(ensureMany(change), (c) =>
                  pick(c, "field", "value", "type")
                ),
              ],
              (c) => c.field,
              "last"
            )
          )
        ),
      ]);
    },
    [overrides]
  );

  return (
    <Card>
      <VStack>
        <Field>
          <EditableHeading
            key="title"
            size="h3"
            text={title || ""}
            autoFocus={autoFocus ?? (!title && !props.template?.id)}
            placeholder={when(template, toTitleOrName) || "Untitled"}
            onChange={(t) => {
              mutateOverride(asMutation({ field: "title", type: "text" }, t));
              mutate(asMutation({ field: "name", type: "text" }, t));
            }}
          />
        </Field>

        <Field>
          <TemplateConfigure {...props} blacklist={["title", "name", "body"]} />
        </Field>

        {!!bodyProp && (
          <Field label={bodyProp?.label} padded>
            <DocumentEditor
              placeholder={
                props.template
                  ? "Defaults to template value..."
                  : "What needs to get done..."
              }
              newLineSpace="large"
              scope={source.scope}
              content={body}
              onChanged={(rt) =>
                mutateOverride(
                  asMutation({ field: "body", type: "rich_text" }, rt)
                )
              }
            />
          </Field>
        )}
      </VStack>
    </Card>
  );
};
