import { groupBy, map, orderBy, some } from "lodash";
import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

import {
  DatabaseID,
  Entity,
  FileMeta,
  HasNotes,
  HasResources,
  Integration,
  Link,
  Person,
  PropertyMutation,
  Ref,
  Resource,
  RichText,
} from "@api";
import { createThread, replyInThread } from "@api/integrations/slack";

import {
  useCreateEntity,
  useCreateFromObject,
  useGetItemFromAnyStore,
  useNestedSource,
  useQueueUpdates,
} from "@state/generic";
import { useCreateNote } from "@state/notes";
import { useLookupAliasForPerson, useMe } from "@state/persons";
import { useEntityParents } from "@state/settings";
import { getItem, setItems } from "@state/store";
import { ID } from "@state/types";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureArray, ensureMany, omitEmpty, OneOrMany } from "@utils/array";
import { useAsyncEffect } from "@utils/effects";
import { isLocalID } from "@utils/id";
import { isSlack } from "@utils/link";
import { Maybe, maybeMap, required, when } from "@utils/maybe";
import { toLink } from "@utils/navigation";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { fallbackPropertyValue, toRef } from "@utils/property-refs";
import { toMarkdown } from "@utils/rich-text";
import { toScope } from "@utils/scope";
import { replaceMentions, unlinkMentions } from "@utils/slack";

import { withExternalLinks } from "@ui/rich-text/utils";

import { ResourceAtom, ResourceStoreAtom } from "./atoms";
import { getResourceLoader, getResourcesLoader } from "./queries";

export function useLazyGetResource(id: ID) {
  const [resource, setResource] = useRecoilState(ResourceAtom(id));

  useAsyncEffect(async () => {
    if (!id || isLocalID(id)) {
      return;
    }

    if (!resource?.fetchedAt) {
      const latest = await getResourceLoader(id);
      setResource(latest);
    }
  }, [id]);

  return resource;
}

export function useLazyGetResources(refs?: Ref[]) {
  const [store, setStore] = useRecoilState(ResourceStoreAtom);

  useAsyncEffect(async () => {
    if (!refs || some(refs, (r) => isLocalID(r.id))) {
      return;
    }

    const latest = await getResourcesLoader(map(refs, (r) => r.id));
    setStore(setItems(latest || []));
  }, [refs]);

  return useMemo(
    () =>
      orderBy(
        maybeMap(refs, (r) => getItem(store, r.id)),
        "createdAt",
        "desc"
      ),
    [store?.lookup, refs]
  );
}

export function useUpdateResource(pageId?: ID) {
  const mutate = useQueueUpdates<Resource>(pageId);

  return useCallback(
    async (
      resource: Resource,
      changes: OneOrMany<PropertyMutation<Resource>>
    ) => resource && mutate(asUpdate(resource, changes)),
    [mutate]
  );
}

export function useCreateResource(
  attachTo: Maybe<HasResources>,
  pageId: Maybe<ID>,
  attach: boolean = true
) {
  const workspaceId = useActiveWorkspaceId();
  const source = useNestedSource(attachTo, "resource");
  const create = useCreateEntity(
    "resource",
    source?.scope || toScope(workspaceId),
    pageId
  );
  const mutate = useQueueUpdates<Resource>(pageId);
  const me = useMe();

  return useCallback(
    (changes: PropertyMutation<Resource>[], transaction?: ID) => {
      if (!attachTo) {
        return;
      }

      // Create the resource
      const resource = create(
        [
          ...changes,
          asMutation({ field: "refs.seenBy", type: "relations" }, [
            { id: required(me, () => "Missing current user.").id },
          ]),
        ],
        transaction
      );

      if (attach) {
        // Link it to the provided entity
        mutate({
          id: attachTo.id,
          method: "update",
          source: attachTo.source,
          transaction,
          changes: [
            {
              field: "refs.resources",
              type: "relations",
              op: "add",
              value: { relations: [{ id: resource.id }] },
            },
          ],
        });
      }

      return resource;
    },
    [attachTo?.source, attach, mutate]
  );
}

export function useAttachLink(
  attachTo: Maybe<HasResources & HasNotes>,
  pageId: Maybe<ID>
) {
  const createResource = useCreateResource(attachTo, pageId, false);
  const createNote = useCreateNote(attachTo, pageId, false);
  const mutate = useQueueUpdates();

  return useCallback(
    (ls: OneOrMany<Link>) => {
      if (!attachTo) {
        return;
      }

      const { links, notes } = groupBy(ensureArray(ls), (l) =>
        isSlack(l?.url) ? "notes" : "links"
      );

      const newNotes = maybeMap(notes, (l) =>
        toRef(
          createNote([
            { field: "type", type: "text", value: { text: "discussion" } },
            {
              field: "body",
              type: "rich_text",
              value: { rich_text: { markdown: l.text || "" } },
            },
            {
              type: "links",
              field: "links",
              value: { links: [l] },
            },
          ])
        )
      );

      const newResources = maybeMap(links, (l) =>
        toRef(
          createResource([
            { field: "name", type: "text", value: { text: l.text || "" } },
            { field: "url", type: "text", value: { text: l.url || "" } },
            { field: "icon", type: "text", value: { text: l.icon || "" } },
            { field: "type", type: "text", value: { text: "link" } },
            {
              field: "pinned",
              type: "boolean",
              value: { boolean: l.pinned || false },
            },
          ])
        )
      );

      mutate(
        asUpdate<Entity>(
          attachTo,
          omitEmpty([
            newNotes?.length
              ? asAppendMutation(
                  { field: "refs.notes", type: "relations" },
                  newNotes
                )
              : undefined,
            newResources?.length
              ? asAppendMutation(
                  { field: "refs.resources", type: "relations" },
                  newResources
                )
              : undefined,
          ])
        )
      );
    },
    [attachTo]
  );
}

export function useAttachFile(
  attachTo: Maybe<HasResources>,
  pageId: Maybe<ID>
) {
  const createResource = useCreateResource(attachTo, pageId, false);
  const mutate = useQueueUpdates();

  return useCallback(
    (files: OneOrMany<FileMeta>) => {
      if (!attachTo) {
        return;
      }

      // Create resources from file meta
      const newResources = maybeMap(ensureMany(files), (f) =>
        toRef(
          createResource(
            omitEmpty([
              { field: "name", type: "text", value: { text: f.name } },
              { field: "path", type: "text", value: { text: f.path } },
              { field: "url", type: "text", value: { text: f.url } },
              { field: "mimeType", type: "text", value: { text: f.mimeType } },
              { field: "type", type: "text", value: { text: "file" } },
              // Pin files by default
              { field: "pinned", type: "boolean", value: { boolean: true } },
            ])
          )
        )
      );

      // Link to entity
      mutate(
        asUpdate<Entity>(
          attachTo,
          omitEmpty([
            asAppendMutation(
              { field: "refs.resources", type: "relations" },
              newResources
            ),
          ])
        )
      );
    },
    [attachTo]
  );
}

export function useCreateDocument(scope: string) {
  const create = useCreateFromObject("page", scope);

  return useCallback(
    async (
      defaults?: Partial<{ title: string; icon: string }>,
      location?: string
    ) => {
      if (!create) {
        return;
      }

      const [created] = create?.([{ ...defaults, location }]) || [];
      return { id: created.id, title: defaults?.title, url: toLink(created) };
    },
    [scope, create]
  );
}

export function useToSlackMentions() {
  const lookup = useLookupAliasForPerson(Integration.Slack);

  const toAlias = useCallback(
    (id: ID) => when(lookup.toAlias(id), (m) => `<@${m}>`),
    [lookup.toAlias]
  );

  return useCallback(
    (text: string) => replaceMentions(unlinkMentions(text), toAlias),
    [toAlias]
  );
}

export function useToExternalLinks() {
  const getItem = useGetItemFromAnyStore();
  return useCallback(
    (html: string) => withExternalLinks(html, async (id: ID) => getItem(id)),
    [getItem]
  );
}

export function useToExternalMarkdown() {
  const toExternalHtml = useToExternalLinks();
  const toSlackMentions = useToSlackMentions();

  return useCallback(
    async (rt: RichText) => {
      if (rt.html) {
        return toSlackMentions(
          toMarkdown({ html: await toExternalHtml(rt.html) })
        );
      }

      return toSlackMentions(toMarkdown(rt));
    },
    [toExternalHtml, toSlackMentions]
  );
}

type Message = { message: RichText; author?: Person };

export function useCreateThread(channel: Maybe<ID>) {
  const me = useMe();
  const toMarkdown = useToExternalMarkdown();

  return useCallback(
    async (message: Message, firstReply?: Message) => {
      if (!channel) {
        throw new Error("Slack channel is unknown.");
      }

      return await createThread(
        channel,
        {
          message: await toMarkdown(message?.message),
          author: message?.author,
        },
        firstReply
          ? {
              message: await toMarkdown(firstReply?.message),
              author: firstReply?.author,
            }
          : { message: `Thread created in Traction by @${me?.id}` }
      );
    },
    [me, toMarkdown, channel]
  );
}

export function useReplyInThread(channel: Maybe<ID>, thread: Maybe<ID>) {
  const toMarkdown = useToExternalMarkdown();

  return useCallback(
    async (message: Message) => {
      if (!channel || !thread) {
        throw new Error("Slack channel or thread is unknown.");
      }

      return await replyInThread(channel, thread, {
        message: await toMarkdown(message?.message),
        author: message?.author,
      });
    },
    [channel, thread]
  );
}

export function useReplyOrCreateThread(channel: Maybe<ID>, thread: Maybe<ID>) {
  const create = useCreateThread(channel);
  const reply = useReplyInThread(channel, thread);

  return useCallback(
    async (message: Message, firstReply?: Message) =>
      thread ? reply(message) : create(message, firstReply),
    [create, reply, thread]
  );
}

export function useCreateThreadForEntity(entityID: ID, source: DatabaseID) {
  const parents = useEntityParents(entityID, source);
  const channel = useMemo(
    () =>
      fallbackPropertyValue(parents.reverse(), {
        field: "settings.channel",
        type: "text",
      })?.text,
    [entityID, source]
  );

  return useCreateThread(channel);
}
