import { atomFamily } from "recoil";

import { Calendar, ID } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type CalendarStoreState = StoreState<Calendar>;

export const WorkspaceCalendarStoreAtom = atomFamily<CalendarStoreState, ID>({
  key: "WorkspaceCalendarStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "calendar" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "calendar")],
});

export const CalendarStoreAtom = WorkspaceWrappedAtom(
  "CalendarStoreAtom",
  WorkspaceCalendarStoreAtom
);
