import { isString } from "lodash";
import { ReactNode } from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { HStack } from "@ui/flex";
import { Modal } from "@ui/modal";
import { Text, TextXLarge } from "@ui/text";

import { Container } from "./container";

import styles from "./dialog-split.module.css";

export interface Props {
  title?: string | ReactNode;
  description?: string | ReactNode;
  actions?: ReactNode;
  className?:
    | string
    | Partial<{
        modal: string;
        left: string;
        right: string;
        bottom: string;
        top: string;
      }>;
  children: ReactNode;
  side?: ReactNode;
  bottom?: ReactNode;
  onDismiss?: Fn<void, void>;
  mode?: "blocking" | "passive";
  direction?: "horizontal" | "vertical";
}

export const DialogSplit = ({
  children,
  title,
  description,
  actions,
  onDismiss,
  side,
  bottom,
  className,
  direction = "horizontal",
}: Props) => (
  <Modal
    open={true}
    onOpenChanged={(open) => !open && onDismiss?.()}
    className={cx(
      styles.dialog,
      styles.split,
      styles[direction],
      isString(className) ? className : className?.modal
    )}
    padding="none"
  >
    <div className={styles.container}>
      {side && (
        <Container
          stack="vertical"
          className={cx(
            styles.split,
            styles.splitLeft,
            !isString(className) && className?.left
          )}
          fit="container"
        >
          {title && isString(title) ? (
            <TextXLarge bold={true}>{title}</TextXLarge>
          ) : (
            title
          )}
          {description &&
            (isString(description) ? <Text>{description}</Text> : description)}
          {side}
        </Container>
      )}

      <Container
        stack="vertical"
        className={cx(
          styles.split,
          direction === "horizontal" ? styles.splitRight : styles.splitTop,
          !isString(className)
            ? direction === "horizontal"
              ? className?.right
              : className?.top
            : undefined
        )}
      >
        <div className={cx(styles.content, styles.fullWidth)}>{children}</div>
        {!!actions && (
          <HStack
            gap={12}
            justify="flex-end"
            className={cx(styles.fullWidth, styles.actions)}
          >
            {actions}
          </HStack>
        )}
      </Container>

      {bottom && (
        <Container
          stack="vertical"
          padding="none"
          className={cx(
            styles.split,
            styles.splitBottom,
            !isString(className) && className?.bottom
          )}
          fit="container"
        >
          {bottom}
        </Container>
      )}
    </div>
  </Modal>
);
