import { forEach, map } from "lodash";

import { PropertyDef, PropertyValue, RichText, VariableDef, Vars } from "@api";

import { maybeLookup } from "./array";
import { Fn, isFunc } from "./fn";
import { Maybe, safeAs, when } from "./maybe";
import { toHtml } from "./property-refs";
import { isEmpty } from "./rich-text";

export const replaceVariables = (
  rt: Maybe<RichText>,
  variables: Vars | Fn<string, Maybe<VariableDef>>
) => {
  // If no rich text or no variables, return as-is
  if (!rt?.html || !variables?.length) return rt || { html: "" };

  const getVar = isFunc(variables)
    ? variables
    : maybeLookup(variables, (v) => v.field);

  // Create a temporary div to manipulate the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = rt.html;

  // Find all variable placeholders
  const varElements = tempDiv.querySelectorAll("[data-var-field]");

  // Iterate through variable placeholders
  forEach(varElements, (el) => {
    const field = el.getAttribute("data-var-field");

    // Find matching variable
    const matchingVar = getVar(field || "");
    const html = when(matchingVar, (def) =>
      toHtml({
        type: def.type,
        value: def.value,
        field: def.field,
        def: safeAs<PropertyDef>(def),
      })
    );

    // If the parent tag is empty after replacing the variable, replace the parent tag
    if (
      html &&
      el.parentElement &&
      !!when(el.parentElement?.innerHTML?.replace(el.outerHTML, ""), (html) =>
        isEmpty({ html })
      )
    ) {
      el.parentElement.outerHTML = html;
    } else if (html) {
      // Replace based on variable type
      el.outerHTML = html;
    }
  });

  // Return updated rich text without the containing div
  return {
    html: tempDiv.innerHTML,
  };
};

export const extractVariables = (rt: Maybe<RichText>) => {
  if (!rt?.html) return [];

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = rt.html;

  const varElements = tempDiv.querySelectorAll("[data-var-field]");

  return Array.from(varElements).map((el) => ({
    field: el.getAttribute("data-var-field") || "",
    type: el.getAttribute("data-var-type") || "text",
    value: undefined,
  }));
};

export const ensureValues = (
  variables: Maybe<VariableDef[]>,
  toDefaultValue?: Fn<VariableDef, PropertyValue>
) =>
  map(variables, (v) => ({
    ...v,
    value: toDefaultValue?.(v) || v.value || { [v.type]: undefined },
  }));
