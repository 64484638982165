import { find } from "lodash";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { ID } from "@api";

import { useLazyEntity, useUnsavedUpdates } from "@state/generic";
import {
  JobStoreAtom,
  useCheckForNewJobs,
  useClaimJob,
  useJob,
} from "@state/jobs";

import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { WorkflowStepsBar } from "@ui/engine/workflow/components";
import { Centered } from "@ui/flex";
import { Icon, SpinnerIcon } from "@ui/icon";
import { LoadingModal } from "@ui/loading-modal";
import { Modal } from "@ui/modal";
import { Text } from "@ui/text";

export const WorkflowRunnerModal = ({
  id,
  onClose,
}: {
  id: ID;
  onClose: Fn<void, void>;
}) => {
  const [jobId, setJobId] = useState<Maybe<ID>>();
  const job = useJob(jobId || "");
  const workflow = useLazyEntity<"workflow">(id);
  const unsaved = useUnsavedUpdates(id);
  const store = useRecoilValue(JobStoreAtom);
  const { claim } = useClaimJob();
  const { check } = useCheckForNewJobs();

  useAsyncEffect(async () => {
    // Wait until all workflow changes are saved...
    if (!!unsaved?.length || store.running || store.claiming) {
      return;
    }

    try {
      const allJobs = await check();
      const toClaim = find(allJobs, (j) => j.data?.id === id);

      if (!toClaim) {
        return;
      }

      await claim(toClaim);
      setJobId(toClaim.id);
    } catch (e) {
      onClose();
    }
  }, [!!unsaved?.length]);

  // Close modal when finished running...
  useEffect(() => {
    if (jobId && (!job || job?.status === "completed")) {
      onClose();
    }
  }, [job?.status]);

  return (
    <LoadingModal onClose={onClose} message="Calculating optimal path...">
      {workflow && <WorkflowStepsBar workflow={workflow} />}
    </LoadingModal>
  );
};
