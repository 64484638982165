import { map } from "lodash";

import {
  Entity,
  HasIcon,
  hasName,
  Person,
  PropertyMutation,
  PropertyRef,
  toTitleOrName,
} from "@api";

import { useVisiblePropertyRefs } from "@state/properties";

import { whenEmpty } from "@utils/array";
import { withHandle } from "@utils/event";
import { safeAs, when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";

import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { EmojiIcon, Icon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { RelationIcon } from "@ui/relation-label";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";

import { ListItemOpts, UIEngine } from "../types";
import { FallbackCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Entity>[] = [
  { field: "status", type: "status" },
  { field: "start", type: "date" },
  { field: "end", type: "date" },
];

export const EntityEngine: UIEngine<Entity> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          text={toTitleOrName(item)}
          icon={<RelationIcon relation={item} />}
        />
      </EntityContextMenu>
    );
  },
  asListCard: function EntityListCard(props) {
    const { item, selection, setSelection, onChange, showProps } = props;
    const { visible } = useVisiblePropertyRefs(
      item,
      whenEmpty(showProps, DEFAULT_PROPS),
      // Props that are visible on the card by default
      { blacklist: ["name", "icon"], hideEmpty: props.hideEmpty }
    );
    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListCard {...props}>
          <VStack className={styles.bar}>
            <HStack>
              <Icon
                icon={
                  when(
                    safeAs<HasIcon>(item)?.icon || safeAs<Person>(item)?.avatar,
                    iconFromString
                  ) || <EmojiIcon emoji="📣" />
                }
              />

              <Label className={styles.title}>
                {toTitleOrName(item) || "Entity"}
              </Label>
            </HStack>
            <HStack>
              {map(visible, (val) => (
                <PropertyValue
                  key={val.field}
                  source={item.source}
                  valueRef={val}
                  inset={true}
                  variant={val.type === "status" ? "labelled" : "unlabelled"}
                  onChange={(c) =>
                    onChange?.({
                      field: val.field,
                      type: val.type,
                      value: c,
                      prev: val.value,
                    } as PropertyMutation<Entity>)
                  }
                />
              ))}
            </HStack>
          </VStack>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props: ListItemOpts<Entity>) {
    const { item, showProps, variant, onChange } = props;
    const { visible } = useVisiblePropertyRefs(
      item,
      whenEmpty(showProps, DEFAULT_PROPS),
      { blacklist: ["name", "icon"], hideEmpty: props.hideEmpty }
    );
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props}>
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={4}>
              {when(
                iconFromString(
                  safeAs<HasIcon>(item)?.icon || safeAs<Person>(item)?.avatar
                ),
                (i) => (
                  <Icon icon={i} />
                )
              )}
              <EditableText
                key={item.id}
                text={toTitleOrName(item)}
                disabled={true}
                placeholder="Untitled"
                blurOnEnter={true}
                onChange={(v) =>
                  onChange?.(
                    toMutation(
                      item,
                      { field: hasName(item) ? "name" : "title", type: "text" },
                      v
                    )
                  )
                }
              />
            </HStack>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              {map(visible, (val) => (
                <PropertyValue
                  key={val.field}
                  valueRef={val}
                  source={item.source}
                  variant={variant || "unlabelled"}
                  onChange={(c) =>
                    onChange?.({
                      field: val.field,
                      type: val.type,
                      value: c,
                      prev: val.value,
                    })
                  }
                />
              ))}
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asCreateDialog: FallbackCreateDialog,
};
