import { filter, map } from "lodash";
import { useMemo } from "react";

import { Entity, hasBody, ID } from "@api";

import { useLazyGetPropertyValueRefs } from "@state/properties";
import { useLazyEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { timeAgo } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { respectHandled } from "@utils/event";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import {
  isAnyProperty,
  isEmptyValue,
  isRelations,
  toKey,
} from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { prefix } from "@utils/string";

import { Card } from "@ui/card";
import { Divider } from "@ui/divider";
import { Icon, OpenIn } from "@ui/icon";
import { PackageLabel } from "@ui/package-label";
import { PropertyValue } from "@ui/property-value";
import { RelationLabel } from "@ui/relation-label";
import { TextSmall } from "@ui/text";

import { HStack, SpaceBetween, VStack } from "./flex";
import { ReadonlyDocument } from "./rich-text";
import { ShowMoreOverflow } from "./show-more-overflow";

import styles from "./parent-card.module.css";

interface Props {
  id: ID;
  label?: string | false;
  showProps?: boolean;
  showBody?: boolean;
  onClick: Fn<Entity, void>;
  className?: string;
}

export const ParentCard = ({
  id,
  className,
  showProps = true,
  showBody = true,
  label,
  onClick,
}: Props) => {
  const entity = useLazyEntity(id);
  const props = useLazyGetPropertyValueRefs(id);
  const visible = useMemo(
    () =>
      filter(
        props,
        (p) =>
          !!p.def &&
          p.def?.visibility !== "hidden" &&
          p.def?.visibility !== "hide_always" &&
          !isAnyProperty(p) &&
          !isRelations(p) &&
          !isEmptyValue(p?.value[p.type])
      ),
    [props]
  );

  if (!entity) {
    return <></>;
  }

  return (
    <Card
      className={className}
      onClick={respectHandled<React.MouseEvent>(() => {
        onClick?.(entity);
      })}
    >
      <VStack fit="container" gap={10}>
        <SpaceBetween>
          <VStack>
            {label !== false && (
              <PackageLabel
                {...entity.source}
                format={when(label || undefined, (l) => prefix(l + " "))}
              />
            )}
            <RelationLabel relation={entity} size="medium" bold />
          </VStack>

          {!!entity.createdAt && (
            <HStack gap={4}>
              <TextSmall subtle className={styles.noWrap}>
                Updated {timeAgo(fromPointDate(entity.updatedAt))}
              </TextSmall>
              <Icon icon={OpenIn} />
            </HStack>
          )}
        </SpaceBetween>

        {showProps && (
          <HStack gap={0} fit="container" wrap className={styles.noPointer}>
            {map(visible, (p, i) => (
              <PropertyValue
                key={toKey(p)}
                inset={i === 0}
                valueRef={p}
                source={entity.source}
              />
            ))}
          </HStack>
        )}

        {showBody && hasBody(entity) && !isEmpty(entity.body) && (
          <>
            <Divider />
            <ShowMoreOverflow
              showAll={false}
              label="Open"
              size="medium"
              onShowAll={() => onClick?.(entity)}
              className={styles.noPointer}
            >
              <ReadonlyDocument
                key={entity?.id}
                className={cx(styles.scaled)}
                content={entity.body}
              />
            </ShowMoreOverflow>
          </>
        )}
      </VStack>
    </Card>
  );
};
