import { snakeCase } from "change-case";
import { every, filter, find, first, map, some } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getRecoil } from "recoil-nexus";

import {
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  getOptimizedForFilter,
  HasRefs,
  HasStatus,
  isEntity,
  isSchedule,
  isStatusable,
  Period,
  Person,
  PropertyDef,
  PropertyMutation,
  PropertyValueRef,
  Ref,
  RichText,
  Status,
  VariableDef,
  Workflow,
  WorkflowStep,
} from "@api";

import { JsonArray } from "@prisma";

import { aiWorkflowStep, useAiUseCase, workflowStepCreate } from "@state/ai";
import { allPropertiesForSource, useLazyProperties } from "@state/properties";
import {
  getStore,
  useCreateFromObject,
  useCreateFromTemplate,
  useGetItemFromAnyStore,
  useLazyEntities,
  useLazyEntity,
  useNestedSource,
  useSource,
  useStore,
  useUnsavedUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useReplyOrCreateThread } from "@state/resources";
import { addPeriod } from "@state/schedule";
import { useSetting } from "@state/settings";
import { mergeItems } from "@state/store";
import { useLazyTemplates } from "@state/templates";

import {
  ensureArray,
  ensureMany,
  indexBy,
  justOne,
  maybeLookup,
  maybeLookupById,
  maybeMap,
  omitEmpty,
  OneOrMany,
} from "@utils/array";
import { ISODate, toISODate, usePointDate } from "@utils/date-fp";
import { addInfo, log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { toJSDate } from "@utils/epoch-date";
import { passes } from "@utils/filtering";
import { composel, fallback, Fn, not } from "@utils/fn";
import { extractVarReference, isFormula } from "@utils/formula";
import { isHumanId, maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { minus } from "@utils/math";
import { Maybe, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { merge } from "@utils/object";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
  flattenChanges,
  fromOverrides,
} from "@utils/property-mutations";
import {
  asFormulaValue,
  asRelationValue,
  getJsonValue,
  getPropertyValue,
  inflateStatus,
  isAnyRelation,
  isEmptyRef,
  isReferencing,
  toPropertyValueRef,
  toRef,
} from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { isMatch, toBaseScope, toChildLocation } from "@utils/scope";
import { toChannelId, toThreadId } from "@utils/slack";
import { toStamp } from "@utils/stamp";
import { isGreaterThan } from "@utils/time";
import { ensureValues } from "@utils/variables";
import { isStar, withoutStar } from "@utils/wildcards";

import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";

import {
  failedRunningStep,
  finishRunningStep,
  runNextStep,
  runWorkflow,
  startRunning,
  toFinishWorkflowUpdates,
} from "./actions";
import { WorkflowRunContextAtom } from "./atoms";
import {
  aiStepToUpdate,
  evalFormula,
  evalFormulas,
  isFinished,
  orderedSteps,
  toStepAssigned,
  toStepMessage,
  toVariables,
} from "./utils";

export const useStartWorkflow = (
  parent: Maybe<Entity>,
  onStarted?: Fn<Ref, void>
) => {
  const me = useMe();
  const [starting, setStarting] = useState<Maybe<Workflow>>();
  const source = useNestedSource(parent, "workflow");
  const mutate = useMutate();
  const [collecting, setCollecting] = useState<VariableDef[]>();
  const [collected, setCollected] = useState(false);
  const onFinished = useCallback(
    (wf: Ref) => {
      if (!starting) {
        throw new Error("No running workflow on callback.");
      }

      onStarted?.(wf);
      setStarting(undefined);

      mutate(
        // Probs don't need this as below usedBy should link
        omitEmpty([
          parent && !equalsAny(parent.source.type, ["workflow", "team"])
            ? asUpdate(
                parent,
                asAppendMutation(
                  {
                    field: isSchedule(parent)
                      ? "instances"
                      : "refs.fromWorkflow",
                    type: "relations",
                  },
                  [{ id: wf.id }]
                )
              )
            : undefined,

          // Mark workflow as dirty and hence ready to run. Prevents runnning before all saved.
          asUpdate(
            { id: wf.id, source: starting.source },
            asMutation({ field: "stamps.dirty", type: "stamp" }, toStamp(me))
          ),

          // Update the templates last run stamp
          asUpdate(
            starting,
            asMutation({ field: "stamps.lastRun", type: "stamp" }, toStamp(me))
          ),
        ])
      );
    },
    [starting, onStarted, setStarting]
  );

  const createFromTemplate = useCreateFromTemplate(source, onFinished);
  const ready = createFromTemplate.ready;

  const start = useCallback(
    (workflow: Workflow, precollected?: PropertyValueRef[]) => {
      if (!ready) {
        showError("Not ready to start workflow.");
        return;
      }

      if (!!starting) {
        // Prevent double starting
        return;
      }

      setStarting(workflow);

      // Match any precollected values to the inputs
      if (precollected?.length) {
        const getPreValue = maybeLookup(precollected, (v) => v.field);
        setCollecting(
          map(precollected, (v) => ({
            ...v,
            value: getPreValue(v.field)?.value || { [v.type]: undefined },
          }))
        );
        setCollected(true);
      } else {
        setCollecting(
          parent?.source.type === "workflow"
            ? ensureValues(workflow.inputs)
            : // Add default value to relation variables that match the parent work
              ensureValues(workflow.inputs, (i) =>
                isAnyRelation(i) &&
                isEmptyRef(i) &&
                !!parent &&
                (isStar(i.options?.references) ||
                  equalsAny(
                    parent.source.type,
                    ensureMany(withoutStar(i.options?.references))
                  ))
                  ? asRelationValue(i.type, parent.id)
                  : i.value
              )
        );
      }
    },
    [createFromTemplate.create, ready]
  );

  useEffect(() => {
    if (!starting || !collecting || !parent) {
      return;
    }

    if (!collected) {
      return;
    }

    createFromTemplate.create(starting, {
      overrides: {
        [starting.id]: {
          inputs: collecting,
          status: { id: "RUN" },
          owner: starting.owner || toRef(me),
          refs: {
            startedFrom: !isEntity(parent, "workflow")
              ? [{ id: parent.id }]
              : [],
            usedBy: !equalsAny(parent.source.type, ["schedule", "team"])
              ? [{ id: parent.id }]
              : [],
          },
        },
        ["workflow_step"]: {
          status: { id: "NTS" },
        },
      },
    });
  }, [starting, collecting, collected, parent?.id]);

  return {
    start,
    starting: starting,
    ready: ready,
    collecting,
    onCollected: (vars: VariableDef[]) => {
      setCollected(true);
      setCollecting(vars);
    },
    onCancelled: () => setStarting(undefined),
  };
};

// Extract workflow input collection logic
const useWorkflowInputCollection = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>
) => {
  const mutate = useMutate();
  const toCollect = useMemo(() => {
    // Only collect workflow inputs
    const vars = workflow?.inputs || [];
    return filter(vars, (v) => v.value === undefined || v.value === null);
  }, [workflow, steps]);

  const [collected, setCollected] = useState(false);

  const onCollected = useCallback(
    (vars: VariableDef[]) => {
      if (!workflow) return;

      const getVar = (field: string) => vars.find((v) => v.field === field);

      const updatedInputs = workflow.inputs?.map((v) => {
        const collectedVar = getVar(v.field);
        return collectedVar ? { ...v, value: collectedVar.value } : v;
      });

      // You might want to use a mutation here depending on your state management
      mutate(
        asUpdate(
          workflow,
          asMutation({ field: "inputs", type: "json" }, updatedInputs)
        )
      );

      setCollected(true);
    },
    [workflow?.inputs]
  );

  return {
    toCollect,
    onCollected,
    collected,
  };
};

export const useRunWorkflow = (
  workflow: Maybe<Workflow>,
  onFinished?: Fn<void, void>,
  onFailed?: Fn<void, void>
) => {
  const me = useMe();
  const mutate = useMutate();
  const unsaved = useUnsavedUpdates(workflow?.id);
  const steps = useWorkflowSteps(workflow);
  const [runContext, setRunContext] = useRecoilState(
    WorkflowRunContextAtom(workflow?.id || "")
  );
  const running = !!runContext;

  // Workflow input collection
  const { toCollect, onCollected, collected } = useWorkflowInputCollection(
    workflow,
    steps
  );

  // Step runner (you might want to extract this to a separate hook)
  const stepRunner = useRunWorkflowStep(workflow, steps);

  // Determine if workflow saving is finished
  const finishedSaving = useMemo(
    () => !!workflow?.id && !unsaved?.length,
    [unsaved?.length, workflow?.id]
  );

  // Workflow readiness conditions
  const ready =
    !!workflow &&
    !workflow?.template &&
    (workflow?.status?.id === "RUN" || workflow?.status?.id === "WAI") &&
    finishedSaving &&
    (!runContext?.running || stepRunner.ready) &&
    (toCollect.length === 0 || collected);

  // Run workflow
  const runNext = () => {
    if (!workflow || !steps.length) {
      return;
    }

    // Still running a step
    if (stepRunner.running || runContext?.running) {
      return;
    }

    // Finished running all that it can
    if (!runContext?.more) {
      return !!runContext?.errored?.length && onFailed
        ? onFailed()
        : onFinished?.();
    }

    // Try progress the run context
    setRunContext(runNextStep(workflow, steps || []));
  };

  const run = useCallback(() => {
    if (!workflow) {
      throw new Error("Not ready to run.");
    }

    setRunContext(runWorkflow(workflow));

    mutate(
      asUpdate(
        workflow,
        asMutation({ field: "stamps.lastRun", type: "stamp" }, toStamp(me))
      )
    );
  }, [running, ready]);

  useEffect(() => {
    if (running && finishedSaving) {
      runNext();
    }
  }, [running, steps.length, finishedSaving, runContext]);

  return {
    run,
    toCollect,
    onCollected,
    running: stepRunner.running || false,
    message:
      stepRunner.message ||
      when(runContext?.running, (s) => toStepMessage(s, true)),
    ready: !!workflow,
  };
};

const useRunWorkflowStep = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>,
  onFinished?: (step: WorkflowStep) => void,
  onFailed?: (step: WorkflowStep) => void
) => {
  const [runContext, setRunContext] = useRecoilState(
    WorkflowRunContextAtom(workflow?.id || "")
  );
  const mutate = useMutate();
  const me = useMe();
  const running = runContext?.running;
  const getItem = useGetItemFromAnyStore();
  const getStep = useMemo(() => maybeLookupById(steps || []), [steps]);
  const stepProps = useLazyProperties(running?.source);

  const creatingSource = useSource(
    fallback(
      () => running?.options?.entity as Maybe<EntityType>,
      () => (running?.action === "message" ? "note" : undefined)
    ),
    when(workflow, (w) => toChildLocation(w.source.scope, w.id))
  );

  const handleCompleted = useCallback(
    (
      step: WorkflowStep,
      status: Status = { id: "FNS" },
      additional?: PropertyMutation[]
    ) => {
      if (!workflow) {
        throw new Error("No workflow to run step in.");
      }

      mutate([
        asUpdate(step, [
          ...(additional || []),
          asMutation(
            { field: "status", type: "status" },
            // If it's a task then set the status to waiting (for the work to be done), otherwise set it to finished
            status
          ),
        ]),
        ...(workflow
          ? [
              asUpdate(
                workflow,
                omitEmpty([
                  // Add the step to the finished refs
                  status?.id === "FNS"
                    ? asAppendMutation(
                        { field: "refs.finished", type: "relations" },
                        [{ id: step.id }]
                      )
                    : undefined,
                ])
              ),
            ]
          : []),
      ]);

      // Ordering important
      setRunContext(finishRunningStep(workflow, step));
      onFinished?.(step);
    },
    [onFinished, workflow]
  );

  const storeRefInOutputs = useCallback(
    (step: WorkflowStep, ref: OneOrMany<Ref>) => {
      const refs = ensureArray(ref);
      if (!refs?.length) {
        return;
      }

      const refSource = {
        scope: step.source.scope,
        type: typeFromId(justOne(refs)?.id || ""),
      } as DatabaseID;

      mutate(
        asUpdate(
          step,
          asMutation(
            { field: "outputs", type: "json" },
            safeAs<JsonArray>(
              map(step.outputs, (v) => {
                return isAnyRelation(v) && isReferencing(v, refSource.type)
                  ? { ...v, value: asRelationValue(v.type, refs) }
                  : { ...v, value: v.value || {} };
              })
            )
          )
        )
      );
    },
    [workflow, steps]
  );

  const handleCreated = useCallback(
    (step: WorkflowStep, newlyCreated?: Ref) => {
      if (!workflow) {
        throw new Error("No workflow to run step in.");
      }

      // Temp hack – add any overrides to the created work
      if (newlyCreated) {
        const newSource = {
          scope: step.source.scope,
          type: typeFromId(newlyCreated.id),
        } as DatabaseID;

        mutate({
          id: newlyCreated.id,
          source: newSource,
          method: "update",
          changes: evalFormulas(
            step.overrides || [],
            toVariables(workflow, steps)
          ) as PropertyMutation[],
        });

        // If the step has outputs, update them with the newly created entity
        if (step.outputs?.length) {
          storeRefInOutputs(step, newlyCreated);
        }
      }

      const hasStatus = when(
        safeAs<EntityType>(step?.options?.entity) ||
          when(newlyCreated?.id, maybeTypeFromId<EntityType>),
        isStatusable
      );
      // Default to waiting for statusable work to be done
      const shouldWait = hasStatus && (step.options?.waitDone ?? true);

      // Ordering important
      setRunContext(finishRunningStep(workflow, step));
      handleCompleted(step, { id: shouldWait ? "WAI" : "FNS" });
    },
    [workflow, handleCompleted]
  );

  const handleRollback = useCallback(
    (
      step: WorkflowStep,
      status: Status = { id: "NTS" },
      additional?: PropertyMutation[]
    ) => {
      if (!workflow) {
        throw new Error("No workflow to run step in.");
      }

      mutate(
        asUpdate(step, [
          asMutation({ field: "status", type: "status" }, status),
          ...(additional || []),
        ])
      );

      // Ordering important
      setRunContext(failedRunningStep(workflow, step));

      if (equalsAny(status.id, ["WAI", "NTS"])) {
        onFinished?.(step);
      } else {
        onFailed?.(step);
      }
    },
    [onFailed]
  );

  const create = useCreateFromObject(
    creatingSource?.type || "task",
    creatingSource?.scope
  );
  const template = useCreateFromTemplate(creatingSource, (created) => {
    running && handleCreated(running, created);
  });
  const ai = useAiUseCase(aiWorkflowStep);
  const aiStepsCreate = useAiUseCase(workflowStepCreate);

  const defaultChannel = useSetting<string>(
    workflow?.id || "",
    "settings.channel"
  );
  const threadValue = useMemo(
    () => asFormulaValue(safeAs<string>(running?.options?.thread)),
    [running?.options?.thread]
  );
  const replyToRef = useMemo(
    () =>
      when(
        threadValue?.formula,
        (formula) =>
          evalFormula(formula, "relation", toVariables(workflow, steps))
            ?.relation
      ),
    [threadValue]
  );
  const replyTo = useLazyEntity<"note">(replyToRef?.id);
  const [channel, thread] = useMemo(
    () =>
      when(replyTo?.links?.[0]?.url, (url) => [
        toChannelId(url),
        toThreadId(url),
      ]) || [
        safeAs<string>(running?.options?.channel) || defaultChannel,
        threadValue.text,
      ],
    [running?.options, replyTo]
  );
  const sendSlack = useReplyOrCreateThread(channel, thread);

  const setStore = useSetRecoilState(
    useMemo(
      () => getStore(safeAs<EntityType>(running?.options?.entity) || "task"),
      [running?.options?.entity]
    )
  );

  const ready = !!workflow && template.ready;

  const run = useCallback(async () => {
    if (!ready || !running) {
      throw new Error("Tried to run workflow step before ready.");
    }

    // Mark the step as running
    mutate(
      asUpdate(running, [
        asMutation({ field: "status", type: "status" }, { id: "RUN" }),
        asMutation({ field: "stamps.lastRun", type: "stamp" }, toStamp(me)),
      ])
    );

    try {
      await switchEnum(running.action || "", {
        exit: () => {
          mutate(toFinishWorkflowUpdates(workflow, steps || []));

          handleCompleted(running, { id: "FNS" });
        },

        entry: () => {
          mutate(
            asUpdate(
              workflow,
              asMutation({ field: "status", type: "status" }, { id: "RUN" })
            )
          );

          handleCompleted(running);
        },

        wait: () => {
          const firstStarted =
            when(running?.stamps?.firstRun?.at, toJSDate) || now();

          let end = safeAs<Maybe<ISODate>>(running?.options?.waitUntil);

          if (!end) {
            const waitTimes =
              when(running?.options?.waitTimes, (times) =>
                isFormula(times)
                  ? evalFormula(times, "number", toVariables(workflow, steps))
                      ?.number
                  : Number(times)
              ) || 0;
            const waitPeriod =
              safeAs<Period>(running?.options?.waitPeriod) || Period.Day;

            end = toISODate(
              addPeriod(firstStarted, waitPeriod, waitTimes),
              "point"
            );

            mutate(
              asUpdate(running, [
                asMutation({ field: "options.waitUntil", type: "date" }, end),
                asMutation(
                  { field: "stamps.firstRun", type: "stamp" },
                  toStamp(me)
                ),
              ])
            );
          }

          if (usePointDate(end, (d) => isGreaterThan(now(), d))) {
            handleCompleted(running);
          } else {
            handleRollback(running, { id: "WAI" });
          }
        },

        condition: () => {
          const targetRaw = safeAs<string>(running.options?.target);
          if (!targetRaw) {
            throw new Error("No target defined for condition.");
          }

          const keepChecking = !!running?.options?.keepChecking;
          const variables = toVariables(workflow, steps);
          const filter = safeAs<FilterQuery>(
            getPropertyValue(running, {
              field: "options.filter",
              type: "json",
            })?.json
          ) || { and: [] };

          const targetVar = isFormula(targetRaw)
            ? when(extractVarReference(targetRaw), (field) =>
                find(variables, (v) => v.field === field)
              )
            : undefined;

          const targetIds =
            when(targetVar?.value?.relation, ensureMany) ||
            targetVar?.value?.relations ||
            (isHumanId(targetRaw) ? [toRef(targetRaw)] : undefined);

          let passed = false;

          // Variable is a relation(s), hence filter it as an entity
          if (targetIds?.length) {
            const targets = maybeMap(targetIds, (r) => getItem(r.id));

            if (targetIds?.length !== targets?.length) {
              throw new Error("No target found for condition.");
            }

            const props = getRecoil(
              allPropertiesForSource(targets?.[0]?.source)
            );
            const indexedProps = indexBy(props, (p) => p.field);

            passed = every(targets, (t) => {
              const res = passes(t, filter, indexedProps);

              // Make sure the target work is linked to the step
              // so that when it is completed the workflow is marked as dirty
              if (
                !res &&
                keepChecking &&
                !containsRef(
                  safeAs<HasRefs>(t)?.refs?.markDirty,
                  toRef(workflow)
                )
              ) {
                mutate(
                  asUpdate(
                    t,
                    asAppendMutation(
                      { field: "refs.markDirty", type: "relations" },
                      [{ id: workflow.id }]
                    )
                  )
                );
              }

              return res;
            });
          } else if (targetVar) {
            // Variable is pointing to a var of property type
            const target = {
              [targetVar.field]: targetVar.value?.[targetVar.type],
            };
            // Evaluate the filter against the mock target that just has the one property value
            passed = passes(target, filter, {
              [targetVar.field]: safeAs<PropertyDef>(targetVar),
            });
          }

          if (!passed && keepChecking) {
            handleRollback(running, { id: "WAI" });
            return;
          }

          // Store results in step outputs
          handleCompleted(running, { id: "FNS" }, [
            asMutation(
              { field: "outputs", type: "json" },
              safeAs<JsonArray>([
                {
                  field: when(running.name, snakeCase) || running.id,
                  type: "boolean",
                  value: { boolean: passed },
                },
              ])
            ),
          ]);
        },

        find: async () => {
          const output = running.outputs?.[0];

          if (!output) {
            throw new Error("No out found for find.");
          }

          const findSource = {
            type: running.options?.entity,
            scope:
              when(safeAs<string>(running?.options?.within), (s) => {
                if (isFormula(s)) {
                  const id = evalFormula(
                    s,
                    "relation",
                    toVariables(workflow, steps)
                  )?.relation?.id;
                  const item = when(id, getItem);
                  return item
                    ? toChildLocation(item?.source.scope, item.id)
                    : id;
                }

                return s;
              }) || toBaseScope(workflow.source.scope),
          } as DatabaseID;

          const baseFilter: FilterQuery = {
            field: "location",
            op: "contains",
            type: "text",
            value: { text: findSource.scope },
          };
          const userFilter = safeAs<FilterQuery>(
            getPropertyValue(running, {
              field: "options.filter",
              type: "json",
            })?.json
          );
          const filter = { and: omitEmpty([baseFilter, userFilter]) };

          const limit = safeAs<number>(running.options?.limit) || 100;
          const { all: ids, changed: items } = await getOptimizedForFilter(
            findSource,
            filter,
            {
              since: undefined, // Fetch all results
              limit,
              archived: false,
              templates: false,
            }
          );

          // Add to store
          if (items?.length) {
            setStore(mergeItems(items));
          }

          mutate(
            asUpdate(
              running,
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>([
                  {
                    ...output,
                    value:
                      limit === 1
                        ? { relation: when(first(ids), toRef) }
                        : { relations: map(ids, toRef) },
                  },
                ])
              )
            )
          );

          handleCompleted(running);
        },

        update: () => {
          const variables = toVariables(workflow, steps);
          const getVar = maybeLookup(variables, (v) => v.field);

          const targetIds = when(safeAs<string>(running.options?.target), (v) =>
            isFormula(v)
              ? evalFormula(v, "relations", getVar)?.relations
              : [toRef(v)]
          );

          // TODO: Need to fetch the items from the server
          const targets = maybeMap(targetIds || [], (r) => getItem(r?.id));

          if (!targets?.length && !!targetIds?.length) {
            addInfo({ targetIds, running });
            throw new Error("No target found for update.");
          }

          mutate(
            map(targets, (target) =>
              asUpdate(
                target,
                fromOverrides(evalFormulas(running.overrides || [], getVar))
              )
            )
          );
          handleCompleted(running);
        },

        set_var: () => {},

        control: () => {
          const all = safeAs<boolean>(running.options?.all) || false;
          const refs = ensureMany(running.refs?.blockedBy);
          const blocked = map(refs, (r) => getStep(r.id));
          const requirement = all ? every : some;

          if (requirement(blocked, (b) => !!b && b.status?.id === "FNS")) {
            handleCompleted(running);
          } else {
            handleRollback(running, { id: "WAI" });
          }
        },

        ai_step_gen: async () => {
          try {
            const result = await aiStepsCreate.run({
              workflow,
              prompt:
                safeAs<RichText>(running.options?.prompt)?.html ||
                "Do nothing.",
              steps: steps || [],
              from: running,
            });

            mutate(map(result, (s) => aiStepToUpdate(workflow, s, stepProps)));

            handleCompleted(running);
          } catch (err) {
            log(err);
            handleRollback(running);
          }
        },

        ai: async () => {
          try {
            const outputValues = await ai.run({
              workflow,
              step: running,
              steps: steps || [],
            });

            const getFieldValue = maybeLookup(
              outputValues || [],
              (v) => safeAs<PropertyValueRef>(v)?.field
            );

            // Add the values to the outputs if when provided by ai
            mutate(
              asUpdate(
                running,
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>(
                    map(
                      running.outputs,
                      (o) =>
                        when(getFieldValue(o.field), (v) => ({
                          ...o,
                          value: safeAs<PropertyValueRef>(v)?.value || o.value,
                        })) || o
                    )
                  )
                )
              )
            );

            handleCompleted(running);
          } catch (err) {
            log(err);
            handleRollback(running);
          }
        },

        message: async () => {
          // TODO: Add support for email/sms

          const messageRaw = toPropertyValueRef(running, {
            field: "options.message",
            type: "rich_text",
          });

          if (!messageRaw) {
            throw new Error("No message found to send.");
          }

          const variables = toVariables(workflow, steps);
          const [message] = evalFormulas([messageRaw], variables);

          const from = when(safeAs<string>(running.options?.from), (v) =>
            isFormula(v)
              ? evalFormula(v, "relation", variables)?.relation
              : toRef(v)
          );

          if (!message.value.rich_text) {
            throw new Error("No message to send after evaluating formulas.");
          }

          const { link: url } = await sendSlack(
            {
              message: message.value.rich_text,
              author: when(from?.id, (id) => getItem<Person>(id)),
            },
            {
              message: {
                html: `<p>Thread created from <a data-mention-id="${workflow.id}">workflow</a>.</p>`,
              },
            }
          );
          const link = url ? { text: "Slack Thread", url: url } : undefined;

          const saved = create?.([
            flattenChanges([
              { field: "type", type: "text", value: { text: "update" } },
              {
                field: "links",
                type: "links",
                value: { links: link ? [link] : undefined },
              },
              {
                field: "title",
                type: "text",
                value: { text: "Workflow Message" },
              },
              {
                field: "body",
                type: "rich_text",
                value: { rich_text: message.value.rich_text },
              },
              {
                field: "refs.fromStep",
                type: "relations",
                value: { relations: [{ id: running.id }] },
              },
              {
                field: "refs.fromWorkflow",
                type: "relations",
                value: { relations: [{ id: workflow.id }] },
              },
              {
                field: "refs.followers",
                type: "relations",
                value: { relations: running.refs?.followers },
              },
            ]),
          ]);

          // Publish note to variables if options variable name is set
          // saved;

          handleCreated(running, justOne(saved));
        },

        create: () => {
          // Already ran and waiting for the work to finish...
          if (
            running?.status?.id === "WAI" ||
            !!running.refs?.created?.length
          ) {
            const work = when(running.refs.created?.[0], (r) => getItem(r.id));
            /// TODO: Cleanup when statuses are inflated by the API
            const status = when(safeAs<HasStatus>(work)?.status, (s) =>
              inflateStatus(s, getRecoil(allPropertiesForSource(work?.source)))
            );

            if (!status || status?.group === "done") {
              return handleCompleted(running);
            } else {
              return handleRollback(running, { id: "WAI" });
            }
          }

          const useTemplate = when(
            safeAs<string>(running.options?.useTemplate),
            toRef
          );

          if (useTemplate) {
            template.create(useTemplate, {
              overrides: {
                // Set orders of the work to the current step index
                [useTemplate.id]: {
                  orders: {
                    default: orderedSteps(steps)?.indexOf(running) || 0,
                  },
                },
                "*": {
                  location: safeAs<string>(running?.options?.inLocation),
                  refs: {
                    fromWorkflow: when(
                      workflow?.id,
                      composel(toRef, ensureArray)
                    ),
                    fromStep: [{ id: running.id }],
                  },
                },
              },
            });
          } else {
            const created = create?.([
              merge(
                flattenChanges(
                  evalFormulas(
                    running.overrides || [],
                    toVariables(workflow, steps)
                  )
                ),
                {
                  location: safeAs<string>(running?.options?.inLocation),

                  // Set orders of the work to the current step index
                  orders: {
                    default: orderedSteps(steps)?.indexOf(running) || 0,
                  },
                  refs: {
                    fromWorkflow: when(
                      workflow?.id,
                      composel(toRef, ensureArray)
                    ),
                    fromStep: [{ id: running.id }],
                  },
                }
              ),
            ]);
            handleCreated(running, justOne(created));
          }
        },
        else: () => {},
      });
    } catch (err) {
      log(err);
      handleRollback(running);
    }
  }, [
    running,
    ready,
    template.create,
    create,
    workflow,
    steps,
    handleCreated,
    handleRollback,
    handleCompleted,
    storeRefInOutputs,
  ]);

  useAsyncEffect(async () => {
    if (running && ready && !runContext.startedAt) {
      setRunContext(startRunning(running));
      await run();
    }
  }, [running?.id, ready]);

  return {
    running: running?.id || false,
    message: useMemo(
      () => (running ? toStepMessage(running, true) : undefined),
      [running]
    ),
    ready: !running && ready,
  };
};

export const useVariables = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>
) => {
  return useMemo(() => toVariables(workflow, steps), [workflow?.inputs, steps]);
};

export const useWorkflowSteps = (workflow: Maybe<Workflow>) => {
  const all = useLazyEntities<"workflow_step">(workflow?.refs?.steps);
  const store = useStore("workflow_step");
  return useMemo(() => orderedSteps(all, store.aliases), [all]);
};

export const useShowRunning = (workflows: Maybe<Workflow[]>) => {
  const [showAll, setShowAll] = useState(false);
  const filtered = useMemo(
    () => filter(workflows, not(isFinished)),
    [workflows, showAll]
  );
  const moreCount = minus(workflows?.length, filtered?.length);

  return {
    visible: showAll ? workflows : filtered,
    hasMore: moreCount > 0,
    moreCount,
    showAll,
    setShowAll,
  };
};

export const useStepOwner = (step: WorkflowStep): Maybe<Ref> => {
  const workflow = useLazyEntity<"workflow">(step.refs?.workflow?.[0]?.id);
  const steps = useWorkflowSteps(workflow);
  return useMemo(
    () => (workflow ? toStepAssigned(step, { workflow, steps }) : undefined),
    [step]
  );
};

export const useWorkflowsForEntity = (entity: Entity) => {
  const scope = useMemo(
    () => toBaseScope(entity.source.scope),
    [entity.source.scope]
  );
  const allWorkflows = useLazyTemplates<"workflow">({
    type: "workflow",
    scope,
  });

  return useMemo(
    () =>
      filter(
        allWorkflows,
        (w) =>
          w.status?.id === "AVL" &&
          isMatch(entity.source.scope, w.source.scope) &&
          equalsAny(
            entity.source.type,
            getJsonValue<EntityType[]>(w, "options.availableOn") || []
          )
      ),
    [allWorkflows]
  );
};
