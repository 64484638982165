import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { isUrl } from "@utils/url";

import { Container, Props as ContainerProps } from "@ui/container";
import { EditableText } from "@ui/editable-text";
import { Icon, ImageIcon } from "@ui/icon";
import { EmojiSelect } from "@ui/select/emoji";

import styles from "./editable-heading.module.css";

interface Props {
  key: string;
  text: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: Fn<string, void>;
  className?: string;
  size?: "h1" | "h2" | "h3";
}

export const EditableHeading = ({
  text,
  placeholder,
  onChange,
  autoFocus = !text,
  className,
  size = "h1",
}: Props) => (
  <EditableText
    text={text || ""}
    className={cx(styles.heading, styles[size], className)}
    autoFocus={autoFocus}
    placeholder={placeholder || "Untitled"}
    onChange={onChange}
  />
);

export const EditableIconHeading = ({
  text,
  icon,
  onChange,
  stack = "horizontal",
}: Omit<Props, "onChange"> & {
  stack?: ContainerProps["stack"];
  icon?: string;
  onChange: Fn<{ text?: string; icon?: string }, void>;
}) => (
  <Container padding="none" stack={stack} gap={0}>
    {when(icon, isUrl) ? (
      <Icon
        className={styles.icon}
        size="xlarge"
        icon={<ImageIcon url={icon || ""} />}
      />
    ) : (
      <EmojiSelect
        className={styles.icon}
        size="xlarge"
        emoji={icon || "💼"}
        onChange={(emoji) => onChange({ icon: emoji })}
      />
    )}

    <EditableText
      text={text || ""}
      className={cx(styles.heading, styles.h1)}
      placeholder="Untitled"
      updateOn="blur"
      onChange={(t) => onChange({ text: t })}
    />
  </Container>
);
