import { find, groupBy, map } from "lodash";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import {
  DatabaseID,
  Entity,
  Form,
  FormPropDef,
  PropertyDef,
  PropertyRef,
  Ref,
} from "@api";

import { PropertyDefStoreAtom, useLazyProperties } from "@state/properties";
import { useLazyFetchResults } from "@state/fetch-results";
import { useLazyEntity, useNestedSource } from "@state/generic";

import { ensureMany, justOne, omitEmpty } from "@utils/array";
import { isWorkspaceId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when, whenTruthy } from "@utils/maybe";
import { isMatch, toBaseScope, toChildLocation } from "@utils/scope";

import { withFixedFields } from "./utils";

export const useFormFields = (
  form: Maybe<Form>
): [FormPropDef, Maybe<PropertyDef>][] => {
  const itemSource = useNestedSource(form, form?.entity);
  // TODO: Modify getPropertyDefs to allow fetching all properties of a given field (regardless of entitytype)
  // Then fetch all properties for the form's fields
  // Make sure fetched
  useLazyProperties(itemSource, false);

  const workflow = useLazyEntity<"workflow">(
    justOne(form?.refs?.runWorkflow)?.id
  );

  const store = useRecoilValue(PropertyDefStoreAtom);
  const defsByField = useMemo(
    () => groupBy(store.lookup, (v) => v?.field),
    [store.lookup]
  );

  const maybeGetDef = useCallback(
    (ref: PropertyRef) =>
      whenTruthy(!!itemSource && defsByField[ref.field], (defs) =>
        find(
          defs,
          (p) =>
            !!p &&
            (!p.entity || equalsAny(form?.entity, ensureMany(p.entity))) &&
            p.type === ref.type &&
            isMatch(p.scope, itemSource?.scope)
        )
      ),
    [itemSource?.scope, form?.entity, defsByField]
  );

  return useMemo(() => {
    // Dynamically determine all the fields that should be shown in the form
    if (form?.template) {
      return [
        ...map(workflow?.inputs || [], (p) => [p, p]),
        ...map(withFixedFields(form?.fields), (f) => [
          f,
          maybeGetDef(f as PropertyRef),
        ]),
      ] as Array<[FormPropDef, Maybe<PropertyDef>]>;
    }

    // When submitting a snapshot of the fields are stored on the form
    return map(form?.fields, (f) => [
      f,
      maybeGetDef(f as PropertyRef),
    ]) as Array<[FormPropDef, Maybe<PropertyDef>]>;
  }, [
    form?.fields,
    maybeGetDef,
    itemSource?.type,
    itemSource?.scope,
    workflow?.inputs,
  ]);
};

const isEntity = (thing: Maybe<Entity | DatabaseID>): thing is Entity =>
  !!safeAs<Entity>(thing)?.id;

export const useFormsForLocation = (
  entityOrSource?: Maybe<Entity | DatabaseID>
) => {
  const source = useMemo(() => {
    if (!entityOrSource) {
      return undefined;
    }

    return isEntity(entityOrSource)
      ? {
          scope: toChildLocation(
            entityOrSource.source.scope,
            entityOrSource.id
          ),
          type: entityOrSource.source.type,
        }
      : entityOrSource;
  }, [
    safeAs<Entity>(entityOrSource)?.id,
    safeAs<DatabaseID>(entityOrSource)?.scope,
  ]);

  const allForms = useLazyFetchResults(
    `forms-for-${source?.scope || "all"}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "template",
            type: "text",
            op: "equals",
            value: { text: "root" },
          },

          // Filter for forms that are in this root location
          when(source?.scope, (scope) =>
            !isWorkspaceId(scope)
              ? {
                  field: "location",
                  type: "text",
                  op: "starts_with",
                  value: { text: toBaseScope(scope) },
                }
              : undefined
          ),
        ]),
      }),
      [source?.scope, source?.type]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};

export const useFormsForTemplate = (template: Ref) => {
  const allForms = useLazyFetchResults(
    `forms-for-template-${template?.id}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "useTemplate",
            type: "relation",
            op: "equals",
            value: { relation: { id: template.id } },
          },
        ]),
      }),
      [template?.id]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};
