import { map, orderBy, without } from "lodash";
import { useMemo, useState } from "react";

import { JsonArray, Period, PropertyMutation, Schedule } from "@api";

import { toDayOfPeriod, toDayOfWeek } from "@state/schedule/utils";
import { useEntityLabels } from "@state/settings";

import { append, OneOrMany } from "@utils/array";
import { fromCalDate, toCalDate } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { plural, titleCase } from "@utils/string";
import { format, keepTime, parseTime, toISOTime } from "@utils/time";

import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { Dropdown } from "@ui/dropdown";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import { Clock, ClockHistory } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { TimezoneSelect } from "@ui/select/timezone";
import { Text } from "@ui/text";
import { TimePicker } from "@ui/time-picker";

import styles from "./editor.module.css";

const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

export const ScheduleRepeatOptions = ({
  schedule,
  mutate,
  showStart = true,
}: {
  schedule: Schedule;
  showStart?: boolean;
  mutate: Fn<OneOrMany<PropertyMutation<Schedule>>, void>;
}) => {
  const toLabel = useEntityLabels(schedule.source.scope, {
    plural: true,
    case: "lower",
  });
  const time = useMemo(
    () => when(schedule.timeOfDay, parseTime),
    [schedule.timeOfDay]
  );

  return (
    <Container stack="vertical" gap={10} padding="none">
      {showStart && (
        <Field label="Starting" layout="horizontal">
          <FillSpace fit="container">
            <DateInputPicker
              portal={true}
              date={fromCalDate(schedule.from, "local")}
              onChanged={(d) =>
                !!d &&
                mutate([
                  asMutation(
                    { field: "from", type: "date" },
                    keepTime(toCalDate(d, "local"), schedule.from)
                  ),
                  asMutation({ field: "daysOfPeriod", type: "json" }, [
                    toDayOfPeriod(toCalDate(d, "local"), schedule.period),
                  ]),
                ])
              }
            />
          </FillSpace>
        </Field>
      )}

      <Field label="Repeat every" layout="horizontal">
        <SpaceBetween gap={6}>
          <TextInput
            value={when(schedule.frequency, String) || "0"}
            updateOn="change"
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "frequency", type: "number" },
                  parseInt(v, 10)
                )
              )
            }
            inputType="number"
          />
          <ButtonGroup>
            {map(["day", "week", "month", "year"], (period) => (
              <SplitButton
                key={period}
                size="small"
                selected={schedule.period === period}
                onClick={() =>
                  mutate([
                    asMutation({ field: "period", type: "text" }, period),
                    asMutation(
                      { field: "daysOfPeriod", type: "json" },
                      when(schedule.from, (f) => [
                        toDayOfPeriod(f, period as Period),
                      ]) as JsonArray
                    ),
                  ])
                }
              >
                {titleCase(period)}
              </SplitButton>
            ))}
          </ButtonGroup>
        </SpaceBetween>
      </Field>

      {schedule.period === "week" && (
        <Field label="On" layout="horizontal">
          <HStack fit="container">
            <ButtonGroup fit="container">
              {map(WEEKDAYS, (day) => (
                <SplitButton
                  key={day}
                  size="small"
                  selected={schedule.daysOfPeriod?.includes(day) ?? false}
                  onClick={() => {
                    mutate(
                      asMutation(
                        { field: "daysOfPeriod", type: "json" },
                        schedule?.daysOfPeriod?.includes(day)
                          ? without(schedule.daysOfPeriod, day)
                          : // Always keep daysOfPeriod ordered
                            orderBy(append(schedule.daysOfPeriod, day))
                      )
                    );
                  }}
                >
                  {titleCase(toDayOfWeek(day)?.slice(0, 3))}
                </SplitButton>
              ))}
            </ButtonGroup>
          </HStack>
        </Field>
      )}

      {/* Currently only meetings require time/zone */}
      {schedule.entity === "meeting" && (
        <Field label="Time" layout="horizontal">
          <SpaceBetween fit="container" gap={6}>
            <TimePicker
              time={time}
              className={styles.timePicker}
              onChange={(t) =>
                mutate(
                  asMutation(
                    { field: "timeOfDay", type: "text" },
                    when(t, toISOTime)
                  )
                )
              }
            >
              <Button
                className={styles.control}
                fit="container"
                size="small"
                subtle
                icon={Clock}
              >
                {when(time, format) || "Choose time"}
              </Button>
            </TimePicker>
            <Text subtle>in</Text>
            <FillSpace>
              <TimezoneSelect
                placeholder="Local timezone"
                value={schedule.timeZone}
                onChange={(tz) =>
                  mutate(
                    asMutation(
                      { field: "timeZone", type: "text" },
                      tz || undefined
                    )
                  )
                }
              />
            </FillSpace>
          </SpaceBetween>
        </Field>
      )}

      <Field label="Until" layout="horizontal">
        <FillSpace fit="container">
          <DateInputPicker
            date={fromCalDate(schedule.to, "local")}
            placeholder="Forever"
            onChanged={(d) =>
              !!d &&
              mutate(
                asMutation({ field: "to", type: "date" }, toCalDate(d, "local"))
              )
            }
          />
        </FillSpace>
      </Field>

      <Field
        label={`Create ${when(schedule.entity, toLabel) || "work"}`}
        layout="horizontal"
      >
        <HStack fit="container" justify="flex-start">
          <div className={styles.smallInput}>
            <TextInput
              value={when(schedule.precreate, String) ?? "1"}
              updateOn="change"
              onChange={(v) =>
                mutate(
                  asMutation(
                    { field: "precreate", type: "number" },
                    Math.max(parseInt(v, 10), 0)
                  )
                )
              }
              inputType="number"
            />
          </div>
          <FillSpace direction="horizontal">
            <Text subtle>
              {plural("week", schedule.precreate || 0)} in advance
            </Text>
          </FillSpace>
        </HStack>
      </Field>
    </Container>
  );
};

// Used for editing the schedule repeat when creating work from an object (not in stores)
export const ScheduleRepeatOptionsDropdown = (props: {
  schedule: Schedule;
  mutate: Fn<OneOrMany<PropertyMutation<Schedule>>, void>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      trigger={
        <Button size="small" icon={ClockHistory}>
          Repeat
        </Button>
      }
    >
      <ScheduleRepeatOptions {...props} />
    </Dropdown>
  );
};
