import { find, map, omit } from "lodash";
import { useCallback, useMemo } from "react";
import {
  Background,
  BackgroundVariant,
  Connection,
  Edge,
  Node,
} from "reactflow";

import { Entity, HasOrders, PropertyRef, toTitleOrName } from "@api";

import {
  useEntitySource,
  useGetItemFromAnyStore,
  useQueueUpdates,
} from "@state/generic";
import { ID } from "@state/types";
import {
  useAddToView,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
  useReorderItemsInView,
} from "@state/views";

import { toFlowDefinition, treeLayout, useFlowData } from "@utils/chart";
import { Maybe, safeAs, when } from "@utils/maybe";
import { getPositionOrder, setPositionOrders } from "@utils/ordering";
import { mapAll } from "@utils/promise";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import {
  getPropertyValue,
  toPropertyValueRef,
  toRef,
} from "@utils/property-refs";

import { AddEntityInput } from "@ui/add-entity-input";
import { usePageId } from "@ui/app-page";
import { Controls, Flow, withFlowProvider } from "@ui/flow";
import { showError } from "@ui/notifications";

import styles from "./canvas.module.css";

interface Props {
  id: ID;
  variant?: "preview" | "editable";
}

type NodeData = {
  label: string;
  entity: Entity;
  parentId: Maybe<ID>;
  direction: "horizontal" | "vertical";
  showProps: Maybe<PropertyRef[]>;
};

type EdgeData = {
  label: Maybe<string>;
  direction: "horizontal" | "vertical";
};

export const CanvasLayout = withFlowProvider(
  ({ id, variant = "editable" }: Props) => {
    const view = useLazyGetView(id);
    const pageId = usePageId();
    const mutate = useQueueUpdates(pageId);
    const { items } = useLazyItemsForView(id);
    const getItem = useGetItemFromAnyStore();
    const itemsSource = useEntitySource(view?.entity || "task", view?.source);
    const onAdded = useAddToView(id);
    const defaults = useDefaultsForView(id);
    const reorderItems = useReorderItemsInView(view, pageId);
    const canvasBy = useMemo(
      () =>
        view &&
        (getPropertyValue(view, {
          field: "settings.canvasBy",
          type: "property",
        })?.property as Maybe<PropertyRef<Entity, "relations">>),
      [view?.settings]
    );

    if (!view) {
      return <></>;
    }

    const strat = useMemo(
      () =>
        treeLayout(
          {
            width: 200,
            height: view.showProps?.length ? 120 : 80,
            hSpace: 5,
            vSpace: 10,
          },
          { orientation: "horizontal" }
        ),
      [view.showProps]
    );

    const flowDefinition = useMemo(
      () =>
        toFlowDefinition<Entity, NodeData, EdgeData>({
          layout: strat,
          fitView: { maxZoom: 1, minZoom: 0.5, padding: 0.05, duration: 100 },
          toNode: (item) => ({
            id: item.id,
            position: getPositionOrder(
              safeAs<HasOrders>(item)?.orders,
              view.id
            ),
            draggable: true,
            data: {
              label: toTitleOrName(item),
              entity: item,
              parentId: view.for?.id,
              direction: "horizontal",
              showProps: view.showProps,
            },
            type: "entity-card",
          }),

          toEdges: (item) => {
            if (!item || !item.id || !canvasBy) {
              return [];
            }

            const blockedBy = toPropertyValueRef(item, canvasBy).value
              .relations;

            return map(blockedBy, (blocker) => ({
              id: `${blocker.id}-${item.id}`,
              source: blocker.id,
              target: item.id,
              type: "hstep",
              data: {
                label: "",
                direction: "horizontal",
              },
            }));
          },
        }),
      [view, canvasBy]
    );

    const { nodes, edges, toPosition, ...flowProps } = useFlowData(
      items.sorted || items.all || [],
      flowDefinition
    );

    const handleConnect = useCallback(
      async (edge: Edge | Connection) => {
        if (!canvasBy) {
          return;
        }

        const from = when(edge.source, getItem);
        const to = when(edge.target, getItem);

        if (!from || !to) {
          showError("Could not find items to connect.");
          return;
        }

        reorderItems([{ entity: to.id }], {
          entity: from.id,
          position: "after",
        });

        mutate(asUpdate(to, asAppendMutation(canvasBy, [toRef(from)])));
      },
      [getItem]
    );

    const handleResetLayout = useCallback(() => {
      mutate(
        map(items.all, (item) =>
          asUpdate(
            item,
            asMutation(
              { field: "orders", type: "json" },
              omit(safeAs<HasOrders>(item)?.orders, view.id)
            )
          )
        )
      );
    }, [items.all]);

    const handleNodeMoved = useCallback(
      (_e: React.MouseEvent, node: Node) => {
        const item = find(items.all, { id: node.id });

        if (!item) {
          return;
        }

        mutate(
          asUpdate(
            item,
            asMutation(
              { field: "orders", type: "json" },
              setPositionOrders(
                safeAs<HasOrders>(item)?.orders,
                view.id,
                node.position
              )
            )
          )
        );
      },
      [items.all]
    );

    const handleDelete = useCallback(
      async (connection: Edge[]) => {
        if (!canvasBy) {
          return;
        }

        await mapAll(connection, async (edge) => {
          const blocker = when(edge.source, getItem);
          const blocked = when(edge.target, getItem);

          if (!blocked || !blocker) {
            showError("Could not find blockeds to disconnect.");
            return;
          }

          mutate(
            asUpdate(
              blocked,
              asAppendMutation(canvasBy, [toRef(blocker)], "remove")
            )
          );
        });
      },
      [getItem]
    );

    const onNodeClicked = useCallback((event: React.MouseEvent, node: Node) => {
      if (event?.defaultPrevented) {
        return;
      }
    }, []);

    if (!view || !canvasBy) {
      return <></>;
    }

    return (
      <Flow
        nodes={nodes}
        edges={edges}
        {...flowProps}
        {...(variant === "preview" && {
          zoomOnScroll: false,
          showControls: false,
        })}
        onConnect={handleConnect}
        onEdgesDelete={handleDelete}
        onNodeClick={onNodeClicked}
        onNodeDragStop={handleNodeMoved}
      >
        {variant === "preview" && (
          <>
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          </>
        )}
        {variant === "editable" && (
          <>
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />

            <Controls onResetLayout={handleResetLayout} />

            <AddEntityInput
              className={styles.floating}
              source={itemsSource}
              defaults={defaults}
              onAdded={onAdded}
            />
          </>
        )}
      </Flow>
    );
  }
);
