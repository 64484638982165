import { Text } from "@ui/text";

import { MenuItem, MenuItemProps } from "./default";

export const EmptyMenuItem = ({
  text,
  children,
  indent = 1,
  ...props
}: MenuItemProps) => (
  <MenuItem disabled indent={indent} {...props}>
    <Text subtle>{text || children}</Text>
  </MenuItem>
);
