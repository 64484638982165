import { isEmpty } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  hasRefs,
  hasResources,
  PropertyDef,
  PropertyValueRef,
} from "@api";

import {
  useInflatedPropertyValue,
  useLazyPropertyDef,
} from "@state/properties";

import { ensureMany, justOne } from "@utils/array";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { useGoTo, usePushTo } from "@utils/navigation";
import { isReferencing } from "@utils/property-refs";
import { isStar } from "@utils/wildcards";

import { Container } from "@ui/container";
import { getEngine } from "@ui/engine";
import { PagesSection } from "@ui/engine/page/section";
import { WorkflowSection } from "@ui/engine/workflow/section";
import { PageBodySection } from "@ui/page-body";
import { ParentCard } from "@ui/parent-card";
import { ChecklistSection } from "@ui/property-checklist-section";
import { ResourcesSection } from "@ui/resources-section";
import { ViewRelationsSection } from "@ui/view-results-section";

interface Props<E extends Entity> {
  entity: E;
  valueRef: PropertyValueRef<E>;
  source: DatabaseID;
  onChange?: Fn<PropertyValueRef<E>["value"], void>;
  inset?: boolean;
  isLast?: boolean;
  className?: string;
  editing?: boolean;
  editable?: boolean;
}

const ReadWriteControl = <E extends Entity>({
  entity,
  valueRef,
  source,
  isLast,
  onChange,
  onClose,
}: Props<E> & { onClose: Fn<void, void> }) => {
  const def = useLazyPropertyDef(source, valueRef);
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const { value } = valueRef;

  if (!def) {
    return <></>;
  }

  // Hard-coded becuase I couldn't figure it out....
  if (def?.displayAs === "section" && def.options?.hierarchy === "parent") {
    return (
      <ParentCard
        label="Parent"
        id={value.relation?.id || value.relations?.[0]?.id || "never"}
        showBody={false}
        onClick={goTo}
      />
    );
  }

  // Hard-coded becuase I couldn't figure it out....
  if (def?.displayAs === "section" && isReferencing(def, "workflow")) {
    return (
      <WorkflowSection
        items={value.relation || value.relations}
        onClick={goTo}
      />
    );
  }

  if (
    equalsAny("resource", ensureMany(def.options?.references)) &&
    hasResources(entity)
  ) {
    return (
      <ResourcesSection
        sectionLabel={def.label}
        entity={entity}
        refs={entity.refs?.resources}
      />
    );
  }

  if (
    equalsAny("page", ensureMany(def.options?.references)) &&
    hasRefs(entity)
  ) {
    return (
      <PagesSection
        sectionLabel={def.label}
        entity={entity}
        refs={entity.refs?.pages}
      />
    );
  }

  if (
    def.type === "relations" &&
    !isStar(def.options?.references) &&
    !isEmpty(def.options?.references) &&
    !!def.options?.sync &&
    def.visibility !== "hidden" &&
    getEngine(def.options?.references as EntityType)
  ) {
    return (
      <ViewRelationsSection
        key={`${String(def.field)}${def.type}`}
        sectionLabel={def.label}
        parentId={entity.id}
        childType={justOne(def.options.references) || "task"}
        onOpen={pushTo}
      />
    );
  }

  if (def.type === "checklist") {
    return (
      <ChecklistSection
        key={`${String(def.field)}${def.type}`}
        entity={entity}
        def={def as PropertyDef<Entity, "checklist">}
        value={value.checklist}
      />
    );
  }

  if (def.type === "rich_text") {
    return (
      <PageBodySection
        label={def.label ?? `Brief`}
        entity={entity}
        body={valueRef.value.rich_text}
        showAll={isLast}
        placeholder={
          def?.label === "Brief"
            ? "What needs to get done..."
            : "Start typing..."
        }
      />
    );
  }

  return <></>;
};

export function PropertyValueSection<E extends Entity>({
  className,
  valueRef,
  source,
  editing: defaultEditing,
  editable = true,
  ...props
}: Props<E>) {
  const inflated = useInflatedPropertyValue(valueRef, source);

  return (
    <Container padding="none" fit="container">
      <ReadWriteControl
        {...props}
        valueRef={inflated}
        source={source}
        onClose={() => {}}
        onChange={(v) => props.onChange?.(v)}
      />
    </Container>
  );
}
