import { startOfDay } from "date-fns";
import { filter, keys, last, map, omit } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  Form,
  hasIcon,
  HasLocation,
  isRepeatable,
  Period,
  PropertyMutation,
  Ref,
  Schedule,
  Task,
  toTitleOrName,
} from "@api";

import { autoLocate, useAiUseCase } from "@state/ai";
import { useActivePage } from "@state/app";
import { useLazyProperties } from "@state/properties";
import { FormData, useFormsForLocation } from "@state/form";
import {
  useCreateFromObject,
  useCreateFromTemplate,
  useSource,
} from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { useMe } from "@state/persons";
import {
  toDayOfPeriod,
  toNextDate,
  toSentence,
  withNextDate,
} from "@state/schedule";
import { useEntityLabel } from "@state/settings";
import { useActiveSpace } from "@state/spaces";
import { useLazyTemplates } from "@state/templates";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany, maybeMap, OneOrMany } from "@utils/array";
import { toISODate } from "@utils/date-fp";
import { isWithinEditable, useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { useShowMore, useStickyState } from "@utils/hooks";
import {
  isPersonId,
  isTeamId,
  isWorkspaceId,
  newLocalHumanId,
} from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { now } from "@utils/now";
import { merge, omitEmptyish } from "@utils/object";
import { flattenChanges } from "@utils/property-mutations";
import {
  asPropertyValueRef,
  getPropertyValue,
  isEmptyRef,
  toRef,
} from "@utils/property-refs";
import { useArrayKey } from "@utils/react";
import { fromScope, toBaseScope, toChildLocation } from "@utils/scope";

import { ActionGroup, ActionItem, ActionMenu } from "@ui/action-menu";
import { usePageId } from "@ui/app-page";
import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit, Props as DialogProps } from "@ui/dialog-split";
import { Divider } from "@ui/divider";
import { Dropdown } from "@ui/dropdown";
import { getEngine, render, useEngine } from "@ui/engine";
import { FormSubmit } from "@ui/engine/form";
import { ScheduleRepeatOptions } from "@ui/engine/schedule";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowLeft,
  ArrowUpRight,
  ClockHistory,
  EyeSlash,
  iconFromString,
  MagicPurple,
  PaintTool,
  PlusAlt,
  SpinnerIcon,
} from "@ui/icon";
import { TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { showError } from "@ui/notifications";
import { OnHover } from "@ui/on-hover";
import { DocumentEditor } from "@ui/rich-text";
import { LocationSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { Switch } from "@ui/switch";
import { Text } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { PillButton, PropertyValuePills } from "./property-value-pill";
import { RelationLabel } from "./relation-label";

import styles from "./add-work-dialog.module.css";

interface Props {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  allowed?: EntityType[];
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
  onModeChanged?: Fn<DialogProps["mode"], void>;
}

export const AddWorkDialog = ({
  onCancel,
  allowed: _allowed,
  onSaved,
  defaults,
}: Props) => {
  const pageId = usePageId();
  const me = useMe();
  const space = useActiveSpace();
  const defaultLocation = safeAs<HasLocation>(defaults)?.location || me.id;
  const pushTo = usePushTo();
  const [location, setLocation] = useState<Maybe<string>>(defaultLocation);
  const [suggested, setSuggested] = useState<Maybe<string[]>>();
  const [type, setType] = useState<EntityType>(() => _allowed?.[0] || "task");
  const [multi, setMulti] = useStickyState<boolean>(false, "add-work-multi");
  const [work, setWork] = useStickyState({}, "add-work-wip");
  const [schedule, setSchedule] = useState<Partial<Schedule>>();
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const workSource = useSource(type, location || defaultLocation);
  const workProps = useLazyProperties(workSource);
  const create = useCreateFromObject(type, workSource.scope, pageId);
  const createSchedule = useCreateFromObject(
    "schedule",
    workSource.scope,
    pageId
  );
  const allForms = useFormsForLocation({
    scope: space.id,
    type: space.mode === "workspace" ? "workspace" : "team",
  });
  const forms = useShowMore(allForms, 5);
  const [usingForm, setUsingForm] = useState<Form>();
  const lastRun = useRef(0);
  const installed = useInstalledEntities(when(location, toBaseScope));
  const allowedTypes = useMemo(
    () =>
      filter(
        _allowed || installed,
        (t) => !getEngine(t)?.asCreateDialog && !equalsAny(t, ["workflow"])
      ),
    [useArrayKey(_allowed), installed, type]
  );

  const ai = useAiUseCase(autoLocate);

  const runAndSet = useCallback(
    async (work: Partial<Entity>, source: DatabaseID, depth?: number) => {
      lastRun.current += 1;
      const run = lastRun.current;
      try {
        const result = await ai.run({
          entity: work as Entity,
          source: source,
          defaultLocation,
          fixed: !!safeAs<HasLocation>(defaults)?.location,
        });

        if (!result || run !== lastRun.current) {
          return;
        }

        setLocation(result);

        // Run again to find more specific location within a team
        if (!isTeamId(source.scope) && isTeamId(result) && !depth) {
          runAndSet(work, { ...source, scope: result }, 1);
        }
      } catch (e) {
        // Do nothing
      }
    },
    [ai]
  );

  const ready = useMemo(
    () => !!(work && toTitleOrName(work as Entity)),
    [work]
  );

  const handleCreate = useCallback(() => {
    if (!create || !createSchedule) {
      return showError("Not ready.");
    }

    const [neww] = create([work]);

    if (schedule) {
      createSchedule([
        withNextDate({
          ...schedule,
          last: toNextDate(schedule as Schedule),
          instances: [toRef(neww)],
          // Save all values to the schedule
          overrides: maybeMap(workProps, (prop) => {
            const value = asPropertyValueRef(
              prop,
              getPropertyValue(work, prop)
            );

            // Don't save empty values or repaating fields
            if (
              isEmptyRef(value) ||
              equalsAny(prop.field, ["start", "end", "publish", "refs.repeat"])
            ) {
              return undefined;
            }

            return omit(value, "def");
          }),
        } as Schedule),
      ]);
      setSchedule(undefined);
    }

    setWork({});

    if (!multi) {
      (onSaved || pushTo)?.(neww);
    }
  }, [create, work, multi, onSaved, schedule, workProps]);

  const resetLocation = useCallback(() => {
    setLocation(defaultLocation);
  }, [defaultLocation]);

  const handleReset = useCallback(() => {
    setWork({});
    resetLocation();
    setSuggested(undefined);
  }, []);

  const handleMention = useCallback(
    (mention: Ref) => {
      if (isPersonId(mention.id)) {
        setWork({ ...work, assigned: toRef(mention) });
      }
    },
    [work]
  );

  const handleClearSchedule = useCallback(() => {
    setSchedule(undefined);
    setScheduleOpen(false);
  }, []);

  const handleScheduleChanged = useCallback(
    (cs: OneOrMany<PropertyMutation>) => {
      setSchedule((s) => merge(s, flattenChanges<Schedule>(ensureMany(cs))));
    },
    [setSchedule]
  );

  const handleScheduleOpen = useCallback(() => {
    if (!scheduleOpen && !schedule) {
      const from = toISODate(startOfDay(now()), "point");

      setSchedule({
        id: newLocalHumanId("schedule"),

        from: from,
        period: Period.Week,
        daysOfPeriod: [toDayOfPeriod(from, Period.Week)],
        frequency: 1,
        precreate: 1,
        timeOfDay: undefined,
        status: { id: "ACT" },

        entity: workSource.type,

        location: location || defaultLocation,
        source: { scope: location || defaultLocation, type: "schedule" },
      });
    }

    setScheduleOpen(!scheduleOpen);
  }, [scheduleOpen]);

  useShortcut(
    { key: "Enter", command: true },
    // Ignore inputs
    [() => true, handleCreate],
    [handleCreate]
  );

  useShortcut(
    { key: "Escape" },
    [(e) => !isWithinEditable(e), () => onCancel?.()],
    [onCancel]
  );

  if (usingForm) {
    return (
      <DialogSplit
        className={styles.modal}
        onDismiss={() => onCancel?.()}
        direction="vertical"
      >
        <SubmittingForm
          form={usingForm}
          defaults={defaults}
          onSaved={onSaved}
          onCancel={() => setUsingForm(undefined)}
        />
      </DialogSplit>
    );
  }

  return (
    <DialogSplit
      className={styles.modal}
      onDismiss={() => onCancel?.()}
      direction="vertical"
      bottom={
        forms.visible?.length ? (
          <OnHover.Trigger>
            <Container stack="horizontal" inset="left" padding="none" gap={4}>
              <OnHover.Target opacity={0.7}>
                {map(forms.visible, (form) => (
                  <Button
                    subtle
                    size="small"
                    icon={iconFromString(form.icon)}
                    onClick={() => setUsingForm(form)}
                  >
                    {form.name}
                  </Button>
                ))}
              </OnHover.Target>
            </Container>
          </OnHover.Trigger>
        ) : undefined
      }
    >
      <VStack gap={20}>
        <SpaceBetween>
          <Container padding="none" inset="left" stack="vertical" gap={0}>
            <HStack gap={0}>
              <LocationSelect
                location={location}
                variant="full"
                showOpen={false}
                onChange={(l) => setLocation(l)}
              />
              <Tooltip text="Ask AI to find the best location for this work.">
                <Button
                  subtle
                  size="small"
                  disabled={!ready}
                  onClick={() => runAndSet(work, workSource)}
                  icon={ai.loading ? SpinnerIcon : MagicPurple}
                >
                  {isPersonId(location) && "Auto Locate"}
                </Button>
              </Tooltip>
            </HStack>
            {suggested && (
              <HStack gap={4}>
                {maybeMap(suggested, (s) =>
                  s !== location ? (
                    <Button
                      key={s}
                      subtle
                      size="tiny"
                      onClick={() => setLocation(s)}
                    >
                      {when(last(fromScope(s)), (id) =>
                        isPersonId(id) ? (
                          <Label subtle size="small" icon={EyeSlash}>
                            Private
                          </Label>
                        ) : (
                          <RelationLabel
                            subtle
                            size="small"
                            relation={{ id }}
                          />
                        )
                      )}
                    </Button>
                  ) : undefined
                )}
              </HStack>
            )}
          </Container>

          <EntityTypeSelect
            value={type}
            onChange={(t) => {
              setType(t);
              setWork({});
              resetLocation();
            }}
            allowed={allowedTypes}
            plural={false}
            scope={location || defaultLocation}
          />
        </SpaceBetween>

        <VStack gap={0}>
          <TextInput
            className={styles.title}
            autoFocus={true}
            placeholder="Untitled"
            value={safeAs<Task>(work)?.title || ""}
            updateOn="change"
            onChange={(title) => setWork({ ...work, title })}
          />
          <div className={styles.docWrapper}>
            <DocumentEditor
              content={safeAs<Task>(work)?.body}
              onChanged={(body) => setWork({ ...work, body })}
              scope={
                isPersonId(workSource.scope) ? undefined : workSource.scope
              }
              newLineSpace="small"
              placeholder="Add details..."
              onMention={handleMention}
            />
          </div>
        </VStack>

        <PropertyValuePills
          entity={work}
          source={workSource}
          blacklist={["title", "name", "body", "refs.repeat"]}
          onChange={(cs) =>
            setWork(merge(work, flattenChanges(ensureMany(cs))))
          }
        >
          {isRepeatable(type) && (
            <Dropdown
              open={scheduleOpen}
              setOpen={handleScheduleOpen}
              className={{ popover: styles.schedulePopover }}
              trigger={
                <PillButton icon={ClockHistory}>
                  {schedule ? toSentence(schedule as Schedule) : "Repeat"}
                </PillButton>
              }
            >
              <VStack>
                {schedule && (
                  <ScheduleRepeatOptions
                    schedule={schedule as Schedule}
                    mutate={handleScheduleChanged}
                  />
                )}
                <SpaceBetween>
                  <Button size="small" subtle onClick={handleClearSchedule}>
                    Clear
                  </Button>
                  <Button size="small" onClick={() => setScheduleOpen(false)}>
                    Done
                  </Button>
                </SpaceBetween>
              </VStack>
            </Dropdown>
          )}
        </PropertyValuePills>

        <SpaceBetween>
          <HStack>
            <Switch
              size="small"
              checked={multi}
              onChange={setMulti}
              subtle
              label="Create multiple"
            />
          </HStack>
          <HStack>
            {keys(work)?.length > 0 ? (
              <Button subtle onClick={handleReset}>
                Clear
              </Button>
            ) : (
              <Button subtle onClick={() => onCancel?.()}>
                Close
              </Button>
            )}
            <Button disabled={!ready} variant="primary" onClick={handleCreate}>
              Create
            </Button>
          </HStack>
        </SpaceBetween>
      </VStack>
    </DialogSplit>
  );
};

export const SmartDefaultsAddWorkDialog = ({
  ...props
}: Omit<Props, "defaults">) => {
  const space = useActiveSpace();
  const page = useActivePage();

  const defaults = useMemo(
    () =>
      omitEmptyish({
        location: isWorkspaceId(space.id)
          ? undefined
          : when(page?.entity, (e) => toChildLocation(e.source?.scope, e.id)) ||
            space.id,
      }),
    [space.id, page?.entity]
  );
  return <AddWorkDialog {...props} defaults={defaults} />;
};

export const AddWorkButton = (props: ButtonProps & Props) => {
  const {
    defaults,
    onSaved,
    onCancel,
    allowed,
    onModeChanged,
    ...buttonProps
  } = props;
  const dialogProps = { defaults, onSaved, onCancel, allowed, onModeChanged };
  const [showing, setShowing] = useState(false);
  const type = props.defaults?.source?.type;
  const engine = useEngine(type);

  return (
    <>
      {showing && !engine?.asCreateDialog && (
        <AddWorkDialog {...dialogProps} onCancel={() => setShowing(false)} />
      )}

      {showing &&
        !!engine?.asCreateDialog &&
        render(engine?.asCreateDialog, {
          ...dialogProps,
          onSaved: (r) => {
            onSaved?.(r);
            setShowing(false);
          },
          onCancel: () => setShowing(false),
        })}

      <Button
        size="small"
        icon={PlusAlt}
        {...buttonProps}
        onClick={() => setShowing(true)}
      />
    </>
  );
};

type ActionMenuProps = {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  onSaved?: Fn<OneOrMany<Ref>, void>;
} & Omit<ButtonProps, "onClick">;

export const AddWorkActionMenu = ({
  defaults,
  onSaved,
  children,
  ...rest
}: ActionMenuProps) => {
  const pageId = usePageId();
  const wId = useActiveWorkspaceId();
  const source = useMemo(
    () => defaults.source || ({ type: "task", scope: wId } as DatabaseID),
    [defaults.source]
  );
  const engine = useEngine(source.type);
  const [showCreate, setShowCreate] = useState(false);
  const create = useCreateFromObject(source.type, source.scope, pageId);
  const createTemplate = useCreateFromTemplate(source, onSaved);
  const goTo = useGoTo();
  const label = useEntityLabel(source.type, source.scope, {
    case: "lower",
  });
  const templates = useLazyTemplates(source);

  const handleCreateBlank = useCallback(() => {
    if (engine.asCreateDialog) {
      setShowCreate(true);
      return;
    }

    if (!create) {
      return showError("Not ready.");
    }

    const [neww] = create([
      { source: source, ...defaults, name: "" } as Partial<Entity>,
    ]);
    onSaved?.(neww);
  }, [create, engine]);

  const handleCreateFromTemplate = useCallback(
    (template: Ref) => {
      if (!createTemplate.ready) {
        return showError("Not ready.");
      }

      createTemplate.create(template, {
        overrides: { [template.id]: { ...omit(defaults, "source"), name: "" } },
      });
    },
    [createTemplate.ready]
  );

  if (!!engine?.asCreateDialog) {
    return (
      <>
        <div onClick={() => setShowCreate(true)}>
          {children || (
            <Button icon={PlusAlt} loading={createTemplate.loading} {...rest}>
              <Text subtle>New {label}</Text>
            </Button>
          )}
        </div>

        {showCreate &&
          !!engine?.asCreateDialog &&
          render(engine?.asCreateDialog, {
            defaults,
            onSaved: (r) => {
              onSaved?.(r);
              setShowCreate(false);
            },
            onCancel: () => setShowCreate(false),
          })}
      </>
    );
  }

  return (
    <>
      <ActionMenu
        actions={
          <>
            <ActionItem icon={PlusAlt} onClick={handleCreateBlank}>
              From empty
            </ActionItem>

            <ActionGroup label="From template">
              {map(templates, (template: Entity) => (
                <ActionItem
                  key={template.id}
                  icon={
                    (template &&
                      hasIcon(template) &&
                      iconFromString(template.icon)) ||
                    PaintTool
                  }
                  onClick={() => handleCreateFromTemplate(template)}
                >
                  {toTitleOrName(template)}
                </ActionItem>
              ))}
            </ActionGroup>

            <ActionItem
              onClick={() =>
                goTo([toBaseScope(source.scope), "/settings/templates"])
              }
              iconRight={ArrowUpRight}
            >
              <Text subtle>Manage Templates</Text>
            </ActionItem>
          </>
        }
      >
        {children || (
          <Button icon={PlusAlt} loading={createTemplate.loading} {...rest}>
            <Text subtle>New {label}</Text>
          </Button>
        )}
      </ActionMenu>
    </>
  );
};

const SubmittingForm = ({
  form,
  onSaved,
  onCancel,
  defaults,
}: { form: Form } & Props) => {
  const [data, setData] = useState<FormData>(
    () => omit(defaults, "source") || {}
  );

  return (
    <VStack gap={20} fit="container">
      <Container padding="none" inset="left" stack="horizontal" gap={0}>
        <Button icon={ArrowLeft} subtle onClick={() => onCancel?.()} />

        <Label bold size="medium">
          {form.name}
        </Label>
      </Container>

      <Divider />

      <FormSubmit
        form={form}
        data={data}
        onChanged={setData}
        onSubmitted={(r) => onSaved?.(ensureMany(r))}
      />
    </VStack>
  );
};
