import { first, last, some } from "lodash";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";

import { Entity, EntityType, ID, View } from "@api";

import { AppCommandsAtom, setCommandsAction } from "@state/app";
import { useLazyEntity, useLocalChanges } from "@state/generic";
import {
  compareViewIds,
  isSetup,
  isTriaging,
  useLazyGetView,
  useLazyViewsForParent,
  useSmartProps,
  ViewStoreAtom,
} from "@state/views";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { isTemplateId } from "@utils/id";
import { fromScope } from "@utils/scope";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { TimesIcon, TrashAlt } from "@ui/icon";
import { Main, PageLayout } from "@ui/page-layout";
import { Sheet, SheetProps, SlideInSheet } from "@ui/sheet-layout";
import { WithSuggestedProps } from "@ui/suggested-props";
import { TextLarge } from "@ui/text";
import { ViewHeader } from "@ui/view-header";
import {
  BrowserLayout,
  CalendarLayout,
  CanvasLayout,
  CardLayout,
  ListLayout,
  TableLayout,
  TimelineLayout,
} from "@ui/view-layouts";
import ViewOptionsMenu from "@ui/view-options-menu";
import { WorkflowActions } from "@ui/workflow-action-button";

import { TriagePane } from "./triage-pane";
import { ViewChangesActions } from "./view-changes-actions";

import styles from "./view-pane.module.css";

type Props = {
  viewId: string;
  onChangeView: Fn<View, void>;
  defaultOptsVisible?: boolean;
  showHeader?: boolean;
  views?: View[];
  showTitle?: boolean;
  showViewsBar?: boolean;
  showOtherViews?: boolean;
  className?: string;
} & Pick<SheetProps, "size">;

export const ViewPane = ({
  viewId,
  views,
  showHeader = true,
  showViewsBar = true,
  showOtherViews = true,
  onChangeView,
  defaultOptsVisible = false,
  className,
  size,
  showTitle,
}: Props) => {
  if (!viewId) {
    throw new Error("Either projectId/teamId/viewId must be provided.");
  }

  const view = useLazyGetView(viewId || "");
  const { changes } = useLocalChanges(viewId, ViewStoreAtom);
  const [optsVisible, onOptsVisible] = useState(defaultOptsVisible);
  const [maxOffset, setMaxOffset] = useState(0);
  const headerEl = useRef<HTMLDivElement>(null);
  const mainEl = useRef<HTMLDivElement>(null);
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const finishedSetup = useMemo(() => !!view && isSetup(view), [view]);
  const suggested = useSmartProps(view);

  // useStickyScroll(mainEl, "view-results");

  useLayoutEffect(() => {
    if (maxOffset < 1) {
      // How much the header can be scrolled up (based on the height of the header and it's content)
      const headerHidableHeight =
        (headerEl.current?.clientHeight ?? 0) -
        (headerEl.current?.firstElementChild?.clientHeight ?? 0) -
        1;

      setMaxOffset(headerHidableHeight);
    }
  }, [headerEl.current]);

  const onScroll = useCallback(() => {
    if (mainEl.current) {
      // Amount of room the container has before hitting bottom
      const available =
        mainEl.current.scrollHeight -
        mainEl.current.clientHeight -
        mainEl.current.scrollTop;

      if (available <= 0) {
        return;
      }

      headerEl.current?.setAttribute(
        "style",
        `margin-top: -${Math.min(mainEl.current.scrollTop, maxOffset)}px`
      );
    }
  }, [mainEl, headerEl, maxOffset]);

  if (!view) {
    return <></>;
  }

  return (
    <>
      <Sheet size={size} className={className}>
        <VStack gap={0} align={"stretch"} className={styles.container}>
          {showHeader && (
            <ViewHeader
              ref={headerEl}
              view={view}
              views={views}
              onOpen={onChangeView}
              showTitle={showTitle}
              showOptions={optsVisible}
              showViewsBar={showViewsBar}
              showOtherViews={showOtherViews}
              onShowOptions={onOptsVisible}
            />
          )}

          <FillSpace className={styles.main}>
            <PageLayout
              className={cx(isTriaging(view) && styles.triagePadding)}
            >
              {!finishedSetup && (
                <Main>
                  <Container>
                    <TextLarge>Finish setting up board.</TextLarge>
                  </Container>
                </Main>
              )}
              <WithSuggestedProps props={suggested}>
                {finishedSetup && (
                  <Main ref={mainEl} onScroll={onScroll}>
                    {view.layout === "browser" && (
                      <Container size="half">
                        <BrowserLayout id={viewId} />
                      </Container>
                    )}
                    {view.layout === "list" && <ListLayout id={viewId} />}
                    {view.layout === "table" && (
                      <TableLayout id={viewId} className={styles.noTopBorder} />
                    )}
                    {view.layout === "timeline" && (
                      <TimelineLayout id={viewId} />
                    )}
                    {view.layout === "card" && <CardLayout id={viewId} />}
                    {view.layout === "calendar" && (
                      <CalendarLayout id={viewId} />
                    )}
                    {view.layout === "canvas" && <CanvasLayout id={viewId} />}

                    {isTriaging(view) && <TriagePane view={view} />}
                  </Main>
                )}
              </WithSuggestedProps>
            </PageLayout>
          </FillSpace>
        </VStack>
      </Sheet>

      {optsVisible && (
        <SlideInSheet
          className={styles.optionsPane}
          size="secondary"
          visible={true}
          setVisible={onOptsVisible}
        >
          <Container width="container" height="container">
            <SpaceBetween
              fit="container"
              width="container"
              direction="vertical"
            >
              <VStack fit="container">
                <SpaceBetween>
                  <TextLarge bold>Edit</TextLarge>
                  {!!changes?.length && <ViewChangesActions viewId={viewId} />}
                  {!changes?.length && (
                    <Button
                      subtle
                      icon={TimesIcon}
                      onClick={() => onOptsVisible(false)}
                    />
                  )}
                </SpaceBetween>

                <ViewOptionsMenu viewId={viewId} />
              </VStack>

              {!isTemplateId(view.id) && view?.alias && (
                <HStack fit="container">
                  <WorkflowActions
                    showCustom={false}
                    entity={view}
                    highlightFirst={false}
                  />

                  <Button
                    onClick={() =>
                      setAppCommands(setCommandsAction("delete", true, view))
                    }
                    icon={TrashAlt}
                  >
                    Revert to defaults
                  </Button>
                </HStack>
              )}

              {!isTemplateId(view.id) && !view?.alias && (
                <HStack fit="container">
                  <Button
                    subtle
                    variant="danger"
                    onClick={() =>
                      setAppCommands(setCommandsAction("delete", true, view))
                    }
                    icon={TrashAlt}
                  >
                    Delete
                  </Button>
                </HStack>
              )}
            </SpaceBetween>
          </Container>
        </SlideInSheet>
      )}
    </>
  );
};

export const SmartViewPane = ({ viewId, ...props }: Omit<Props, "views">) => {
  const view = useLazyGetView(viewId);
  const _views = useLazyViewsForParent(
    view?.for?.id || last(fromScope(view?.location)) || "",
    view?.entity || "task",
    true
  );
  const views = useMemo(
    () =>
      some(
        _views,
        (v) =>
          compareViewIds(v.id, view?.id || "") ||
          compareViewIds(v.id, view?.alias || "")
      )
        ? _views
        : view?.id
        ? [view]
        : [],
    [_views, view]
  );

  return <ViewPane viewId={viewId} views={views} {...props} />;
};

export const ChildViewPane = ({
  child,
  parent,
  ...props
}: Omit<Props, "views" | "viewId" | "onChangeView"> & {
  child: EntityType;
  parent: Entity;
}) => {
  const views = useLazyViewsForParent(parent.id, child, true);
  const [viewId, setViewId] = useStickyState(
    () => first(views)?.id || "",
    `child-view-pane-${parent.id}-${child}`
  );

  if (!viewId) {
    return <></>;
  }

  return (
    <ViewPane
      viewId={viewId}
      views={views}
      onChangeView={(v) => {
        setViewId(v.id);
      }}
      {...props}
    />
  );
};

export const ViewSubRoute = ({ id, viewId }: { id: ID; viewId: ID }) => {
  const parent = useLazyEntity(id);
  const view = useLazyEntity<"view">(viewId);

  if (!parent || !viewId || !view?.entity) {
    return <></>;
  }

  return (
    <WithSuggestedProps props={[]}>
      <ChildViewPane
        parent={parent}
        child={view?.entity}
        className={styles.noBorder}
        showTitle={false}
      />
    </WithSuggestedProps>
  );
};

export default SmartViewPane;
