import { ReactNode } from "react";

import { useHasAlphaFeatures } from "@state/persons";

export const AlphaFeature = ({
  children,
  fallback,
}: {
  children: ReactNode;
  fallback?: ReactNode;
}) => {
  const hasAlpha = useHasAlphaFeatures();
  return <>{hasAlpha ? children : fallback || false}</>;
};
