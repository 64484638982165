import { reduce, union, uniq, without } from "lodash";
import { atomFamily, selectorFamily } from "recoil";

import { EntityType, FetchOptions, FilterQuery, ID } from "@api";

import { getStore } from "@state/generic";
import {
  getItem,
  indexedDBStorageForSimpleStore,
  modifySimpleStoreState,
  SimpleStoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { PointDate } from "@utils/date-fp";
import { passes } from "@utils/filtering";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { isDefault } from "@utils/recoil";

import { appendKey, localStorageEffect } from "../local-storage-effect";
import { setFetchResults } from "./actions";

export type FetchResultsState = {
  ids: ID[];
  fetchedAt?: PointDate;
};

export type FetchResultsStoreState = SimpleStoreState<FetchResultsState>;

export const toFetchResultKey = (id: ID, archived: Maybe<boolean>) =>
  archived && !!id ? `${id}|archived` : id;

export const dropArchivedResults = (
  state: Partial<FetchResultsStoreState>
): FetchResultsStoreState => ({
  ...state,
  lookup: reduce(
    state.lookup,
    (acc, v, k) => (k?.includes("archived") ? acc : { ...acc, [k]: v }),
    {}
  ),
});

export const WorkspaceFetchResultsStoreAtom = atomFamily<
  FetchResultsStoreState,
  ID
>({
  key: "WorkspaceFetchResultsStoreAtom",
  default: { dirty: [], lookup: {} },
  effects: (wid) => [
    indexedDBStorageForSimpleStore(wid, "fetch-results", dropArchivedResults),
  ],
});

export const FetchResultsStoreAtom = WorkspaceWrappedAtom(
  "FetchResultsStoreAtom",
  WorkspaceFetchResultsStoreAtom
);

export const FetchResultsAtom = selectorFamily({
  key: "FetchResultsAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const { archived } = get(GlobalFetchOptionsAtom);
      const store = get(FetchResultsStoreAtom);
      return store.lookup?.[toFetchResultKey(id, archived)];
    },

  set:
    (id: ID) =>
    ({ set, get }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        const { archived } = get(GlobalFetchOptionsAtom);
        set(
          FetchResultsStoreAtom,
          modifySimpleStoreState(
            toFetchResultKey(id, archived),
            setFetchResults(newValue.ids, newValue.fetchedAt)
          )
        );
      }
    },
});

export const lastFetchedAt = selectorFamily({
  key: "lastFetchedAt",
  get:
    (id: ID) =>
    ({ get }) => {
      const results = get(FetchResultsAtom(id));
      return results?.fetchedAt;
    },
});

export const itemsForFetchResults = selectorFamily({
  key: "itemsForFetchResults",
  get:
    (
      params: Maybe<{
        key: string;
        type: EntityType;
        filter?: Maybe<FilterQuery>;
      }>
    ) =>
    ({ get }) => {
      if (!params) {
        return [];
      }
      const { key, type, filter } = params;
      const results = get(FetchResultsAtom(key));
      const store = get(getStore(type));

      if (filter) {
        return union(
          // Include all items from API excluding any dirty items
          maybeMap(without(results?.ids, ...(store?.dirty || [])) || [], (id) =>
            getItem(store, id)
          ),
          // Manually filter dirty items to check whether they should be included
          maybeMap(uniq(store?.dirty) || [], (id) =>
            when(getItem(store, id), (t) =>
              passes(t, filter, {}) ? t : undefined
            )
          )
        );
      }

      return maybeMap(results?.ids, (id) => getItem(store, id));
    },
});

/*
 * Global Fetch Options
 */

export const WorkspaceGlobalFetchOptionsAtom = atomFamily<
  Partial<FetchOptions>,
  ID
>({
  key: "WorkspaceGlobalFetchOptionsAtom",
  default: { archived: false },
  effects: (wid) => [
    localStorageEffect<Partial<FetchOptions>>({
      key: appendKey("traction.store.fetch-options", wid),
    }),
  ],
});

export const GlobalFetchOptionsAtom = WorkspaceWrappedAtom(
  "GlobalFetchOptionsAtom",
  WorkspaceGlobalFetchOptionsAtom
);
