import { last } from "lodash";

import { PropertyRef, Schedule } from "@api";

import { useLazyPropertyValue } from "@state/properties";
import { useUpdateEntity } from "@state/generic";
import { toSentence } from "@state/schedule";

import { whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { asMutation } from "@utils/property-mutations";
import { isSelected } from "@utils/selectable";
import { plural } from "@utils/string";

import { Button } from "@ui/button";
import { DueDate } from "@ui/due-date";
import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Cog } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text } from "@ui/text";

import { ListItemOpts, UIEngine } from "../types";
import { ScheduleCreateDialog } from "./create-dialog";
import { ScheduleEditorPopover } from "./editor";
import SchedulePage from "./page";
import { SchedulePane } from "./pane";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Schedule>[] = [];

export { ScheduleCreateDialog } from "./create-dialog";
export {
  ScheduleButton,
  ScheduleEditor,
  ScheduleEditorPopover,
} from "./editor";
export {
  ScheduleRepeatOptions,
  ScheduleRepeatOptionsDropdown,
} from "./repeat-options";
export {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "./secondary-panes";

function ScheduleListItem(props: ListItemOpts<Schedule>) {
  const { item, className, showProps, onChange } = props;
  const template = item.useTemplate;
  const mutate = useUpdateEntity(item.id);
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });

  return (
    <EntityContextMenu
      entity={item}
      selection={props.selection}
      setSelection={props.setSelection}
    >
      <SelectableListItem {...props} className={cx(styles.listItem, className)}>
        <SpaceBetween fit="container" gap={4}>
          <PropertyValue
            inset
            variant="icon-only"
            valueRef={status}
            onChange={(s) =>
              mutate(asMutation({ field: "status", type: "status" }, s.status))
            }
            source={item.source}
          />
          <FillSpace>
            <Ellipsis>
              <HStack>
                <Text>
                  {item.name} <Text subtle>{toSentence(item)}</Text>
                </Text>
              </HStack>
            </Ellipsis>
          </FillSpace>

          <PropertyValueStack
            justify="flex-end"
            gap={2}
            item={item}
            props={whenEmpty(showProps, DEFAULT_PROPS)}
            hideEmpty={props.hideEmpty}
            onChange={onChange}
          />

          <ScheduleEditorPopover
            schedule={item}
            parentId={item.useTemplate?.id || last(item.instances)?.id}
            source={item?.source}
            portal={true}
          >
            <Button subtle icon={Cog} />
          </ScheduleEditorPopover>
        </SpaceBetween>
      </SelectableListItem>
    </EntityContextMenu>
  );
}

export const ScheduleEngine: UIEngine<Schedule> = {
  asPrimaryPane: SchedulePane,
  asPage: SchedulePage,

  asMenuItem: function ({ item, onOpen, ...rest }) {
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const { value: next } = useLazyPropertyValue(item, {
      field: "next",
      type: "date",
    });

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <PropertyValue
              inset
              variant="icon-only"
              valueRef={status}
              editable={false}
              source={item.source}
            />
            <FillSpace>
              <Ellipsis>
                <Text>
                  {item.name} {toSentence(item)}
                </Text>
              </Ellipsis>
            </FillSpace>

            <HStack>{next?.date && <DueDate date={next?.date} />}</HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    const mutate = useUpdateEntity(item.id);
    const selected = isSelected(props.selection, item.id);
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListCard {...props}>
          <SpaceBetween fit="container">
            <VStack fit="container" gap={0}>
              <Text>{item.name}</Text>
              {!!item.useTemplate ? (
                <Text subtle>{toSentence(item)} using template </Text>
              ) : (
                <Text subtle>
                  {toSentence(item)} using {item.overrides?.length}{" "}
                  {plural("value", item.overrides?.length || 1)}
                </Text>
              )}
            </VStack>

            <HStack gap={0}>
              <PropertyValue
                variant="icon-only"
                valueRef={status}
                onChange={(s) =>
                  mutate(
                    asMutation({ field: "status", type: "status" }, s.status)
                  )
                }
                source={item.source}
              />
              <ScheduleEditorPopover
                schedule={item}
                parentId={item.useTemplate?.id || last(item.instances)?.id}
                source={item?.source}
                portal={true}
              >
                <Button subtle icon={Cog} />
              </ScheduleEditorPopover>
            </HStack>
          </SpaceBetween>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: ScheduleListItem,
  asCreateDialog: ScheduleCreateDialog,
};
