import { filter, get, map, omit, reduce, takeWhile } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  HasLocation,
  HasRefs,
  PropertyRef,
  Ref,
} from "@api";

import {
  useCreateFromObject,
  useLazyEntities,
  useQueueUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { mapUniq, replace } from "@utils/array";
import { now } from "@utils/date-fp";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { maybeTypeFromId } from "@utils/id";
import { equalsAny, ifDo } from "@utils/logic";
import { Maybe, maybeMap, safeAs } from "@utils/maybe";
import { setDirty } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { fromScope, toScope } from "@utils/scope";
import { SelectionState } from "@utils/selectable";
import { toArray } from "@utils/set";
import { plural } from "@utils/string";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Dialog } from "@ui/dialog";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import { ArrowRight, Icon, PlusAlt, StatusConvert, TrashAlt } from "@ui/icon";
import { Field, Fields } from "@ui/input";
import { CheckMenuItem } from "@ui/menu-item";
import { showError } from "@ui/notifications";
import { LocationSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { ScopedPropertySelect } from "@ui/select/property";
import { Tooltip } from "@ui/tooltip";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

export const ConvertDialog = ({ targets, onCancel, onComplete }: Props) => {
  const pageId = usePageId();
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const entities = useLazyEntities(targets);
  const toLabel = useEntityLabels(entities?.[0]?.source.scope);

  const [archive, setArchvie] = useState(false);
  const [convertTo, setConvertTo] = useState<EntityType>("task");
  const fromType = useMemo(() => {
    const types = mapUniq(entities, (e) => e.source.type);
    return types.length === 1 ? toLabel(types[0]) : "item";
  }, [entities]);
  const [location, setLocation] = useState(() =>
    toScope(
      ...takeWhile(
        fromScope(safeAs<Maybe<HasLocation>>(entities?.[0])?.location || wID),
        (id) => !equalsAny(maybeTypeFromId(id), ["agenda", "note", "resource"])
      )
    )
  );

  const [mappings, setMappings] = useState<
    [Maybe<PropertyRef>, Maybe<PropertyRef>][]
  >([]);

  const [picking, setPicking] = useState(false);
  const mutate = useQueueUpdates(pageId);

  const create = useCreateFromObject(convertTo, location, pageId);

  const handleSubmit = useCallback(() => {
    if (!entities?.length || !location) {
      return;
    }

    if (!create) {
      showError("Not ready to create. Try again.");
    }

    // Create new things for each entity
    const created = create?.(
      map(entities, (e) => {
        const cleaned = {
          ...omit(e, "id", "status", "source", "location", "refs", "workspace"),
          refs: {
            ...(safeAs<HasRefs>(e)?.refs || {}),
            convertedFrom: toRef(e),
          },
        } as Partial<Entity>;

        // Map all fields defined in the mappings
        return reduce(
          mappings,
          (acc, [from, to]) => {
            if (!from || !to) {
              return acc;
            }

            return setDirty(acc, to.field as keyof Entity, get(e, from.field));
          },
          cleaned
        );
      })
    );

    // Go through each entity and link it to it's created counterpart
    mutate(
      map(entities, (e, i) =>
        asUpdate(e, [
          asMutation(
            { field: "refs.convertedTo", type: "relation" },
            created?.[i]
          ),
          ...(ifDo(archive, () => [
            asMutation({ field: "archivedAt", type: "date" }, now()),
            asMutation({ field: "archivedBy", type: "relation" }, toRef(me)),
          ]) || []),
        ])
      )
    );

    // Callback
    onComplete?.();
  }, [
    entities,
    location,
    convertTo,
    create,
    onComplete,
    mutate,
    archive,
    mappings,
  ]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      title="Convert work"
      description={"Convert and organise this work."}
      onDismiss={onCancel}
      actions={
        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Convert {entities?.length}{" "}
            {plural(entities[0]?.source.type, entities?.length)}
          </Button>
        </HStack>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields label="Convert to">
              <EntityTypeSelect
                value={convertTo}
                scope={entities[0]?.source?.scope}
                onChange={(t) => t && setConvertTo(t)}
                plural={entities?.length > 1}
              />
            </Fields>

            <Fields label="Location">
              <LocationSelect
                fit="container"
                variant="full"
                open={picking}
                setOpen={setPicking}
                location={location}
                defaultOpen={true}
                onChange={setLocation}
              />
            </Fields>

            <Field label="Mappings">
              {map(mappings, ([from, to], i) => (
                <SpaceBetween key={i} gap={10}>
                  <FillSpace>
                    <ScopedPropertySelect
                      placeholder="From field..."
                      value={from}
                      scope={entities[0]?.source.scope}
                      type={entities[0]?.source.type}
                      allowOtherTypes={false}
                      onChanged={
                        (p) => setMappings(replace(mappings, i, [p, undefined])) // clear to mapping on select
                      }
                    />
                  </FillSpace>
                  <Icon icon={ArrowRight} />
                  <FillSpace>
                    <ScopedPropertySelect
                      placeholder="To field..."
                      value={to}
                      scope={location || entities[0]?.source.scope}
                      type={convertTo || entities[0]?.source.type}
                      allowOtherTypes={false}
                      whitelist={(p) => p.type === from?.type}
                      onChanged={(p) =>
                        setMappings(replace(mappings, i, [from, p]))
                      }
                    />
                  </FillSpace>
                  <Button
                    icon={TrashAlt}
                    size="small"
                    onClick={() =>
                      setMappings(filter(mappings, (_, i2) => i2 !== i))
                    }
                  />
                </SpaceBetween>
              ))}

              <Button
                subtle
                size="small"
                icon={PlusAlt}
                onClick={() =>
                  setMappings([...mappings, [undefined, undefined]])
                }
              >
                Add mapping
              </Button>
            </Field>

            <Field label="Cleanup">
              <CheckMenuItem checked={archive} onChecked={setArchvie}>
                Archive {targets?.length} {plural(fromType, targets?.length)}
              </CheckMenuItem>
            </Field>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};

interface ButtonProps {
  item: Entity;
  selection?: SelectionState;
}

export const ConvertButton = ({ item, selection }: ButtonProps) => {
  const [open, setOpen] = useState(false);
  const targets = useMemo(
    () =>
      maybeMap(
        selection?.selected?.has(item.id)
          ? toArray(selection?.selected)
          : [item.id],
        toRef
      ) || [],
    [selection, item?.id]
  );

  return (
    <>
      <Tooltip text="Convert to task">
        <Button
          icon={StatusConvert}
          iconSize="small"
          size="small"
          subtle
          onClick={withHardHandle(() => setOpen(true))}
        />
      </Tooltip>
      {open && (
        <div
          onDoubleClick={withHardHandle(() => {})}
          onClick={withHardHandle(() => {})}
        >
          <ConvertDialog
            targets={targets}
            onComplete={() => setOpen(false)}
            onCancel={() => setOpen(false)}
          />
        </div>
      )}
    </>
  );
};
