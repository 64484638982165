import { Content, Entity, ID } from "@api";

import { useMarkAsSeen, useQueueUpdates } from "@state/generic";
import { useActionTriggers } from "@state/workflows";

import { timeAgo } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { usePushTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { useAppPageContext } from "@ui/app-page";
import { Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityProperties } from "@ui/entity-properties";
import { EntitySheet } from "@ui/entity-sheet";
import { HStack, VStack } from "@ui/flex";
import { EmojiSelect } from "@ui/select/emoji";
import { TemplateBanner } from "@ui/template-banner";
import { Text } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export default function ContentPane({ id, item }: PaneOpts<Content>) {
  const pageId = useAppPageContext();
  const pushTo = usePushTo();

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!item) {
    return <></>;
  }

  return (
    <EntitySheet size="primary" entity={item}>
      {item.template && <TemplateBanner />}

      <ContentHeader id={id} content={item} />

      <Container
        stack="vertical"
        gap={20}
        fit="container"
        style={{ paddingTop: "10px" }}
      >
        <EntityProperties entityId={id} />
      </Container>
    </EntitySheet>
  );
}

interface HeaderProps {
  id: ID;
  content: Content;
  quickActions?: boolean;
}

export const ContentHeader = ({
  quickActions = true,
  content: entity,
}: HeaderProps) => {
  const pageId = useAppPageContext();

  const mutate = useQueueUpdates(pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <div>
      <EntityHeaderBar padding="both" entity={entity} />

      <VStack className={styles.panel} gap={12}>
        <VStack gap={0} fit="container">
          <Container
            fit="container"
            inset="left"
            padding="none"
            stack="horizontal"
            gap={6}
          >
            <EmojiSelect
              size="large"
              emoji={entity.icon || "✉️"}
              onChange={(emoji) =>
                mutate(
                  asUpdate(entity, [
                    asMutation({ field: "icon", type: "text" }, emoji),
                  ])
                )
              }
            />
            <EditableHeading
              key={entity.id}
              size="h2"
              text={entity.name || ""}
              placeholder="Untitled content..."
              onChange={(t) =>
                t &&
                mutate(
                  asUpdate<Entity>(entity, [
                    asMutation({ field: "name", type: "text" }, t),
                  ])
                )
              }
            />
          </Container>
        </VStack>

        <Container gap={2} stack="horizontal" inset="vertical" padding="none">
          {entity.publish && (
            <Text subtle>
              Content to be published {useISODate(entity.publish, timeAgo)}
            </Text>
          )}
        </Container>

        {quickActions && !entity?.template && (
          <HStack gap={4}>
            <WorkflowActions entity={entity} />
          </HStack>
        )}
      </VStack>
    </div>
  );
};
