import { isString, map } from "lodash";
import { useMemo } from "react";
import { GroupBase } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import { RelationRef } from "@api";

import { cx } from "@utils/class-names";
import { when } from "@utils/maybe";

import { Button } from "@ui/button";
import { HStack, SpaceBetween } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import { AngleDownIcon } from "@ui/icon";
import { RelationLabel, RelationMenuItem } from "@ui/relation-label";
import { PlaceholderText } from "@ui/text";

import { MultiProps, MultiSelect } from "./multi-select";
import { Select, SelectProps } from "./single-select";

import styles from "./select.module.css";

export type RelationSelectProps = SelectProps<RelationRef> & {
  icon?: boolean;
};

export const RelationSelect = ({
  children,
  icon,
  className,
  ...props
}: RelationSelectProps) => {
  const overrides: Partial<
    SelectComponents<RelationRef, false, GroupBase<RelationRef>>
  > = useMemo(
    () => ({
      Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
        <div ref={innerRef} {...innerProps}>
          <RelationMenuItem
            className={cx(styles.menuItem, isFocused && styles.focused)}
            // Dont know why isSelected is always false and so not highlighting...
            selected={isSelected}
            icon={icon}
            relation={data}
            iconRight={<GoToButton item={data} />}
          />
        </div>
      ),
      MultiValueLabel: ({ innerProps, data }) => (
        <RelationLabel
          {...innerProps}
          icon={icon}
          className={styles.tag}
          relation={data}
        />
      ),
      ...props.overrides,
    }),
    [props.overrides, icon]
  );

  return (
    <Select
      {...props}
      overrides={overrides}
      className={{
        ...(!isString(className) ? className : {}),
        popover: cx(
          styles.relationSelect,
          isString(className) ? className : className?.popover
        ),
      }}
    >
      {children ?? (
        <Button
          className={cx(
            styles.button,
            isString(className) ? className : className?.trigger
          )}
          subtle
          size="small"
          iconRight={props.caret === true ? AngleDownIcon : undefined}
        >
          <HStack gap={3} className={cx(styles.value)}>
            {when(props.value, (data) => (
              <SpaceBetween>
                <RelationLabel relation={data} />
                <GoToButton item={data} />
              </SpaceBetween>
            )) ||
              when(props.placeholder || "Select...", (text) => (
                <PlaceholderText>{text}</PlaceholderText>
              ))}
          </HStack>
        </Button>
      )}
    </Select>
  );
};

export type MultiRelationSelectProps = MultiProps<RelationRef>;

export const MultiRelationSelect = ({
  children,
  placeholder,
  className,
  ...props
}: MultiRelationSelectProps) => {
  const overrides: Partial<
    SelectComponents<RelationRef, true, GroupBase<RelationRef>>
  > = useMemo(
    () => ({
      Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
        <div ref={innerRef} {...innerProps} key={data.id}>
          <RelationMenuItem
            className={cx(styles.menuItem, isFocused && styles.focused)}
            checked={isSelected}
            selected={isSelected}
            relation={data}
            iconRight={<GoToButton item={data} />}
          />
        </div>
      ),
      MultiValueLabel: ({ innerProps, data }) => (
        <RelationLabel
          key={data.id}
          {...innerProps}
          className={styles.tag}
          relation={data}
        />
      ),
      ...props.overrides,
    }),
    [props.overrides]
  );

  return (
    <MultiSelect {...props} className={className} overrides={overrides}>
      {children ?? (
        <Button
          className={cx(
            styles.button,
            isString(className) ? className : className?.trigger
          )}
          subtle
          size="small"
        >
          <HStack gap={3} className={cx(styles.value)}>
            {map(props.value, (data) => (
              <SpaceBetween key={data.id}>
                <RelationLabel relation={data} />
                <GoToButton item={data} />
              </SpaceBetween>
            ))}
            {!props.value?.length && (
              <PlaceholderText>{placeholder || "Select..."}</PlaceholderText>
            )}
          </HStack>
        </Button>
      )}
    </MultiSelect>
  );
};
