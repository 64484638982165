import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { last, map, orderBy, some } from "lodash";
import { useMemo, useRef } from "react";

import {
  Entity,
  hasAvatar,
  hasIcon,
  hasStatus,
  ID,
  isStatusable,
  isTask,
  PropertyValueRef,
  Ref,
  toTitleOrName,
} from "@api";

import { getStore, useEntitySource, useQueueUpdates } from "@state/generic";
import { useLazyProperties, useLazyPropertyDef } from "@state/properties";
import { useEntityLabels } from "@state/settings";
import { useStableViewKey } from "@state/store";
import {
  useAddToView,
  useDefaultsForView,
  useDropInView,
  useLazyGetView,
  useLazyItemsForView,
} from "@state/views";

import { maybeLookup, maybeMap, reverse, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useShowMore, useViewportSize } from "@utils/hooks";
import { equalsAny, switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toPropertyValueRef } from "@utils/property-refs";
import { usePageSelection } from "@utils/selectable";

import { AddEntityInput } from "@ui/add-entity-input";
import { usePageId } from "@ui/app-page";
import { Container } from "@ui/container";
import { EditableText } from "@ui/editable-text";
import { EmptyState } from "@ui/empty-state";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { DropTarget } from "@ui/entity-drag-drop";
import { HStack } from "@ui/flex";
import { AngleDownIcon, AngleUp, EmojiIcon, Icon, PersonIcon } from "@ui/icon";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { SelectableTableRow } from "@ui/selectable-items";
import { useSuggestedProps } from "@ui/suggested-props";
import { TaskCheck } from "@ui/task-check";

import styles from "./table.module.css";

interface TableLayoutProps {
  id: ID;
  className?: string;
  onOpen?: Fn<Ref, void>;
}

// Column helper to create typed columns
const columnHelper = createColumnHelper<Entity>();

export function TableLayout({ id, className, onOpen }: TableLayoutProps) {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const view = useLazyGetView(id);
  const { items } = useLazyItemsForView(id);
  const onAdded = useAddToView(id);

  const itemsSource = useEntitySource(view?.entity || "task", view?.source);
  const defaults = useDefaultsForView(id);
  const [selection, setSelection] = usePageSelection();
  const selectionAsMap = useMemo(
    () => Object.fromEntries([...selection.selected].map((x) => [x, true])),
    [selection?.selected]
  );
  const suggestedProps = useSuggestedProps();
  const showProps = useMemo(
    () => reverse(whenEmpty(view?.showProps, suggestedProps || [])),
    [view?.showProps, suggestedProps]
  );
  const allProps = useLazyProperties(itemsSource);
  const mutate = useQueueUpdates(pageId);
  const onReorder = useDropInView(view, pageId);
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));
  const status = useLazyPropertyDef(itemsSource, {
    field: "status",
    type: "status",
  });
  const getStatus = useMemo(
    () => maybeLookup(status?.values?.status || [], (s) => s.id),
    [status]
  );

  const props = useMemo(() => {
    const visible = new Set(map(showProps, (prop) => prop.field));
    const all = maybeMap(allProps, (prop) => {
      if (prop.type === "rich_text") {
        return undefined;
      }

      // Core fields
      if (equalsAny(prop.field, ["title", "name"])) {
        return prop;
      }

      if (!visible.has(prop.field)) {
        return undefined;
      }

      return prop;
    });

    return orderBy(all, (prop) =>
      switchEnum(prop.field, {
        title: () => 0.1,
        name: () => 0.1,
        status: () => 0,
        code: () => 0,
        location: () => 3,
        else: () => {
          if (!prop.system) {
            return 1;
          }

          switchEnum(prop.type, {
            relations: () => 10,
            else: () => Number("2." + (prop.order || allProps.length)),
          });
        },
      })
    );
  }, [allProps, showProps]);

  const toLabel = useEntityLabels(view?.source.scope, {
    case: "lower",
    plural: true,
  });
  const windowWidth = useViewportSize()?.width;
  const { visible, showMore, moreCount, hasMore } = useShowMore(
    items.sorted,
    100,
    true
  );

  // Define columns based on showProps
  const columns = useMemo(() => {
    const showingStatus = some(props, { field: "status" });

    return map(props, (prop) => {
      const isTitle = equalsAny(prop.field, ["title", "name"]);

      return columnHelper.accessor(
        (row: Entity): PropertyValueRef => toPropertyValueRef(row, prop),
        {
          id: prop.field,
          header: () => prop.label || prop.field,
          maxSize: switchEnum(prop.field, {
            name: windowWidth * 0.35,
            title: windowWidth * 0.35,
            email: 250,
            location: 250,
            else: () => 120,
          }),
          size: switchEnum(prop.type, {
            else: () => undefined,
          }),
          minSize:
            switchEnum(prop.field, {
              name: 400,
              title: 400,
              email: 250,
              else: () => undefined,
            }) ||
            switchEnum(prop.type, {
              text: 120,
              status: 120,
              relations: 160,
              relation: 120,
              else: () => 80,
            }),
          cell: (cell) => {
            const entity = cell.row.original;
            const editable = cell.row.getIsSelected();

            if (isTitle) {
              return (
                <HStack key={prop.field}>
                  {/* TODO: This needs to be inflated... */}
                  {!showingStatus &&
                    isStatusable(entity.source.type) &&
                    hasStatus(entity) && (
                      <TaskCheck
                        task={entity}
                        status={
                          when(entity.status?.id, getStatus) || entity.status
                        }
                        onChange={(status) =>
                          mutate(
                            asUpdate(
                              entity,
                              asMutation(
                                { field: "status", type: "status" },
                                status
                              )
                            )
                          )
                        }
                      />
                    )}
                  {hasIcon(entity) && entity.icon && (
                    <Icon icon={<EmojiIcon emoji={entity.icon} />} />
                  )}
                  {hasAvatar(entity) && (
                    <Icon icon={<PersonIcon person={entity} />} />
                  )}

                  <EditableText
                    key={entity.id}
                    disabled={!editable}
                    className={cx(styles.title)}
                    text={toTitleOrName(entity)}
                    placeholder="Untitled"
                    blurOnEnter={true}
                    onChange={(v) =>
                      mutate(asUpdate(entity, asMutation(prop, v)))
                    }
                  />
                </HStack>
              );
            }

            // Render the cell content based on property type
            return (
              <PropertyValue
                editable={editable}
                className={styles.cellValue}
                fit="container"
                source={entity.source}
                inset={true}
                variant="inline"
                valueRef={cell.getValue()}
                onChange={(cs) =>
                  mutate(asUpdate(cell.row.original, { ...prop, value: cs }))
                }
              />
            );
          },
        }
      );
    });
  }, [props]);

  // Initialize table
  const table = useReactTable({
    data: visible,
    state: { rowSelection: selectionAsMap },
    getRowId: (row) => row.id,
    columns,
    enableSorting: false,
    enableRowSelection: true,
    onRowSelectionChange: () => {},
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 37,
    overscan: 40,
  });

  if (!view?.entity) {
    return <></>;
  }

  if (!visible.length) {
    return (
      <Container className={styles.emptyStateContainer}>
        <EmptyState
          image="/IMG-list-empty.png"
          text={`No ${toLabel(view?.entity)} here yet.`}
        >
          <AddEntityInput
            source={itemsSource}
            defaults={defaults}
            onAdded={onAdded}
          />
        </EmptyState>
      </Container>
    );
  }

  return (
    <div className={cx(styles.container, className)} ref={parentRef}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          // Hack to fix add items being overlapped
          marginBottom: 30,
        }}
      >
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const canSort = header.column.getCanSort();
                  const sorted = header.column.getIsSorted();

                  return (
                    <th
                      key={header.id}
                      className={cx(
                        styles.headerCell,
                        canSort && styles.sortable
                      )}
                      style={{ width: header.getSize() }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : (
                        <div className={styles.headerContent}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {canSort && (
                            <span
                              className={cx(
                                styles.sortIcon,
                                sorted && styles.active
                              )}
                            >
                              <Icon
                                size="small"
                                icon={
                                  sorted === "asc" ? (
                                    <AngleUp />
                                  ) : sorted === "desc" ? (
                                    <AngleDownIcon />
                                  ) : (
                                    <AngleDownIcon />
                                  )
                                }
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {virtualizer.getVirtualItems().map((vRow, index) => {
              const row = rows[vRow.index];
              return (
                <SelectableTableRow
                  key={toStableKey(row.id)}
                  className={cx(
                    styles.row,
                    row.getIsSelected() && styles.selected
                  )}
                  style={{
                    height: `${vRow.size}px`,
                    transform: `translateY(${
                      vRow.start - index * vRow.size
                    }px)`,
                  }}
                  item={row.original}
                  selection={selection}
                  setSelection={setSelection}
                  onOpen={onOpen || pushTo}
                  onReorder={onReorder}
                >
                  <EntityContextMenu
                    entity={row.original}
                    selection={selection}
                    setSelection={setSelection}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={styles.cell}
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </EntityContextMenu>
                </SelectableTableRow>
              );
            })}
          </tbody>
        </table>
      </div>

      {hasMore && <ShowMoreMenuItem count={moreCount} onClick={showMore} />}

      <DropTarget position="after" item={last(visible)} type={view.entity}>
        <div className={styles.addInputContainer}>
          <AddEntityInput
            source={itemsSource}
            onAdded={onAdded}
            defaults={defaults}
          />
        </div>
      </DropTarget>
    </div>
  );
}
