import { find, first } from "lodash";
import { useCallback, useMemo } from "react";

import { EntityType, ID, Link } from "@api";

import { useMarkAsSeen } from "@state/generic";
import { useLazyGetNote, useUpdateNote } from "@state/notes";
import { useSetting } from "@state/settings";

import { formatHuman } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { composel, Fn } from "@utils/fn";
import { typeFromId } from "@utils/id";
import { isSlack } from "@utils/link";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toMutation } from "@utils/property-mutations";
import { toMarkdown } from "@utils/rich-text";
import { redirect } from "@utils/url";

import { useAppPageContext } from "@ui/app-page";
import { Button } from "@ui/button";
import { CardHeader } from "@ui/card-header";
import { Container } from "@ui/container";
import { HStack, SpaceBetween } from "@ui/flex";
import { SlackColor, TimesIcon } from "@ui/icon";
import { Field } from "@ui/input";
import { PeopleStack } from "@ui/people-stack";
import { ReadonlyDocument, ReadonlyTextBox } from "@ui/rich-text";
import { TextSmall } from "@ui/text";

import { LocationButton } from "./location-button";
import { RelationLabel } from "./relation-label";
import { SlackThread } from "./slack-thread";

import styles from "./update-thread.module.css";

interface Props {
  noteId: ID;
  onClose?: Fn<void, void>;
  actions?: React.ReactNode;
  showParent?: boolean;
}

export const UpdateThread = ({
  noteId,
  actions,
  onClose,
  showParent = false,
}: Props) => {
  const pageId = useAppPageContext();
  const note = useLazyGetNote(noteId);
  const mutate = useUpdateNote(noteId);
  const goTo = useGoTo();
  const channel = useSetting<string>(noteId, "channel");

  const onEntity = useMemo(() => {
    const entityId = first(note?.refs?.backlinks)?.id;
    const type = when(entityId, typeFromId) as Maybe<EntityType>;
    return entityId && type ? { id: entityId, type: type } : undefined;
  }, [note]);

  // Mark the note as seen by current user
  useMarkAsSeen(noteId, pageId);

  const slackThread = useMemo(
    () => find(note?.links, (l) => isSlack(l.url)),
    [note]
  );

  const openInSlack = useCallback(
    () => when(slackThread?.url, (t) => redirect(t, true)),
    [slackThread]
  );

  const onNewThread = useCallback(
    (t: Link) =>
      note &&
      mutate([
        toMutation(note, { field: "links", type: "links" }, [
          ...(note.links || []),
          { ...t, text: "Replies in Slack" },
        ]),
      ]),
    [note]
  );

  return (
    <SpaceBetween direction="vertical">
      <CardHeader className={styles.header}>
        <SpaceBetween>
          <LocationButton
            location={note?.location}
            variant="full"
            showCaret={false}
          />

          <HStack gap={4}>
            <HStack gap={0}>
              {slackThread && (
                <Button subtle onClick={openInSlack} icon={SlackColor} />
              )}
              {onClose && (
                <Button subtle onClick={() => onClose?.()} icon={TimesIcon} />
              )}
            </HStack>
          </HStack>
        </SpaceBetween>
      </CardHeader>

      <Container className={styles.update} stack="vertical" gap={20}>
        <SpaceBetween fit="container">
          {note?.author && (
            <RelationLabel size="medium" relation={note.author} />
          )}
          <TextSmall subtle>
            {when(note?.createdAt, composel(fromPointDate, formatHuman))}
          </TextSmall>
        </SpaceBetween>

        <ReadonlyDocument
          key={note?.id}
          className={styles.text}
          content={note?.body}
        />

        {showParent && !!onEntity && (
          <Button
            subtle
            onClick={() => goTo(onEntity, { open: note?.id || "" })}
            inset
          >
            <RelationLabel relation={onEntity} />
          </Button>
        )}

        {!!note?.refs?.seenBy?.length && (
          <Field label="Seen By">
            <PeopleStack people={note?.refs?.seenBy} />
          </Field>
        )}
      </Container>

      <SlackThread
        thread={slackThread?.url}
        defaultChannel={channel}
        defaultTitle={toMarkdown(note?.body)}
        defaultAuthor={note?.createdBy}
        header="subtle"
        onNewThread={onNewThread}
      />
    </SpaceBetween>
  );
};
