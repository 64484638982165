import { atomFamily } from "recoil";

import { ID, Outcome } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type { Outcome };
export type OutcomeStoreState = StoreState<Outcome>;

export const WorkspaceOutcomeStoreAtom = atomFamily<OutcomeStoreState, ID>({
  key: "WorkspaceOutcomeStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "outcome" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "outcome")],
});

export const OutcomeStoreAtom = WorkspaceWrappedAtom(
  "OutcomeStoreAtom",
  WorkspaceOutcomeStoreAtom
);
