import { forwardRef } from "react";

import { cx } from "@utils/class-names";

import { Container, Props as ContainerProps } from "./container";

import styles from "./card.module.css";

type Props = {
  className?: string;
  interactable?: boolean;
  children: React.ReactNode;
} & ContainerProps;

export const Card = forwardRef<HTMLDivElement, Props>(
  (
    { className, interactable = true, children, padding, size, ...rest },
    ref
  ) => (
    <Container
      ref={ref}
      className={cx(
        styles.card,
        interactable && styles.interactable,
        className
      )}
      padding={padding}
      size={size || "half"}
      {...rest}
    >
      {children}
    </Container>
  )
);
