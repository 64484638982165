import { find, groupBy, map } from "lodash";
import { ReactNode, useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  ID,
  PropertyDef,
  PropertyMutation,
  PropertyValueRef,
  VariableDef,
} from "@api";

import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { extractVarReference, toVarReference } from "@utils/formula";
import { toFieldName } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { EditAlt, Icon } from "@ui/icon";
import { PropertyEditDialog } from "@ui/property-edit-dialog";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { PropertyValue } from "@ui/property-value";
import { WithVariableSelect } from "@ui/select/variable";
import { TextSmall } from "@ui/text";

import { HStack, SpaceBetween, VStack } from "./flex";
import { DocumentEditor } from "./rich-text";

import styles from "./property-values-list.module.css";

interface Props {
  values: PropertyValueRef[];
  parent?: Entity;
  source: DatabaseID;
  className?: string;
  children?: ReactNode;
  editable?: boolean;
  propsEditable?: boolean;
  inset?: boolean;
  variables?: VariableDef[];
  onMutate?: (
    changes: PropertyMutation<Entity> | PropertyMutation<Entity>[],
    transaction?: ID
  ) => void;
}

export const PropertyValuesList = ({
  values,
  source,
  className,
  onMutate,
  children,
  parent,
  editable = true,
  variables,
  propsEditable = editable,
  inset = true,
}: Props) => {
  const me = useMe();
  const [editing, setEditing] = useState<PropertyDef<Entity>>();

  return (
    <div className={cx(styles.container, styles.oneColumn, className)}>
      {map(values, (valueRef) => (
        <div className={styles.row} key={valueRef.field}>
          <Button
            className={styles.colProp}
            inset={inset}
            subtle
            size="small"
            icon={<PropertyTypeIcon {...(valueRef.def || valueRef)} />}
            onClick={() =>
              propsEditable &&
              valueRef.def &&
              setEditing(valueRef.def as PropertyDef<Entity>)
            }
          >
            <SpaceBetween>
              {toFieldName(valueRef)}
              <HStack gap={0}>
                {valueRef.def?.scope === me.id && (
                  <TextSmall className={styles.private} subtle>
                    Private
                  </TextSmall>
                )}
                {propsEditable && (
                  <Icon className={styles.editIcon} icon={EditAlt} />
                )}
              </HStack>
            </SpaceBetween>
          </Button>

          <div className={styles.colVal}>
            {!variables && (
              <PropertyValue
                valueRef={valueRef}
                source={source}
                parent={parent}
                editable={editable}
                propTypeIcon={false}
                variant="labelled"
                className={styles.value}
                onChange={(v) =>
                  onMutate?.([
                    {
                      field: valueRef.field,
                      type: valueRef.type,
                      value: v,
                      prev: valueRef.value,
                    },
                  ])
                }
              />
            )}
            {!!variables && (
              <WithVariableSelect
                value={find(variables, {
                  field: extractVarReference(valueRef.value?.formula || ""),
                })}
                allowed={valueRef.type}
                onChange={(v) =>
                  onMutate?.([
                    {
                      field: valueRef.field,
                      type: valueRef.type,
                      value: { formula: v && toVarReference(v) },
                      prev: valueRef.value,
                    },
                  ])
                }
                options={variables}
              >
                <PropertyValue
                  valueRef={valueRef}
                  source={source}
                  parent={parent}
                  editable={editable}
                  propTypeIcon={false}
                  variant="labelled"
                  className={styles.value}
                  onChange={(v) =>
                    onMutate?.([
                      {
                        field: valueRef.field,
                        type: valueRef.type,
                        value: v,
                        prev: valueRef.value,
                      },
                    ])
                  }
                />
              </WithVariableSelect>
            )}
          </div>
        </div>
      ))}

      {children}
      {editing && (
        <PropertyEditDialog
          source={source}
          prop={editing}
          onClose={() => setEditing(undefined)}
        />
      )}
    </div>
  );
};
