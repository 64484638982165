import { isArray, isString, omit } from "lodash";

import { Entity, Json, JsonObject, Ref } from "@api";

import { asJSON } from "@utils/json";

// Extracts JSON from a markdown code block
const extractJsonMarkdown = (markdown: string): string => {
  const match = markdown.match(/```json\n([\s\S]+?)\n```/);
  return match ? match[1] : markdown;
};

export const parseJsonResponse = (response: string | Json): Json => {
  if (isString(response)) {
    return asJSON(extractJsonMarkdown(response));
  }
  return response;
};

export const asJsonObject = (json: Json): JsonObject => {
  if (isArray(json)) {
    return json[0] as JsonObject;
  }
  return json;
};

export const toLightModel = (work: Entity | Ref) =>
  omit(
    work,
    "body",
    "stamps",
    "settings",
    "fetchedAt",
    "createdAt",
    "createdBy",
    "archivedAt",
    "archivedBy",
    "deletedAt",
    "deletedBy"
  );
