import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  ReactRenderer,
} from "@tiptap/react";
import Suggestion, {
  SuggestionOptions,
  SuggestionProps,
} from "@tiptap/suggestion";
import { filter, find, first, isString, last, map, snakeCase } from "lodash";
import {
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import tippy, { Instance } from "tippy.js";

import { DatabaseID, FormPropDef, PropertyType, VariableDef, Vars } from "@api";

import { useLazyProperties } from "@state/properties";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { typeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import { isVisibleProp } from "@utils/property-refs";
import { ComponentOrNode } from "@utils/react";
import { fromScope } from "@utils/scope";
import { titleCase } from "@utils/string";

import { Card } from "@ui/card";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, NumberIcon, TextField } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuDivider, MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { RelationLabel } from "@ui/relation-label";
import { Text, TextSmall } from "@ui/text";

import { getExtensionOptions, isFocused as isEditorFocused } from "./utils";

import styles from "./variable-extension.module.css";

type VariableListItem = {
  id: string;
  title: string;
  subtitle?: string;
  icon?: ComponentOrNode;
  def: VariableDef;
};

type VariableExtensionProps = SuggestionProps & {
  items: VariableListItem[];
  scope: string;
  variables: Vars;
  onNewVariable?: Fn<VariableDef, void>;
  command: Fn<{ id: string; label: string }, void>;
};

type VariableExtensionOptions = {
  scope: string;
  suggestion: Partial<SuggestionOptions>;
  variables: Vars;
  onNewVariable?: Fn<VariableDef, void>;
  HTMLAttributes: Record<string, any>;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    variable: {
      setVariable: (options: FormPropDef) => ReturnType;
    };
  }
}

export const VariableList = forwardRef(
  (
    { command, query, scope, variables, onNewVariable }: VariableExtensionProps,
    ref
  ) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const items = useMemo((): VariableListItem[] => {
      const createCommand = {
        id: "create",
        title: `Add ${query} varaiable`,
        subtitle: "Text",
        icon: TextField,
        def: {
          field: snakeCase(query)?.replace(/[^a-zA-Z0-9\_]/g, ""),
          label: titleCase(query?.replace(/[\{\}]/g, "")),
          type: "text",
        },
      };

      const options = [
        ...map(variables, (v) => ({
          id: v.field,
          title: v.label || v.field,
          subtitle: v.description || `Inserts variable value.`,
          icon: <PropertyTypeIcon type={v.type} />,
          def: v,
        })),
        createCommand,
      ] as VariableListItem[];

      if (!query) {
        return options;
      }

      return filter(
        options,
        (item) =>
          !!item.title?.toLowerCase().includes(query.toLowerCase()) ||
          !!item.subtitle?.includes(query.toLowerCase())
      );
    }, [query]);

    const selectItem = useCallback(
      (item: VariableListItem) => {
        item && command({ ...item.def, label: item.def?.label || item.title });
        if (item.id === "create") {
          onNewVariable?.(item.def);
        }
      },
      [command]
    );

    const upHandler = useCallback(() => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    }, [items, selectedIndex]);

    const downHandler = useCallback(() => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    }, [items, selectedIndex]);

    const enterHandler = useCallback(() => {
      when(items[selectedIndex], selectItem);
    }, [items, selectedIndex, selectItem]);

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(
      ref,
      () => ({
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (event.key === "Enter") {
            enterHandler();
            return true;
          }

          return false;
        },
      }),
      [enterHandler, downHandler, upHandler]
    );

    useLayoutEffect(() => {
      const menu = scrollRef.current;
      const item = itemsRef.current?.children?.[
        selectedIndex
      ] as Maybe<HTMLElement>;

      if (!item || !menu) {
        return;
      }

      const { top, bottom } = item.getBoundingClientRect();
      const { top: menuTop, bottom: menuBottom } = menu.getBoundingClientRect();

      if (top < menuTop) {
        menu.scrollTop -= menuTop - top;
      } else if (bottom > menuBottom) {
        menu.scrollTop += bottom - menuBottom;
      }
    }, [selectedIndex]);

    return (
      <Card className={styles.popover} width="content" ref={scrollRef}>
        <Menu className={styles.menu}>
          <MenuGroup ref={itemsRef}>
            {map(items, (item, i) => (
              <Fragment key={item.id}>
                {item.id === "create" && items?.length > 1 && (
                  <MenuDivider className={styles.divider} />
                )}
                <MenuItem
                  className={cx(
                    selectedIndex === items.indexOf(item) && "focus"
                  )}
                  onClick={() => selectItem(item)}
                >
                  <SpaceBetween>
                    <HStack>
                      <Icon
                        className={styles.icon}
                        icon={item.icon || NumberIcon}
                      />
                      <VStack gap={0}>
                        <Text>{item.title}</Text>
                        <TextSmall subtle>{item.subtitle}</TextSmall>
                      </VStack>
                    </HStack>
                  </SpaceBetween>
                </MenuItem>
              </Fragment>
            ))}
            {!items.length && (
              <MenuItem>
                <SpaceBetween>
                  <HStack>
                    <VStack gap={0}>
                      <Text>No matching commands.</Text>
                    </VStack>
                  </HStack>
                </SpaceBetween>
              </MenuItem>
            )}
          </MenuGroup>
        </Menu>
      </Card>
    );
  }
);

export const VariableExtension = Node.create<VariableExtensionOptions>({
  name: "variable",

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addOptions() {
    return {
      ...this.parent?.(),
      scope: "",
      suggestion: {
        pluginKey: new PluginKey("suggest-variable"),
        char: "{",
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run();

          // get reference to `window` object from editor element, to support cross-frame JS usage
          editor.view.dom.ownerDocument.defaultView
            ?.getSelection()
            ?.collapseToEnd();
        },
        render: function () {
          let reactRenderer: Maybe<ReactRenderer>;
          let popup: Maybe<Instance>;

          const handleClose = (editor: SuggestionProps["editor"]) => {
            editor.commands.focus(); // Focus the editor to ensure the selection is correct
            editor.commands.setMeta("plugin-focused", false);
            popup?.destroy();
            reactRenderer?.destroy();
          };

          return {
            onStart: (props) => {
              if (!props.clientRect || !when(props.editor, isEditorFocused)) {
                return;
              }
              const { scope, variables, onNewVariable } = getExtensionOptions(
                "variable",
                props.editor
              );

              props.editor.commands.setMeta("plugin-focused", true);

              reactRenderer = new ReactRenderer(VariableList, {
                props: {
                  ...props,
                  scope,
                  variables,
                  onNewVariable,
                  onClose: () => handleClose(props.editor),
                },
                editor: props.editor,
              });

              popup = first(
                tippy("body", {
                  getReferenceClientRect: () =>
                    props.clientRect?.() || DOM_RECT_FALLBACK,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "auto-start",
                })
              );
            },

            onUpdate(props) {
              reactRenderer?.updateProps({
                ...props,
                onClose: () => handleClose(props.editor),
              });

              if (!props.clientRect) {
                return;
              }

              popup?.setProps({
                getReferenceClientRect: () =>
                  props.clientRect?.() || DOM_RECT_FALLBACK,
              });
            },

            onKeyDown(props) {
              if (props.event.key === "Escape") {
                popup?.hide();

                return true;
              }

              return (reactRenderer?.ref as Maybe<any>)?.onKeyDown(props);
            },

            onExit(props) {
              if (popup && reactRenderer) {
                handleClose(props.editor);
              }
            },
          };
        },
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => false,
      // TODO: Modify for when inside the variable span[data-var-field]
      // Command-A only selects the current value in the / search
      "Mod-a": ({ editor }) => {
        const state = editor.view.state;
        const { selection } = state;
        const { $anchor } = selection;

        // Check if the selection is a mention
        const text = selection.$from.nodeBefore?.textContent;
        const currentWord = last(
          text?.slice(0, selection.$anchor.parentOffset)?.split(" ")
        );

        const char = this.options.suggestion.char || "{";

        if (currentWord?.startsWith(char)) {
          editor.commands.setTextSelection({
            from: $anchor.pos - currentWord.length + 1,
            to: $anchor.pos,
          });
          return true;
        }

        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },

  addCommands() {
    return {
      setVariable:
        ({ field, type, label, description }: FormPropDef) =>
        ({ tr, dispatch, chain, view }) => {
          const { selection } = tr;
          const node = this.type.create({ field, type, description, label });

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          // move the cursor after the mention
          chain().selectTextblockEnd().run();

          return true;
        },
    };
  },

  addAttributes() {
    return {
      field: {
        default: undefined,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-var-field"),
        renderHTML: (attributes) => {
          return {
            "data-var-field": attributes.field,
          };
        },
      },

      type: {
        default: "text",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-var-type"),
        renderHTML: (attributes) => {
          return { "data-var-type": attributes.type };
        },
      },

      label: {
        default: "New variable",
        parseHTML: (element: HTMLElement) =>
          element.textContent ||
          element.getAttribute("data-var-label") ||
          element.getAttribute("data-var-field"),
        renderHTML(attributes) {
          // Don't store as a field, store as node content
          return {};
        },
      },

      description: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-var-description"),
        renderHTML: (attributes) => {
          // Don't save descriptions to HTML
          return {};
        },
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(VariableCompWrapper);
  },

  // Rules for extracting from html
  parseHTML() {
    return [
      {
        tag: "[data-var-field]",
        priority: 2000,
        excludes: "_",
        getAttrs: (element) => {
          if (isString(element)) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
  renderHTML(props) {
    const { node, HTMLAttributes } = props;
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      node.textContent || node.attrs.label || 0,
    ];
  },
});

const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

const VariableCompWrapper = (props: NodeViewProps) => {
  return (
    <NodeViewWrapper as="span">
      <VariableComp {...props} />
    </NodeViewWrapper>
  );
};

const VariableComp = (props: NodeViewProps) => {
  const { variables } = getExtensionOptions("variable", props.editor);
  const variable = useMemo(
    () => find(variables, { field: props.node.attrs.field }),
    [variables, props.node.attrs.field]
  );

  if (!variable?.value?.[variable?.type]) {
    return <span className={styles.variable}>{props.node.attrs.label}</span>;
  }

  return (
    <span className={styles.variable}>
      {switchEnum<PropertyType, ReactNode>(variable.type, {
        relation: () => <RelationLabel relation={variable.value?.relation} />,
        else: () => <>{String(variable.value[variable.type])}</>,
      })}
    </span>
  );
};
