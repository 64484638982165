import { filter, find, map, orderBy, pick, some, take } from "lodash";
import { filter as filter_ } from "lodash/fp";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import {
  Entity,
  EntityType,
  FilterQuery,
  GroupByProp,
  isAssignable,
  isOwnable,
  isStatusable,
  Json,
  JsonArray,
  PropertyDef,
  PropertyFilter,
  PropertyMutation,
  PropertyRef,
  View,
  ViewGrouping,
  ViewLayout,
} from "@api";

import { useLazyProperties } from "@state/properties/effects";
import { useEntitySource, useNestedSource } from "@state/generic";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { useActiveSpace } from "@state/spaces";
import { useDefaultTeam } from "@state/teams";
import { ID } from "@state/types";
import {
  useGetPropertiesForView,
  useLazyGetView,
  useSourceForView,
  useToViewTitle,
  useUpdateView,
  ViewAtom,
} from "@state/views";
import {
  isTemplateViewId,
  toDataSource,
  toLayoutName,
} from "@state/views/utils";
import { useActiveWorkspaceId } from "@state/workspace";

import {
  maybeLookup,
  maybeMap,
  omitEmpty,
  pushDirty,
  replace,
} from "@utils/array";
import { cx } from "@utils/class-names";
import { withHardHandle } from "@utils/event";
import {
  append,
  isAnd,
  isEmptyFilter,
  isNested,
  toList,
} from "@utils/filtering";
import { composel, Fn } from "@utils/fn";
import { isLocalID, isPersonId, maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, Nullable, safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import {
  defaultFormatForType,
  formatsForType,
  isAnyRelation,
  isVisibleProp,
  sortByUseful,
  toPropertyFormatLabel,
  toPropertyValueRef,
  toRef,
} from "@utils/property-refs";
import { toParentScope } from "@utils/scope";
import { fuzzyMatch } from "@utils/search";
import { plural, sentenceCase } from "@utils/string";

import { Banner } from "@ui/banner";
import { Button, Props as ButtonProps } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { Dropdown } from "@ui/dropdown";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  AngleDownIcon,
  AngleRightIcon,
  ChartGrowthAlt,
  Check,
  CheckIcon,
  CornerLeftUp,
  Database,
  Eye,
  EyeSlash,
  FilterAlt,
  Icon,
  LayoutColumns,
  LayoutRows,
  Magic,
  MinusIcon,
  NumberIcon,
  PlusIcon,
  SortAsc,
  SortDesc,
  StatusIcon,
  Sync,
  TextAlt,
  ViewIcon,
} from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { RadioMenuGroup, RadioMenuItem } from "@ui/menu-item/radio";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { RadioGroup, RadioItem } from "@ui/radio";
import { GlobalEntitySelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import PropertySelect, { ScopedPropertySelect } from "@ui/select/property";
import { Text } from "@ui/text";

import { NestedPropertyFilter } from "./property-filter";
import { RelationIcon, RelationLabel, RelationText } from "./relation-label";
import { ViewChangesActions } from "./view-changes-actions";

import styles from "./view-options-menu.module.css";

export type ViewOption =
  | "layout"
  | "showProps"
  | "sort"
  | "aggregate"
  | "filter"
  | "name"
  | "source";

interface Props {
  viewId: ID;
  section?: ViewOption;
  highlight?: ViewOption;
  onSectionChanged?: Fn<Maybe<ViewOption>, void>;
  header?: ReactNode;
  title?: string;
  className?: string;
}

const toFieldName = <T extends Entity>(p: PropertyDef<T> | PropertyRef<T>) =>
  (p as PropertyDef<T>)?.label || sentenceCase(p.field as string);

function useEnsureViewInStore(id: ID) {
  const [view, setView] = useRecoilState(ViewAtom(id));
  useEffect(() => {
    if (view?.id && isTemplateViewId(view.id)) {
      setView(view);
    }
  }, [id]);
}

type ViewOptionsDropdownProps = {
  viewId: string;
  text: string;
  summary?: string;
  icon: ButtonProps["icon"];
  open: boolean;
  onOpen: Fn<boolean, void>;
  highlighted?: boolean;
  children?: ReactNode;
};

export default function ViewOptionsMenu({
  viewId,
  section,
  highlight,
  header,
  onSectionChanged,
  className,
}: Props) {
  const me = useMe();
  const view = useLazyGetView(viewId);
  const allProperties = useGetPropertiesForView(viewId);
  const mutate = useUpdateView(viewId, true);
  const toTitle = useToViewTitle(view);
  const toLabel = useEntityLabels(view?.source.scope);

  const [_editing, _setEditing] = useState<ViewOption>();
  const [editing, setEditing] = onSectionChanged
    ? [section, onSectionChanged]
    : [_editing, _setEditing];

  useEnsureViewInStore(viewId);

  const getPropDef = useMemo(
    () =>
      !!allProperties?.length
        ? maybeLookup(allProperties, (p) => p.field)
        : () => undefined,
    [allProperties]
  );

  const showingProps = useMemo(
    () => maybeMap(view?.showProps || [], (p) => getPropDef(p.field)),
    [view, allProperties]
  );

  const filters = useMemo(() => when(view?.filter, toList) || [], [view]);

  const handleOnEdit = useCallback(
    (section?: ViewOption) => () => setEditing(section),
    [setEditing]
  );

  if (!view) {
    return <></>;
  }

  return (
    <Menu className={className}>
      {editing && (
        <VStack fit="container" gap={20} className={styles.editing}>
          {header === true ||
            (header === undefined && (
              <HStack gap={4}>
                <ButtonOption
                  selected={false}
                  onClick={handleOnEdit(undefined)}
                  icon={CornerLeftUp}
                />
                <Divider direction="vertical" />
                <ButtonOption
                  selected={editing === "layout"}
                  onClick={handleOnEdit("layout")}
                  icon={<Icon icon={iconForLayout(view.layout)} />}
                >
                  Layout
                </ButtonOption>
                <ButtonOption
                  selected={editing === "showProps"}
                  onClick={handleOnEdit("showProps")}
                  icon={<Icon icon={Eye} />}
                >
                  Fields
                </ButtonOption>
                <ButtonOption
                  selected={editing === "sort"}
                  onClick={handleOnEdit("sort")}
                  icon={<Icon icon={SortAsc} />}
                >
                  Sorting
                </ButtonOption>
                <ButtonOption
                  selected={editing === "filter"}
                  onClick={handleOnEdit("filter")}
                  icon={<Icon icon={FilterAlt} />}
                >
                  Filters
                </ButtonOption>
              </HStack>
            ))}

          {switchEnum(editing, {
            showProps: () => (
              <ShowPropsOptionsMenu viewId={viewId} title="Showing fields" />
            ),
            sort: () => <SortByOptionsMenu viewId={viewId} title="Sorting" />,
            aggregate: () => (
              <AggregateOptionsMenu viewId={viewId} title="Aggregating" />
            ),
            layout: () => <LayoutOptionsMenu viewId={viewId} title="Layout" />,
            filter: () => <FilterOptionsMenu viewId={viewId} title="Filters" />,
            source: () => <SourceOptionsMenu viewId={viewId} title="Source" />,
            name: () => (
              <MenuGroup label="Name" inset={false}>
                <TextInput
                  value={view.name}
                  autoFocus={true}
                  placeholder={toTitle(view)}
                  onChange={(v) =>
                    mutate([asMutation({ field: "name", type: "text" }, v)])
                  }
                />
              </MenuGroup>
            ),
            else: () => <></>,
          })}
        </VStack>
      )}

      {!editing && (
        <>
          {header}

          <MenuGroup label="Board">
            <MenuItem
              selected={highlight === "name"}
              onClick={handleOnEdit("name")}
            >
              <SpaceBetween>
                <Label icon={<Icon dark icon={TextAlt} />} text="Name" />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {view.name || toTitle(view)}
                </Label>
              </SpaceBetween>
            </MenuItem>
          </MenuGroup>

          <MenuGroup label="Visual">
            <MenuItem
              selected={highlight === "layout"}
              onClick={handleOnEdit("layout")}
            >
              <SpaceBetween>
                <Label
                  icon={<Icon dark icon={iconForLayout(view.layout)} />}
                  text="Layout"
                />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {find(LAYOUTS, (l) => l.id === view.layout)?.name}
                </Label>
              </SpaceBetween>
            </MenuItem>

            <MenuItem onClick={handleOnEdit("layout")}>
              <SpaceBetween>
                <Label
                  icon={
                    <Icon
                      dark
                      icon={<ViewIcon layout={view.grouping || view.layout} />}
                    />
                  }
                  text="Sections"
                />
                {view.grouping && (
                  <Label subtle iconRight={AngleRightIcon} fit="content">
                    As {plural(view.grouping)}
                  </Label>
                )}
              </SpaceBetween>
            </MenuItem>

            <MenuItem
              selected={highlight === "showProps"}
              onClick={handleOnEdit("showProps")}
            >
              <SpaceBetween>
                <Label icon={<Icon dark icon={Eye} />} text="Fields" />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {showingProps?.length
                    ? `${showingProps?.length} shown`
                    : "No fields selected"}
                </Label>
              </SpaceBetween>
            </MenuItem>

            <MenuItem
              selected={highlight === "sort"}
              onClick={handleOnEdit("sort")}
            >
              <SpaceBetween>
                <Label icon={<Icon dark icon={SortAsc} />} text="Sort" />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {view.sort?.length
                    ? `Sorting by ${view.sort?.length} field`
                    : "Manual order"}
                </Label>
              </SpaceBetween>
            </MenuItem>

            <MenuItem
              selected={highlight === "aggregate"}
              onClick={handleOnEdit("aggregate")}
            >
              <SpaceBetween>
                <Label
                  icon={<Icon dark icon={ChartGrowthAlt} />}
                  text="Aggregate"
                />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {view.aggregate?.length
                    ? `Aggregate by ${view.aggregate?.length} field`
                    : "No aggregation"}
                </Label>
              </SpaceBetween>
            </MenuItem>
          </MenuGroup>

          <MenuGroup label="Data">
            <MenuItem
              selected={highlight === "source"}
              onClick={handleOnEdit("source")}
            >
              <SpaceBetween>
                <Label icon={<Icon dark icon={Database} />} text="Source" />
                {view.for && (
                  <Label subtle fit="content">
                    {toLabel(view.entity, { case: "title", plural: true })} for{" "}
                    {toDataSource(view) === me.id ? (
                      "me"
                    ) : (
                      <RelationLabel
                        subtle
                        fit="content"
                        className={styles.inlineTable}
                        icon={false}
                        relation={view.for}
                      />
                    )}
                  </Label>
                )}

                {!view.for && (
                  <Label subtle fit="content">
                    {sentenceCase(plural(view.entity, 2))}
                  </Label>
                )}
              </SpaceBetween>
            </MenuItem>

            <MenuItem
              selected={highlight === "filter"}
              onClick={handleOnEdit("filter")}
            >
              <SpaceBetween>
                <Label icon={<Icon dark icon={FilterAlt} />} text="Filters" />
                <Label subtle iconRight={AngleRightIcon} fit="content">
                  {filters?.length
                    ? `${filters?.length} ${plural("filter", filters?.length)}`
                    : "No filters"}
                </Label>
              </SpaceBetween>
            </MenuItem>
          </MenuGroup>
        </>
      )}
    </Menu>
  );
}

export const ViewOptionsDropdown = ({
  icon,
  text,
  summary,
  viewId,
  open,
  onOpen,
  highlighted,
  children,
}: ViewOptionsDropdownProps) => {
  return (
    <Dropdown
      open={open}
      setOpen={onOpen}
      className={{
        popover: styles.dropdown,
      }}
      closeOnClickAway={false} // Having issues with double dropdowns closing each other
      position="bottom-right"
      trigger={
        <Button
          subtle
          size="small"
          variant="secondary"
          icon={icon}
          className={cx(highlighted && styles.highlighted, open && "hover")}
        >
          <Text subtle className={cx(highlighted && styles.highlighted)}>
            {text}
          </Text>
        </Button>
      }
    >
      <SpaceBetween className={styles.headerContainer}>
        <Text subtle>{summary || text}</Text>
        <Button subtle size="small" onClick={() => onOpen(false)}>
          Close
        </Button>
      </SpaceBetween>
      <div className={styles.scrollContainer}>{children}</div>
      <HStack justify="flex-end">
        <ViewChangesActions
          viewId={viewId}
          onSaved={() => onOpen(false)}
          onCancelled={() => onOpen(false)}
        />
      </HStack>
    </Dropdown>
  );
};

export const ShowPropsOptionsMenu = ({ viewId, title }: Props) => {
  const view = useLazyGetView(viewId);
  const allProperties = useGetPropertiesForView(viewId);
  const mutate = useUpdateView(viewId, true);
  const [search, setSearch] = useState("");
  const getPropDef = useMemo(
    () =>
      !!allProperties?.length
        ? maybeLookup(allProperties, (p) => p.field)
        : () => undefined,
    [allProperties]
  );
  const withClear = useCallback(
    (callback: Fn<void, void>) => () => {
      callback();
      setSearch("");
    },
    []
  );

  const selected = useMemo(
    () => maybeMap(view?.showProps || [], (p) => getPropDef(p.field)),
    [view?.showProps, getPropDef, search]
  );

  const inUse = useMemo(() => maybeMap(selected, (f) => f.field), [selected]);

  const available = useMemo(
    () =>
      composel(
        filter_(
          (p: PropertyDef<Entity>) =>
            !inUse?.includes(p.field) &&
            ((isVisibleProp(p) && !equalsAny(p.type, ["rich_text"])) ||
              p.field === "location") &&
            (!search || fuzzyMatch(search, toFieldName(p)))
        ),
        sortByUseful
      )(allProperties),
    [view, allProperties, search]
  );

  useEnsureViewInStore(viewId);

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      <MenuGroup label={title}>
        {map(selected, (p, i) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            iconRight={Eye}
            onClick={withClear(() =>
              mutate([
                asMutation(
                  { field: "showProps", type: "json" },
                  filter(view.showProps || [], (g) => g.field !== p.field)
                ),
              ])
            )}
          >
            {toFieldName(p)}
          </MenuItem>
        ))}
        {!selected.length && (
          <MenuItem disabled icon={Magic}>
            <Text subtle>Smart defaults will be used.</Text>
          </MenuItem>
        )}
      </MenuGroup>

      <MenuGroup label="Options">
        <MenuItem
          icon={<CheckIcon checked={!!view?.settings?.hideEmptyFields} />}
          onClick={() =>
            mutate([
              asMutation(
                { field: "settings.hideEmptyFields", type: "boolean" },
                !view?.settings?.hideEmptyFields
              ),
            ])
          }
        >
          Hide empty fields
        </MenuItem>
      </MenuGroup>

      <MenuGroup label="Available fields">
        <TextInput
          autoFocus={!selected.length}
          value={search}
          placeholder="Filter..."
          updateOn="change"
          onChange={setSearch}
          className={styles.searchInput}
        />

        {map(available, (p) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            text={toFieldName(p)}
            iconRight={PlusIcon}
            onClick={withClear(
              () =>
                p &&
                mutate([
                  asMutation({ field: "showProps", type: "json" }, [
                    ...(view.showProps || []),
                    pick(p, "field", "type"),
                  ]),
                ])
            )}
          />
        ))}
      </MenuGroup>
    </Menu>
  );
};

export const SortByOptionsMenu = ({ viewId, title }: Props) => {
  const view = useLazyGetView(viewId);
  const allProperties = useGetPropertiesForView(viewId);
  const mutate = useUpdateView(viewId, true);
  const [search, setSearch] = useState("");
  const getPropDef = useMemo(
    () =>
      !!allProperties?.length
        ? maybeLookup(allProperties, (p) => p.field)
        : () => undefined,
    [allProperties]
  );

  const withClear = useCallback(
    (callback: Fn<void, void>) => () => {
      callback();
      setSearch("");
    },
    []
  );

  const empty = useMemo(() => view?.sort?.[0]?.empty || "last", [view?.sort]);

  const selected = useMemo(
    () =>
      maybeMap(view?.sort || [], (p) => ({ ...p, def: getPropDef(p.field) })),
    [view?.sort, getPropDef]
  );

  const inUse = useMemo(() => maybeMap(selected, (f) => f.field), [selected]);

  const available = useMemo(
    () =>
      composel(
        filter_(
          (p: PropertyDef<Entity>) =>
            !inUse?.includes(p.field) &&
            (((isVisibleProp(p) || equalsAny(p.field, ["title", "name"])) &&
              !["rich_text"]?.includes(p.type)) ||
              p.field === "location") &&
            (!search || fuzzyMatch(search, toFieldName(p)))
        ),
        (ts) =>
          orderBy(
            ts, // Preferred group by field types, sorts left to right here
            (p) =>
              `${[
                "status",
                "multi_select",
                "select",
                "date",
                "checklist",
                "relation",
                "relations",
              ].indexOf(p.type)}.${p.order}`,
            "asc"
          )
      )(allProperties),
    [view, allProperties, search]
  );

  useEnsureViewInStore(viewId);

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      <MenuGroup label={title}>
        {map(selected, (p, i) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            iconRight={MinusIcon}
            onClick={withClear(() =>
              mutate([
                asMutation(
                  { field: "sort", type: "json" },
                  filter(view.sort || [], (g) => g.field !== p.field)
                ),
              ])
            )}
          >
            <SpaceBetween>
              {toFieldName(p.def || p)}
              <Button
                size="tiny"
                subtle
                onClick={withHardHandle(() =>
                  mutate([
                    asMutation(
                      { field: "sort", type: "json" },
                      replace(view.sort || [], i, {
                        ...pick(p, "type", "field"),
                        direction: p.direction === "asc" ? "desc" : "asc",
                      })
                    ),
                  ])
                )}
                icon={p.direction === "asc" ? SortAsc : SortDesc}
              />
            </SpaceBetween>
          </MenuItem>
        ))}
        {!selected.length && (
          <MenuItem disabled icon={NumberIcon}>
            <Text subtle>Manual ordering will be used.</Text>
          </MenuItem>
        )}
      </MenuGroup>

      <MenuGroup label="Options">
        <MenuItem
          icon={<CheckIcon checked={empty === "first"} />}
          onClick={() =>
            mutate([
              asMutation(
                { field: "sort", type: "json" },
                map(view.sort, (s) => ({
                  ...s,
                  empty: empty === "first" ? "last" : "first",
                }))
              ),
            ])
          }
        >
          Empty values first
        </MenuItem>
      </MenuGroup>

      <MenuGroup label="Available fields">
        <TextInput
          autoFocus={!selected.length}
          value={search}
          placeholder="Filter..."
          updateOn="change"
          onChange={setSearch}
          className={styles.searchInput}
        />

        {map(available, (p) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            text={toFieldName(p)}
            iconRight={PlusIcon}
            onClick={withClear(
              () =>
                p &&
                mutate([
                  asMutation({ field: "sort", type: "json" }, [
                    ...(view.sort || []),
                    { ...pick(p, "field", "type"), direction: "asc" },
                  ]),
                ])
            )}
          />
        ))}
      </MenuGroup>
    </Menu>
  );
};

const AGG_METHODS = ["sum", "avg", "min", "max"] as const;

export const AggregateOptionsMenu = ({ viewId, title }: Props) => {
  const view = useLazyGetView(viewId);
  const allProperties = useGetPropertiesForView(viewId);
  const mutate = useUpdateView(viewId, true);
  const [search, setSearch] = useState("");
  const getPropDef = useMemo(
    () =>
      !!allProperties?.length
        ? maybeLookup(allProperties, (p) => p.field)
        : () => undefined,
    [allProperties]
  );

  const withClear = useCallback(
    (callback: Fn<void, void>) => () => {
      callback();
      setSearch("");
    },
    []
  );

  const selected = useMemo(
    () =>
      maybeMap(view?.aggregate || [], (p) => ({
        ...p,
        def: getPropDef(p.field),
      })),
    [view?.aggregate, getPropDef]
  );

  const inUse = useMemo(() => maybeMap(selected, (f) => f.field), [selected]);

  const available = useMemo(
    () =>
      composel(
        filter_(
          (p: PropertyDef<Entity>) =>
            !inUse?.includes(p.field) &&
            isVisibleProp(p) &&
            equalsAny(p.type, ["number", "select", "multi_select"]) &&
            (!search || fuzzyMatch(search, toFieldName(p)))
        ),
        sortByUseful
      )(allProperties),
    [view, allProperties, search]
  );

  useEnsureViewInStore(viewId);

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      <MenuGroup label={title}>
        {map(selected, (p, i) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            iconRight={MinusIcon}
            onClick={withClear(() =>
              mutate([
                asMutation(
                  { field: "aggregate", type: "json" },
                  filter(view.aggregate || [], (g) => g.field !== p.field)
                ),
              ])
            )}
          >
            <SpaceBetween>
              {toFieldName(p.def || p)}
              <ButtonGroup>
                {map(AGG_METHODS, (method) => (
                  <SplitButton
                    size="tiny"
                    subtle
                    onClick={withHardHandle(() =>
                      mutate([
                        asMutation(
                          { field: "aggregate", type: "json" },
                          replace(view.aggregate || [], i, {
                            ...pick(p, "type", "field"),
                            method: method,
                          })
                        ),
                      ])
                    )}
                    selected={p.method === method}
                  >
                    {sentenceCase(method)}
                  </SplitButton>
                ))}
              </ButtonGroup>
            </SpaceBetween>
          </MenuItem>
        ))}
        {!selected.length && (
          <MenuItem disabled icon={NumberIcon}>
            <Text subtle>No aggregation setup.</Text>
          </MenuItem>
        )}
      </MenuGroup>

      <MenuGroup label="Available fields">
        <TextInput
          autoFocus={!selected.length}
          value={search}
          placeholder="Filter..."
          updateOn="change"
          onChange={setSearch}
          className={styles.searchInput}
        />

        {map(available, (p) => (
          <MenuItem
            key={p.field}
            icon={<PropertyTypeIcon {...p} />}
            text={toFieldName(p)}
            iconRight={PlusIcon}
            onClick={withClear(
              () =>
                p &&
                mutate([
                  asMutation({ field: "aggregate", type: "json" }, [
                    ...(view.aggregate || []),
                    { ...pick(p, "field", "type"), method: "sum" },
                  ]),
                ])
            )}
          />
        ))}
      </MenuGroup>
    </Menu>
  );
};

export const FilterOptionsMenu = ({
  viewId,
  title,
  showOptions = true,
}: Props & { showOptions?: boolean }) => {
  const view = useLazyGetView(viewId);
  const allProperties = useGetPropertiesForView(viewId);
  const mutate = useUpdateView(viewId, true);
  const toLabel = useEntityLabels(view?.source.scope, {
    case: "lower",
    plural: true,
  });
  const nestedSource = useSourceForView(view);
  const getProp = useMemo(
    () => maybeLookup(allProperties, (p) => p.field),
    [allProperties]
  );
  const topFilter = useMemo(
    () =>
      when(view?.filter, (f) => (isNested(f) ? f : { and: [f] })) || {
        and: [],
      },
    [view?.filter]
  );

  useEnsureViewInStore(viewId);

  const onChange = useCallback(
    (filter: Maybe<FilterQuery>) =>
      mutate([
        asMutation(
          { field: "filter", type: "json" },
          (filter || { and: [] }) as Json
        ),
      ]),
    [view?.filter]
  );

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      {!!topFilter && (
        <MenuGroup label={title}>
          <NestedPropertyFilter
            // editing={openLast && i === filters.length - 1}
            filter={topFilter}
            definition={getProp}
            onChanged={onChange}
            source={nestedSource}
          />
        </MenuGroup>
      )}

      {showOptions && (
        <MenuGroup label="Include work">
          <MenuItem
            icon={<CheckIcon checked={!view?.settings?.hideNested} />}
            onClick={() =>
              mutate([
                asMutation(
                  { field: "settings.hideNested", type: "boolean" },
                  !view?.settings?.hideNested
                ),
              ])
            }
          >
            Anywhere within{" "}
            {toLabel(
              typeFromId<EntityType>(
                view?.for?.id || toParentScope(view?.location)
              ),
              { plural: false }
            )}
          </MenuItem>
          <MenuItem
            icon={<CheckIcon checked={!!view?.settings?.showSubs} />}
            onClick={() =>
              mutate([
                asMutation(
                  { field: "settings.showSubs", type: "boolean" },
                  !view?.settings?.showSubs
                ),
              ])
            }
          >
            Show sub-{toLabel(view.entity)}
          </MenuItem>
        </MenuGroup>
      )}
    </Menu>
  );
};

const LAYOUTS = [
  {
    id: "list",
    name: toLayoutName("list"),
    icon: <ViewIcon layout="list" />,
    format: "list",
  },
  {
    id: "table",
    name: toLayoutName("table"),
    icon: <ViewIcon layout="table" />,
    format: "table",
  },
  {
    id: "card",
    name: toLayoutName("card"),
    icon: <ViewIcon layout="card" />,
    format: "card",
  },
  // { id: "quadrants", name: "Quadrants", icon: LayoutQuadrants },
  { id: "timeline", name: "Timeline", icon: <ViewIcon layout="timeline" /> },
  {
    id: "calendar",
    name: toLayoutName("calendar"),
    icon: <ViewIcon layout="calendar" />,
  },
  {
    id: "canvas",
    name: toLayoutName("canvas"),
    icon: <ViewIcon layout="canvas" />,
  },
  {
    id: "browser",
    name: toLayoutName("browser"),
    // TODO: Need a drive icon
    icon: <ViewIcon layout="list" />,
  },
];

const GROUPINGS = [
  {
    id: "rows",
    name: "Rows",
    icon: <ViewIcon layout="rows" />,
    dimensions: 1,
  },
  {
    id: "columns",
    name: "Columns",
    icon: <ViewIcon layout="columns" />,
    dimensions: 1,
  },
  {
    id: "columns_rows",
    name: "Columns & Rows",
    icon: <ViewIcon layout="columns_rows" />,
    dimensions: 2,
  },
  // {
  //   id: "quadrants",
  //   name: "Quadrants",
  //   icon: <ViewIcon layout="quadrants" />,
  // },
];

const groupingsForLayout = (layout: Maybe<ViewLayout>) =>
  switchEnum(layout || "", {
    card: () => GROUPINGS,
    list: () => filter(GROUPINGS, (g) => ["rows"]?.includes(g.id)),
    // TODO: Table grouping not setup yet
    // table: () => filter(GROUPINGS, (g) => ["rows"]?.includes(g.id)),
    timeline: () => filter(GROUPINGS, (g) => ["rows"]?.includes(g.id)),
    browser: () => filter(GROUPINGS, (g) => ["rows"]?.includes(g.id)),
    else: () => [],
  });

export const iconForLayout = (layout: ViewLayout) =>
  find(LAYOUTS, (l) => l.id === layout)?.icon || LayoutColumns;

type TargetMode = "location" | "entity" | "private" | "workspace";

export const SourceOptionsMenu = ({ viewId, title }: Props) => {
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const space = useActiveSpace();
  const defaultTeam = useDefaultTeam();
  const view = useLazyGetView(viewId);
  const mutate = useUpdateView(viewId, true);
  const toLabel = useEntityLabels(view?.source.scope, { case: "lower" });

  const locationType = useMemo(
    () => when(toParentScope(view?.location), maybeTypeFromId<EntityType>),
    [view?.for, view?.location]
  );
  const targetType = useMemo(
    () => when(view, (v) => when(toDataSource(v), maybeTypeFromId<EntityType>)),
    [view?.for, view?.location]
  );
  const [targetMode, setTargetMode] = useState<TargetMode>(() => {
    if (!view?.for) {
      return "location";
    }

    if (targetType === "person") {
      return "private";
    }

    if (targetType === "workspace") {
      return targetType as TargetMode;
    }

    return "entity";
  });

  const handleChangeFor = useCallback(
    (value: TargetMode) => {
      if (value === "entity") {
        const parent = toParentScope(view?.location);
        mutate([
          asMutation(
            { field: "for", type: "relation" },
            toRef(parent === me.id ? defaultTeam?.id || parent : parent)
          ),
        ]);
      } else if (value === "location") {
        mutate([asMutation({ field: "for", type: "relation" }, undefined)]);
      } else if (value === "private") {
        mutate([asMutation({ field: "for", type: "relation" }, { id: me.id })]);
      } else if (value === "workspace") {
        mutate([asMutation({ field: "for", type: "relation" }, { id: wId })]);
      }
      setTargetMode(value);
    },
    [view?.location, defaultTeam]
  );

  if (!view) {
    return <></>;
  }

  return (
    <VStack gap={20}>
      <Field label="What to show on this board?">
        <EntityTypeSelect
          value={view.entity}
          scope={view.source.scope}
          portal
          onChange={(t) =>
            mutate([
              asMutation({ field: "entity", type: "text" }, t),
              asMutation({ field: "filter", type: "json" }, undefined),
            ])
          }
        />
      </Field>

      {view.entity && (
        <Field label={`From which location?`}>
          <Container padding="left" size="half" fit="container">
            <RadioGroup
              value={targetMode || ""}
              onChanged={(v) => handleChangeFor(v as TargetMode)}
            >
              {locationType !== "person" && (
                <RadioItem
                  value="location"
                  name={`Within this ${
                    when(locationType, toLabel) || "location"
                  }`}
                />
              )}

              <RadioItem value="private" name="Private" />

              <RadioItem value="entity" name="In Location" />

              {targetMode === "entity" && (
                <GlobalEntitySelect
                  value={view.for}
                  portal={true}
                  scope={view.for?.id || wId}
                  allowed="*"
                  exclude={["person"]}
                  createable={false}
                  showNewButton={false}
                  className={{
                    dropdown: styles.control,
                  }}
                  onChange={(t) =>
                    mutate([asMutation({ field: "for", type: "relation" }, t)])
                  }
                >
                  <MenuItem style={{ width: "100%" }} iconRight={AngleDownIcon}>
                    {view.for && !isPersonId(view.for.id) && (
                      <RelationLabel relation={view.for} />
                    )}
                    {(!view?.for || isPersonId(view.for?.id)) && (
                      <Label icon={EyeSlash}>Private</Label>
                    )}
                  </MenuItem>
                </GlobalEntitySelect>
              )}

              <RadioItem value="workspace" name="Everywhere" />
            </RadioGroup>
          </Container>
        </Field>
      )}

      {isLocalID(view.id) && (
        <Field label="Add quick filter">
          <HStack>
            {isAssignable(view.entity) && (
              <QuickFilterButton
                icon={<RelationIcon relation={me} />}
                view={view}
                mutate={mutate}
                filter={{
                  field: "assigned",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: me.id } },
                }}
              >
                Assigned to me
              </QuickFilterButton>
            )}

            {isOwnable(view.entity) && (
              <QuickFilterButton
                icon={<RelationIcon relation={me} />}
                view={view}
                mutate={mutate}
                filter={{
                  field: "owner",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: me.id } },
                }}
              >
                Owned by me
              </QuickFilterButton>
            )}

            {isStatusable(view.entity) && (
              <QuickFilterButton
                icon={<StatusIcon status={{ group: "in-progress" }} />}
                view={view}
                mutate={mutate}
                filter={{
                  field: "status",
                  type: "status",
                  op: "does_not_equal",
                  value: { status: { group: "done" } },
                }}
              >
                Not completed
              </QuickFilterButton>
            )}
          </HStack>
        </Field>
      )}

      {targetMode === "workspace" && isEmptyFilter(view.filter) && (
        <Banner color="yellow" variant="rounded">
          <HStack gap={4}>
            <Text>This can be a lot of data. Suggest adding </Text>
            <Icon className={styles.inline} size="small" icon={FilterAlt} />
            <Text>filters.</Text>
          </HStack>
        </Banner>
      )}
    </VStack>
  );
};
export const LayoutOptionsMenu = ({
  viewId,
  title,
  layouts: allowedLayouts,
  groupings: allowedGroupings,
}: Props & { layouts?: ViewLayout[]; groupings?: ViewGrouping[] }) => {
  const view = useLazyGetView(viewId);
  const mutate = useUpdateView(viewId, true);

  const nestedSource = useNestedSource(view, view?.entity);
  const props = useLazyProperties(nestedSource);
  const layouts = useMemo(
    () =>
      filter(
        LAYOUTS,
        (l) => !allowedLayouts || allowedLayouts.includes(l.id as ViewLayout)
      ),
    [allowedLayouts]
  );

  const onLayoutChange = useCallback(
    (layout: ViewLayout) => () => {
      const changes: PropertyMutation<View>[] = [];

      pushDirty(changes, asMutation({ field: "layout", type: "text" }, layout));

      // Default canvasBy to blockedBy if exists
      if (layout === "canvas") {
        pushDirty(
          changes,
          asMutation(
            { field: "settings.canvasBy", type: "property" },
            find(props, { field: "refs.blockedBy" })
          )
        );
      }

      if (layout === "calendar") {
        pushDirty(
          changes,
          asMutation(
            { field: "settings.calStart", type: "property" },
            find(props, (s) => ["start", "publish"]?.includes(s.field))
          ),
          asMutation(
            { field: "settings.calEnd", type: "property" },
            find(props, (s) => ["end"]?.includes(s.field))
          )
        );
      }

      // Clear out the grouping and groups
      const allowedGroupings = groupingsForLayout(layout);
      if (
        (view?.grouping && !allowedGroupings.length) ||
        layout === "browser"
      ) {
        pushDirty(
          changes,
          asMutation({ field: "grouping", type: "text" }, undefined),
          asMutation({ field: "group", type: "json" }, [])
        );
      }
      // Check the existing view.grouping is still valid
      else if (
        view?.grouping &&
        !some(allowedGroupings, (g) => g.id === view?.grouping)
      ) {
        // Set to first allowed grouping
        pushDirty(
          changes,
          asMutation(
            { field: "grouping", type: "text" },
            allowedGroupings[0]?.id
          )
        );

        // Reduce groupings to correct size
        if (allowedGroupings[0]?.dimensions !== 2) {
          pushDirty(
            changes,
            asMutation({ field: "group", type: "json" }, [
              view?.group?.[0] ?? null,
            ])
          );
        }
      }

      mutate(changes);
    },
    [mutate, props]
  );

  useEnsureViewInStore(viewId);

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      <MenuGroup inset={false} label={title}>
        {map(layouts, (l) => (
          <MenuItem
            key={l.id}
            selected={l.id === view.layout}
            onClick={onLayoutChange(l.id as ViewLayout)}
            icon={<ViewIcon layout={l.id as ViewLayout} />}
          >
            {l.name}
          </MenuItem>
        ))}
      </MenuGroup>

      <GroupOptions view={view} mutate={mutate} groupings={allowedGroupings} />

      {view.layout === "canvas" && (
        <CanvasOptions view={view} mutate={mutate} />
      )}

      {view.layout === "calendar" && (
        <CalendarOptions view={view} mutate={mutate} />
      )}

      {(!!view?.group?.length || view.layout === "calendar") && (
        <MenuGroup
          label={view.layout === "calendar" ? "No Date" : "Empty Section"}
        >
          <CheckMenuItem
            checked={view?.settings?.triageEmpty ?? false}
            onChecked={() =>
              mutate([
                asMutation(
                  { field: "settings.triageEmpty", type: "boolean" },
                  !view?.settings?.triageEmpty
                ),
              ])
            }
          >
            Show as triage pane
          </CheckMenuItem>
        </MenuGroup>
      )}
    </Menu>
  );
};

export const GroupOptionsMenu = ({ viewId, title }: Props) => {
  const view = useLazyGetView(viewId);
  const mutate = useUpdateView(viewId, true);

  if (!view) {
    return <></>;
  }

  return (
    <Menu>
      <GroupOptions view={view} mutate={mutate} title={title} />
    </Menu>
  );
};

type OptionsProps = {
  view: View;
  mutate: Fn<PropertyMutation<View>[], void>;
};

const CanvasOptions = ({ view, mutate }: OptionsProps) => {
  const nestedSource = useNestedSource(view, view.entity);
  const props = useLazyProperties(nestedSource);
  // Only allow self-referencing relations
  const allowed = useMemo(
    () =>
      filter(
        props,
        (p) => isAnyRelation(p) && p.options?.references === view.entity
      ),
    [props]
  );
  const canvasBy = useMemo(
    () =>
      when(
        toPropertyValueRef(view, {
          field: "settings.canvasBy",
          type: "property",
        })?.value?.property?.field,
        (field) => find(props, (p) => p.field === field)
      ),

    [props, view.settings]
  );

  return (
    <MenuGroup label="Options">
      <PropertySelect
        placeholder={`Using field...`}
        clearable={false}
        portal={true}
        subtitle="Connect using"
        options={allowed}
        value={canvasBy}
        className={styles.propertySelect}
        onChanged={(ref) =>
          ref &&
          mutate([
            asMutation({ field: "settings.canvasBy", type: "property" }, ref),
          ])
        }
      />
    </MenuGroup>
  );
};

const GroupOptions = ({
  view,
  mutate,
  title,
  groupings: allowed,
}: OptionsProps & { title?: string; groupings?: ViewGrouping[] }) => {
  const availableGroupings = useMemo(
    () =>
      filter(
        groupingsForLayout(view?.layout),
        (g) => !allowed || allowed.includes(g.id as ViewGrouping)
      ),
    [view?.layout]
  );

  const onGroupingChange = useCallback(
    (grouping: Maybe<ViewGrouping>) => () => {
      const changes: PropertyMutation<View>[] = [
        asMutation({ field: "grouping", type: "text" }, grouping),
      ];

      // Clear group fields if no grouping
      if (!grouping) {
        pushDirty(changes, asMutation({ field: "group", type: "json" }, []));
      }
      // If not a 2 dimensional grouping then reduce to 1 item
      else if (grouping !== "columns_rows" && (view?.group?.length || 0) > 1) {
        pushDirty(
          changes,
          asMutation(
            { field: "group", type: "json" },
            take(omitEmpty(view?.group || []), 1)
          )
        );
      } else if (
        !!grouping &&
        !view?.group?.length &&
        isStatusable(view.entity)
      ) {
        // Add a default grouping
        pushDirty(
          changes,
          asMutation({ field: "group", type: "json" }, [
            { field: "status", type: "status" },
          ])
        );
      }

      mutate(changes);
    },
    [mutate]
  );

  if (!availableGroupings.length) {
    return <></>;
  }

  return (
    <>
      <MenuGroup label={title ?? "Sections"}>
        <MenuItem
          selected={!view.grouping}
          icon={<></>}
          onClick={onGroupingChange(undefined)}
        >
          No sections
        </MenuItem>

        {map(availableGroupings, (p) => {
          const isSelected = p.id === view.grouping;

          return (
            <>
              <MenuItem
                key={p.id}
                selected={isSelected}
                onClick={onGroupingChange(p.id as ViewGrouping)}
                iconRight={
                  p.id === "columns_rows" &&
                  !!view?.group?.[0] &&
                  !!view?.group?.[1] && (
                    <Button
                      size="tiny"
                      subtle
                      icon={Sync}
                      onClick={withHardHandle(() =>
                        mutate([
                          asMutation({ field: "group", type: "json" }, [
                            view?.group?.[1],
                            view?.group?.[0],
                          ] as JsonArray),
                        ])
                      )}
                    />
                  )
                }
              >
                <Label
                  icon={
                    <Icon
                      className={cx(isSelected && styles.selectedLabel)}
                      icon={p.icon}
                    />
                  }
                  className={cx(isSelected && styles.selectedLabel)}
                >
                  {p.name}
                </Label>
              </MenuItem>
            </>
          );
        })}
      </MenuGroup>

      <GroupByOptions view={view} mutate={mutate} />
    </>
  );
};

const CalendarOptions = ({ view, mutate }: OptionsProps) => {
  const nestedSource = useNestedSource(view, view.entity);
  const props = useLazyProperties(nestedSource);
  // Only allow self-referencing relations
  const allowed = useMemo(
    () => filter(props, (p) => p.type === "date" && p.visibility !== "hidden"),
    [props]
  );
  const [start, end] = useMemo(
    () => [
      when(
        toPropertyValueRef(view, {
          field: "settings.calStart",
          type: "property",
        })?.value?.property?.field,
        (field) => find(props, (p) => p.field === field)
      ),
      when(
        toPropertyValueRef(view, {
          field: "settings.calEnd",
          type: "property",
        })?.value?.property?.field,
        (field) => find(props, (p) => p.field === field)
      ),
    ],

    [props, view.settings]
  );

  return (
    <MenuGroup label="Options">
      <CheckMenuItem
        checked={view?.settings?.weekends ?? false}
        onChecked={() =>
          mutate([
            asMutation(
              { field: "settings.weekends", type: "boolean" },
              !view?.settings?.weekends
            ),
          ])
        }
      >
        Show weekends
      </CheckMenuItem>

      <PropertySelect
        placeholder={`Start date field...`}
        clearable={false}
        portal={true}
        subtitle="Start Date"
        options={allowed}
        value={start}
        className={styles.propertySelect}
        onChanged={(ref) =>
          mutate([
            asMutation({ field: "settings.calStart", type: "property" }, ref),
          ])
        }
      />
      <PropertySelect
        placeholder={`End date field...`}
        clearable={false}
        portal={true}
        subtitle="End Date"
        options={allowed}
        value={end}
        className={styles.propertySelect}
        onChanged={(ref) =>
          mutate([
            asMutation({ field: "settings.calEnd", type: "property" }, ref),
          ])
        }
      />
    </MenuGroup>
  );
};

const GroupByOptions = ({ view, mutate }: OptionsProps) => {
  const itemSource = useEntitySource(view?.entity || "task", view?.source);
  const props = useLazyProperties(itemSource);

  const group1 = view?.group?.[0];
  const group2 = view?.group?.[1];

  const onChangeGrouping = useCallback(
    (def: Maybe<GroupByProp<Entity>>, position: number) => {
      const groups = [...(view?.group || [])] as JsonArray;
      groups[position] = def ?? null;
      mutate([asMutation({ field: "group", type: "json" }, groups)]);
    },
    [view]
  );

  const available = useMemo(
    () =>
      composel(
        filter_(
          (p: PropertyDef<Entity>) =>
            // Not allowed grouping properties
            !equalsAny(p.type, ["json", "properties", "property"]) ||
            p.field === "location"
        ),
        (ts) =>
          orderBy(
            ts,
            // Preferred group by field types, sorts right to left here
            (p) =>
              `${[
                "relations",
                "relation",
                "status",
                "select",
                "multi_select",
              ].indexOf(p.type)}.${p.order}`,
            "desc"
          )
      )(props),
    [view, props]
  );

  return (
    <>
      {(view.grouping === "columns" || view.grouping === "columns_rows") && (
        <GroupingOptions
          view={view}
          group={group1}
          label={view.grouping === "columns" ? "Section By" : "Columns"}
          axis="column"
          props={available}
          onChange={(g) => onChangeGrouping(g, 0)}
        />
      )}

      {(view.grouping === "rows" || view.grouping === "columns_rows") && (
        <GroupingOptions
          view={view}
          group={view.grouping === "columns_rows" ? group2 : group1}
          label={view.grouping === "rows" ? "Section By" : "Rows"}
          axis="row"
          props={available}
          onChange={(g) =>
            onChangeGrouping(g, view.grouping === "columns_rows" ? 1 : 0)
          }
        />
      )}
    </>
  );
};

const ButtonOption = ({
  selected,
  onClick,
  children,
  ...props
}: {
  selected: boolean;
} & ButtonProps) => (
  <Button
    // subtle
    size="small"
    variant={selected ? "primary" : "secondary"}
    onClick={onClick}
    {...props}
  >
    {children}
  </Button>
);

function GroupingOptions({
  view,
  group,
  axis,
  label,
  onChange,
  props,
}: {
  view: View;
  axis: "row" | "column";
  label?: string;
  group: Nullable<GroupByProp<Entity>>;
  props: PropertyDef<Entity>[];
  onChange: Fn<Maybe<GroupByProp<Entity>>, void>;
}) {
  const nestedSource = useNestedSource(view, view.entity);
  const def = useMemo(
    () => find(props, (p) => p.field === group?.field),
    [props, group]
  );
  const availableFormats = useMemo(
    () =>
      when(group, (g) =>
        map(formatsForType(g), (f) => ({
          id: f,
          label: toPropertyFormatLabel(f),
        }))
      ),
    [group]
  );

  return (
    <MenuGroup label={label || `${sentenceCase(axis)} Sections`}>
      <ScopedPropertySelect
        type={nestedSource.type}
        scope={nestedSource.scope}
        subtitle={`${sentenceCase(axis)} by`}
        placeholder={`Pick field to section by...`}
        blacklist={(p) => equalsAny(p.type, ["json", "properties", "property"])}
        portal={true}
        value={def}
        icon={
          def ? (
            <PropertyTypeIcon type={def.type} field={def.field} />
          ) : (
            LayoutRows
          )
        }
        className={styles.propertySelect}
        onChanged={(ref) =>
          ref &&
          onChange({
            field: ref.field,
            type: ref.type,
            format: group ? defaultFormatForType(ref) : undefined,
          })
        }
      />

      {!!availableFormats?.length && (
        <HStack gap={0}>
          <Divider className={styles.padded} direction="vertical" />
          <ButtonGroup>
            {map(availableFormats, (f) => (
              <SplitButton
                key={f.id}
                size="tiny"
                fit="container"
                selected={group?.format === f.id}
                onClick={() => group && onChange({ ...group, format: f.id })}
              >
                {f.label}
              </SplitButton>
            ))}
          </ButtonGroup>
        </HStack>
      )}

      {!!group && (
        <MenuItem
          icon={<CheckIcon checked={!!group?.hideEmpty} />}
          onClick={() =>
            group && onChange({ ...group, hideEmpty: !group?.hideEmpty })
          }
        >
          Hide empty sections
        </MenuItem>
      )}

      {!!group &&
        view.layout === "list" &&
        view.grouping === "rows" &&
        axis === "row" && (
          <MenuItem
            icon={<CheckIcon checked={!!group?.collapse} />}
            onClick={() =>
              group && onChange({ ...group, collapse: !group?.collapse })
            }
          >
            Collapse sections
          </MenuItem>
        )}
    </MenuGroup>
  );
}

const QuickFilterButton = ({
  filter,
  view,
  mutate,
  ...props
}: {
  view: View;
  filter: FilterQuery;
  mutate: Fn<PropertyMutation[], void>;
} & ButtonProps) => {
  const isAdded = useMemo(() => {
    if (!view.filter) {
      return false;
    }

    return some(
      isAnd(view.filter) ? view.filter.and : [view.filter],
      (f) =>
        safeAs<PropertyFilter>(f)?.field ===
        safeAs<PropertyFilter>(filter)?.field
    );
  }, [view.filter]);

  const handleAdd = useCallback(() => {
    if (isAdded) {
      return;
    }

    mutate([
      asMutation(
        { field: "filter", type: "json" },
        append(view.filter, filter) as Json
      ),
    ]);
  }, [isAdded, view.filter]);

  return (
    <Button
      disabled={isAdded}
      size="small"
      onClick={handleAdd}
      {...props}
      icon={isAdded ? Check : props.icon || PlusIcon}
    />
  );
};
