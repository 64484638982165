import { Outcome } from "@api";

import { useIsShowing, useLazyPropertyValue } from "@state/properties";

import { cx } from "@utils/class-names";
import { asMutation } from "@utils/property-mutations";
import { isEmptyRef } from "@utils/property-refs";
import { useSlowSelected } from "@utils/selectable";

import { DueDate } from "@ui/due-date";
import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { SelectableListCard } from "@ui/selectable-items";

import { ListCardOpts } from "../types";

import styles from "./list-card.module.css";

export const OutcomeListCard = (props: ListCardOpts<Outcome>) => {
  const { item, showProps, selection, setSelection, className, onChange } =
    props;
  const editable = useSlowSelected(item.id, selection);
  const isShowing = useIsShowing(showProps);
  const statusProp = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const assignedProp = useLazyPropertyValue(item, {
    field: "assigned",
    type: "relation",
  });

  return (
    <EntityContextMenu
      entity={item}
      selection={selection}
      setSelection={setSelection}
    >
      <SelectableListCard {...props} className={cx(styles.card, className)}>
        <SpaceBetween className={styles.upper}>
          <HStack gap={0}>
            {item.end && !isShowing("end") && (
              <DueDate
                date={item.end}
                status={statusProp.value.status?.group}
                onClick={() => props.onOpen?.(item)}
              />
            )}

            {statusProp && !isShowing("status") && (
              <PropertyValue
                source={item.source}
                parent={item}
                valueRef={statusProp}
                onChange={(e) => onChange?.(asMutation(statusProp, e.status))}
                variant={"icon-only"}
              />
            )}

            {assignedProp &&
              !isEmptyRef(assignedProp) &&
              !isShowing("assigned") && (
                <PropertyValue
                  source={item.source}
                  valueRef={assignedProp}
                  variant="icon-only"
                  onChange={(e) =>
                    onChange?.(asMutation(assignedProp, e.relation))
                  }
                />
              )}
          </HStack>
        </SpaceBetween>

        <VStack
          className={styles.middle}
          gap={0}
          fit="container"
          align="stretch"
        >
          <EditableText
            key={item.id}
            className={styles.title}
            text={item.title || ""}
            placeholder="Untitled"
            disabled={!editable}
            blurOnEnter={true}
            onChange={(v) =>
              onChange?.(asMutation({ field: "title", type: "text" }, v))
            }
          />

          <PropertyValueStack
            wrap
            item={item}
            editable={editable}
            props={showProps}
            onChange={onChange}
            hideEmpty={props.hideEmpty}
          />
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  );
};
