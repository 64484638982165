import { atomFamily } from "recoil";

import { Deal, ID } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type DealStoreState = StoreState<Deal>;

export const WorkspaceDealStoreAtom = atomFamily<DealStoreState, ID>({
  key: "WorkspaceDealStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "deal" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "deal")],
});

export const DealStoreAtom = WorkspaceWrappedAtom(
  "DealStoreAtom",
  WorkspaceDealStoreAtom
);
