import {
  filter,
  find,
  findLast,
  first,
  flatMap,
  last,
  map,
  reduce,
  some,
} from "lodash";

import {
  Campaign,
  CreateOrUpdate,
  Entity,
  EntityType,
  HasAssigned,
  hasAssigned,
  hasDates,
  hasFollowers,
  hasLocation,
  HasNotes,
  hasNotes,
  HasOwner,
  hasOwner,
  hasOwnerOrAssigned,
  HasRefs,
  hasRefs,
  HasStatus,
  hasStatus,
  HasTemplate,
  hasTimestamps,
  NoteType,
  Project,
  Sprint,
  Update,
} from "@api";

import { findAvailableStatuses } from "@state/properties";
import { getItem, isCreateOrUpdate, StoreState } from "@state/store";
import {
  WorkflowAction,
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowSuggestion,
} from "@state/workflows/types";

import { ensureMany, omitEmpty, pushDirty } from "@utils/array";
import { explode, isWorthy } from "@utils/confetti";
import { now } from "@utils/date-fp";
import { log } from "@utils/debug";
import { fallback, use } from "@utils/fn";
import { isLocalID, isPersonId, maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, ifDo } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import {
  getPropertyValue,
  inflateStatus,
  isAnyRelation,
  isField,
  isParent,
  isTimestamp,
  toPropertyValueRef,
  toPropertyValueRefs,
  toRef,
} from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { fromScope, toScope } from "@utils/scope";
import { toStamp } from "@utils/stamp";
import { isCompleteStatus } from "@utils/status";

import { ArchiveDialog } from "@ui/archive-dialog";
import { Archive, EmojiIcon, StatusNext } from "@ui/icon";
import { LocationDialog } from "@ui/location-dialog";
import { NoteCreateDialog } from "@ui/note-create-dialog";
import { PublishDialog } from "@ui/publish-dialog";

// Mark parent task as in progress when child task completed
export const startAllNestedWork: WorkflowSuggestion<Entity> = {
  id: "startAllNestedWork",
  trigger: "SUGGEST",
  type: "*",
  allowed: ({ entity, update }, { stores, props }) =>
    isCreateOrUpdate(update) &&
    hasStatus(entity) &&
    entity.source.type !== "schedule" &&
    // Ignore changes in a transaction (batch update)
    !update.transaction &&
    !entity.template &&
    some(
      update.changes,
      (c) =>
        c.field === "status" &&
        c.prev &&
        inflateStatus(c.prev.status, props)?.group === "planning" &&
        inflateStatus(c.value?.status, props)?.group !== "planning"
    ) &&
    some(
      toPropertyValueRefs(entity, props),
      (vr) =>
        isAnyRelation(vr) &&
        vr.def?.options?.hierarchy === "child" &&
        !!vr.value.relations?.length
    ),
  suggestion: {
    id: "start-nested",
    text: "Do you want to publish all nested work?",
    description:
      "There is draft work nested under this item that won't be visible to people.",
    options: [
      { title: "Dismiss", id: "dismiss" },
      { title: "Publish", id: "update" },
    ],
  },

  collect: ({ data: { entity }, onCollected, onCancelled, context }) => {
    return (
      <PublishDialog
        targets={[entity]}
        onComplete={() => onCollected([])}
        onCancel={onCancelled}
      />
    );
  },

  execute: ({ entity }, { stores, props }) => {
    // Updated in dialog above
    return [];
  },
};

// Warn people that they have created work for someone but that person doesn't have access
export const privateWorkAssignedToSomeoneElse: WorkflowSuggestion<Entity> = {
  id: "privateWorkAssignedToSomeoneElse",
  trigger: "SUGGEST",
  type: "*",
  allowed: ({ entity, update }, { stores, props, session }) =>
    isCreateOrUpdate(update) &&
    hasOwnerOrAssigned(entity) &&
    !(entity as HasTemplate)?.template &&
    // Is private
    (
      find(update.changes, (c) => c.field === "location")?.value.text ||
      entity.location
    )?.includes(session.user.id) &&
    use(
      // Assigned/owner is set to someone else
      (
        find(
          update.changes,
          (c) => c.field === "assigned" || c.field === "owner"
        )?.value?.relation ||
        (entity as HasAssigned).assigned ||
        (entity as HasOwner).owner
      )?.id,
      (assigned) => !!assigned && assigned !== session.user.id
    ),

  suggestion: {
    id: "private-assigned-work",
    text: "This work will not be visible to the assigned person!",
    description: "Private work is only visible to yourself.",
    options: [
      { title: "Ignore", id: "dismiss" },
      { title: "Change location", id: "move" },
    ],
  },

  collect: ({
    data: { entity, update },
    onCollected,
    onCancelled,
    context,
  }) => {
    return (
      <LocationDialog
        targets={[entity]}
        onComplete={() => onCollected([])}
        onCancel={onCancelled}
      />
    );
  },

  execute: ({ entity }, { stores, props }) => {
    // Updated in dialog above
    return [];
  },
};

const updateParentFieldsOnLocationChange: WorkflowDefinition<Entity> = {
  id: "updateParentFieldsOnLocationChange",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }, { props }) =>
    // When changing location
    isCreateOrUpdate(update) &&
    hasLocation(entity) &&
    some(update.changes, (c) => c.field === "location"),

  execute: ({ entity, update }, { props, stores }) => {
    // Find any parent props that are referencing the type from the new location
    // and set them to be the value of the new parent
    const newLocation = find(
      (update as CreateOrUpdate<Entity>).changes,
      (c) => c.field === "location"
    )?.value?.text;
    const parentId = last(fromScope(newLocation));
    const typeFromNewLocation = when(parentId, maybeTypeFromId);
    const parentProps = filter(
      props,
      (p) =>
        isAnyRelation(p) &&
        p.options?.hierarchy === "parent" &&
        p.options?.references === typeFromNewLocation
    );

    if (!parentProps.length) {
      return [];
    }

    const changes = maybeMap(parentProps, (p) => {
      const currentVal = toPropertyValueRef(entity, p);
      // If the value is already set to the new parent, don't change it
      if (
        (p.type === "relation" &&
          currentVal?.value?.relation?.id === parentId) ||
        (p.type === "relations" &&
          some(currentVal?.value?.relations, { id: parentId }))
      ) {
        return undefined;
      }

      return asMutation(
        p,
        p.type === "relations" ? [{ id: parentId }] : { id: parentId }
      );
    });

    return changes?.length ? [asUpdate(entity, changes)] : [];
  },
};

const removeSyncedOnDelete: WorkflowDefinition<Entity> = {
  id: "removeSyncedOnDelete",
  trigger: "WILL_UPDATE",
  type: "*",

  allowed: ({ entity, update }, { props }) => update.method === "delete",

  execute: ({ entity, update }, context) =>
    reduce(
      context.props,
      // Go through every realtion prop that has a sync option
      (acc, p) => {
        const sync = p.options?.sync;

        if (!isAnyRelation(p) || !sync) {
          return acc;
        }

        // Get the relatied values
        const val = getPropertyValue<Entity>(entity, p);

        // Slighly more performant for reduces
        pushDirty(
          acc,
          // For each relation, find the related entity and remove the back reference
          ...maybeMap(val.relations || ensureMany(val.relation), (r) => {
            const related = context.getItem(r.id);

            if (!related) {
              log("Related entity was not loaded to remove back reference.", {
                entity: entity.source.type,
                field: p.field,
                backField: sync,
              });
            }

            return asUpdate<Entity>(
              {
                id: related?.id || r.id,
                source: {
                  type: typeFromId<EntityType>(r.id),
                  // If we don't know the correct source, use the sour
                  scope: related?.source.scope || entity.source.scope,
                },
              } as Pick<Entity, "id" | "source">,
              [
                // API allows remove op without knowing whether it's a relation | relations
                asAppendMutation(
                  { field: sync, type: "relation" },
                  toRef(entity.id),
                  "remove"
                ),
              ]
            );
          })
        );

        return acc;
      },
      [] as Update[]
    ),
};

const addStatusTimestamps: WorkflowDefinition<Entity> = {
  id: "addStatusTimestamps",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }) =>
    isCreateOrUpdate(update) &&
    hasTimestamps(entity) &&
    hasStatus(entity) &&
    some(update.changes, (c) => equalsAny(c.type, ["status"])),

  execute: ({ entity, update }, { props, session }) => {
    const statusChange = findLast(
      safeAs<CreateOrUpdate<Entity>>(update)?.changes,
      { field: "status" }
    );

    return when(inflateStatus(statusChange?.value?.status, props), (status) => [
      asUpdate(entity, [
        asMutation(
          { field: "stamps.status", type: "stamp" },
          toStamp(session.user)
        ),
        asMutation(
          { field: `stamps.status_${status.group}`, type: "stamp" },
          toStamp(session.user)
        ),
      ]),
    ]);
  },
};

const addMessagedTimestamps: WorkflowDefinition<Entity> = {
  id: "addMessagedTimestamps",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }) =>
    isCreateOrUpdate(update) &&
    hasTimestamps(entity) &&
    some(update.changes, (c) => c.field === "refs.notes"),

  execute: ({ entity, update }, context) => {
    return asUpdate(entity, [
      asMutation(
        { field: "stamps.notes", type: "stamp" },
        toStamp(context.session.user)
      ),
    ]);
  },
};

const addResourceTimestamps: WorkflowDefinition<Entity> = {
  id: "addResourceTimestamps",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }) =>
    isCreateOrUpdate(update) &&
    hasTimestamps(entity) &&
    some(update.changes, (c) => c.field === "refs.resources"),

  execute: ({ entity, update }, context) => {
    return asUpdate(entity, [
      asMutation(
        { field: "stamps.resources", type: "stamp" },
        toStamp(context.session.user)
      ),
    ]);
  },
};

const addAssignedTimestamp: WorkflowDefinition<Entity> = {
  id: "addAssignedTimestamp",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }) =>
    isCreateOrUpdate(update) &&
    hasTimestamps(entity) &&
    (hasOwner(entity) || hasAssigned(entity)) &&
    some(update.changes, (c) => equalsAny(c.field, ["owner", "assigned"])),

  execute: ({ entity, update }, context) => {
    return asUpdate(entity, [
      asMutation(
        { field: "stamps.assigned", type: "stamp" },
        toStamp(context.session.user)
      ),
    ]);
  },
};

// Keep the workflow step owner in sycn with the created work owner
const syncWorkflowStepOwner: WorkflowDefinition<Entity> = {
  id: "syncWorkflowStepOwner",
  trigger: "DID_UPDATE",
  type: "*",
  allowed: ({ entity, update }, { props }) =>
    // When changing owner/assigned and has a workflow step
    isCreateOrUpdate(update) &&
    some(update.changes, (c) => equalsAny(c.field, ["owner", "assigned"])) &&
    hasRefs(entity) &&
    !!entity.refs?.fromStep?.length,

  execute: async ({ entity, update }, { props, stores }) => {
    const person = find(safeAs<CreateOrUpdate>(update)?.changes, (c) =>
      equalsAny(c.field, ["owner", "assigned"])
    )?.value?.relation;
    const stepId = first(safeAs<HasRefs>(entity)?.refs.fromStep)?.id;
    const step = stepId && getItem(stores.workflow_step, stepId);

    return step && person
      ? [
          asUpdate(
            step,
            asMutation({ field: "owner", type: "relation" }, person)
          ),
        ]
      : [];
  },
};

// Whenever a step is updated, mark the workflow as dirty
export const markWorkflowAsDirtyWatchers: WorkflowDefinition<Entity> = {
  id: "markWorkflowAsDirtyWatchers",
  trigger: "WILL_UPDATE",
  type: "*",

  // When any work with refs.markDirty is updated
  allowed: ({ entity, update }, context) =>
    !safeAs<HasTemplate>(entity)?.template &&
    isCreateOrUpdate(update) &&
    !!safeAs<HasRefs>(entity)?.refs?.markDirty?.length &&
    !some(update.changes, (c) => c.field === "stamps.dirty"),

  // Mark the workflow as dirty
  execute: ({ entity }, context) =>
    map(safeAs<HasRefs>(entity)?.refs.markDirty, (workflowRef) =>
      asUpdate<Entity>(
        {
          id: workflowRef?.id,
          source: { type: "workflow", scope: entity.source.scope },
        },
        asMutation(
          { field: "stamps.dirty", type: "stamp" },
          toStamp(context.session.user)
        )
      )
    ),
};

// Mark workflow step as finished when task is completed
export const autoCompleteWorkflowStep: WorkflowDefinition<Entity> = {
  id: "autoCompleteWorkflowStep",
  trigger: "DID_UPDATE",
  type: "*",
  // Updating status and has some workflow steps linked
  allowed: ({ update, entity }) =>
    update.method === "update" &&
    !!safeAs<HasRefs>(entity)?.refs?.fromStep?.length &&
    some(update.changes, { field: "status" }),

  execute: async ({ entity, update }, context) => {
    const newStatus = find(safeAs<CreateOrUpdate>(update)?.changes, {
      field: "status",
    })?.value.status;
    const status = inflateStatus(newStatus, context.props);
    const steps = safeAs<HasRefs>(entity)?.refs.fromStep;

    if (!status || status.group !== "done" || !steps?.length) {
      return;
    }

    // Mark all linked steps as finished
    return maybeMap(
      steps,
      (s) =>
        s &&
        asUpdate(
          {
            id: s.id,
            source: { type: "workflow_step", scope: entity.source.scope },
          },
          asMutation({ field: "status", type: "status" }, { id: "FNS" })
        )
    );
  },
};

export const updateLocationOnFieldChange: WorkflowSuggestion<Entity> = {
  id: "updateLocationOnFieldChange",
  trigger: "SUGGEST",
  type: "*",
  allowed: ({ entity, update }, { props }) =>
    !equalsAny(entity.source?.type, ["view", "action", "agenda"]) &&
    // Don't run this on creates as otherwise when using templates you get a bunch of messages each time you use it...
    update.method === "update" &&
    hasLocation(entity) &&
    !isLocalID(update.id) &&
    // Don't show for templates
    !(entity as Maybe<HasTemplate>)?.template &&
    // The field being changed is the only field (not part of a batch update)
    !update.transaction &&
    update.changes.length === 1 &&
    // Some dynamic condition when we re-eval
    some(update.changes, (c) => {
      const prop = find(props, (p) => c.field === p.field);

      return (
        // Found the prop for this change
        prop &&
        // It's a parent hierarchy relation
        ["relation", "relations"]?.includes(prop.type) &&
        prop.options?.hierarchy === "parent" &&
        // One of the following:
        // When clearing the value
        (c.op === "clear" ||
          // Removing a relation field and the old value is in the current location
          (c.op === "remove" &&
            entity.location?.includes(c.value?.relation?.id || "false")) ||
          // Setting a relation field and the new value is empty or not in the current location
          ((c.op || "set") === "set" &&
            (!c.value?.relation?.id ||
              !entity.location?.includes(c.value.relation.id))) ||
          // Setting a relation field and the current location is just a team/user
          (["set", "add"].includes(c.op || "set") &&
            fromScope(entity.location).length === 1))
      );
    }),

  suggestion: {
    id: "move-location",
    text: "Do you want to move where this work is located?",
    description: "The property you just changed is a parent to this work.",
    options: [
      { title: "Keep current location", id: "dismiss" },
      { title: "Move work", id: "move" },
    ],
  },
  collect: ({
    data: { entity, update },
    onCollected,
    onCancelled,
    context,
  }) => {
    const { props, stores } = context;
    const changes = (update as CreateOrUpdate<Entity>)?.changes || [];

    // Parent relation fields for this entity
    const organisingFields = filter(props, isParent);

    const newParentId =
      // Look through changes with all parent fields to find the newly set value
      reduce(
        organisingFields,
        (loc, f) =>
          loc ||
          when(
            find(changes, (c) => c.field === f.field),
            (field) =>
              field?.value?.relation?.id || first(field?.value?.relations)?.id
          ),
        undefined as Maybe<string>
      ) ||
      // Fallback to existing set values on the entity that are not the fields being set
      reduce(
        organisingFields,
        (loc, f) =>
          loc ||
          ifDo(
            !some(changes, (c) => c.field === f.field),
            when(
              toPropertyValueRef(entity, f),
              (field) =>
                field?.value?.relation?.id || first(field?.value?.relations)?.id
            )
          ),
        undefined as Maybe<string>
      );

    // Get the new parent and build a new location
    const newParent = when(newParentId, (id) =>
      getItem(
        stores[
          typeFromId<Exclude<EntityType, "workspace">>(id)
        ] as StoreState<Entity>,
        id
      )
    );

    // Finally we have a new suggested location to move the work
    const newLocation = when(
      newParent,
      (p) =>
        when((p as { location?: string }).location, (loc) =>
          toScope(loc, p.id)
        ) || p.id
    );

    return (
      <LocationDialog
        suggested={newLocation}
        targets={[entity]}
        onComplete={() => onCollected([])}
        onCancel={onCancelled}
      />
    );
  },

  execute: ({ entity }, { props, stores }) => {
    // Mutations done by the dialog
    return [];
  },
};

// Mark task as started
export const markAsStarted: WorkflowDefinition<Entity> = {
  id: "markAsStarted",
  trigger: "ACTION",
  type: ["task", "outcome"],
  icon: StatusNext,
  title: "Mark In Progress",

  allowed: ({ entity }, context) =>
    // Is not started status group
    hasStatus(entity) &&
    entity?.status?.group === "not-started" &&
    // Is assigned to me
    ((entity as Maybe<HasAssigned>)?.assigned?.id ||
      (entity as Maybe<HasOwner>)?.owner?.id) === context.session.user.id,

  execute: ({ entity }, { props }) => {
    const statuses = findAvailableStatuses(props, entity.source);
    const inProgress = find(statuses, (s) => s.group === "in-progress");

    return [
      asUpdate(
        entity,
        omitEmpty([
          asMutation({ field: "status", type: "status" }, inProgress),

          ifDo(hasDates(entity) && !!entity.start, () =>
            asMutation({ field: "start", type: "date" }, now())
          ),
        ])
      ),
    ];
  },
};

// Mark task as to do
export const markAsTodo: WorkflowDefinition<Entity> = {
  id: "markAsTodo",
  trigger: "ACTION",
  type: ["task", "outcome"],
  icon: StatusNext,
  title: "Mark Ready to Start",

  allowed: ({ entity }, _context) =>
    hasStatus(entity) && entity?.status?.group === "planning",

  execute: ({ entity }, { props }) => {
    const statuses = findAvailableStatuses(props, entity.source);
    const toDo = find(statuses, (s) => s.group === "not-started");

    return [
      asUpdate(entity, [asMutation({ field: "status", type: "status" }, toDo)]),
    ];
  },
};

// Complete task
export const markAsComplete: WorkflowDefinition<Entity> = {
  id: "markAsComplete",
  trigger: "ACTION",
  type: ["task", "outcome"],
  icon: StatusNext,
  title: "Mark Completed",

  allowed: ({ entity }, _context) =>
    hasStatus(entity) && entity?.status?.group === "in-progress",

  execute: ({ entity: task }, { props }) => {
    const statuses = findAvailableStatuses(props, task.source);
    const done = find(statuses, (s) => s.group === "done" || s.name === "Done");

    // Called here manually since workflow actions don't run from other actions
    explode();

    return [
      asUpdate(
        task,
        omitEmpty([asMutation({ field: "status", type: "status" }, done)])
      ),
    ];
  },
};

export const postUpdate: WorkflowDefinition<Entity> = {
  id: "postUpdate",
  trigger: "ACTION",
  type: ["task", "outcome", "content", "campaign", "project"],
  icon: <EmojiIcon emoji="📣" />,
  title: "Post an update",

  allowed: ({ entity }, _context) =>
    hasNotes(entity) &&
    (entity as Maybe<HasStatus>)?.status?.group !== "not-started",
  collect: ({ data: { entity }, onCollected, onCancelled }) => (
    <NoteCreateDialog
      entity={entity as HasNotes}
      type={NoteType.Update}
      // channel={when(settings, (s) => getSetting<string>("channel", s))}
      status={(entity as Maybe<HasStatus>)?.status}
      onCancel={onCancelled}
      onSaved={(note) => {
        // Note is attatched to task in the status update dialog
        onCollected?.([]);
      }}
    />
  ),
  execute: ({ entity: task, collected }, {}) =>
    when(task, (t) => [
      asUpdate(
        t,
        map(collected, (c) => asMutation(c, c.value[c.type]))
      ),
    ]),
};

export const archiveCompleted: WorkflowDefinition<Project | Sprint | Campaign> =
  {
    id: "archiveCompleted",
    trigger: "ACTION",
    type: ["project", "sprint", "campaign"],
    icon: Archive,
    title: "Archive",

    allowed: ({ entity }) =>
      !entity.template &&
      !entity.archivedAt &&
      hasStatus(entity) &&
      !!entity?.status &&
      isCompleteStatus(entity?.status),

    collect: ({ data: { entity }, onCollected, onCancelled, context }) => {
      return (
        <ArchiveDialog
          targets={[entity]}
          onCancel={onCancelled}
          onComplete={() => {
            onCollected?.([]);
          }}
        />
      );
    },

    execute: ({ entity }, { props }) => {
      return [];
    },
  };

export const autoAssignPrivateWork: WorkflowDefinition<Entity> = {
  id: "autoAssignPrivateWork",
  trigger: "WILL_UPDATE",
  type: "*",

  allowed: ({ update, entity }, _context) =>
    update.method === "create" &&
    isPersonId(entity.source.scope) &&
    hasAssigned(entity) &&
    !entity.assigned,

  execute: ({ entity }, { session }) => {
    return [
      asUpdate(
        entity,
        asMutation(
          { field: "assigned", type: "relation" },
          { id: session.user.id }
        )
      ),
    ];
  },
};

// Confetti animation on task completion
export const confetti: WorkflowDefinition<Entity> = {
  id: "confeti",
  trigger: "WILL_UPDATE",
  type: "*",

  allowed: ({ update, entity }, _context) =>
    update.method === "update" &&
    hasStatus(entity) &&
    some(
      update.changes,
      (c) =>
        c.type === "status" &&
        isWorthy(c.value[c.type]) &&
        !when(c.prev?.[c.type], isWorthy)
    ),

  execute: () => {
    explode();
    return [];
  },
};

// Sets the task.followers from creator/updators
export const followWhenChanging: WorkflowDefinition<Entity> = {
  id: "followWhenChanging",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }, { session, props }) =>
    isCreateOrUpdate(update) &&
    hasRefs(entity) &&
    some(props, (p) => p.field === "refs.followers") &&
    !some(update.changes, isField(["refs.seenBy", "refs.followers"])) &&
    !containsRef(entity?.refs?.followers, session.user.id),

  execute: ({ entity }, { session }) =>
    entity &&
    asUpdate(entity, [
      asAppendMutation({ field: "refs.followers", type: "relations" }, [
        { id: session.user.id },
      ]),
    ]),
};

// Sets the task.followers from person relations
export const addFollowersFromRelationFields: WorkflowDefinition<Entity> = {
  id: "addFollowersFromRelationFields",
  trigger: "WILL_UPDATE",
  type: "*",
  allowed: ({ entity, update }, { props }) =>
    isCreateOrUpdate(update) &&
    hasFollowers(entity) &&
    some(
      update.changes,
      (c) =>
        isAnyRelation(c) &&
        !isTimestamp(c.field) &&
        find(props, { field: c.field })?.options?.references === "person" &&
        c.field !== "refs.followers" &&
        c.field !== "refs.seenBy"
    ),
  execute: ({ entity, update }, { props }) => {
    if (!isCreateOrUpdate(update)) {
      return;
    }

    const newFollowers = flatMap(update.changes, (c) =>
      isAnyRelation(c) &&
      find(props, { field: c.field })?.options?.references === "person"
        ? fallback(
            () => when(c?.value?.relation?.id, (id) => [{ id }]),
            () => c?.value?.relations,
            () => []
          )
        : []
    );

    if (!newFollowers?.length) {
      return;
    }

    return asUpdate(
      entity,
      asAppendMutation(
        { field: "refs.followers", type: "relations" },
        newFollowers
      )
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Entity> = {
  triggers: [
    confetti,
    followWhenChanging,
    addFollowersFromRelationFields,
    updateParentFieldsOnLocationChange,
    addStatusTimestamps,
    addAssignedTimestamp,
    addMessagedTimestamps,
    addResourceTimestamps,
    syncWorkflowStepOwner,
    removeSyncedOnDelete,
    autoAssignPrivateWork,
    markWorkflowAsDirtyWatchers,
    autoCompleteWorkflowStep,
  ],
  actions: [
    markAsComplete,
    markAsStarted,
    markAsTodo,
    archiveCompleted as WorkflowAction<Entity>, // TODO: Need to figure out this....
    postUpdate,
  ],
  suggestions: [
    updateLocationOnFieldChange,
    startAllNestedWork,
    privateWorkAssignedToSomeoneElse,
  ],
};

export default definitions;
