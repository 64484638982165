import { DatabaseID, Status } from "@api";

import { useInflatedPropertyValue } from "@state/properties";

import { Maybe } from "@utils/maybe";

import { StatusTag } from "@ui/tag";

export const InflatedStatusTag = ({
  status,
  source,
}: {
  status: Maybe<Status>;
  source: DatabaseID;
}) => {
  const inflated = useInflatedPropertyValue(
    { field: "status", type: "status", value: { status } },
    source
  );
  return <StatusTag status={inflated.value.status || status} />;
};
