import { subWeeks } from "date-fns";
import { filter, flatMap, isEmpty, keys, map, mapKeys, some } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Form, HasDates, HasRefs, Ref, VariableDef } from "@api";

import { formSubmissionName, useAiUseCase } from "@state/ai";
import {
  FormData,
  isReadonlyField,
  makeNestedOverrides,
  toStoredField,
  useFormFields,
} from "@state/form";
import {
  flattenFor,
  NestableOverrides,
  useCreateFromObject,
  useCreateFromTemplate,
  useLazyEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useRunWorkflow, useStartWorkflow } from "@state/workflow";

import { ensureMany, justOne, maybeLookup } from "@utils/array";
import { explode } from "@utils/confetti";
import { useISODate } from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { usePreventClose } from "@utils/event";
import { Fn } from "@utils/fn";
import { useSlowMemo } from "@utils/hooks";
import { newID } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { merge } from "@utils/object";
import {
  asMutation,
  asUpdate,
  flattenChanges,
} from "@utils/property-mutations";
import { toFieldKey, toFieldName, toRef } from "@utils/property-refs";
import { toChildLocation, toParentScope, toScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween } from "@ui/flex";
import { LoadingModal } from "@ui/loading-modal";
import { useMutate } from "@ui/mutate";
import { showSuccess } from "@ui/notifications";
import { RelationButton } from "@ui/relation-label";
import { Text } from "@ui/text";

import { FormFields } from "./fields";

interface Props {
  form: Form;
  data: FormData;
  onChanged: Fn<FormData, void>;
  onSubmitted?: Fn<Maybe<Ref>, void>;
}

export const FormSubmit = ({ form, data, onChanged, onSubmitted }: Props) => {
  const me = useMe();
  const pageId = usePageId();
  const [instanceRef, setInstance] = useState<Ref>();
  const createdInstance = useLazyEntity<"form">(instanceRef?.id);
  const [saving, setSaving] = useState(false);
  const fields = useFormFields(form);
  const mutate = useMutate();

  const formTemplate = useLazyEntity(form?.useTemplate?.id);
  const formTemplateSchedule = useLazyEntity<"schedule">(
    justOne(safeAs<HasRefs>(formTemplate)?.refs.repeat)?.id
  );

  const [suggestedName, setSuggestedName] = useState("");
  const { run } = useAiUseCase(formSubmissionName);
  const workflow = useLazyEntity<"workflow">(
    justOne(form.refs?.runWorkflow)?.id
  );

  const getLabel = useMemo(() => {
    const getField = maybeLookup(fields, ([f, d]) => f?.field);
    return (field: string) => {
      const [prop, def] = getField(field) || [];
      if (!prop) {
        return field;
      }

      return prop?.label || toFieldName(def || prop);
    };
  }, [fields]);

  const itemSource = useMemo(
    () =>
      form?.entity && {
        type: form?.entity,
        scope:
          when(createdInstance, (i) => toChildLocation(i.location, i.id)) ||
          form?.inLocation ||
          form?.source.scope,
      },
    [
      form?.entity,
      createdInstance?.source.scope,
      form?.inLocation,
      form?.source.scope,
    ]
  );
  const create = useCreateFromObject(
    itemSource?.type || "task",
    itemSource?.scope,
    pageId
  );
  const moveResourcesTo = useCallback(
    (location: string) => {
      const resourceFields = filter(
        fields,
        ([f, d]) =>
          d?.type === "relations" &&
          equalsAny("resource", ensureMany(d?.options?.references))
      );
      const resources = flatMap(
        resourceFields,
        ([f, d]) => (data[f.field] || []) as Ref[]
      );

      if (isEmpty(resources)) {
        return;
      }

      const transaction = newID();

      // Move resources to the new location
      mutate(
        map(resources, (r) => ({
          id: r.id,
          method: "update",
          transaction,
          changes: [asMutation({ field: "location", type: "text" }, location)],
          source: { type: "resource", scope: form?.source.scope || location },
        }))
      );
    },
    [fields, data, mutate, form?.source.scope]
  );

  const onCreated = useCallback(
    (ref?: Maybe<Ref>) => {
      setSaving(false);
      showSuccess("Form submitted!");
      onSubmitted?.(ref);
      explode();

      if (ref && !!form.entity && createdInstance) {
        mutate(
          asUpdate(createdInstance, [
            asMutation({ field: "refs.created", type: "relations" }, [ref]),
          ])
        );
      }

      // TODO: Don't know why this is necessary...
      // If this form created from template, then copy resources to the new location
      if (form?.inLocation && !form?.useTemplate?.id && ref?.id) {
        moveResourcesTo(toScope(form?.inLocation, ref.id));
      }

      setInstance(undefined);
      onChanged({});
    },
    [moveResourcesTo, setSaving, onChanged, createdInstance?.id]
  );

  // First a copy of the form is saved
  const saveFormCopy = useCreateFromTemplate(form.source, setInstance);
  // then post-save actions are run
  const createFromTemplate = useCreateFromTemplate(itemSource, onCreated);
  const runWorkflow = useStartWorkflow(createdInstance || form, onCreated);

  const readyToSubmit = useMemo(
    () =>
      some(fields, (f) => !isReadonlyField(f)) &&
      !!create &&
      createFromTemplate.ready,
    [fields, create, createFromTemplate.ready]
  );

  const handleSubmit = useCallback(() => {
    if (saving || !fields || !form) {
      return;
    }

    setSaving(true);

    // Add createdBy as the person submitting the form
    const finalFormData = {
      ...data,
      createdBy: toRef(me),
    } as FormData;

    // Save a copy of the form
    saveFormCopy.create(form, {
      overrides: {
        [form.id]: {
          // Store all the fields in the form (includes workflow fields)
          fields: map(fields, toStoredField),
          // Store all values in the form
          custom: mapKeys(finalFormData, (_v, k) => toFieldKey(k)),
          // Clear out the runWorkflow reference
          refs: { runWorkflow: undefined },
        },
      },
    });
  }, [
    saving,
    setSaving,
    fields,
    form,
    data,
    me,
    saveFormCopy,
    createFromTemplate,
  ]);

  const runOnSubmitted = useCallback(
    (form: Form) => {
      // Form triggers a workflow
      if (workflow) {
        runWorkflow.start(
          workflow,
          // Pass in form values as pre-collected workflow inputs
          map(fields, ([f]) => ({
            ...(f as VariableDef),
            value: { [f.type]: data[f.field] },
          }))
        );

        return;
      }

      // Form just submits the form
      if (!form.entity) {
        onCreated();
        return;
      }

      // Form is creating an entity

      let defaults = {};

      const finalFormData = { ...data, createdBy: toRef(me) } as FormData;

      let overrides = merge<NestableOverrides>(
        form?.overrides?.length
          ? {
              [form?.useTemplate?.id || form?.entity || "*"]: flattenChanges(
                form?.overrides || []
              ),
            }
          : {},
        makeNestedOverrides(form, finalFormData, fields, me),
        {
          [form?.useTemplate?.id || form?.entity || "*"]: {
            title: suggestedName || form.name,
            refs: { fromForm: [{ id: form.id }] },
          },
        }
      );

      // If the form is setting a due date on the work, and the template's schedule
      // has a precreate(weeks) rule, set the start dates to the due date - precreate weeks
      const precreate = formTemplateSchedule?.precreate;
      const workDate = safeAs<HasDates>(
        overrides?.[form?.useTemplate?.id || "*"]
      )?.end;

      if (precreate && workDate) {
        overrides = merge(overrides, {
          task: {
            start: useISODate(workDate, (date) => subWeeks(date, precreate)),
          },
        });
      }

      if (form?.useTemplate) {
        createFromTemplate.create(form.useTemplate, {
          defaults,
          overrides,
        });
      } else {
        const [newly] =
          create?.([
            flattenFor(
              merge(defaults, overrides),
              when(form.entity, (type) => ({
                source: { type, scope: form.source.scope },
              })) || {}
            ),
          ]) || [];
        onCreated(newly);
      }
    },
    [form, data, fields, onCreated, runWorkflow, createFromTemplate]
  );

  useEffect(() => {
    if (createdInstance) {
      runOnSubmitted(createdInstance);
    }
  }, [createdInstance?.id]);

  const slowFormData = useSlowMemo(() => data, [1500, 100000], [data]);

  useAsyncEffect(async () => {
    // Don't suggest a name if the form is not ready to submit
    if (
      !form ||
      keys(data).length < Math.max(1, (form.fields?.length || 0) / 3)
    ) {
      return;
    }

    const suggested = await run({
      form: form,
      data: {
        ...mapKeys(data, (_, k) => getLabel(k)),
        submittedBy: me.fullName,
      },
    });
    setSuggestedName(suggested);
  }, [slowFormData]);

  usePreventClose(
    saving,
    () =>
      "This form is still submitting. Wait a few seconds and try again. Or reload anyway?"
  );

  if (!form) {
    return <></>;
  }

  return (
    <>
      <FormFields
        form={form}
        data={data}
        onChange={(changes) => onChanged({ ...data, ...changes })}
      />

      {saving && (
        <LoadingModal
          message={
            !createdInstance
              ? "Submitting form..."
              : "Running post-save automations..."
          }
        />
      )}

      <Divider />

      <SpaceBetween>
        {when(toParentScope(form.inLocation), (p) => (
          <HStack gap={2}>
            <Text subtle>Creates work in </Text>
            <RelationButton size="tiny" relation={{ id: p }} />
          </HStack>
        )) || <span />}

        <Button
          variant="primary"
          onClick={handleSubmit}
          loading={saving}
          disabled={!readyToSubmit}
        >
          Submit form
        </Button>
      </SpaceBetween>
    </>
  );
};
