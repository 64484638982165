import { map, omit } from "lodash";
import { useCallback, useEffect, useState } from "react";

import {
  isEntity,
  Process,
  Ref,
  VariableDef,
  Workflow,
  WorkflowStep,
} from "@api";

import { processToWorkflow, useAiUseCase } from "@state/ai";
import { useCreateFromObject } from "@state/generic";
import { healPropertyValue } from "@state/workflow";
import { WorkflowRunnerModal } from "@state/workflow/runner-modal";
import { WorkflowDefinition, WorkflowDefinitionConfig } from "@state/workflows";

import { useAsyncEffect } from "@utils/effects";
import { now } from "@utils/epoch-date";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { merge } from "@utils/object";
import { toRef } from "@utils/property-refs";
import { toChildLocation } from "@utils/scope";
import { useTick } from "@utils/time";

import { Magic, SpinnerIcon, StatusIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { CollectDialog } from "@ui/workflow-collect-dialog";

export const generateWorkflow: WorkflowDefinition<Process> = {
  id: "generateWorkflow",
  trigger: "ACTION",
  type: "process",
  icon: Magic,
  title: "Automate process",

  allowed: ({ entity }, _context) =>
    !entity.template && !entity?.refs?.workflows?.length,

  collect: ({ data: { entity }, onCollected, onCancelled }) => {
    const pushTo = usePushTo();
    const ai = useAiUseCase(processToWorkflow);
    const create = useCreateFromObject("workflow", entity.location);
    const createSteps = useCreateFromObject("workflow_step", entity.location);
    const [loading, setLoading] = useState(0);
    const [collecting, setCollecting] =
      useState<[Partial<Workflow>, Partial<WorkflowStep>[]]>();
    const [running, setRunning] = useState<Maybe<Ref>>();

    useEffect(() => {
      setLoading(loading + 1000);
    }, [useTick("1 second")]);

    const handleCreateWorkflow = useCallback(
      async (
        result: Partial<Workflow>,
        steps: Partial<WorkflowStep>[],
        inputs: VariableDef[]
      ) => {
        if (!create || !createSteps) {
          throw new Error("Not ready to create workflow.");
        }

        setLoading(now());
        const [wf] = create([{ ...result, status: { id: "RUN" }, inputs }]);

        createSteps(
          map(steps, (s) =>
            merge(omit(s, "overrides"), {
              overrides: map(s.overrides, healPropertyValue),
              location: toChildLocation(wf.source.scope, wf.id),
              refs: {
                workflow: [toRef(wf)],
              },
            } as Partial<WorkflowStep>)
          )
        );

        setRunning(wf);
        setCollecting(undefined);
        setLoading(0);
      },
      [create, createSteps]
    );

    useAsyncEffect(async () => {
      if (!create || !createSteps) {
        return;
      }

      try {
        const [wf, ...steps] = (await ai.run({ process: entity })) || [];
        if (!wf || !isEntity(wf, "workflow")) {
          throw new Error("Workflow must be returned first.");
        }

        setCollecting([wf, steps as Partial<WorkflowStep>[]]);
      } catch (e) {
        onCancelled();
      }
    }, [!!create, !!createSteps]);

    if (collecting) {
      return (
        <CollectDialog
          collect={collecting[0].inputs || []}
          onCollected={(inputs) =>
            handleCreateWorkflow(collecting[0], collecting[1], inputs)
          }
          onCancel={onCancelled}
          source={entity.source}
        />
      );
    }

    if (running) {
      return <WorkflowRunnerModal id={running.id} onClose={onCancelled} />;
    }

    return (
      <Modal open={true} autoPosition>
        <Menu>
          <MenuGroup>
            <MenuItem
              icon={
                loading < 2000 ? (
                  SpinnerIcon
                ) : (
                  <StatusIcon status={{ group: "done", color: "green" }} />
                )
              }
              text="Collecting information"
            />
            {loading >= 2000 && (
              <MenuItem
                icon={
                  loading < 5000 ? (
                    SpinnerIcon
                  ) : (
                    <StatusIcon status={{ group: "done", color: "green" }} />
                  )
                }
                text="Packaging for AI"
              />
            )}
            {loading >= 5000 && (
              <MenuItem
                icon={
                  loading < 9000 ? (
                    SpinnerIcon
                  ) : (
                    <StatusIcon status={{ group: "done", color: "green" }} />
                  )
                }
                text="Analyzing process"
              />
            )}
            {loading >= 9000 && (
              <MenuItem icon={SpinnerIcon} text="Optimizing paths" />
            )}
          </MenuGroup>
        </Menu>
      </Modal>
    );
  },

  execute: ({ entity, collected }, context) => [],
};

export const definitions: WorkflowDefinitionConfig<Process> = {
  triggers: [],
  suggestions: [],
  actions: [generateWorkflow],
};

export default definitions;
