import { forEach } from "lodash";

import { Entity, Update, Workflow, WorkflowStep } from "@api";

import { pushDirty, whenEmpty } from "@utils/array";
import { toPointDate } from "@utils/date-fp";
import { toEpoch } from "@utils/epoch-date";
import { newID, typeFromId } from "@utils/id";
import { Maybe } from "@utils/maybe";
import { now } from "@utils/now";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toScopedRef } from "@utils/property-refs";

import { WorkflowRunContext } from "./atoms";
import { isFinished, toNextSteps } from "./utils";

export const toFinishWorkflowUpdates = (
  workflow: Maybe<Workflow>,
  steps: Maybe<WorkflowStep[]>
): Update<Entity>[] => {
  if (!workflow) {
    return [];
  }

  const transaction = newID();

  const updates = [
    asUpdate(
      workflow,
      asMutation({ field: "status", type: "status" }, { id: "FNS" }),
      transaction
    ),
  ];

  forEach(steps, (s) => {
    if (!isFinished(s)) {
      pushDirty(
        updates,
        asUpdate(
          s,
          asMutation({ field: "status", type: "status" }, { id: "SKP" }),
          transaction
        )
      );
    }
  });

  return updates;
};

export const finishRunningStep =
  (_workflow: Workflow, step: WorkflowStep) =>
  (state: Maybe<WorkflowRunContext>) => {
    if (!state || !state.running || state.running.id !== step.id) {
      return state;
    }

    return {
      ...state,
      running: undefined,
      startedAt: undefined,
      ran: [...state.ran, step.id],
    };
  };

export const failedRunningStep =
  (_workflow: Workflow, step: WorkflowStep) =>
  (state: Maybe<WorkflowRunContext>) => {
    if (!state || !state.running || state.running.id !== step.id) {
      return state;
    }

    return {
      ...state,
      running: undefined,
      startedAt: undefined,
      ran: [...state.ran, step.id],
      errored: [...(state.errored || []), step.id],
    };
  };

export const startRunning =
  (step: WorkflowStep) => (state: Maybe<WorkflowRunContext>) => {
    if (!state || state.startedAt || state.running?.id !== step.id) {
      return state;
    }

    return {
      ...state,
      startedAt: toEpoch(now()),
    };
  };

export const runNextStep =
  (workflow: Workflow, steps: WorkflowStep[]) =>
  (state: Maybe<WorkflowRunContext>) => {
    // Not allowed to run if already running
    if (!state || state.running) {
      return state;
    }

    const queue = whenEmpty(state.queue, () =>
      toNextSteps(workflow, steps, state)
    );

    return {
      ...state,
      running: queue[0],
      startedAt: undefined,
      // If there was one or more steps, mark as more since the next iteration might reveal more
      more: !!queue.length,
    };
  };

export const runWorkflow =
  (workflow: Workflow) =>
  (state: Maybe<WorkflowRunContext>): WorkflowRunContext => ({
    workflow: workflow,
    ran: [],
    queue: [],
    more: true,
    running: undefined,
    startedAt: undefined,
  });
