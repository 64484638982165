import { first, flatMap } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { EntityType, Ref, Roadmap, SortByProp } from "@api";

import { useLazyProperties } from "@state/properties";
import { useCreateFromObject, useEntitySource } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { getSetting, isAnyRelation, setSetting } from "@utils/property-refs";
import { withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { showError } from "@ui/notifications";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { ScopedPropertiesSelect } from "@ui/select/properties";
import { Text } from "@ui/text";

interface Props {
  defaults?: Partial<Omit<Roadmap, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const RoadmapCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wID = useActiveWorkspaceId();
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const create = useCreateFromObject("roadmap", scope);

  const [roadmap, setRoadmap] = useState<Partial<Roadmap>>({
    icon: "🗺️",
    ...defaults,
    source: { type: "roadmap", scope },
  });
  const childSource = useEntitySource(
    getSetting<EntityType>(roadmap.settings, "child_type") || "outcome",
    roadmap.source
  );
  const relations = useLazyProperties(roadmap?.source);
  const roadmappable = useMemo(
    () =>
      flatMap(relations, (p) =>
        isAnyRelation(p) &&
        p.options?.references !== "view" &&
        // Star cannot be used for roadmapping relations
        p.options?.hierarchy === "child"
          ? ensureMany(withoutStar(p?.options?.references))
          : []
      ),
    [relations]
  );

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([roadmap]);

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [create, roadmap, onSaved]);

  useEffect(() => {
    const defaultt = first(roadmappable);
    if (!defaultt) {
      return;
    }

    setRoadmap((r) => ({
      ...r,
      settings: setSetting(r.settings, "child_type", defaultt),
    }));
  }, [roadmappable]);

  return (
    <DialogSplit
      title={"New roadmap"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>
            Roadmaps are about creating clarity on where you are heading.
          </Text>
          <EducationTip relevantTo={["roadmap"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create roadmap
          </Button>
        </>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            {roadmap.icon && (
              <Field layout="horizontal">
                <MagicEmojiSelect
                  key={roadmap.id || "new-roadmap"}
                  entity={roadmap as Roadmap}
                  size="xlarge"
                  emoji={roadmap.icon}
                  onChange={(icon) => setRoadmap((r) => ({ ...r, icon }))}
                />
              </Field>
            )}

            <Field label="Name">
              <TextInput
                value={roadmap.name || ""}
                onChange={(t) => setRoadmap((r) => ({ ...r, name: t }))}
                updateOn="change"
                autoFocus={true}
                placeholder="Give this roadmap a name..."
              />
            </Field>

            <Field label="Roadmapping">
              <EntityTypeSelect
                value={getSetting<EntityType>(roadmap.settings, "child_type")}
                allowed={roadmappable}
                portal
                scope={scope}
                onChange={(entity) =>
                  setRoadmap((r) => ({
                    ...r,
                    settings: setSetting(r.settings, "child_type", entity),
                  }))
                }
              />
            </Field>

            <Field label="Prioritize by">
              <ScopedPropertiesSelect
                source={childSource}
                emptyOption={false}
                value={roadmap.fields}
                placeholder="Pick fields to use..."
                onChanged={(refs) =>
                  setRoadmap({
                    ...roadmap,
                    fields: refs as Maybe<SortByProp[]>,
                  })
                }
              />
            </Field>
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
