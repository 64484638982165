import { addDays, addMonths, addQuarters, addWeeks, addYears } from "date-fns";
import {
  filter,
  find,
  findIndex,
  first,
  flatMap,
  has,
  isArray,
  keys,
  last,
  map,
  orderBy,
  reduce,
  reduceRight,
  times,
  uniq,
} from "lodash";
import { useMemo } from "react";

import { isTemplatable, isTemplateEntity, toTitleOrName } from "@api";
import {
  Entity,
  EntityType,
  FilterQuery,
  GroupByProp,
  GroupByPropDef,
  HasLocation,
  HasOrder,
  HasOrders,
  ID,
  PropertyDef,
  PropertyFilter,
  PropertyMutation,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
  Roadmap,
  SelectOption,
  SingleFilterQuery,
  SortByProp,
  Status,
  View,
} from "@api/types";

import { getViewTemplate } from "@state/templates";

import {
  ensureArray,
  ensureMany,
  groupByMany,
  justOne,
  omitEmpty,
  pushDirty,
  replace,
  uniqBy,
  whenEmpty,
} from "@utils/array";
import {
  fromISO,
  fromPointDate,
  now,
  toPointDate,
  useISODate,
} from "@utils/date-fp";
import {
  isAnd,
  isEmptyFilter,
  isOr,
  isValidFilter,
  toList,
} from "@utils/filtering";
import { cachedFunc, composel, fallback, Fn, use } from "@utils/fn";
import {
  GroupedItems,
  isNested,
  NestedGroup,
  toGroupValueRef,
} from "@utils/grouping";
import {
  isTeamId,
  isWorkspaceId,
  newHumanId,
  newLocalHumanId,
  toHumanPrefix,
  typeFromId,
} from "@utils/id";
import { equalsAny, ifDo, ifDo_, switchEnum } from "@utils/logic";
import {
  isDefined,
  Maybe,
  maybeMap,
  safeAs,
  SafeRecord,
  when,
} from "@utils/maybe";
import { omitEmpty as omitEmptyObj, setDirty } from "@utils/object";
import { asMutation } from "@utils/property-mutations";
import {
  asPropertyValueRef,
  asValue,
  getPropertyValue,
  isAnyRelation,
  isArrayType,
  isEmptyValue,
  toFieldName,
  toGroupKeys,
  toKey,
  toRef,
  toSortable,
} from "@utils/property-refs";
import { fromScope, toLocation } from "@utils/scope";
import { toSentence } from "@utils/string";
import { toMilliSeconds } from "@utils/time";

import { applyExtensions } from "./filter-extensions";

export const EMPTY_KEY = "EmPTy";

export const toValue = (filter: SingleFilterQuery) =>
  justOne(filter.value?.[filter.type as Exclude<PropertyType, "json">]) ||
  justOne(
    filter.values?.[
      filter.type as Exclude<PropertyType, "json">
    ] as SelectOption[]
  );

export const isFiltering = (view: View) =>
  (!!view.filter && !isEmptyFilter(view.filter)) || view?.settings?.hideNested;

export const isGrouping = (view: View) =>
  !!view.group?.length || !!view.grouping;

export const newView = (v: Partial<View> & Pick<View, "source">): View => ({
  id: newLocalHumanId("view"),
  name: "",
  alias: undefined,
  icon: undefined,
  order: undefined,
  layout: "list",
  grouping: undefined,
  entity: "task",
  filter: undefined,
  sort: undefined,
  group: undefined,
  showProps: [],
  location: toLocation(v.source.scope),
  template: undefined,

  createdAt: now(),
  updatedAt: now(),
  fetchedAt: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  ...v,
});

export const sortItems = (
  items: Entity[],
  sorts?: SortByProp[],
  scope: string = "default",
  props?: SafeRecord<string, PropertyDef>
) =>
  // Reduce right so that the last sort is the first to be applied
  reduceRight(
    [
      ...(sorts || []),
      { direction: "asc", field: "orders", type: "json" },
    ] as SortByProp[],
    (results, sort) =>
      orderBy(
        results,
        (t) =>
          use(
            toSortable(toGroupValueRef(t, props?.[sort.field] ?? sort), scope),
            // Sort all empty values to end of list (regardless of sort direction)
            (sortVal) =>
              isEmptyValue(sortVal)
                ? sort?.empty === "first"
                  ? "0"
                  : "1"
                : sort.direction === "desc"
                ? `1${sortVal}`
                : `0${sortVal}`
          ),
        sort.direction
      ),
    items
  );

export const groupItems = (
  items: Entity[],
  [group, ...subGroups]: GroupByPropDef<Entity, PropertyType>[],
  hideEmpty?: boolean,
  whitelist?: string[],
  getItem?: Fn<ID, Maybe<Entity>>
): { groups: NestedGroup[]; hidden: NestedGroup[] } | undefined => {
  if (!group) {
    return undefined;
  }

  // First go through all the allowed values defined on the group and add
  // a group for them.
  const allGroups: PropertyValueRef<Entity>[] = [];

  // Extend allGroups out with all the possible values for this group
  pushDirty(
    allGroups,
    ...switchEnum<PropertyType | "default", PropertyValueRef<Entity>[]>(
      group.values?.[group.type] ? group.type : "default",
      {
        status: () =>
          map(group.values?.status, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        select: () =>
          map(group.values?.select, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        multi_select: () =>
          map(group.values?.multi_select, (v) =>
            asPropertyValueRef(group, { [group.type]: v })
          ),
        date: () => {
          const [min, max] = reduce(
            allGroups,
            (res, g) => {
              const date = fromISO(g.value.date);
              if (!date) {
                return res;
              }
              const [min, max] = res;
              return [
                !min || date < min ? date : min,
                !max || date > max ? date : max,
              ];
            },
            [fromPointDate(now()), fromPointDate(now())] as [
              Maybe<Date>,
              Maybe<Date>
            ]
          );

          if (!max || !min) {
            return [];
          }

          const bumperFn = switchEnum(group.format || "day", {
            // Determine the function to use to add time based on the group format
            day: () => addDays,
            week: () => addWeeks,
            month: () => addMonths,
            quarter: () => addQuarters,
            year: () => addYears,
            else: () => addDays,
          });

          return [
            // Add equal units of time between min/max unless it's a day
            ...reduce(
              times(20),
              (acc, i) =>
                use(bumperFn(min, i), (bumped) =>
                  bumped > max
                    ? acc
                    : pushDirty(
                        acc,
                        asPropertyValueRef(group, {
                          date: toPointDate(bumped),
                        })
                      )
                ),
              [] as PropertyValueRef<Entity>[]
            ),
            // Add 3 units of time (based on group.format) ahead of the max date
            ...times(3, (i) =>
              asPropertyValueRef(group, {
                date: toPointDate(bumperFn(max, i + 1)),
              })
            ),
          ];
        },
        else: () => [],
      }
    )
  );

  // Always add Empty Group
  pushDirty(allGroups, asPropertyValueRef(group, { [group.type]: undefined }));

  // Group all items by group key, adding groups to allGroups as we go
  const groupedItems = groupByMany(items, (task) => {
    const valueRef = toGroupValueRef(task, group);

    // Add all uniq value for this group and add the individual value
    map(
      ensureArray(valueRef.value[group.type as Exclude<PropertyType, "json">]),
      (v) => {
        pushDirty(allGroups, {
          ...valueRef,
          value: { [group.type]: isArrayType(group.type) ? [v] : v },
        });
      }
    );

    const valKeys = toGroupKeys(
      valueRef,
      group.format || valueRef?.def?.format,
      EMPTY_KEY
    );

    return isArray(valKeys)
      ? whenEmpty(valKeys, [EMPTY_KEY])
      : valKeys || EMPTY_KEY;
  });

  // Uniq groups by formatted values
  const uniqGroups = uniqBy(
    allGroups,
    (g) => toKey(g, group.format || g.def?.format, EMPTY_KEY),
    "first" // values from definition are added first
  );
  const orderedGroups = orderBy(
    uniqGroups,
    (v, i) =>
      v.type === "date"
        ? useISODate(v.value.date, (d) => d?.getTime())
        : isEmptyValue(v.value[v.type])
        ? "~~" // Last
        : equalsAny(v.type, ["status", "select", "multi_select"])
        ? findIndex(
            (group.values?.[group.type] as SelectOption[]) || [],
            (val) => val.id === toKey(v, group.format || v.def?.format)
          ) ?? i
        : ["relation", "relations"]?.includes(v.type) && !!getItem
        ? when(
            when(
              v.value.relation?.id || justOne(v.value.relations)?.id,
              getItem
            ),
            (item) => {
              return (
                safeAs<HasOrder>(item)?.order ??
                safeAs<HasOrders>(item)?.orders?.["default"] ??
                toTitleOrName(item)
              );
            }
          )
        : v.type === "number"
        ? v.value.number
        : toKey(v, group.format || v.def?.format),
    group.sort || "asc"
  );

  return reduce(
    orderedGroups,
    (res, valueRef) => {
      const key = toKey(
        valueRef,
        group.format || valueRef.def?.format,
        EMPTY_KEY
      );
      const items = groupedItems[key || EMPTY_KEY];
      const valueKey = key || EMPTY_KEY;

      // If has subroups keep nesting downwards
      const nextGroup = fallback(
        () =>
          ifDo(!!subGroups?.length, () =>
            when(groupItems(items || [], subGroups), (sub) => ({
              value: valueRef,
              def: group,
              ...sub,
            }))
          ),
        // Return a simple GroupedItems group
        () =>
          ({ value: valueRef, def: group, items: items || [] } as NestedGroup)
      );

      const isHidden =
        // View is setup to hide empty
        ((hideEmpty || group?.hideEmpty) &&
          // And this group is empty
          !items?.length &&
          // And it's now marked to always show by parent group or view
          (!group.alwaysShow?.includes(valueKey) ||
            whitelist?.includes(valueKey))) ||
        // OR view wants to always hide this group
        group.alwaysHide?.includes(valueKey);

      return isHidden
        ? setDirty(res, "hidden", pushDirty(res.hidden, nextGroup))
        : setDirty(res, "groups", pushDirty(res.groups, nextGroup));
    },
    { groups: [] as NestedGroup[], hidden: [] as NestedGroup[] }
  );
};

export const groupItemsBy2Dimensions = (
  items: Entity[],
  [dimension1, dimension2, ...rest]: GroupByPropDef<Entity, PropertyType>[],
  hideEmpty?: boolean
): { groups: NestedGroup[]; hidden: NestedGroup[] } | undefined => {
  if (rest?.length) {
    throw new Error("A max of 2 levels of grouping is currently supported.");
  }

  // Separately group by each dimension so that determining empty groups is easier
  const grouped1 = groupItems(items, [dimension1], hideEmpty);
  const grouped2 = groupItems(items, [dimension2], hideEmpty);

  // If there's one or no dimensions, return it
  if (!grouped1 || !grouped2) {
    return grouped1;
  }

  const groups = map(
    // We can assume there is no nested as we only passed in 1 above
    grouped1.groups as GroupedItems[],
    (outer) => {
      const items = outer.items || [];

      // Ditto as above re: assumption
      const subGroups = map(grouped2.groups as GroupedItems[], (inner) => {
        const items = inner.items || [];
        return {
          ...inner,
          items: filter(items, (i) => outer.items?.includes(i)),
        };
      });

      return {
        ...outer,
        items,
        groups: subGroups,
      };
    }
  );

  return { groups, hidden: grouped1.hidden };
};

export const toGroupByProps = (view: View): GroupByProp<Entity>[] =>
  view.layout === "calendar"
    ? [
        {
          field:
            getPropertyValue(view, {
              field: "settings.calStart",
              type: "property",
            })?.property?.field ||
            (view.entity === "content" ? "publish" : "start"),
          type: "date",
          hideEmpty: false,
          format: "day",
        },
      ]
    : !!view.group?.length
    ? maybeMap(
        view.group || [],
        (g) =>
          g && {
            ...g,
            hideEmpty: g.hideEmpty,
          }
      )
    : view.layout === "columns"
    ? [{ field: "status", type: "status", hideEmpty: false }]
    : [];

export const replaceFilter = (
  curr: Maybe<FilterQuery>,
  index: number,
  filter: Maybe<FilterQuery>
): Maybe<FilterQuery> => {
  if (!curr) {
    return filter;
  }

  if (isAnd(curr)) {
    return {
      and: filter ? replace(curr.and, index, filter) : replace(curr.and, index),
    };
  }

  if (isOr(curr)) {
    return {
      or: filter ? replace(curr.or, index, filter) : replace(curr.or, index),
    };
  }

  return filter;
};

export const addNewFilter = (
  curr: Maybe<FilterQuery>,
  filter: Maybe<FilterQuery>,
  combine: "and" | "or" = "and"
): FilterQuery => {
  if (!filter) {
    return curr || { and: [] };
  }

  if (!curr) {
    return { [combine || "and"]: [filter] } as FilterQuery;
  }

  if (isAnd(curr) && combine !== "or") {
    return { and: [...curr.and, filter] };
  }

  if (isOr(curr) && combine !== "and") {
    return { or: [...curr.or, filter] };
  }

  return { [combine || "and"]: [curr, filter] } as FilterQuery;
};

export const readyToFetchViewItems = (view: View, props: PropertyDef[]) =>
  // The parent is fetched and the filter returns
  isValidFilter(toViewBaseFilter(view, props)) ||
  // Person views allow empty filters as that is used to get everyone in workspace
  view.entity === "person";

// If the view does not define the for, then use it's location as the base filter
// Useful for template views inside document fields
export const toDataSource = (view: Partial<View>) =>
  view.for?.id || last(fromScope(view.location));

// Views have a base filter that describes what they are "looking at", then view.filter is applied on top
export const toViewBaseFilter = (
  view: Partial<View>,
  props: PropertyDef[],
  mode: "strict" | "loose" = "loose"
): Maybe<FilterQuery> => {
  const parentId = toDataSource(view);
  const childType = view.entity;

  if (!parentId) {
    return undefined;
  }

  const parentType = typeFromId<EntityType>(parentId);

  // If the view is a workspace-wide and there is no filter setup, then return undefined (invalid filter)
  if (parentType === "workspace" && !view.filter) {
    return undefined;
  }

  if (parentType === "form") {
    return {
      field: "refs.fromForm",
      op: "contains",
      type: "relations",
      value: { relations: [{ id: parentId }] },
    };
  }

  if (parentType === "person" && childType === "meeting") {
    return {
      or: [
        {
          field: "refs.people",
          op: "contains",
          type: "relations",
          value: { relations: [{ id: parentId }] },
        },
        {
          field: "owner",
          op: "equals",
          type: "relation",
          value: { relation: { id: parentId } },
        },
      ],
    };
  }

  // Loading data across entire workspace
  if (parentType === "workspace") {
    return {
      field: "workspace",
      type: "relation",
      op: "equals",
      value: { relation: { id: parentId } },
    };
  }

  // Loading data from within a team
  if (parentType === "team") {
    // Query team id with location filter
    return {
      field: "location",
      op: "contains",
      type: "text",
      value: { text: parentId },
    };
  }

  // Loading private data
  if (parentType === "person") {
    return {
      field: "location",
      op: "contains",
      type: "text",
      value: { text: parentId },
    };
  }

  // Loose: Fields pointing to the parent or located within the parent
  if (mode === "loose") {
    return {
      or: [
        ...maybeMap(
          props,
          (p): Maybe<PropertyFilter> =>
            isAnyRelation(p) &&
            p.visibility !== "hidden" &&
            p.options?.hierarchy === "parent" &&
            ensureArray(p.options?.references)?.includes(parentType)
              ? {
                  field: p.field,
                  type: p.type,
                  op: "contains",
                  values: {
                    [p.type]: [toRef(parentId)],
                  },
                }
              : undefined
        ),
        {
          field: "location",
          type: "text",
          op: "contains",
          value: { text: parentId },
        },
      ],
    };
  }

  // Strict: Only fields pointing to the parent
  return {
    or: maybeMap(props, (p) =>
      isAnyRelation(p) &&
      p.visibility !== "hidden" &&
      p.options?.hierarchy === "parent" &&
      ensureArray(p.options?.references)?.includes(parentType)
        ? {
            field: p.field,
            type: p.type,
            op: "contains",
            values: {
              [p.type]: [toRef(parentId)],
            },
          }
        : undefined
    ),
  };
};

export const toFullFilter = (
  view: Partial<View>,
  props: PropertyDef[],
  opts?: { archived?: boolean; templates?: boolean }
): Maybe<FilterQuery> => {
  if (!view.entity) {
    throw new Error("View must have an entity type.");
  }

  // Special case for people views
  if (view.entity === "person") {
    return applyExtensions(view.filter || { and: [] });
  }

  // Override templates option if it is an always-template entity (forms/workflows)
  const includeTemplates = opts?.templates ?? !!view.template;

  const additional = omitEmpty([
    // Exclude templates items unless explicitly asked for
    ifDo(isTemplatable(view.entity), () =>
      includeTemplates
        ? ({
            field: "template",
            type: "text",
            op: "equals",
            value: { text: "root" },
          } as FilterQuery)
        : ({
            field: "template",
            type: "text",
            op: "is_empty",
          } as FilterQuery)
    ),

    // Exclude archived items unless explicitly asked for
    ifDo(
      opts?.archived !== true,
      () =>
        ({
          field: "archivedAt",
          type: "date",
          op: "is_empty",
        } as FilterQuery)
    ),

    ifDo(
      opts?.archived === true && view.entity !== "team",
      () =>
        ({
          field: "archivedAt",
          type: "date",
          op: "is_not_empty",
        } as FilterQuery)
    ),

    when(toDataSource(view), (forId) => {
      // Changing from loose to strict because subTasks is showing up blockedBy tasks
      // Can't remember why I wanted it loose for.... If you're reading this then you now know why...
      // Some Time Later: When you are looking at a campaign and want to see all the work within in
      // regardless of the parent relation, then you want it to be loose
      let entityFilter = toViewBaseFilter(view, props);

      if (!!view.entity && !view.settings?.showSubs) {
        entityFilter = {
          and: omitEmpty([
            // Matched base filter
            entityFilter,

            // But not within a thing of this view type
            {
              field: "location",
              op: "does_not_contain",
              type: "text",
              value: {
                text: `/${toHumanPrefix(view.entity)}_`,
              },
            },
          ]),
        } as FilterQuery;
      }

      if (view?.settings?.hideNested === true) {
        // Filter only things that are either live directly under the parent or have the relation set
        entityFilter = {
          or: omitEmpty([
            // Teams don't have view relations, so just filter location
            ifDo(!isTeamId(forId), () => {
              // Only construct this if there is a property relation to the parent
              if (!isEmptyFilter(entityFilter)) {
                return {
                  and: [
                    {
                      field: "location",
                      op: "does_not_contain",
                      type: "text",
                      value: { text: forId },
                    },

                    entityFilter,
                  ],
                };
              }

              return entityFilter;
            }),
            {
              field: "location",
              op: "ends_with",
              type: "text",
              value: { text: forId },
            },
          ]),
        } as FilterQuery;
      }

      return entityFilter;
    }),
  ]);

  const base = applyExtensions(view.filter);

  return !!additional?.length
    ? addNewFilter(base, { and: additional }, "and")
    : base;
};

export const isTemplateViewId = (id: string) => id.startsWith("v_tmp-");

export const toTemplateIdBase = cachedFunc(
  (id: string) => first(id?.split(".")) || "",
  toMilliSeconds("1 minute")
);

export const toTemplateViewId = (
  template: string,
  params: Record<string, Maybe<string>>
) =>
  `v_tmp-${template.replace(/[\s_\.]/gi, "-")}${reduce(
    keys(params),
    (res, k) => (isDefined(params[k]) ? `${res}.${k}=${params[k]}` : res),
    ""
  )}`;

export const useTemplateViewId = (
  template: string,
  params: Record<string, Maybe<string>>
) => {
  return useMemo(
    () => toTemplateViewId(template, params),
    [
      template,
      params?.team,
      params?.parent,
      params?.assigned,
      params?.owner,
      params?.for,
    ]
  );
};

export const fromTemplateViewId = (
  id: string
): { key: string; params: Record<string, Maybe<string>> } => {
  const [keyPart, ...paramsParts] = id.split(".");
  const key = keyPart.replace("v_tmp-", "");
  const params = reduce(
    paramsParts,
    (res, p) => {
      const [k, v] = p.split("=");
      return { ...res, [k]: v };
    },
    {}
  );
  return { key, params };
};

export const propsToSatisfyFilter = <T extends Entity>(
  filter: FilterQuery<T>,
  available: PropertyDef<Entity>[]
): PropertyMutation<T>[] => {
  if (isAnd(filter)) {
    return flatMap(filter.and, (f) => propsToSatisfyFilter(f, available));
  }

  if (isOr(filter)) {
    return (
      when(first(filter.or), (f) =>
        ensureArray(propsToSatisfyFilter(f, available))
      ) || []
    );
  }

  const { type } = filter;

  if (type === "json") {
    return [];
  }

  const value = switchEnum(filter.op, {
    is_empty: () => undefined,
    does_not_contain: () => undefined,
    contains: () => toValue(filter),
    equals: () => toValue(filter),
    does_not_equal: () => undefined,
    else: () => toValue(filter),
  });

  if (!value) {
    return [];
  }

  // TODO: This does not support does_not_equal filters on status.group... especially inside an AND filter
  // https://dev.traction.team/t_BlU2luW2
  // If it's a status filter, filtering on a status group, then we need to find the first
  // status in that group and use that as the value
  if (
    filter?.type === "status" &&
    (value as Maybe<Status>)?.group &&
    !(value as Maybe<Status>)?.id
  ) {
    const group = (value as Maybe<Status>)?.group;
    const statuses = find(
      available,
      (a) => a?.type === "status" && a.field === filter?.field
    ) as Maybe<PropertyDef<Entity, "status">>;
    const firstInGroup = find(
      statuses?.values?.status,
      (a) => a?.group === group
    );

    return [asPropertyValueRef(filter, { status: firstInGroup })];
  }

  return [asPropertyValueRef(filter, asValue(type, value))];
};

export const propsToSatisfyView = (
  view: View,
  available: PropertyDef<Entity>[]
) =>
  omitEmpty([
    ...(when(
      // Strict filter only respects parent relations
      !when(view.for?.id, isWorkspaceId)
        ? toViewBaseFilter(view, available, "strict") // Strict ignores location and only looks at props
        : undefined,

      (f) => propsToSatisfyFilter(f, available)
    ) || []),

    view?.template
      ? asMutation({ field: "template", type: "text" }, "nested")
      : undefined,

    // Pull out props from filters
    ...(when(view?.filter, (f) => propsToSatisfyFilter<Entity>(f, available)) ||
      []),
  ]);

export const orderedItemsForGroup = (group: NestedGroup): Entity[] =>
  isNested(group)
    ? reduce(
        group.groups,
        (res, g) => [...res, ...orderedItemsForGroup(g)],
        [] as Entity[]
      )
    : group.items || [];

export const countForGroup = (group: NestedGroup): number =>
  orderedItemsForGroup(group).length;

export type PropertyValueRefCount = PropertyValueRef & { count: number };

export const countUniqValues = (
  items: Entity[],
  prop: PropertyDef<Entity>
): PropertyValueRefCount[] => {
  const uniqPropVals = map(prop.values?.[prop.type], (v) =>
    asPropertyValueRef(prop, { [prop.type]: v })
  );
  const usedVals = flatMap(items, (t) => toGroupValueRef(t, prop));

  const grouped = groupByMany(usedVals, (prop) => {
    const value = prop?.value[prop?.type];
    return isArray(value)
      ? maybeMap(value as SelectOption[], (v) =>
          toKey(asPropertyValueRef(prop, { [prop.type]: v }))
        )
      : toKey(prop);
  });

  return maybeMap(uniqPropVals, (val) => {
    const key = toKey(val);
    const vals = key && grouped[key];

    return key && vals?.length
      ? { ...val, count: vals?.length || 0 }
      : undefined;
  });
};

export const orderedItemsFromGroups = (groups: NestedGroup[]): Entity[] =>
  flatMap(groups, (g) =>
    isNested(g)
      ? orderedItemsFromGroups(g.groups)
      : (g.items || [])?.length
      ? g.items
      : []
  );

export const defaultViewsForParent = (
  parent: Entity,
  itemType: EntityType,
  forr: string = parent.id
) => {
  const withType = omitEmptyObj({
    parent: parent.id,
    for: forr,
    entity: itemType,
  });
  const withoutType = omitEmptyObj({
    parent: parent.id,
    for: forr,
  });

  return (
    ifDo(parent?.source?.type === "person", () => [
      toTemplateViewId(`my-${itemType}s`, withType),
    ]) ||
    switchEnum(itemType, {
      // Don't have defaults for note/resource/action
      note: () => [],
      resource: () => [],
      action: () => [],
      page: () => [toTemplateViewId("nested-pages", withoutType)],
      meeting: () => [toTemplateViewId("all-meetings", withoutType)],
      else: () => undefined,
    }) ||
    switchEnum(parent.source?.type, {
      roadmap: () => [
        toTemplateViewId("roadmap-planned", withoutType),
        toTemplateViewId("roadmap-all", withoutType),
      ],
      pipeline: () => [toTemplateViewId("pipeline-default", withType)],
      backlog: () => [toTemplateViewId("backlog-list", withType)],
      calendar: () => [toTemplateViewId(`calendar-${itemType}`, withType)],

      team: () =>
        // Handled below
        itemType !== "content"
          ? [toTemplateViewId(`team-${itemType}`, withType)]
          : undefined,
      else: () => undefined,
    }) ||
    switchEnum(itemType, {
      content: () =>
        omitEmpty([
          toTemplateViewId(`content-list`, withoutType),
          toTemplateViewId(`content-cal`, withoutType),
        ]),
      else: () => undefined,
    }) ||
    fallback(
      ifDo_(
        has(parent as HasLocation, "location") &&
          !!getViewTemplate(`${parent.source.type}-${itemType}`),
        () => [toTemplateViewId(`${parent.source.type}-${itemType}`, withType)]
      ),

      () => [toTemplateViewId(`default-list`, withType)]
    )
  );
};

export const toSortKey = (view: Maybe<View>) =>
  when(view?.location, composel(fromScope, last)) || view?.id || "default";

export const toSortProps = (
  view: Maybe<View>,
  parent?: Entity
): SortByProp[] => {
  if (view?.layout === "calendar") {
    return [
      { field: "start", type: "date", direction: "asc" },
      { field: "end", type: "date", direction: "asc" },
      { field: "publish", type: "date", direction: "asc" },
    ];
  }
  if (
    parent?.source.type === "roadmap" &&
    !!(parent as Maybe<Roadmap>)?.fields?.length
  ) {
    return maybeMap((parent as Roadmap).fields, (f) => ({
      ...f,
      direction: f.direction || "asc",
    }));
  }

  return view?.sort || [];
};

export const toRecommendedProps = (
  view: View,
  suggested: Maybe<PropertyRef[]>,
  props: PropertyDef[]
) => {
  const fields = uniq([
    ...maybeMap(suggested, (p) => p.field),
    ...maybeMap(view.group || [], (g) => g?.field),
    ...maybeMap(view.showProps || [], (p) => p.field),
  ]);
  return filter(props, (p) => fields.includes(p.field));
};

export const toLayoutName = (layout: View["layout"]) =>
  switchEnum(layout, {
    list: "List",
    card: "Cards",
    table: "Table",
    canvas: "Flowchart",
    timeline: "Timeline",
    calendar: "Calendar",
    browser: "Browser",
    else: () => "Unknown",
  });

export const compareViewIds = (a: ID, b: ID) =>
  toTemplateIdBase(a) === toTemplateIdBase(b);

export const toDateFields = (view: View) =>
  [
    getPropertyValue(view, {
      field: "settings.calStart",
      type: "property",
    })?.property,
    getPropertyValue(view, {
      field: "settings.calEnd",
      type: "property",
    })?.property,
  ] as [Maybe<PropertyRef>, Maybe<PropertyRef>];

export const isTriaging = (view: Maybe<View>) =>
  !!view?.settings?.triageEmpty &&
  (!!view?.group?.length || view.layout === "calendar");

export const isSetup = (view: View) =>
  switchEnum(view.layout, {
    canvas: () => !!view?.settings?.canvasBy,
    calendar: () => !!toDateFields(view)[0]?.field,
    else: () => undefined,
  }) ??
  switchEnum(view.grouping || "none", {
    rows: () => !!view?.group?.[0],
    columns: () => !!view?.group?.[0],
    columns_rows: () => !!view?.group?.[0] && !!view?.group?.[1],
    quadrants: () => !!view?.group?.[0],
    else: () => true,
  });

export const toGroupLabel = (view: View, props: PropertyDef[]) =>
  !!view.group?.length || view.layout === "columns"
    ? `by ${
        view.group?.[0]
          ? when(
              find(props, (p) => p.field === view.group?.[0]?.field),
              (d) => d.label || d.field
            ) ?? view.group?.[0]?.field
          : "status"
      }`
    : undefined;

export const toShortTitle = (view: View, props?: PropertyDef[]) =>
  toSentence(
    fallback(
      () => when(view?.name || undefined, ensureMany),
      () =>
        !!view.group?.length ? [toGroupLabel(view, props || [])] : undefined,
      () =>
        when(view.filter && toList(view.filter), (filters) => [
          "filtered by",
          when(
            find(
              props,
              (d) => d.field === safeAs<SingleFilterQuery>(filters[0])?.field
            ) || (filters[0] as PropertyRef<Entity>),
            toFieldName
          ),
          filters?.length > 1 ? "and more" : undefined,
        ]),
      () => [toLayoutName(view.layout)]
    )
  );
