import { reduce, values } from "lodash";
import { selectorFamily } from "recoil";

import { Entity, EntityType, ID, PropertyDef } from "@api";

import { ActiveUserAtom } from "@state/workspace";

import { maybeMap, pushDirty_ } from "@utils/array";
import { Maybe, when } from "@utils/maybe";
import { isAnyRelation } from "@utils/property-refs";
import { isMatch } from "@utils/scope";

import { PropertyDefStoreAtom } from "./atoms";
import { propertiesForEntity, propertiesForScope } from "./utils";

export const allPropertiesForType = selectorFamily<
  PropertyDef<Entity>[],
  Maybe<EntityType>
>({
  key: "allPropertiesForType",
  get:
    (type) =>
    ({ get }) =>
      type ? propertiesForEntity(get(PropertyDefStoreAtom), type) : [],
});

export const allPropertiesForSource = selectorFamily<
  PropertyDef<Entity>[],
  Maybe<{ type: EntityType; scope: string }>
>({
  key: "allPropertiesForSource",
  get:
    (source) =>
    ({ get }) => {
      const me = get(ActiveUserAtom);

      if (!source || !me) {
        return [];
      }

      return propertiesForScope(get(PropertyDefStoreAtom), source, me);
    },
});

export const allStatusesForTeam = selectorFamily<PropertyDef<Entity>[], string>(
  {
    key: "allStatusesForTeam",
    get:
      (teamId) =>
      ({ get }) =>
        teamId
          ? maybeMap(values(get(PropertyDefStoreAtom).lookup), (p) =>
              !!p &&
              p.type === "status" &&
              // Is in the right scope
              isMatch(p.scope, teamId)
                ? p
                : undefined
            )
          : [],
  }
);

export const allPropertiesForTeam = selectorFamily<
  PropertyDef<Entity>[],
  string
>({
  key: "allPropertiesForTeam",
  get:
    (teamId) =>
    ({ get }) =>
      teamId
        ? maybeMap(values(get(PropertyDefStoreAtom).lookup), (p) =>
            !!p &&
            // Is in the right scope
            isMatch(p.scope, teamId)
              ? p
              : undefined
          )
        : [],
});

export const allRelationsForTeam = selectorFamily<
  PropertyDef<Entity>[],
  string
>({
  key: "allRelationsForTeam",
  get:
    (teamId) =>
    ({ get }) =>
      teamId
        ? maybeMap(values(get(PropertyDefStoreAtom).lookup), (p) =>
            !!p &&
            isAnyRelation(p) &&
            // Exclude view relations
            p.entity[0] !== "view" &&
            p.options?.references !== "view" &&
            // Is in the right scope
            isMatch(p.scope, teamId)
              ? p
              : undefined
          )
        : [],
});

export const allPropertiesForIds = selectorFamily<PropertyDef<Entity>[], ID[]>({
  key: "allPropertiesForIds",
  get:
    (ids: ID[]) =>
    ({ get }) => {
      const store = get(PropertyDefStoreAtom);
      return reduce(
        ids,
        (acc, id) => when(store.lookup[id], pushDirty_(acc)) || acc,
        [] as PropertyDef<Entity>[]
      );
    },
});
