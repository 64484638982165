import { isNumber, isString } from "lodash";
import { ReactNode, useMemo } from "react";

import { cx } from "@utils/class-names";

import styles from "./on-hover.module.css";

export const Trigger = ({ children }: { children: ReactNode }) => {
  return <div className={styles.parent}>{children}</div>;
};

export const Target = ({
  children,
  show,
  opacity = "none",
}: {
  show?: boolean;
  opacity?: "none" | "partial" | number;
  children: ReactNode;
}) => {
  const css = useMemo(
    () => (isNumber(opacity) ? { opacity } : undefined),
    [opacity]
  );
  return (
    <div
      style={css}
      className={cx(
        styles.target,
        isString(opacity) && styles[opacity],
        show && styles.show
      )}
    >
      {children}
    </div>
  );
};

export const OnHover = { Trigger, Target };
