import { keys, map } from "lodash";
import { useCallback } from "react";
import { RecoilState, useRecoilCallback } from "recoil";
import { getRecoil } from "recoil-nexus";

import { EntityType } from "@api";

import { FetchResultsStoreAtom } from "@state/fetch-results";
import { EntityStores, getStore, useAllStores } from "@state/generic";
import { JobStoreAtom } from "@state/jobs";
import { PropertyDefStoreAtom } from "@state/properties";
import { RecentsAtom } from "@state/recents";
import { AppSpaceAtom } from "@state/spaces";
import { storage } from "@state/storage";

import { debug, pause } from "@utils/debug";
import { switchEnum } from "@utils/logic";

export function useClearStore() {
  const reset = useRecoilCallback(({ reset }) => (store: RecoilState<any>) => {
    reset(store);
  });
  return useCallback(
    (
      type: EntityType | "fetchResults" | "props" | "jobs" | "space" | "recents"
    ) => {
      try {
        switchEnum(type, {
          jobs: () => reset(JobStoreAtom),
          props: () => reset(PropertyDefStoreAtom),
          fetchResults: () => reset(FetchResultsStoreAtom),
          recents: () => reset(RecentsAtom),
          space: () => reset(AppSpaceAtom),
          else: () => {
            const Store = getStore(type as EntityType);
            reset(Store);
            reset(FetchResultsStoreAtom);
          },
        });
      } catch (err) {
        debug("Cant find store.", type);
      }
    },
    []
  );
}

export function useInspectStoresEffect() {
  const stores = useAllStores();
  return async () => {
    pause();
    return stores;
  };
}

export function useClearAllStores(logout: boolean = true) {
  const clearStore = useClearStore();
  return useCallback(() => {
    if (logout) {
      storage().clear();
    }
    map(keys(getRecoil(EntityStores)), (id) => clearStore(id as EntityType));
    clearStore("jobs");
    clearStore("props");
    clearStore("fetchResults");
    clearStore("recents");
    clearStore("space");
  }, [clearStore]);
}
