import { atomFamily } from "recoil";

import { ID, Pipeline } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type PipelineStoreState = StoreState<Pipeline>;

export const WorkspacePipelineStoreAtom = atomFamily<PipelineStoreState, ID>({
  key: "WorkspacePipelineStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "pipeline" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "pipeline")],
});

export const PipelineStoreAtom = WorkspaceWrappedAtom(
  "PipelineStoreAtom",
  WorkspacePipelineStoreAtom
);
