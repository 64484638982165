import { first } from "lodash";
import { useCallback } from "react";

import { WorkflowStep } from "@api";

import { useLazyPropertyValue } from "@state/properties";
import { useGetItemFromAnyStore } from "@state/generic";
import { useStepOwner } from "@state/workflow";

import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { Button } from "@ui/button";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon } from "@ui/icon";
import { useMutate } from "@ui/mutate";
import { OnHover } from "@ui/on-hover";
import { RelationIcon, RelationLabel, RelationText } from "@ui/relation-label";
import { SelectableListItem } from "@ui/selectable-items";
import { Text, TextSmall } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { EntityEngine } from "../fallback";
import { UIEngine } from "../types";
import { WorkflowStepIcon } from "./icon";

export { WorkflowStepIcon } from "./icon";

export const WorkflowStepEngine: UIEngine<WorkflowStep> = {
  asMenuItem: EntityEngine.asMenuItem as UIEngine<WorkflowStep>["asMenuItem"],
  asListCard: EntityEngine.asListCard as UIEngine<WorkflowStep>["asListCard"],

  asListItem: (props) => {
    const { item, selection, setSelection, className, onOpen } = props;
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const assigned = useStepOwner(item);

    const getItem = useGetItemFromAnyStore();
    const mutate = useMutate();
    const markComplete = useCallback(() => {
      mutate(
        asUpdate(
          item,
          asMutation({ field: "status", type: "status" }, { id: "FNS" })
        )
      );
      const work = when(first(item.refs?.created), (r) => getItem(r.id));

      if (work && work.source.type === "task") {
        mutate(
          asUpdate(
            work,
            asMutation({ field: "status", type: "status" }, { group: "done" })
          )
        );
      }
    }, [mutate]);

    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <OnHover.Trigger>
          <SelectableListItem className={cx(className)} {...props}>
            <SpaceBetween>
              <HStack fit="container">
                <Icon size="large" icon={<WorkflowStepIcon step={item} />} />
                <Text subtle={status.value.status?.group === "done"}>
                  {item.name}
                </Text>
              </HStack>

              {!!assigned && (
                <Tooltip
                  text={
                    <TextSmall>
                      <RelationText relation={assigned} />
                    </TextSmall>
                  }
                >
                  <RelationLabel relation={assigned} />
                </Tooltip>
              )}

              {item.action === "wait" &&
                status.value.status?.group !== "done" && (
                  <OnHover.Target>
                    <Button
                      variant="primary"
                      size="small"
                      onClick={withHandle(markComplete)}
                    >
                      Mark as complete
                    </Button>
                  </OnHover.Target>
                )}
            </SpaceBetween>
          </SelectableListItem>
        </OnHover.Trigger>
      </EntityContextMenu>
    );
  },

  // asListCard: ({ item, onOpen, ...rest }) => {
  //   const status = useLazyPropertyValue(item, {
  //     field: "status",
  //     type: "status",
  //   });

  //   return (
  //     <EntityContextMenu entity={item}>
  //       <ListCard
  //         padding="none"
  //         selectable={false}
  //         onClick={respectHandled(() => onOpen?.(item))}
  //       >
  //         <Container fit="container" stack="vertical" gap={10} size="half">
  //           <SpaceBetween align="flex-start">
  //             <VStack gap={0}>
  //               <SectionLabel primary>Workflow</SectionLabel>
  //               <TextLarge bold>{item.name || "Workflow"}</TextLarge>
  //             </VStack>

  //             <HStack fit="container" align="center" justify="flex-end">
  //               <StatusTag status={status.value.status || item?.status} />
  //               <Tooltip text="Started by">
  //                 <Icon icon={<RelationIcon relation={item?.createdBy} />} />
  //               </Tooltip>
  //             </HStack>
  //           </SpaceBetween>
  //           <WorkflowStepsBar workflow={item} status={status?.value.status} />
  //         </Container>
  //       </ListCard>
  //     </EntityContextMenu>
  //   );
  // },
};
