import { filter, find, flatMap, map, pick, some } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import {
  ConnectionRef,
  EntityType,
  Form,
  FormPropDef,
  ID,
  PropertyDef,
  PropertyMutation,
  PropertyRef,
  PropertyType,
} from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyProperties } from "@state/properties";
import { FormData, useFormFields } from "@state/form";
import {
  useEntitySource,
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useAddToRecents } from "@state/recents";
import { useEntityLabels } from "@state/settings";

import {
  ensureMany,
  justOne,
  move,
  OneOrMany,
  overlaps,
  pushDirty,
} from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useConst } from "@utils/hooks";
import { cid, maybeTypeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { isDefined, Maybe, maybeMap, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import {
  isAnyRelation,
  isPropertyDef,
  toFieldName,
  toPropertyTypeLabel,
} from "@utils/property-refs";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { Banner } from "@ui/banner";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { DropHighlight } from "@ui/drop-highlight";
import { EditableHeading } from "@ui/editable-heading";
import { EditableText } from "@ui/editable-text";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { useGenericDragDrop } from "@ui/generic-drag-drop";
import {
  AngleDownIcon,
  BoltAlt,
  BoxOpen,
  ClipboardNotes,
  Empty,
  Icon,
  iconFromString,
  InfoCircle,
  PlusAlt,
  PlusIcon,
  TrashAlt,
  UserIcon,
  WorkflowFilled,
} from "@ui/icon";
import { Field } from "@ui/input";
import { Label } from "@ui/label";
import { ListCard } from "@ui/list-card";
import { ListItem } from "@ui/list-item";
import { showError } from "@ui/notifications";
import { OnHover } from "@ui/on-hover";
import AppPage from "@ui/page/app-page";
import { Main, PageLayout } from "@ui/page-layout";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { RelationLabel } from "@ui/relation-label";
import { ColoredSection } from "@ui/section";
import {
  GlobalEntitySelect,
  LocationSelect,
  Option as SelectOption,
  Select,
  SelectProps,
  TemplateSelect,
} from "@ui/select";
import { EmojiSelect } from "@ui/select/emoji";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { PropertySelect, ScopedPropertySelect } from "@ui/select/property";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TemplateConfigure } from "@ui/template-configure";
import { Text, TextMedium } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { FormFields } from "./fields";

import styles from "./builder.module.css";

export const FormBuilderPage = ({ id }: { id: ID }) => {
  const form = useLazyEntity<"form">(id);
  const [page] = useRegisterPage(id, form);
  const [previewData, setPreviewData] = useState<FormData>({});
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, form?.id);

  // Add to recents
  useAddToRecents(id);

  if (!form) {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SpaceBetween fit="container" height="container">
              <Main className={cx(styles.main, styles.left)}>
                <VStack gap={60}>
                  <Container>
                    <EntityHeaderBar entity={form} />
                  </Container>
                  <FormBuilder form={form} />
                </VStack>
              </Main>

              <Divider direction="vertical" className={styles.divider} />

              <Main className={styles.main}>
                <Container
                  fit="container"
                  stack="vertical"
                  overflow="scroll"
                  height="container"
                >
                  <TextMedium subtle bold>
                    Form Preview
                  </TextMedium>

                  <Card width="container" height="content">
                    <Container stack="vertical" gap={20}>
                      <Label
                        size="large"
                        bold
                        icon={when(form.icon, iconFromString)}
                      >
                        {form.name}
                      </Label>

                      <Divider />

                      <FormFields
                        form={form}
                        data={previewData}
                        onChange={(changes) =>
                          setPreviewData((d) => ({ ...d, ...changes }))
                        }
                      />
                    </Container>
                  </Card>
                </Container>
              </Main>
            </SpaceBetween>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

export const FormBuilder = ({ form }: { form: Form }) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(form.id, pageId);
  const goTo = useGoTo();
  const nestedSource = useEntitySource(form.entity, form.source);
  const allProps = useLazyProperties(nestedSource);
  const fields = useFormFields(form);
  const existingBlacklist = useMemo(
    () =>
      pushDirty(
        map(form.fields, (f) => f.field),
        "location"
      ),
    [form.fields]
  );

  const handleAddField = useCallback(
    (prop: Maybe<PropertyRef & Partial<FormPropDef>>) => {
      if (!prop) {
        return;
      }

      // Already exists
      if (some(form.fields, (f) => f.field === prop.field)) {
        return;
      }

      const shouldConnect = some(allProps, (p) => p.field === prop.field);

      mutate(
        asMutation({ field: "fields", type: "properties" }, [
          ...(form.fields || []),
          {
            ...pick(prop, "field", "type"),
            // Connect when it's a known property on this entity type
            connections: shouldConnect
              ? [{ id: form?.useTemplate?.id, field: prop.field }]
              : [],
          },
        ])
      );
    },
    [form, mutate, allProps]
  );

  const handleAddFormField = useCallback(
    (type: PropertyTypeIsh) => {
      const field = `custom.${type}_${cid(4)}`;

      const prop = switchEnum<PropertyTypeIsh, FormPropDef>(type, {
        person: () => ({
          field,
          label: "Select someone...",
          type: "relation",
          options: { references: ["person"] },
        }),
        resource: () => ({
          field,
          label: "Attachments",
          type: "relations",
          options: { references: ["resource"] },
        }),
        else: () => ({ field, type: type as PropertyType }),
      });

      mutate(
        asMutation({ field: "fields", type: "properties" }, [
          ...(form.fields || []),
          prop,
        ])
      );
    },
    [handleAddField]
  );

  const handleRemoveField = useCallback(
    (prop: Maybe<PropertyRef>) => {
      if (!prop) {
        return;
      }

      mutate(
        asMutation(
          { field: "fields", type: "properties" },
          filter(form.fields, (f) => f.field !== prop.field)
        )
      );
    },
    [form, mutate]
  );

  const handleReorderFields = useCallback(
    (from: number, to: number) => {
      mutate(
        asMutation(
          { field: "fields", type: "properties" },
          move(form.fields || [], from, to)
        )
      );
    },
    [form.fields]
  );

  const handleChangeField = useCallback(
    (prop: PropertyRef, changes: Partial<FormPropDef>) => {
      mutate(
        asMutation(
          { field: "fields", type: "properties" },
          map(form.fields, (f) =>
            f.field === prop.field ? { ...f, ...changes } : f
          )
        )
      );
    },
    [form, mutate]
  );

  return (
    <Centered padding="horizontal" className={styles.centerd}>
      <VStack gap={30} fit="container">
        <VStack>
          <HStack gap={0}>
            <EmojiSelect
              emoji={form.icon || ""}
              onChange={(i) =>
                mutate(asMutation({ field: "icon", type: "text" }, i))
              }
            >
              <Button inset size="small" subtle>
                <Icon
                  size="large"
                  icon={when(form.icon, iconFromString) || ClipboardNotes}
                />
              </Button>
            </EmojiSelect>

            <EditableHeading
              key={form.id}
              text={form.name || ""}
              placeholder="What is this form for?"
              onChange={(text) => {
                when(text, (i) =>
                  mutate(asMutation({ field: "name", type: "text" }, i))
                );
              }}
            />
          </HStack>

          <SpaceBetween>
            <LabelledPropertyValueList entity={form} onChange={mutate} />
            <Button variant="primary" onClick={() => goTo(form)}>
              Use form
            </Button>
          </SpaceBetween>
        </VStack>

        <Divider />

        <VStack gap={10}>
          <VStack>
            {!!form.refs?.runWorkflow?.length && (
              <Banner>
                <Label icon={WorkflowFilled} subtle>
                  Workflow input variables are automatically added.
                </Label>
              </Banner>
            )}

            {maybeMap(fields, ([field, def], index) =>
              field.field !== "createdBy" ? (
                <FieldBuilder
                  key={field.field}
                  index={index}
                  field={field}
                  def={def}
                  form={form}
                  onChange={handleChangeField}
                  onRemove={handleRemoveField}
                  onReorder={handleReorderFields}
                />
              ) : undefined
            )}
          </VStack>

          <HStack gap={0}>
            <FormFieldSelect value={undefined} onChange={handleAddFormField}>
              <Button size="small" subtle icon={PlusAlt}>
                <Text subtle>New form field</Text>
              </Button>
            </FormFieldSelect>

            {!!form.entity && (
              <ScopedPropertySelect
                scope={form.source.scope}
                type={form.entity}
                value={undefined}
                createable={false}
                allowOtherTypes={true}
                blacklist={existingBlacklist}
                emptyOption={false}
                onChanged={handleAddField}
              >
                <Button size="small" subtle icon={PlusAlt}>
                  <Text subtle>Add existing field</Text>
                </Button>
              </ScopedPropertySelect>
            )}
          </HStack>
        </VStack>

        <FormSubmission form={form} onChange={mutate} />
      </VStack>
    </Centered>
  );
};

export default FormBuilderPage;

type PropertyTypeIsh = PropertyType | "person" | "resource";

type FormFieldSelectProps = {
  onChange?: Fn<PropertyTypeIsh, void>;
} & Omit<
  SelectProps,
  "options" | "onChange" | "toNewOption" | "toIcon" | "overrides"
>;

const ALLOWED_FORM_FIELDS: PropertyTypeIsh[] = [
  "text",
  "rich_text",
  "number",
  "date",
  "boolean",
  "person",
  "resource",
  // "select",
  // "multi_select",
];

const FormFieldSelect = ({
  onChange,
  children,
  ...rest
}: FormFieldSelectProps) => {
  const options = useMemo(
    () =>
      map(ALLOWED_FORM_FIELDS, (t) =>
        switchEnum<string, SelectOption>(t, {
          person: () => ({
            id: t,
            name: "Person",
            icon: UserIcon,
          }),
          resource: () => ({
            id: t,
            name: "Attachments",
            icon: BoxOpen,
          }),
          else: () => ({
            id: t,
            name: toPropertyTypeLabel(t as PropertyType),
            icon: <PropertyTypeIcon type={t as PropertyType} />,
          }),
        })
      ),
    []
  );

  return (
    <Select
      searchable={false}
      {...rest}
      options={options}
      value={undefined}
      portal={true}
      onChange={(v) => v && onChange?.(v.id as PropertyTypeIsh)}
    >
      {children}
    </Select>
  );
};

type FieldBuilderProps = {
  form: Form;
  field: FormPropDef;
  index: number;
  def: Maybe<PropertyDef>;
  onChange: (prop: PropertyRef, changes: Partial<FormPropDef>) => void;
  onRemove: Fn<Maybe<PropertyRef>, void>;
  onReorder: (from: number, to: number) => void;
};

const FieldBuilder = ({
  form,
  index,
  field,
  def,
  onChange,
  onRemove,
  onReorder,
}: FieldBuilderProps) => {
  const dragDropRef = useRef<HTMLLIElement>(null);
  const toEntityLabel = useEntityLabels(form.source.scope);
  const isWorkflowInput = useMemo(() => !!def && !isPropertyDef(def), [def]);

  const { dropping } = useGenericDragDrop({
    item: field,
    order: index,
    ref: dragDropRef,
    onReorder: (from, to) =>
      isDefined(to.order) && onReorder(from.order, to.order),
  });

  return (
    <ListCard selectable={false} ref={dragDropRef}>
      {dropping && <DropHighlight offset={-5} />}
      <ContextMenu
        actions={
          <>
            <ContextItem
              icon={TrashAlt}
              text="Remove"
              onClick={() => onRemove(field)}
            />
          </>
        }
      >
        <OnHover.Trigger>
          <SpaceBetween>
            <HStack align="flex-start">
              <Icon
                icon={
                  <PropertyTypeIcon
                    type={field.type}
                    field={field.field}
                    options={field.options}
                  />
                }
              />

              <VStack fit="content" gap={0}>
                <HStack>
                  <EditableText
                    text={field.label || toFieldName(def || field)}
                    disabled={isWorkflowInput}
                    className={styles.fieldLabel}
                    onChange={(t) => onChange(field, { label: t })}
                  />
                  {field.description === undefined && !isWorkflowInput && (
                    <OnHover.Target>
                      <Button
                        size="tiny"
                        subtle
                        icon={PlusAlt}
                        onClick={() =>
                          onChange(field, {
                            description: "",
                          })
                        }
                      >
                        <Text subtle>Add description</Text>
                      </Button>
                    </OnHover.Target>
                  )}
                </HStack>
                {field.description !== undefined && (
                  <EditableText
                    textSize="small"
                    disabled={isWorkflowInput}
                    text={field.description}
                    className={styles.fieldDesc}
                    placeholder="Add instructions"
                    onChange={(t) => onChange(field, { description: t })}
                  />
                )}
              </VStack>
            </HStack>

            {!!def && isWorkflowInput ? (
              <Tooltip text="This field is added automatically from the workflow inputs.">
                <Text subtle>
                  <HStack gap={4}>
                    <Icon icon={InfoCircle} size="small" />
                    Workflow
                  </HStack>
                </Text>
              </Tooltip>
            ) : !!def && isPropertyDef(def) ? (
              <Tooltip text={toFieldName(def)}>
                <Text subtle>
                  <HStack gap={4}>
                    <Icon icon={InfoCircle} size="small" />
                    {toEntityLabel(form.entity)} field{" "}
                  </HStack>
                </Text>
              </Tooltip>
            ) : (
              <Text subtle>Form field</Text>
            )}
          </SpaceBetween>
        </OnHover.Trigger>
      </ContextMenu>
    </ListCard>
  );
};

type FormSubmissionMode = "submit" | "create" | "workflow";

const FormSubmission = ({
  form,
  onChange,
}: {
  form: Form;
  onChange: Fn<OneOrMany<PropertyMutation<Form>>, void>;
}) => {
  const [mode, _setMode] = useState<FormSubmissionMode>(
    justOne(form.refs?.runWorkflow)
      ? "workflow"
      : form.entity
      ? "create"
      : "submit"
  );
  const modeOptions = useConst([
    { id: "submit", name: "do nothing", icon: Empty },
    { id: "create", name: "create work", icon: PlusIcon },
    { id: "workflow", name: "run workflow", icon: WorkflowFilled },
  ]);
  const modeSelected = useMemo(() => find(modeOptions, { id: mode }), [mode]);
  const setMode = useCallback(
    (mode: FormSubmissionMode) => {
      onChange([
        asMutation({ field: "refs.runWorkflow", type: "relation" }, undefined),
        asMutation({ field: "entity", type: "text" }, undefined),
        asMutation({ field: "useTemplate", type: "relation" }, undefined),
      ]);

      _setMode(mode);
    },
    [onChange]
  );

  const nestedSource = useEntitySource(form.entity, form.source);

  return (
    <VStack gap={10}>
      <HStack gap={4}>
        <Icon icon={BoltAlt} />
        <TextMedium>When this form is submitted</TextMedium>
        <Select
          value={modeSelected}
          options={modeOptions}
          onChange={(v) => v && setMode(v.id as "create" | "workflow")}
          searchable={false}
          clearable={false}
        >
          <Button variant="link" iconRight={AngleDownIcon}>
            {modeSelected?.name}
          </Button>
        </Select>
      </HStack>

      {mode === "create" && (
        <>
          <SpaceBetween fit="container" gap={10}>
            <Field label="Create" fit="container" style={{ width: "35%" }}>
              <EntityTypeSelect
                value={form.entity}
                portal
                scope={form.source.scope}
                placeholder="Select work type"
                plural={false}
                onChange={(entity) =>
                  onChange(
                    asMutation({ field: "entity", type: "text" }, entity)
                  )
                }
              />
            </Field>
            <Field label="In location" fit="container">
              <LocationSelect
                className={{ trigger: styles.control }}
                location={form.inLocation || form.source.scope}
                onChange={(l) =>
                  onChange(asMutation({ field: "inLocation", type: "text" }, l))
                }
                fit="container"
                showOpen={true}
                variant="full"
                showCaret={true}
              />
            </Field>
          </SpaceBetween>

          {nestedSource && (
            <Field label="Using template" fit="container">
              <TemplateConfigure
                template={form.useTemplate}
                overrides={form.overrides}
                source={nestedSource}
                onChange={onChange}
              />
            </Field>
          )}

          <FormConnections form={form} onChange={onChange} />
        </>
      )}

      {mode === "workflow" && (
        <VStack fit="container">
          <Field>
            <TemplateSelect
              className={{ trigger: styles.control }}
              placeholder="Select workflow"
              scope={form?.source.scope}
              type="workflow"
              allowed={["workflow"]}
              templates={true}
              value={justOne(form.refs?.runWorkflow)}
              onChange={(t) =>
                onChange([
                  asMutation(
                    { field: "refs.runWorkflow", type: "relation" },
                    t
                  ),
                  asMutation(
                    { field: "useTemplate", type: "relation" },
                    undefined
                  ),
                  asMutation({ field: "entity", type: "text" }, undefined),
                  asMutation({ field: "inLocation", type: "text" }, undefined),
                ])
              }
            />
          </Field>
        </VStack>
      )}
    </VStack>
  );
};

type Connection = [ConnectionRef, FormPropDef, Maybe<PropertyDef>];

const FormConnections = ({
  form,
  onChange,
}: {
  form: Form;
  onChange: Fn<OneOrMany<PropertyMutation<Form>>, void>;
}) => {
  const fields = useFormFields(form);
  const connections = useMemo(
    () =>
      flatMap(fields, ([f, d]) =>
        map(f.connections || [], (c) => [c, f, d] as const)
      ),
    [fields]
  );
  const toLabel = useEntityLabels(form.source.scope);

  const handleRemoveConnection = useCallback(
    ([connection, field]: Connection) => {
      if (!field || !connection) {
        return;
      }

      onChange(
        asMutation(
          { field: "fields", type: "properties" },
          map(fields, ([f, d]) =>
            f.field === field.field
              ? {
                  ...f,
                  connections: filter(
                    f.connections || [],
                    (c) =>
                      c.id !== connection.id || c.field !== connection.field
                  ),
                }
              : f
          )
        )
      );
    },
    [onChange]
  );

  return (
    <CollapsibleSection title="Connections" defaultOpen={false}>
      <VStack gap={10}>
        <Text subtle>
          Set properties in your template with submitted values. All fields will
          be shown in a table.
        </Text>

        <VStack gap={2}>
          {map(connections, ([connection, field, def]) => (
            <ContextMenu
              key={`${field?.field}-${connection?.field}`}
              actions={
                <ContextItem
                  icon={TrashAlt}
                  text="Remove"
                  onClick={() =>
                    handleRemoveConnection([connection, field, def])
                  }
                />
              }
            >
              <ListItem>
                <SpaceBetween>
                  <Label
                    className={styles.connectionCell}
                    icon={<PropertyTypeIcon {...field} />}
                    fit="content"
                  >
                    {field.label || toFieldName(def || field)}
                  </Label>

                  <Text subtle>→</Text>

                  <HStack className={styles.connectionCell} justify="flex-end">
                    {connection.id ? (
                      <RelationLabel
                        fit="content"
                        relation={{ id: connection.id }}
                      />
                    ) : (
                      <Label fit="content">
                        Created {toLabel(form.entity, { case: "lower" })}
                      </Label>
                    )}
                    <Text subtle>
                      ({" "}
                      {toFieldName(
                        def ||
                          when(connection?.field, (f) => ({
                            field: f,
                            type: field.type,
                          })) ||
                          field
                      )}{" "}
                      )
                    </Text>
                  </HStack>
                </SpaceBetween>
              </ListItem>
            </ContextMenu>
          ))}
        </VStack>

        <AddNewConnection form={form} fields={fields} onChange={onChange} />
      </VStack>
    </CollapsibleSection>
  );
};

type EditingConnection = [
  Partial<ConnectionRef>,
  FormPropDef,
  Maybe<PropertyDef>
];

const AddNewConnection = ({
  form,
  fields,
  onChange,
}: {
  form: Form;
  fields: [FormPropDef, Maybe<PropertyDef>][];
  onChange: Fn<OneOrMany<PropertyMutation<Form>>, void>;
}) => {
  const template = useLazyEntity(form.useTemplate?.id);
  const [newConnection, setNewConnection] =
    useState<Maybe<EditingConnection>>();
  const [connection, field, def] = newConnection || [];
  const targetSource = useMemo(
    () =>
      when(
        connection?.id
          ? maybeTypeFromId<EntityType>(connection?.id)
          : form.entity,
        (type) => ({ type: type, scope: form.source.scope })
      ),
    [connection?.id, form.source.scope]
  );
  const targetFields = useLazyProperties(targetSource);
  const allowedFields = useMemo(
    () =>
      filter(
        targetFields,
        (f) =>
          (f.type === field?.type ||
            !!(isAnyRelation(f) && when(field, isAnyRelation))) &&
          !f.readonly &&
          (!isAnyRelation(f) ||
            overlaps(
              ensureMany(f.options?.references),
              ensureMany((def?.options || field?.options)?.references)
            ))
      ),
    [
      targetFields,
      field?.type,
      def?.options?.references,
      field?.options?.references,
    ]
  );

  const templateSource = useNestedSource(template);
  const formFields = useMemo(() => [...map(fields, ([f]) => f)], [fields]);

  const handleAddConnection = useCallback(
    ([connection, field]: EditingConnection) => {
      if (!field || !connection || !connection.field) {
        showError("Fill out all fields.");
        return;
      }

      onChange(
        asMutation(
          { field: "fields", type: "properties" },
          map(fields, ([f, d]) =>
            f.field === field.field
              ? {
                  ...f,
                  connections: [...(f.connections || []), connection],
                }
              : f
          )
        )
      );

      setNewConnection(undefined);
    },
    [onChange]
  );

  const handleNewConnection = useCallback(
    (r: Maybe<PropertyRef>) => {
      if (!r) {
        return;
      }

      const [field, def] = find(fields, ([f]) => f.field === r?.field) || [];

      if (!field) {
        return;
      }

      setNewConnection([
        { id: form?.useTemplate?.id, field: def ? def.field : undefined },
        field,
        def,
      ]);
    },
    [formFields, form?.useTemplate?.id]
  );

  if (!connection || !field) {
    return (
      <PropertySelect
        value={undefined}
        createable={false}
        emptyOption={false}
        onChanged={handleNewConnection}
        options={formFields}
      >
        <Button size="small" subtle icon={PlusAlt}>
          Add connection
        </Button>
      </PropertySelect>
    );
  }

  return (
    <ColoredSection>
      <Field label="Connect form field">
        <PropertySelect
          value={field}
          createable={false}
          caret={true}
          emptyOption={false}
          onChanged={handleNewConnection}
          options={formFields}
        />
      </Field>

      <SpaceBetween>
        {!!form?.useTemplate && (
          <Field label="To work inside template">
            <GlobalEntitySelect
              value={when(connection?.id, (id) => ({ id }))}
              type={form.entity}
              allowed={["task", "event", "outcome", "content", "request"]}
              showTeam={false}
              showOtherTeams={false}
              scope={templateSource?.scope}
              onChange={(r) =>
                r &&
                setNewConnection([{ id: r.id, field: field.field }, field, def])
              }
              templates={true}
              placeholder="Choose destination..."
            />
          </Field>
        )}
        <Field label="Field">
          <PropertySelect
            value={
              connection?.field && field?.type
                ? { field: connection?.field, type: field?.type }
                : undefined
            }
            options={allowedFields}
            onChanged={(r) =>
              r &&
              setNewConnection([
                { id: connection?.id, field: r.field },
                field,
                def,
              ])
            }
            closeOnSelect={true}
            placeholder="Choose property..."
          />
        </Field>
      </SpaceBetween>

      <SpaceBetween>
        <span></span>
        <Button
          variant="primary"
          size="small"
          disabled={!newConnection}
          onClick={() => newConnection && handleAddConnection(newConnection)}
        >
          Add connection
        </Button>
      </SpaceBetween>
    </ColoredSection>
  );
};
