import { filter, find, first, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";

import { addToWorkspace, EntityType, Person, Ref } from "@api";

import { useLazyProperties } from "@state/properties";
import { addViewResultsIds } from "@state/fetch-results";
import { getStore, useCreateFromObject, useSource } from "@state/generic";
import { mergeItems } from "@state/store";
import { toTemplateViewId, ViewFetchResultsAtom } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { replace } from "@utils/array";
import { isEmail } from "@utils/email";
import { Fn } from "@utils/fn";
import { asValue } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { TextInput } from "@ui/input";
import { CheckMenuItem } from "@ui/menu-item";
import { showError } from "@ui/notifications";
import { PropertyValue } from "@ui/property-value";
import { Text } from "@ui/text";

interface Props {
  defaults: Partial<Omit<Person, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const PersonCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wId = useActiveWorkspaceId();
  const scope = useMemo(() => wId, []);
  const source = useSource("person", scope);
  const create = useCreateFromObject("person", scope);
  const [loading, setLoading] = useState(false);
  const [invite, setInvite] = useState(false);
  const setStore = useSetRecoilState(getStore("person"));
  const setViewResults = useSetRecoilState(
    ViewFetchResultsAtom(toTemplateViewId("all-people", { parent: wId }))
  );
  const [people, setPeople] = useState<Partial<Person>[]>(() => [
    { ...defaults, role: { id: "member" } },
  ]);
  const props = useLazyProperties<EntityType>({ type: "person", scope });
  const roleProp = useMemo(
    () => first(filter(props, { field: "role" })),
    [props]
  );

  const handlePaste = useCallback((e: React.ClipboardEvent) => {
    const text = e.clipboardData.getData("text");
    // Split new line, commas or spaces
    const emails = text.split(/[\n, ]/);
    if (emails.length > 1) {
      const newPeople = map(emails, (email) => ({
        ...defaults,
        email,
        role: { id: "member" },
      }));
      setPeople((people) =>
        filter([...people, ...newPeople], (p) => !!p.email)
      );
      e.preventDefault();
    }
  }, []);

  const onCreate = useCallback(async () => {
    if (!create) {
      return showError("Not ready.");
    }

    const invalid = find(people, (p) => !!p.email && !isEmail(p.email));

    if (invalid) {
      return showError(`Invalid email ${invalid.email}`);
    }

    const cleaned = filter(people, (p) => !!p.email && isEmail(p.email));

    if (!cleaned.length) {
      return showError("Enter a valid email.");
    }

    setLoading(true);

    setPeople(cleaned);

    const saved = await addToWorkspace(people);

    if (saved) {
      setStore(mergeItems(saved));
      setViewResults(addViewResultsIds(map(saved, "id")));
      onSaved?.(saved?.[0]);
    } else {
      onCancel?.();
    }
    setLoading(false);
  }, [create, people, onSaved]);

  return (
    <DialogSplit
      title="Add Team"
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>Add teammates to this workspace.</Text>
          <EducationTip tip="We won't notify people by default so you can quiety set things up." />
        </SpaceBetween>
      }
      actions={
        <SpaceBetween>
          <HStack>
            <CheckMenuItem
              checked={invite}
              onChecked={setInvite}
              text="Notify via email"
            />
          </HStack>

          <HStack>
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate} loading={loading}>
              Add people
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            {map(people, (person, i) => (
              <SpaceBetween>
                <TextInput
                  value={person.email || ""}
                  onChange={(t) =>
                    setPeople(replace(people, i, { ...person, email: t }))
                  }
                  isValid={isEmail}
                  updateOn="change"
                  autoFocus={!person.email}
                  placeholder="Email..."
                  onPaste={handlePaste}
                />

                <HStack justify="flex-end">
                  <PropertyValue
                    valueRef={{
                      type: "select",
                      field: "role",
                      value: asValue("select", person.role),
                      def: roleProp,
                    }}
                    onChange={(v) =>
                      setPeople(
                        replace(people, i, { ...person, role: v.select })
                      )
                    }
                    source={source}
                  />
                </HStack>
              </SpaceBetween>
            ))}

            <Button
              onClick={() =>
                setPeople([...people, { ...defaults, role: { id: "member" } }])
              }
              variant="secondary"
              size="small"
              subtle
              icon={PlusIcon}
            >
              <Text subtle>Add more</Text>
            </Button>
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
