import { subWeeks } from "date-fns";
import { first, map, orderBy } from "lodash";
import { useEffect, useMemo } from "react";

import { Meeting } from "@api";

import { useLazyPropertyDef } from "@state/properties";
import { useLazyFetchResults } from "@state/fetch-results";
import { useLazyEntities, useLazyQuery, useUpdateEntity } from "@state/generic";

import { omitEmpty } from "@utils/array";
import { useISODate } from "@utils/date-fp";
import { isHumanId, isLocalID, isTemplateId, maybeTypeFromId } from "@utils/id";
import { extractTractionIds } from "@utils/link";
import { equalsAny, ifDo } from "@utils/logic";
import { Maybe, maybeMap } from "@utils/maybe";
import { now } from "@utils/now";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { inflateProperty, toRef } from "@utils/property-refs";
import { withoutRefs } from "@utils/relation-ref";
import { toHtml } from "@utils/rich-text";
import { useTick } from "@utils/time";

import { useMutate } from "@ui/mutate";

import { isInProgress } from "./utils";

const isRelatable = (id: string) =>
  !equalsAny(maybeTypeFromId(id), [
    "schedule",
    "view",
    "agenda",
    "team",
    "person",
    "workspace",
  ]);

export const useExtractRelatedWork = (entity: Meeting) => {
  const agendas = useLazyEntities<"agenda">(entity?.refs.agendas || []);
  const combinedHTML = useMemo(
    () => maybeMap(agendas, (a) => toHtml(a.body)).join(" "),
    [agendas]
  );

  return useMemo(() => {
    const allIds = extractTractionIds(combinedHTML);
    return maybeMap(allIds, (r) =>
      isHumanId(r) && isRelatable(r) && !isLocalID(r) && !isTemplateId(r)
        ? r
        : undefined
    );
  }, [combinedHTML]);
};

export const useAutoLinkRelated = (meeting: Meeting, pageId?: string) => {
  const relatedWork = useExtractRelatedWork(meeting);
  const mutate = useUpdateEntity(meeting.id, pageId);

  useEffect(() => {
    const relatedRefs = maybeMap(relatedWork, toRef);
    const newRefs = withoutRefs(relatedRefs, meeting.refs.related || []);
    mutate(
      asAppendMutation({ field: "refs.related", type: "relations" }, newRefs)
    );
  }, [relatedWork]);
};

export const useMeetingAgendas = (meeting: Meeting) => {
  const _agendas = useLazyEntities<"agenda">(meeting.refs.agendas || []);
  return useMemo(() => orderBy(_agendas, (a) => a.order), [_agendas]);
};

export const usePastMeetings = (meeting: Maybe<Meeting>) => {
  return useLazyQuery(
    "meeting",
    useMemo(
      () => ({
        and: [
          {
            field: "name",
            type: "text",
            op: "equals",
            value: { text: meeting?.name },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: meeting?.id },
          },
          {
            field: "start",
            type: "date",
            op: "after",
            value: {
              date: useISODate(meeting?.start, (s) => subWeeks(s || now(), 6)),
            },
          },
          {
            field: "start",
            type: "date",
            op: "before",
            value: { date: meeting?.start },
          },
        ],
      }),
      [meeting?.start]
    )
  );
};

export const useRelatedActions = (meeting: Meeting, showDone?: boolean) => {
  const past = usePastMeetings(meeting);
  const allMeetings = useMemo(
    () => map([meeting, ...past], (m) => toRef(m)),
    [meeting?.id, past]
  );
  return useLazyFetchResults(
    `meeting-actions-${meeting.id}`,
    "action",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "refs.meeting",
            type: "relations",
            op: "equals",
            value: { relations: allMeetings },
          },

          ifDo(!showDone, () => ({
            field: "status",
            type: "status",
            op: "does_not_equal",
            value: { status: { group: "done" } },
          })),

          {
            field: "createdAt",
            type: "date",
            op: "after",
            value: { formula: "=today-90d" },
          },
        ]),
      }),
      [allMeetings, showDone]
    )
  );
};

export const useAutoStartMeeting = (meeting: Meeting) => {
  const mutate = useMutate();
  const agendas = useMeetingAgendas(meeting);
  const running = useMemo(
    () => isInProgress(meeting),
    [meeting, useTick("10 seconds")]
  );
  const statusProp = useLazyPropertyDef(meeting.source, {
    field: "status",
    type: "status",
  });

  // Auto start meetings when host pane is opened and meeting is in progress from times
  useEffect(() => {
    const inflated = inflateProperty(meeting, statusProp).status;
    if (running && inflated?.group === "not-started") {
      mutate(
        asUpdate(meeting, [
          asMutation({ field: "status", type: "status" }, { id: "INP" }), // From meeting/repo
          asMutation(
            { field: "refs.current", type: "relation" },
            toRef(first(agendas))
          ),
        ])
      );
    }
  }, [running, meeting.status]);
};
